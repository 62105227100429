import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PrivacyPolicyItem, { IPrivacyPolicyItem } from './PrivacyPolicyItem';
import PrivacyPolicyScreen from './Style';

const PrivacyPolicy: FC = () => {
    const { t } = useTranslation();
    const topics = {
        topic1: 3,
        topic2: 2,
        topic3: 3,
        topic4: 9
    };

    const retrieveCount = (currentIndex: number) => {
        let result = 0;
        Object.entries(topics).forEach((item, index) => {
            if (index < currentIndex) {
                result += item[1];
            }
        });
        return result;
    };

    const privacyPolicyItems: IPrivacyPolicyItem[] = [
        {
            startPoint: 17,
            nrOfParagraphs: 10
        },
        {
            startPoint: 27,
            nrOfParagraphs: 2
        },
        {
            startPoint: 29,
            nrOfParagraphs: 7
        }
    ];

    return (
        <>
            <PrivacyPolicyScreen>
                <div className="title">
                    <span>{t('screenLabels.privacyPolicy')}</span>
                </div>

                {[...Array(5)].map((item, index) => (
                    <div key={item}>
                        <h3 className="topic">{t(`privacyPolicy.topic${index + 1}`)}</h3>
                        <div className="content">
                            <span>
                                {t(`privacyPolicy.description${index + 1}`)}
                            </span>
                        </div>
                    </div>
                ))}

                <PrivacyPolicyItem privacyPolicyItem={
                    {
                        startPoint: 0,
                        nrOfParagraphs: 6,
                        titleLabel: 'privacyPolicy.subTopic',
                        descriptionLabel: 'privacyPolicy.subDescription'
                    }
                }
                />

                <div>
                    <div className="content">
                        {Object.entries(topics).map((topic, indexI) => (
                            <div key={topic[1]}>
                                <h4 className="subTopic">{t(`privacyPolicy.subTopic${(indexI + 1) + 6}`)}</h4>
                                {indexI === 3 && <span>{t('privacyPolicy.subTopicDescription9')}</span>}
                                {[...Array(topic[1])].map((item, index) => (
                                    <div key={item}>
                                        <h5 className="paragraph">{t(`privacyPolicy.paragraph${indexI !== 0
                                            ? (index + 1) + retrieveCount(indexI)
                                            : (index + 1)}`)}
                                        </h5>
                                        <span className="paragraphDescription">{t(`privacyPolicy.paragraphDescription${indexI !== 0
                                            ? (index + 1) + retrieveCount(indexI)
                                            : (index + 1)}`)}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )
                        )}
                    </div>
                </div>

                {privacyPolicyItems
                    .map((privacyPolicyItem) => (
                        <PrivacyPolicyItem
                            privacyPolicyItem={privacyPolicyItem}
                        />
                    ))}

            </PrivacyPolicyScreen>
        </>
    );
};

export default PrivacyPolicy;
