/* eslint-disable no-unused-vars */

import { ResourceStatusEnum } from '../Protocol/IHttpInterface';
import { IHttpError } from '../Service/IDocumentService';
import { IError } from '../Utils/Constants/Errors';

interface IChallengeManager {
    initChallengeManager(parameters: IChallengeProps): void;
    startChallenge(): Promise<any>;
    processFrame(): number;
    verifyChallenge(): Promise<any>;
    retrieveCapturedSelfie(): string;
    clearCapturedFrames(): void;
    processChallengeError(errorObject: IHttpError & number): IError & IHttpError;
    getCapturedFrames(): IFEFrame[];
    isFaceDetectorReady(): ResourceStatusEnum;
    setFaceDetectorReady(value: ResourceStatusEnum): void;
    verifyCustomer(picture: string, type: PictureSideEnum): Promise<any>;
    saveCapturedFrame(frame: IFEFrame): number;
}

export enum PictureSideEnum {
    FRONTAL = 'FRONTAL',
    SMILE = 'SMILE',
    ROTATED = 'ROTATED',
    UNKNOWN = 'UNKNOWN'
}

export interface IFEFrame {
    frame: string;
    timestamp: number;
}

export interface IChallengeProps {
    videoElement: HTMLVideoElement;
    invisibleCanvas: HTMLCanvasElement;
}

export default IChallengeManager;
