import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

interface IProps {
    emptyFeedbackColor: string;
    averageFeedbackColor: string;
    satisfiedFeedbackColor: string;
}

const StarsRatingComponent = styled.div<IProps>`
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    width: inherit;

    .star {
        margin: auto 1.1vw;
        font-size: 16vw;
        background-color: transparent;
    }

    .empty-star {
        color: ${({ emptyFeedbackColor }) => emptyFeedbackColor};
    }

    .average-star {
        color: ${({ averageFeedbackColor }) => averageFeedbackColor};
    }

    .satisfied-star {
        color: ${({ satisfiedFeedbackColor }) => satisfiedFeedbackColor};
    }
}
`;

export default StarsRatingComponent;
