import styled from 'styled-components';
import Pixel2Rem from '../../../../Utils/Pixel2Rem';
import { themeProvider } from '../../../Theme/ThemeProvider';

interface IProps {
    videoWidth: number;
    videoHeight: number;
}

const ChallengeWrapper = styled.div<IProps>`
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: hidden;

    .detection-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 300;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #000;
        overflow-x: auto;
        overflow-y: hidden;


        .detection-wrapper__back-icon {
            position: absolute;
            height: ${Pixel2Rem(24)};
            width: ${Pixel2Rem(24)};
            top: 0;
            left: 0;
            margin: ${Pixel2Rem(24)} 0 0 ${Pixel2Rem(24)};
            z-index: 1000;
        }

        .detection-wrapper-instructions {
            position: absolute;
            display: flex;
            align-items: start;
            justify-content: center;
            left: 0;
            right: 0;
            // top: 95%;
            bottom: 77%;
            padding: ${Pixel2Rem(14)};
            color: ${themeProvider.darkGrey};
            font-family: ${themeProvider.fontFamily};
            font-size: ${Pixel2Rem(15)};
            letter-spacing: 0;
            line-height: ${Pixel2Rem(20)};
            background-color: ${themeProvider.backgroundColor};
            border-radius: ${Pixel2Rem(6)};
            margin: -14vh auto auto auto;
            width: 75vw;
            min-height: ${Pixel2Rem(30)};

            .detection-wrapper-instructions__icon {
                flex-grow: 1;
                margin-right: ${Pixel2Rem(10)};
                height: ${Pixel2Rem(24)};
                width: ${Pixel2Rem(24)};
            }

            .detection-wrapper-instructions__description {
                flex-grow: 100;
            }

            .detection-wrapper-instructions__center-face {
                margin-top: ${Pixel2Rem(2.5)};
            }
        }

        .detection-wrapper__check-icon {
            position: absolute;
            z-index: 1;
            width: ${Pixel2Rem(32.5)};
            height: ${Pixel2Rem(32.5)};
        }

        .detection-wrapper__helper-icon {
            position: absolute;
            z-index: 30;
            width: ${Pixel2Rem(55)};
            height: ${Pixel2Rem(55)};
        }

    }

    #webcamVideo {
        position: absolute;
        width: 100%;
        max-width: ${(props) => Pixel2Rem(props.videoWidth)};
    }

    #overlayCanvas {
        position: absolute;
        width: 100%;
        max-width: ${(props) => Pixel2Rem(props.videoWidth)};
    }

    .rotate {
        transform: rotateY(180deg);
    }
`;

export default ChallengeWrapper;
