import HttpInterfaceMock from '../Tests/Mocks/HttpInterfaceMock';
import HttpInterfaceProduction from './HttpInterface';

const REACT_APP_FORCE_PROD_HTTP_IF = true; // INFO: tsheldon decides if we want to use PROD HTTP or not
export const USING_MOCKS = process.env.NODE_ENV === 'development' && !REACT_APP_FORCE_PROD_HTTP_IF;
const exportedInterfaceModule = USING_MOCKS
    ? HttpInterfaceMock
    : HttpInterfaceProduction;

export default exportedInterfaceModule;
