import i18next from 'i18next';
import {
    FC, useEffect, useMemo, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import ISessionManager from '../../../../Business/ISessionManager';
import Constants from '../../../../Utils/Constants';
import { ERRORS_DESCRIPTION, ERROR_CODES } from '../../../../Utils/Constants/Errors';
import MARKET_CONFIGURATIONS from '../../../../Utils/Constants/MarketConfigurations';
import DI from '../../../../Utils/DI';
import Utils from '../../../../Utils/Utils';
import Button from '../../../Components/Button/Button';
import Input from '../../../Components/Input/Input';
import Loader from '../../../Components/Loader/Loader';
import Modal from '../../../Components/Modal/Modal';
import ResultScreen from '../../../Components/ResultScreen/ResultScreen';
import { IFormStepProps } from '../../PageWrapper/PageWrapper';
import IAgentLoginData, { ITempError } from '../agent-login.types';
import ValidateAgentScreen from './Style';

const ValidateAgent: FC<IFormStepProps> = ({ nextStepHandler }) => {
    const { t } = useTranslation();

    const [agentData, setAgentData] = useState<IAgentLoginData>({
        operatorId: {
            input: '',
            isValid: true,
            type: 'text',
            maxLength: MARKET_CONFIGURATIONS.LENGTHS.OPERATOR_ID.MAX,
            minLength: MARKET_CONFIGURATIONS.LENGTHS.OPERATOR_ID.MIN,
            placeHolder: t('agentLogin.operatorId.operatorId')
        },
        pin: {
            input: '',
            isValid: true,
            isLabelVisible: false,
            type: 'password',
            maxLength: MARKET_CONFIGURATIONS.LENGTHS.PIN.MAX,
            minLength: MARKET_CONFIGURATIONS.LENGTHS.PIN.MIN,
            inputMode: 'tel',
            placeHolder: t('agentLogin.operatorId.pin')
        }
    });

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const sessionManager: ISessionManager = DI.get('SessionManager');

    const formIsValid = Object.entries(agentData).every(
        ([, value]) => Utils.validateFieldLength({
            value: value.input, max: value.maxLength, min: value.minLength
        })
    );

    const [error, setError] = useState<ITempError>({
        hasError: false,
        errorCode: NaN
    });

    const errorTitle: string = useMemo(() => ERRORS_DESCRIPTION[error.errorCode]?.title
    || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title, [error]);

    const errorDescription: string = useMemo(() => ERRORS_DESCRIPTION[error.errorCode]?.description
    || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description, [error]);

    const handleChange = (event: any) => {
        const { value, name } = event.target;
        const cloneName = name as keyof IAgentLoginData;

        if (
            String(value).length <= agentData[cloneName].maxLength
            && ((/^[A-Za-z0-9]*$/.test(value) && cloneName === 'operatorId')
            || (cloneName === 'pin' && Number.isInteger(Number(value))))
        ) {
            setAgentData((prev) => ({
                ...prev,
                [name]: {
                    ...prev[cloneName],
                    isLabelVisible: true,
                    input: value
                }
            }));
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setIsLoading(true);
        sessionManager.validateAgent({
            pin: String(agentData.pin.input),
            operatorId: String(agentData.operatorId.input),
            language: i18next.language.toLowerCase()
        })
            .then(() => {
                nextStepHandler({
                    ...Constants.FORM_STEPS.VALIDATE_OTP,
                    IS_NOT_VALIDATION_STEP: true,
                    PROPS: {
                        title: t('validatePhoneNumber.validateSelfMsisdn'),
                        description: t('validatePhoneNumber.sentCode')
                    }
                });
            })
            .catch((errorCode) => {
                setIsLoading(false);
                if (errorCode === ERROR_CODES.VALIDATE_AGENT.INCORRECT_PIN) {
                    setShowModal(true);
                    setError(() => ({ hasError: false, errorCode }));
                    return;
                }
                setError({ hasError: true, errorCode });
            });
    };

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef && inputRef.current) {
            if (showModal) {
                inputRef.current.blur();
            } else {
                inputRef.current.focus();
            }
        }
    }, [showModal]);

    return (
        <>
            {isLoading && <Loader description={t('general.justAMoment')} />}
            {!isLoading && !error.hasError && (
                <ValidateAgentScreen onSubmit={handleSubmit}>
                    <div className="operator-id-wrapper">
                        <h1 className="operator-id-wrapper__title">{t('agentLogin.getStarted')}</h1>
                        <span className="operator-id-wrapper__description">{t('agentLogin.operatorId.enterOperatorId')}</span>

                        {Object.entries(agentData).map(([key, value], index) => (
                            <div
                                key={key}
                                className="operator-id-wrapper__input"
                                onFocus={() => {
                                    const cloneKey = key as keyof IAgentLoginData;
                                    setAgentData((prev) => ({
                                        ...prev,
                                        [key]: {
                                            ...prev[cloneKey],
                                            isLabelVisible: true
                                        }
                                    }));
                                }}
                                onBlur={() => {
                                    const cloneKey = key as keyof IAgentLoginData;
                                    setAgentData((prev) => ({
                                        ...prev,
                                        [key]: {
                                            ...prev[cloneKey],
                                            isLabelVisible: String(value.input).length !== 0
                                        }
                                    }));
                                }}
                            >
                                <Input
                                    data={value}
                                    handleChange={handleChange}
                                    ref={index === 0 ? inputRef : null}
                                    type={value.type}
                                    id={key}
                                    inputLabel={t(`agentLogin.operatorId.${key}`)}
                                    grow={1}
                                    inputMode={value.inputMode}
                                    maxLength={value.maxLength}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="footer">
                        <div className="footer__button">
                            <Button
                                action={() => {}}
                                mode="normal"
                                type="submit"
                                disabled={!formIsValid}
                                id="continue-button"
                            >{t('general.continue')}
                            </Button>
                        </div>
                    </div>
                </ValidateAgentScreen>
            )}

            {error.hasError && !isLoading && (
                <ResultScreen
                    title={t(`${errorTitle}`)}
                    description={t(`${errorDescription}`)}
                    actionText={t('general.tryAgain')}
                    actionFn={() => setError((prev) => ({ ...prev, hasError: false }))}
                    showActionButton
                />
            )}

            <Modal
                onClose={() => {
                    setShowModal(false);
                    setAgentData((prev) => ({
                        ...prev,
                        pin: { ...prev.pin, input: '', isLabelVisible: false },
                        operatorId: { ...prev.operatorId, input: '' }
                    }));
                }}
                open={showModal}
                title={t(`${errorTitle}`)}
                description={t(`${errorDescription}`)}
                fullScreen
            />
        </>
    );
};

export default ValidateAgent;
