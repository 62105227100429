import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DrivingLicenseBackImage from '../../Assets/images/driving_license_back.png';
import TakeDocumentPhoto, { ITakeDocumentPhotoInstructions } from '../../Components/TakeDocumentPhoto/TakeDocumentPhoto';
import DI from '../../../Utils/DI';
import Constants, { DOCUMENT_ORIENTATION, DOCUMENT_TYPES } from '../../../Utils/Constants';
import { IFormStepProps } from '../PageWrapper/PageWrapper';
import IChallengeManager from '../../../Business/IChallengeManager';
import IDocumentManager from '../../../Business/IDocumentManager';
import IUserManager from '../../../Business/UserManager/IUserManager';
import useGaEventTracker from '../../Hooks/useGaEventTracker';
import IAnalyticsManager from '../../../Business/IAnalyticsManager';
import useStepContext from '../../Hooks/useStepContext';
import Utils from '../../../Utils/Utils';
import ISessionManager from '../../../Business/ISessionManager';

const DrivingLicenseBack: FC<IFormStepProps> = ({ nextStepHandler }) => {
    const { t } = useTranslation();

    const documentManager: IDocumentManager = DI.get('DocumentManager');
    const userManager: IUserManager = DI.get('UserManager');
    const challengeManager: IChallengeManager = DI.get('ChallengeManager');
    const { eventTracker } = useGaEventTracker();
    const analyticsManager: IAnalyticsManager = DI.get('AnalyticsManager');
    const { currentFormStep } = useStepContext();
    const takeDocumentPhotoInstructions: ITakeDocumentPhotoInstructions = {
        key: 'drivingLicenseBack',
        instructionsLength: 3
    };

    const { screenTracker } = useGaEventTracker();
    const sessionManager: ISessionManager = DI.get('SessionManager');
    const isAgentLogged: boolean | undefined = useMemo(() => sessionManager.getSession()?.getIsValid(), []);

    const onChangeFunction = (file: File) => {
        documentManager.processDocumentData(
            String(userManager.getMsisdn()),
            file,
            DOCUMENT_TYPES.DRIVING_LICENSE,
            DOCUMENT_ORIENTATION.BACK
        )
            .then(
                () => {
                    if (challengeManager.retrieveCapturedSelfie()) {
                        screenTracker({ screen: '6.REVIEW_DETAILS', screenClass: 'GENERAL_FORM' });
                        nextStepHandler(Constants.FORM_STEPS.REVIEW_DRIVING_LICENSE);
                    } else {
                        screenTracker({ screen: '5.LIVENESS_CHECK', screenClass: 'LIVENESS_CHECK' });
                        if (isAgentLogged) {
                            nextStepHandler(Constants.FORM_STEPS.CUSTOMER_VALIDATION);
                            return;
                        }
                        nextStepHandler(Constants.FORM_STEPS.SELFIE);
                    }
                    const elapsedTime = moment()
                        .subtract(moment.unix(analyticsManager.getDocumentUploadInitTime()).unix(), 'milliseconds').format('mm:ss');
                    eventTracker({
                        name: 'OCR',
                        objectValue: {
                            SUCCESS: `TOC: ${currentFormStep.KEY} - ${Utils.getTimeInterval(elapsedTime)}`
                        }
                    });
                    eventTracker({
                        name: 'OCR',
                        objectValue: {
                            SUCCESS: `ATTEMPTS - ${currentFormStep.KEY} - ${analyticsManager.getDocumentUploadAttemptsCounter()}`
                        }
                    });
                    analyticsManager.setDocumentUploadAttemptsCounter(1);
                },
                () => analyticsManager.setDocumentUploadAttemptsCounter(analyticsManager
                    .getDocumentUploadAttemptsCounter() + 1)
            );
    };

    return (
        <>
            <TakeDocumentPhoto
                title={t('drivingLicenseBack.message')}
                image={DrivingLicenseBackImage}
                instructionText={t('takePhoto.moveAndCenterDocument')}
                captureTitle={t('drivingLicenseBack.captureTitle')}
                validateTitle={t('drivingLicenseBack.validateTitle')}
                buttonText={t('drivingLicenseBack.buttonText')}
                onChangeFn={onChangeFunction}
                takeDocumentPhotoInstructions={takeDocumentPhotoInstructions}
            />
        </>
    );
};

export default DrivingLicenseBack;
