import React, { ChangeEvent, FC } from 'react';
import { IOtpWrapperProps } from './otp-wrapper-types';

import OtpWrapperComponent from './Style';

const OtpWrapper: FC<IOtpWrapperProps> = ({
    otp,
    setOtp,
    disabled = false,
    allowLetters = false
}) => {
    const setOtpDigit = (value: string, index: number) => { // INFO: Assigns/replaces the otp digit to/on the existing otp set
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
    };

    const isSingleDigit = (value: string) => value.length === 1;

    const isOtpDigit = (value: string): boolean => (
        allowLetters
            ? (isSingleDigit(value) && allowLetters && /[a-zA-Z]/.test(value))
            : (isSingleDigit(value) && !isNaN(Number(value)))
    );

    const moveToNextInput = (value: string) => (
        allowLetters
            ? /[a-zA-Z]/.test(value) && value.trim().length !== 0
            : value.trim().length !== 0 && !isNaN(Number(value))
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const { value, nextElementSibling } = e.target;

        if (isOtpDigit(value)) {
            setOtpDigit(value, index);
        }

        if (nextElementSibling && moveToNextInput(value)) {
            (nextElementSibling as HTMLElement)!.focus();
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement> | any, index: number) => {
        if (event.key === 'Delete' || event.key === 'Backspace') {
            if (otp[index] === '') {
                const { previousElementSibling } = event.target;
                if (previousElementSibling && otp[index] === '') {
                    previousElementSibling.focus();
                }
                return;
            }
            setOtpDigit('', index);
        }
    };

    return (
        <OtpWrapperComponent>
            <div className="otp">
                {otp.map((digit, index) => (
                    <input
                        disabled={disabled}
                        key={index}
                        value={digit}
                        className="otp__digit"
                        type="password"
                        inputMode={allowLetters ? 'text' : 'numeric'}
                        minLength={1}
                        required
                        autoFocus={index === 0}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        id={`otp-digit-${index + 1}`}
                    />
                ))}
            </div>
        </OtpWrapperComponent>
    );
};

export default OtpWrapper;
