import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

interface IProps {
    open: boolean;
    fullScreen?: boolean;
}
const ModalComponent = styled.div<IProps>`
    display: ${({ open }) => (open ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100vh;
    width: 100%;
    z-index: ${({ fullScreen }) => (fullScreen ? '110' : '100')};

    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.32);
        z-index: 10;
    }

    .modal-view {
        max-width: ${Pixel2Rem(296)};
        min-height: ${Pixel2Rem(165)};
        height: fit-content;
        background-color: ${themeProvider.backgroundColor};
        max-height: 100%;
        align-items: center;
        text-align: center;
        z-index: 100;
        border-radius: ${Pixel2Rem(6)};
        filter: drop-shadow(${Pixel2Rem(2)} ${Pixel2Rem(4)} ${Pixel2Rem(6)} rgba(0, 0, 0, .4));
    }

    .modal-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .modal-content-wrapper {
            text-align: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            padding: 0 ${Pixel2Rem(35)} ${Pixel2Rem(32)} ${Pixel2Rem(35)};
        }

        .modal-content__close-icon {
            align-self: flex-end;
            padding: ${Pixel2Rem(14)} ${Pixel2Rem(14)} 0 ${Pixel2Rem(14)};
        }

        .modal-content__icon {
            align-self: center;
            width: ${Pixel2Rem(38)};
            height: fit-content;
        }

        .modal-content__title {
            color: ${themeProvider.textColor};
            font-family: 'VodafoneR';
            font-size: ${Pixel2Rem(18)};
            font-weight: bold;
            letter-spacing: ${Pixel2Rem(0.17)};
            line-height: ${Pixel2Rem(24)};
            text-align: center;
        }

        .modal-content__description {
            padding: 0 ${Pixel2Rem(10)};
            color: rgba(0, 0, 0, 0.6);
            font-family: 'VodafoneR';
            font-size: ${Pixel2Rem(16)};
            letter-spacing: 0;
            line-height: ${Pixel2Rem(20)};
            text-align: center;
        }

        .modal-content__action {
            color: ${themeProvider.errorColor} !important;
            font-weight: bold !important;
            cursor: pointer;
        }
    }
`;

export default ModalComponent;
