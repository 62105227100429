import Logger from '../../Utils/Logger';
import IHttpInterface, { IHttpRequestConfig, ResourceStatusEnum } from '../../Protocol/IHttpInterface';
import MockData, { getAreaBox, getNoseBox } from './MockData';
import Constants, { LOGGER_TAGS } from '../../Utils/Constants';
import EncryptionService from '../../Service/EncryptionService';
import DI from '../../Utils/DI';
import { SuccessStatusCodesKeys } from '../../Protocol/protocol-constants';
import InterceptorsMap from '../../Protocol/Interceptors/InterceptorsMap';
import { InterceptorTypeEnum } from '../../Protocol/Interceptors/interceptors-types.ts';

interface IBehaviors {
    [key: string]: {
        ERROR?: any;
        SUCCESS?: any;
        SESSION_EXPIRE?: any;
        NOT_ALLOWED?: any;
        NOT_FOUND?: any;
        data?: any;
        status?: number;
    }
}

class HttpInterfaceMock implements IHttpInterface {
    private readonly RESPONSE_TIME: number = 250;

    private counter: number = 1;

    private MIN_FALLBACK_REQUESTS = 2;

    private _requestInterceptFn?: Function;

    private _responseInterceptFn?: Function;

    private _successStatusCodes: SuccessStatusCodesKeys[] = [200, 201, 204];

    private readonly BEHAVIORS: IBehaviors = {
        GENERIC: {
            ERROR: { status: 500 },
            SUCCESS: { status: 200 },
            SESSION_EXPIRE: {
                status: 400,
                data: {
                    errorCode: '400-101',
                    errorSource: 'WIE',
                    message: 'Cookie expired'
                }
            },
            NOT_ALLOWED: { status: 403, data: { error: { internalCode: 1 } } },
            NOT_FOUND: { status: 404 }
        },

        REQUEST_OTP: {
            status: 200,
            data: MockData.REQUEST_OTP
        },

        VALIDATE_OTP: {
            status: 200,
            data: MockData.VALIDATE_OTP
        },

        PUBLIC_KEY_NEGOTIATION: {
            status: 200,
            data: MockData.PUBLIC_KEY_NEGOTIATION
        },

        UPLOAD_DOCUMENT_FRONT: {
            status: 200,
            data: MockData.UPLOAD_DOCUMENT_FRONT
        },

        START_CHALLENGE: {
            status: 200,
            data: MockData.START_CHALLENGE
        },
        VERIFY_CHALLENGE: {
            status: 200,
            data: MockData.VERIFY_CHALLENGE
        },

        ACCOUNT_CREATION: {
            status: 200,
            data: MockData.REQUEST_ACCOUNT_CREATION
        },

        SUBMIT_RATING: {
            status: 200,
            data: MockData.SUBMIT_RATING
        },

        UPLOAD_DOCUMENT_BACK: {
            status: 200,
            data: MockData.UPLOAD_DOCUMENT_BACK
        },
        VALIDATE_RECAPTCHA: {
            status: 200,
            data: MockData.VALIDATE_RECAPTCHA
        },
        VALIDATE_AGENCY: {
            status: 200,
            data: MockData.VALIDATE_AGENCY
        },
        FALLBACK: {
            status: 200,
            data: MockData.FALLBACK
        },

        VALIDATE_AGENT: {
            status: 200,
            data: MockData.VALIDATE_AGENT
        },
        GA_EVENTS: {
            status: 200,
            data: MockData.GA_EVENTS
        },
        SESSION: {
            status: 200,
            data: MockData.SESSION
        },
        LOGOUT: {
            status: 200,
            data: MockData.LOGOUT
        },
        VERIFY_CUSTOMER_BY_AGENT: {
            status: 200,
            data: MockData.VERIFY_CUSTOMER_BY_AGENT
        },
        QUERY_CUSTOMER_INFO: {
            status: 200,
            data: MockData.QUERY_CUSTOMER_INFO
        },
        VALIDATE_AGENT_PIN: {
            status: 200,
            data: MockData.VALIDATE_AGENT_PIN
        },
        START_AGENT_FLOW: {
            status: 204,
            data: MockData.START_AGENT_FLOW
        },
        VALIDATE_CUSTOMER_ID: {
            status: 200,
            data: MockData.VALIDATE_CUSTOMER_ID
        },
        VALIDATE_REVERSE_OTP: {
            status: 200,
            data: MockData.VALIDATE_REVERSE_OTP
        },
        SEND_REVERSE_OTP: {
            status: 200,
            data: MockData.SEND_REVERSE_OTP
        },
        QUERY_CONSENT_TERMS_AND_CONDITIONS_ID_STATUS: {
            status: 200,
            data: MockData.VALIDATE_CUSTOMER_ID
        },
        SEND_USSD_PUSH: {
            status: 200,
            data: MockData.SEND_USSD_PUSH
        }
    };

    constructor() {
        Logger.log(LOGGER_TAGS.HTTP_INTERFACE_MOCK, `${LOGGER_TAGS.HTTP_INTERFACE_MOCK} created.`);
    }

    private get encryptionService(): EncryptionService {
        return DI.get('EncryptionService');
    }

    send(requestConfig: IHttpRequestConfig): Promise<any> {
        return this.sendRequest(requestConfig);
    }

    private sendRequest(requestConfig: IHttpRequestConfig) {
        let requestInterceptPromise: Promise<any>;
        if (this._requestInterceptFn) {
            requestInterceptPromise = this._requestInterceptFn({
                url: requestConfig.relativeUrl
            });
        } else {
            requestInterceptPromise = Promise.resolve();
        }

        return requestInterceptPromise.then(
            this.getApiBehavior.bind(this, requestConfig)
        );
    }

    private async getApiBehavior(requestConfig: IHttpRequestConfig): Promise<any> {
        Logger.log(LOGGER_TAGS.HTTP_INTERFACE_MOCK, `Requested: ${requestConfig.relativeUrl}`, JSON.stringify(requestConfig.data));

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.VALIDATE_CUSTOMER_OTP
            )
        ) {
            return this.sendResponse(true, this.BEHAVIORS.VALIDATE_OTP);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.REQUEST_CUSTOMER_OTP
            )
        ) {
            return this.sendResponse(true, this.BEHAVIORS.REQUEST_OTP);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.VALIDATE_AGENT_OTP
            )
        ) {
            return this.sendResponse(true, this.BEHAVIORS.VALIDATE_OTP);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.RESEND_AGENT_OTP
            )
        ) {
            return this.sendResponse(true, this.BEHAVIORS.REQUEST_OTP);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.REQUEST_CUSTOMER_UPLOAD_DOCUMENT
            )
        ) {
            const decryptedData = await this.encryptionService.decryptMessage(requestConfig?.data);

            const response = decryptedData.resource.side === 'FRONT'
                ? this.BEHAVIORS.UPLOAD_DOCUMENT_FRONT
                : this.BEHAVIORS.UPLOAD_DOCUMENT_BACK;

            return this.sendResponse(true, response);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.REQUEST_AGENT_UPLOAD_DOCUMENT
            )
        ) {
            const decryptedData = await this.encryptionService.decryptMessage(requestConfig?.data);

            const response = decryptedData.resource.side === 'FRONT'
                ? this.BEHAVIORS.UPLOAD_DOCUMENT_FRONT
                : this.BEHAVIORS.UPLOAD_DOCUMENT_BACK;

            return this.sendResponse(true, response);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.START_CHALLENGE
            )
        ) {
            const decryptedData = await this.encryptionService.decryptMessage(requestConfig?.data);
            const { dimensionWidth, dimensionHeight } = decryptedData.resource;

            const faceBox = getAreaBox(dimensionWidth, dimensionHeight);
            const noseBox = getNoseBox(dimensionWidth, dimensionHeight);

            const { startLivenessDetectionResponse } = this.BEHAVIORS.START_CHALLENGE.data!;
            return this.sendResponse(
                true,
                {
                    ...this.BEHAVIORS.START_CHALLENGE,
                    data: {
                        startLivenessDetectionResponse: {
                            ...startLivenessDetectionResponse,
                            data: {
                                areaBox: { ...faceBox },
                                noseBox: { ...noseBox }
                            }
                        }
                    }
                }
            );
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.VERIFY_CHALLENGE
            )
        ) {
            return this.sendResponse(true, this.BEHAVIORS.VERIFY_CHALLENGE);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.SUBMIT_RATING
            )
        ) {
            return this.sendResponse(true, this.BEHAVIORS.SUBMIT_RATING);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.CUSTOMER.ACCOUNT_CREATION
            )
        ) {
            return this.sendResponse(true, this.BEHAVIORS.CUSTOMER_ACCOUNT_CREATION);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.VALIDATE_CUSTOMER_RECAPTCHA
            )
        ) {
            return this.sendResponse(true, this.BEHAVIORS.VALIDATE_RECAPTCHA);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.VALIDATE_AGENT_RECAPTCHA
            )
        ) {
            return this.sendResponse(true, this.BEHAVIORS.VALIDATE_RECAPTCHA);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.VALIDATE_AGENCY
            )
        ) {
            return this.sendResponse(true, this.mockPendingResponse('VALIDATE_AGENCY', 'validateAgencyResponse'));
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.VALIDATE_AGENT
            )
        ) {
            return this.sendResponse(true, this.mockPendingResponse('VALIDATE_AGENT', 'validateAgentResponse'));
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.GA_EVENTS
            )
        ) {
            return this.sendResponse(true, this.BEHAVIORS.GA_EVENTS);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.VERIFY_CHALLENGE_BY_AGENT
            )
        ) {
            return this.sendResponse(true, this.BEHAVIORS.VERIFY_CUSTOMER_BY_AGENT);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.FALLBACK
            )
        ) {
            const [, , propertyKey] = Object.keys(this.BEHAVIORS.FALLBACK.data!);
            const isResponsePending = this.isResponsePending(this.counter);

            const response = isResponsePending
                ? {
                    ...this.BEHAVIORS.FALLBACK,
                    data: {
                        ...this.BEHAVIORS.FALLBACK.data,
                        [propertyKey]: {
                            ...this.BEHAVIORS.FALLBACK.data![propertyKey],
                            status: ResourceStatusEnum.PENDING
                        }
                    }
                }
                : this.BEHAVIORS.FALLBACK;

            if (isResponsePending) {
                this.counter++;
            } else {
                this.counter = 1;
            }

            return this.sendResponse(true, response);
        }

        if (this.urlMatchesApi(
            requestConfig.relativeUrl,
            Constants.API_ENDPOINTS.SESSION
        )) {
            return this.sendResponse(true, this.BEHAVIORS.SESSION);
        }

        if (this.urlMatchesApi(
            requestConfig.relativeUrl,
            Constants.API_ENDPOINTS.LOGOUT
        )) {
            return this.sendResponse(true, this.BEHAVIORS.LOGOUT);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.AGENT.QUERY_CUSTOMER_INFO
            )
        ) {
            return this.sendResponse(true, this.mockPendingResponse('QUERY_CUSTOMER_INFO', 'validateMsisdnResponse'));
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.CUSTOMER.QUERY_CUSTOMER_INFO
            )
        ) {
            return this.sendResponse(true, this.mockPendingResponse('QUERY_CUSTOMER_INFO', 'validateMsisdnResponse'));
        }

        if (this.urlMatchesApi(
            requestConfig.relativeUrl,
            Constants.API_ENDPOINTS.AGENT.VALIDATE_PIN
        )) {
            return this.sendResponse(true, this.mockPendingResponse('VALIDATE_AGENT_PIN', 'confirmRegistrationResponse'));
        }

        if (this.urlMatchesApi(requestConfig.relativeUrl, Constants.API_ENDPOINTS.AGENT.START_AGENT_FLOW)) {
            return this.sendResponse(true, this.BEHAVIORS.START_AGENT_FLOW);
        }

        if (this.urlMatchesApi(requestConfig.relativeUrl, Constants.API_ENDPOINTS.AGENT.ACCOUNT_CREATION)) {
            return this.sendResponse(true, this.BEHAVIORS.ACCOUNT_CREATION);
        }
        if (this.urlMatchesApi(requestConfig.relativeUrl, Constants.API_ENDPOINTS.CUSTOMER.QUERY_CONSENT_TERMS_AND_CONDITIONS_ID_STATUS)) {
            return this.sendResponse(true, this.BEHAVIORS.VALIDATE_CUSTOMER_ID);
        }

        if (this.urlMatchesApi(requestConfig.relativeUrl, Constants.API_ENDPOINTS.AGENT.VALIDATE_REVERSE_OTP)) {
            return this.sendResponse(true, this.BEHAVIORS.VALIDATE_REVERSE_OTP);
        }
        if (this.urlMatchesApi(requestConfig.relativeUrl, Constants.API_ENDPOINTS.AGENT.SEND_REVERSE_OTP)) {
            return this.sendResponse(true, this.BEHAVIORS.SEND_REVERSE_OTP);
        }

        if (
            this.urlMatchesApi(requestConfig.relativeUrl,
                `${Constants.API_ENDPOINTS.AGENT.QUERY_CONSENT_TERMS_AND_CONDITIONS_ID_STATUS}/mocked`)
        ) {
            return this.sendResponse(true, this.BEHAVIORS.QUERY_CONSENT_TERMS_AND_CONDITIONS_ID_STATUS);
        }

        if (this.urlMatchesApi(requestConfig.relativeUrl, Constants.API_ENDPOINTS.AGENT.SEND_USSD_PUSH)) {
            return this.sendResponse(true, this.BEHAVIORS.SEND_USSD_PUSH);
        }

        if (
            this.urlMatchesApi(
                requestConfig.relativeUrl,
                Constants.API_ENDPOINTS.PUBLIC_KEY_NEGOTIATION
            )
        ) {
            const mockedServerKey = this.BEHAVIORS
                .PUBLIC_KEY_NEGOTIATION.data!.publicKeyResponse.data;
            return this.sendResponse(true, {
                ...this.BEHAVIORS.PUBLIC_KEY_NEGOTIATION,
                data: {
                    publicKeyResponse: {
                        data: {
                            ...mockedServerKey,
                            // use our local key for tests
                            serverPublicKey: requestConfig.data.appPublicKey
                        }
                    }
                }
            }, false);
        }

        return this.sendResponse(false, this.BEHAVIORS.GENERIC.ERROR);
    }

    private sendResponse(success: boolean, response: any, mustEncrypt: boolean
    = true): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            if (success) {
                InterceptorsMap.setCurrentInterceptor(response, InterceptorTypeEnum.RESPONSE);
                if (this._responseInterceptFn && response) {
                    Logger.log(
                        LOGGER_TAGS.HTTP_INTERFACE_MOCK, `Error status code intercepted: ${response.status}`, response);
                    this._responseInterceptFn(response.status);
                }

                setTimeout(() => {
                    if (mustEncrypt) {
                        this.encryptionService
                            .encryptMessage(
                                JSON.stringify(response.data),
                                this.encryptionService.getPublicKey().armor()
                            )
                            .then((encryptedResponse) => {
                                resolve({
                                    status: response.status,
                                    data: encryptedResponse
                                });
                                Logger.log(LOGGER_TAGS.HTTP_INTERFACE_MOCK, 'Responded:', response);
                            });
                    } else {
                        resolve(response);
                        Logger.log(LOGGER_TAGS.HTTP_INTERFACE_MOCK, 'Responded:', response);
                    }
                }, this.RESPONSE_TIME);
                return;
            }

            setTimeout(() => {
                Logger.error(LOGGER_TAGS.HTTP_INTERFACE_MOCK, 'Responded:', response);
                reject(response);
            }, this.RESPONSE_TIME);
        });

        return promise;
    }

    setAuthValue(authValue: string) {}

    setRequestInterceptor(interceptFn?: Function | undefined): void {
        this._requestInterceptFn = interceptFn;
    }

    setResponseInterceptor(interceptFn?: Function | undefined): void {
        this._responseInterceptFn = interceptFn;
    }

    private urlMatchesApi(url: string, api: string): boolean {
        let apiRegexString = api.replace(/{.*}/gi, '.*');
        apiRegexString = apiRegexString.replace(/\//gi, '\\/');
        const apiRegex = new RegExp(apiRegexString);
        // return apiRegex.test(url);
        return url === api;
    }

    private isResponsePending(count: number): boolean {
        return count < this.MIN_FALLBACK_REQUESTS;
    }

    /**
 * method to manipulate the simulate a response in the pending status
 * @returns {object} a BEHAVIOR object response where the status is pending
 * @param {string} responseKey - key of the BEHAVIOR object that must be set to pending
 * * @param {string} propertyKey - key of the data object inside the BEHAVIOR object where the status property is located
 */
    private mockPendingResponse(responseKey: string, propertyKey: string) {
        const mockedResponse = this.BEHAVIORS[responseKey];
        return {
            ...mockedResponse,
            data: {
                ...mockedResponse.data,
                [propertyKey]: {
                    ...mockedResponse.data[propertyKey],
                    status: ResourceStatusEnum.PENDING
                }
            }
        };
    }

    public getSuccessStatusCodes(): SuccessStatusCodesKeys[] {
        return this._successStatusCodes;
    }

    public setSuccessStatusCodes(value: SuccessStatusCodesKeys[]): void {
        this._successStatusCodes = value;
    }
}

export default HttpInterfaceMock;
