/* eslint-disable no-unused-vars */

import { ISendUssdPushResponseData } from '../Business/RegistrationManager/IRegistrationManager';
import {
    IBEResponse,
    IBEResponseData,
    InternalResourceStatusEnum,
    ResourceStatusEnum
} from '../Protocol/IHttpInterface';
import { ISOLanguageCodeEnum, DOCUMENT_TYPES } from '../Utils/Constants';

export type IDocumentType = keyof typeof DOCUMENT_TYPES;
export interface ISubmitRegistrationRequest {
    relativeUrl: string;
    data: {
        msisdn: string;
        resource: {
            language: ISOLanguageCodeEnum;
            firstName: string;
            lastName: string;
            placeOfBirth: string;
            dateOfBirth: string;
            gender: string;
            nationality: string;
            town: string;
            province: string;
            street: string;
            commune: string;
            documentNumber: string;
            documentType: IDocumentType;
        }
    }
}

interface IRegistrationService {
    requestAccountCreation(submitRegistrationRequest: ISubmitRegistrationRequest): Promise<any>;
    validateAgentPin(agentPin: string): Promise<any>;
    validateCustomerId(validateCustomerIdRequest: IValidateCustomerIdRequest): Promise<any>;
    validateReverseOtp(validateReverseOtpRequest: IValidateReverseOtpRequest): Promise<IBEResponseData<IValidateReverseOtpResponseData>>;
    sendReverseOtp(sendOtpRequest: IReverseOtpRequest): Promise<any>;
    sendUssdPush(ussdPushRequest: IUssdPushRequest): Promise<any>;
}

export interface ISubmitRegistrationResponseData {
    status: ResourceStatusEnum;
}

export interface ISubmitRegistrationResponse extends IBEResponse {
    submitRegistrationResponse: IBEResponseData<ISubmitRegistrationResponseData>;
}

export interface IValidateAgentPinResponseData {
    valid: boolean;
}

export interface IValidateAgentPinResponse extends IBEResponse {
    confirmRegistrationResponse: IBEResponseData<IValidateAgentPinResponseData>;
}

export interface IValidateCustomerIdRequest {
    id: string;
}

export enum ConsentOtpStatusEnum {
    VALID = 'VALID',
    CONSENTED = 'CONSENTED',
    VALIDATED = 'VALIDATED',
    IN_PROGRESS = 'IN_PROGRESS'
}

export type IValidateCustomerIdResponseDataType = IBEResponseData<IValidateCustomerIdResponseData>;
export interface IValidateCustomerIdResponseData {
    status: ConsentOtpStatusEnum;
}

export interface IValidateCustomerIdResponse extends IBEResponse {
    consentResponse: IValidateCustomerIdResponseDataType;
}

export interface IValidateReverseOtpRequest {
    otp: string;
    uniqueIdentifier: string;
}

export interface IValidateReverseOtpResponseData {
    status: InternalResourceStatusEnum;
}

export interface IValidateReverseOtpResponse extends IBEResponse {
    validateOtpCodeResponse: IBEResponseData<IValidateReverseOtpResponseData>;
}
export interface IReverseOtpRequest {
    msisdn: string;
    language: ISOLanguageCodeEnum;
}

export interface ISendReverseOtpResponseData {
    timeLeftForExpiration?: number;
    timeLeftForResend?: number;
    status: InternalResourceStatusEnum;
    reverseOtpCode: string;
    uniqueIdentifier: string;
    isBlocked: boolean;
    timeLeft?: number; // INFO: In blocked cases, this property indicates to time left for resend
}

export interface ISendReverseOtpResponse extends IBEResponse {
    reverseOtpCodeResponse: IBEResponseData<Omit<ISendReverseOtpResponseData, 'isBlocked'>>
}

export interface IUssdPushRequest {
    language: ISOLanguageCodeEnum;
    msisdn: string;
}

export interface ISendUssdPushResponse extends IBEResponse {
    UssdResponse: IBEResponseData<ISendUssdPushResponseData>
}

export default IRegistrationService;
