import { LOGGER_TAGS, INTERNET_CONNECTION_STATUS } from '../../../Utils/Constants';
import EventEmitter from '../../../Utils/EventEmitter';
import Logger from '../../../Utils/Logger';
import { IInterceptor } from '../interceptors-types.ts';

class InternetConnectionInterceptor implements IInterceptor {
    private _isNetworkAvailable: boolean = true;

    setIsNetworkAvailable(value: boolean): void {
        this._isNetworkAvailable = value;
    }

    interceptorFn(): void {
        const internetConnectionStatus = this._isNetworkAvailable ? INTERNET_CONNECTION_STATUS.ONLINE : INTERNET_CONNECTION_STATUS.OFFLINE;

        EventEmitter.dispatch(
            LOGGER_TAGS.INTERNET_CONNECTION_INTERCEPTOR,
            false,
            { status: this._isNetworkAvailable }
        );

        if (this._isNetworkAvailable) {
            Logger.log(LOGGER_TAGS.INTERNET_CONNECTION_INTERCEPTOR, 'Internet connection is: ', internetConnectionStatus);
        } else {
            Logger.error(LOGGER_TAGS.INTERNET_CONNECTION_INTERCEPTOR, 'Internet connection is: ', internetConnectionStatus);
        }
    }
}

export default new InternetConnectionInterceptor();
