import styled from 'styled-components';
import Pixel2Rem from '../../../../Utils/Pixel2Rem';
import Constants from '../../../../Utils/Constants';
import { themeProvider } from '../../../Theme/ThemeProvider';
import RetakePictureIcon from '../../../Assets/icons/ic_circle_edit.svg';
import AcceptPictureIcon from '../../../Assets/icons/ic_check.svg';

export const Container = styled.div`
  height: 100%;

  .wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: yellow; */
    border-radius: 100%;
  }

  .captured-image {
    height: 300px;
    width: 250px;
    background-color: yellow;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      // max-width: 100%;
      // max-height: 100%;
      object-fit: contain;
    }
  }
`;

export const VideoContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background-color: ${themeProvider.textColor};
  display: flex;
  align-items: center;
  justify-content: center;

  & .icon-back {
    position: fixed;
    display: flex;
    top: 0;
    // background-color: black;
    width: -webkit-fill-available;
    height: 49px;
    z-index: 1200;
  }

  .instructions {
    display: flex;
    height: 52px;
    width: fill-available;
    justify-content: flex-start;
    align-items: center;
    margin: 0 24px;
    bottom: 15%; 
    border-radius: 8px;
    background-color: #FFFFFF;
    padding: 3px 14px;
    position: absolute;
    z-index: 3;

    .instructions__icon {
        margin-right: 5%;
    }

    .instructions__description {
      font-size: 16px;
      line-height: 20px;
      font-family: ${themeProvider.fontFamily};
    }
  }
`;

export const CropContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ICloseButton{
    icon: string;
}
export const CloseButton = styled.button<ICloseButton>`
  position: fixed;
  top: ${Pixel2Rem(14)};
  left: ${Pixel2Rem(24)};
  background: url(${(props) => props.icon}) no-repeat center;
  background-size: ${Pixel2Rem(24)};
  border: none;
  width: ${Pixel2Rem(24)};
  height: ${Pixel2Rem(24)};
  cursor: pointer;
  z-index: 4;

  &.icon-back {
    background-color: yellow;
    width: 200px;
    align-items: left;  

  }
`;

export const Video = styled.video`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  // margin: auto;
`;

export const Adjust = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  margin: auto;
`;

export const FrameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  
`;

export const SideTitle = styled.p`
  color: ${themeProvider.backgroundColor};
  text-align: center;
  font-family: ${themeProvider.fontBold};
  font-size: ${Pixel2Rem(24)};
  line-height: ${Pixel2Rem(28)};
  z-index: 4;
  margin: 0;
`;

export const SideSubTitle = styled.p`
  color: ${themeProvider.backgroundColor};
  text-align: center;
  font-family: ${themeProvider.fontFamily};
  font-size: ${Pixel2Rem(16)};
  line-height: ${Pixel2Rem(18)};
  z-index: 4;
  padding: 0 ${Pixel2Rem(24)};

  @media all and (max-width: 330px){
    &{
      font-size: ${Pixel2Rem(13)};
      margin-top: 0;
      margin-bottom: ${Pixel2Rem(26)};
    }
  }
`;

export const PhotoFrame = styled.div<{ overlay?: boolean}>`
  position: relative;
  width: calc(100vw - 1.2rem);
  max-width: ${() => Pixel2Rem(Constants.DOCUMENT_CAPTURE.CROP_BOX_MAX_WIDTH)};
  height: calc((100vw - ${Pixel2Rem(10)}) * ${() => Constants.DOCUMENT_CAPTURE.CROP_BOX_WIDTH_TO_HEIGHT_RATIO});
  // max-height: ${() => Pixel2Rem(Constants.DOCUMENT_CAPTURE.CROP_BOX_MAX_HEIGHT)};
  margin: ${Pixel2Rem(30)} auto ${Pixel2Rem(20)};
  box-sizing: border-box;
  z-index: 3;

  content: "";
  position: absolute;
  inset: 0 0 20% 0;
  border-radius: ${Pixel2Rem(20)};
  width: 90%;
  height: 60%;
  border-radius: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ overlay }) => overlay && 'box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.5)'};
  

  

  .borders {
    position: relative;
    height: 100%;
    width: 100%;

    // &:after, &:before, &>:first-child:before, &>:first-child:after {
    //   position: absolute;
    //   content: "";
    //   border-color: ${themeProvider.backgroundColor};
    //   border-style: solid;
    //   height: ${Pixel2Rem(73)};
    //   width: ${Pixel2Rem(73)};
    // }

    // &:after {
    //   top: -${Pixel2Rem(16)};
    //   right: 0;
    //   border-width: ${Pixel2Rem(4)} ${Pixel2Rem(4)} 0 0;
    //   border-radius: 0 ${Pixel2Rem(21)} 0 0;
    // }

    // &:before {
    //   top: -${Pixel2Rem(16)};
    //   left: 0;
    //   border-width: ${Pixel2Rem(4)} 0 0 ${Pixel2Rem(4)};
    //   border-radius: ${Pixel2Rem(21)} 0 0 0;
    // }


    // &>:first-child:before {
    //   bottom: ${Pixel2Rem(16)};
    //   right: 0;
    //   border-width: 0 ${Pixel2Rem(4)} ${Pixel2Rem(4)} 0;
    //   border-radius: 0 0 ${Pixel2Rem(21)} 0;
    // }


    // &>:first-child:after {
    //   bottom: ${Pixel2Rem(16)};
    //   left: 0;
    //   border-width: 0 0 ${Pixel2Rem(4)} ${Pixel2Rem(4)};
    //   border-radius: 0 0 0 ${Pixel2Rem(21)};
    // }
  }

  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 40%;
  //   border-radius: ${Pixel2Rem(20)};
  //   box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.5);
  //   width: 95%;
  //   height: 200%;
  //   border-radius: 100%;
  //   margin: auto;
  //   display: flex;
  //   justify-content: center;
  // }



  .photo-frame__borders {
    position:relative;
    width: 101.7%;
    z-index: 100;
    left: -${Pixel2Rem(3)};
    height: 100%;
    box-sizing: border-box;
    display:inline-flex;
    font-size: ${Pixel2Rem(20)};
    justify-content: center;
    align-items: center;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${themeProvider.backgroundColor};
      padding: ${Pixel2Rem(3)};
      border-radius: ${Pixel2Rem(20)};
      -webkit-mask: linear-gradient(${themeProvider.backgroundColor} 0 0) top /calc(100% - 2* ${Pixel2Rem(80)}) ${Pixel2Rem(4)},
      linear-gradient(${themeProvider.backgroundColor} 0 0) bottom/calc(100% - 2* ${Pixel2Rem(80)}) ${Pixel2Rem(4)},
      linear-gradient(${themeProvider.backgroundColor} 0 0) left / ${Pixel2Rem(4)} calc(100% - 2* ${Pixel2Rem(75)}),
      linear-gradient(${themeProvider.backgroundColor} 0 0) right / 
      ${Pixel2Rem(4)} calc(100% - 2* ${Pixel2Rem(75)}), linear-gradient(${themeProvider.backgroundColor} 0 0) content-box,
      linear-gradient(${themeProvider.backgroundColor} 0 0);
      mask-composite: destination-out;
      mask-repeat: no-repeat;
      outline: none;
    }
  }
`;

export const Controllers = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: ${Pixel2Rem(20)};

  z-index: 3;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const CropControllers = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: ${Pixel2Rem(20)};
  margin: 0 ${Pixel2Rem(24)};
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const TakePhotoButton = styled.button`
  position: relative;
  background-color: transparent;
  border: ${Pixel2Rem(3)} solid ${themeProvider.backgroundColor};
  width: ${Pixel2Rem(64)};
  height: ${Pixel2Rem(64)};
  border-radius: 50%;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: ${Pixel2Rem(5)};
    left: ${Pixel2Rem(5)};
    background-color: ${themeProvider.backgroundColor};
    width: ${Pixel2Rem(48)};
    height: ${Pixel2Rem(48)};
    border-radius: 50%;
  }
`;

export const CroppedPhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .title{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .document-photo{
    position: relative;
  }
`;

export const ValidateTitle = styled.p`
  color: ${themeProvider.titleColor};
  text-align: center;
  font-family: ${themeProvider.fontBold};
  font-size: ${Pixel2Rem(24)};
  line-height: ${Pixel2Rem(26)};
`;

export const Photo = styled.img`
  width: 100%;
  max-width: ${() => Pixel2Rem(Constants.DOCUMENT_CAPTURE.CROP_BOX_MAX_WIDTH)};
  height: auto;
  border-radius: ${Pixel2Rem(6)};
`;

export const EnlargeImageButton = styled.button`
  position: absolute;
  bottom: ${Pixel2Rem(30)};
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;

  padding: ${Pixel2Rem(7)} ${Pixel2Rem(10)};
  border-radius: ${Pixel2Rem(10)};
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: ${Pixel2Rem(6)};
  border: none;
  cursor: pointer;

  span{
    margin-left: ${Pixel2Rem(5)};
  }
`;

export const ConfirmImageContainer = styled.div`
  position: fixed;
  background-color: black;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  z-index: 9999;
  width: 100vw;
  height: 100vh;
`;

export const DocumentImagePreview = styled.img`
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: auto;
`;

export const ValidateSubTitle = styled.p`
  color: ${themeProvider.titleColor};
  text-align: center;
  font-family: ${themeProvider.fontFamily};
  font-size: ${Pixel2Rem(16)};
  line-height: ${Pixel2Rem(20)};
`;

export const Footer = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: ${Pixel2Rem(20)};

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  Button{
    margin-bottom: ${Pixel2Rem(15)};
  }
`;

export const RetakeButton = styled.button`
  background: url(${RetakePictureIcon}) no-repeat center;
  background-size: ${Pixel2Rem(44)};
  border: ${Pixel2Rem(5)} solid ${themeProvider.errorColor};
  width: ${Pixel2Rem(64)};
  height: ${Pixel2Rem(64)};
  border-radius: 50%;
`;

export const AcceptButton = styled.button`
  background: url(${AcceptPictureIcon}) no-repeat center;
  background-size: ${Pixel2Rem(44)};
  border: ${Pixel2Rem(5)} solid ${themeProvider.successColor};
  width: ${Pixel2Rem(64)};
  height: ${Pixel2Rem(64)};
  border-radius: 50%;
`;
