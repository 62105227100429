import styled from 'styled-components';
import { themeProvider } from '../../Theme/ThemeProvider';

const TimerComponent = styled.div`
    .timer__action-text {
        display: inline-block;
        color: ${themeProvider.primaryColor};
        cursor: pointer;
    }
`;

export default TimerComponent;
