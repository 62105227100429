/* eslint-disable import/prefer-default-export */
const red = '#E60000';
export const themeProvider = {
    primaryColor: red,
    backgroundColor: '#fff',
    fontFamily: 'VodafoneR',
    fontBold: 'VodafoneB',
    fontExtraBold: 'VodafoneExB',
    languageDropdownFont: 'Inter',
    titleSize: 28,
    titleColor: '#222222',
    textColor: '#454545',
    navBarColor: '#F6F6F6',
    progressBarColor: '#ebebeb',
    successColor: '#007C92',
    inputColor: '#999999',
    disableColor: '#CCCCCC',
    errorColor: red,
    linkColor: red,
    dropdownBorderColor: '#dacece',
    muted: '#666666',
    borderColor: '#EBEBEB',
    cardShadow: '#00000029',
    darkGrey: '#333333',
    personalDocumentsSeparator: '#EBEBEB',
    badgeColor: '#e6000014',
    blackBackgroundWithOpacity: '#00000066',
    noFaceRedSquare: '#ff0000',
    detectedFaceGreenSquare: '#009900',
    detectedFacialExpressionBlueSquare: '#7b00ff',
    moveNoseYellowSquare: '#fff700',
    emptyStarRating: '#cccccc',
    averageStarRating: '#eb9700',
    satisfiedStarRating: '#a8b400',
    aquaBlue: '#00B0CA',
    agentConfirmationText: '#797979',
    rotateFaceOverlay: '#7b00ff3d',
    editIconShadow: '#82828280'
};
