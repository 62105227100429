import styled from 'styled-components';
import Pixel2Rem from '../../../../Utils/Pixel2Rem';
import { themeProvider } from '../../../Theme/ThemeProvider';

const DropdownItemComponent = styled.li`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${Pixel2Rem(10)} ${Pixel2Rem(14)};
    cursor: pointer;
    &:last-child{
        border-bottom: .5px solid ${themeProvider.dropdownBorderColor} !important;
    }

    .item__icon {
        flex-grow: 1;
        margin-right: ${Pixel2Rem(14)};
    }

    .item__description {
        flex-grow: 100;
    }

    .item__selected-icon {
        flex-grow: 1;
        width: ${Pixel2Rem(28)};
        height: ${Pixel2Rem(28)};
    }

`;

export default DropdownItemComponent;
