import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

const PrivacyPolicyScreen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${Pixel2Rem(24)} ${Pixel2Rem(24)};
    font-family: ${themeProvider.fontFamily};


    .title {
        color: ${themeProvider.titleColor};
        font-family: ${themeProvider.fontBold};
        font-size: ${Pixel2Rem(28)};
        font-weight: bold;
        letter-spacing: 0;
        line-height: ${Pixel2Rem(33)};
    }

    .topic {
    }

    .content {
        margin: ${Pixel2Rem(24)} 0;
        text-align: justify;
        white-space: pre-line;
        color: ${themeProvider.titleColor};
        font-size: ${Pixel2Rem(16)};
        letter-spacing: 0;
        line-height: ${Pixel2Rem(20)};
    }
`;
export default PrivacyPolicyScreen;
