import {
    FC, useCallback, useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import EnterPhoneNumberScreen from './Style';
import Button from '../../Components/Button/Button';
import { themeProvider } from '../../Theme/ThemeProvider';
import DI from '../../../Utils/DI';
import Input from '../../Components/Input/Input';
import Constants, {
    COUNTRY_CODE, RESEND_RECAPTCHA_INTERVAL, SPAM_GUARD_MECHANISM, SPAM_GUARD_MECHANISMS_ENUM
} from '../../../Utils/Constants';
import { ReactComponent as DrcFlagIcon } from '../../Assets/icons/ic_flag_drc.svg';
import { IFormStepProps } from '../PageWrapper/PageWrapper';
import Loader from '../../Components/Loader/Loader';
import Modal from '../../Components/Modal/Modal';
import CustomizedWrapper from '../../Components/CustomizedWrapper/CustomizedWrapper';
import {
    ERRORS_DESCRIPTION, ERROR_CODES, IError, SPAM_GUARD_ERRORS
} from '../../../Utils/Constants/Errors';
import Timer from '../../Components/Timer/Timer';
import Utils from '../../../Utils/Utils';
import useGaEventTracker from '../../Hooks/useGaEventTracker';
import IMsisdn from './enter-phone-number-types';
import ISessionManager from '../../../Business/ISessionManager';
import IDataManager from '../../../Business/IDataManager';
import IUserManager, { IRequestMsisdnError } from '../../../Business/UserManager/IUserManager';
import ResultScreen from '../../Components/ResultScreen/ResultScreen';
import { IResultScreenProps } from '../../Components/ResultScreen/result-screen-types';

const EnterPhoneNumber: FC<IFormStepProps> = ({ nextStepHandler }) => {
    const [msisdn, setMsisdn] = useState<IMsisdn>({
        input: '',
        isValid: true
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const dataManager: IDataManager = DI.get('DataManager');
    const sessionManager: ISessionManager = DI.get('SessionManager');
    const isAgentLogged = useMemo(() => sessionManager?.getSession()?.getIsValid(), []);
    const [resourceAvailabilityInformation, setResourceAvailabilityInformation] = useState({
        isBlocked: false,
        timeLeft: 0
    });
    const [resultScreen, setResultScreen] = useState<{show: boolean, props: IResultScreenProps}>({
        show: false,
        props: {
            title: '',
            description: '',
            actionText: '',
            actionFn: () => {},
            showActionButton: true
        }
    });

    const { screenTracker } = useGaEventTracker();

    const countryCodeConfiguration = {
        countryCode: COUNTRY_CODE.code,
        initialNumberRegex: COUNTRY_CODE.initialNumberRegex,
        validPrefixesRegex: COUNTRY_CODE.validPrefixesRegex
    };

    const [eventDate, setEventDate] = useState(moment().add(RESEND_RECAPTCHA_INTERVAL, 'm').unix());

    const { FORM_STEPS } = Constants;

    const [error, setError] = useState<IError>({
        hasError: false,
        description: '',
        title: ''
    });
    const [showTimer, setShowTimer] = useState(false);

    const closeModal = () => {
        setError({
            hasError: false,
            description: '',
            title: ''
        });
    };

    const handleChange = (event: any) => {
        const { value } = event.target;
        if (
            (value.length <= 9 && !Number.isNaN(parseInt(value, 10)))
            || value.length === 0
        ) {
            setMsisdn((prev) => ({
                ...prev,
                input: value,
                isValid: countryCodeConfiguration.initialNumberRegex.test(value)
                 || countryCodeConfiguration.validPrefixesRegex.test(value)
            }));
        }
    };

    const handleSubmit = useCallback((event: any) => {
        setIsLoading(true);
        let resultScreenProps: IResultScreenProps;
        event.preventDefault();
        const userManager: IUserManager = dataManager.convertToUserManager();
        userManager
            .requestMsisdnValidationFromEntitledManager(msisdn.input)
            .then(() => {
                if (isAgentLogged) {
                    screenTracker({ screen: '3.PERSONAL_DOCUMENTS', screenClass: 'SELECT_DOCUMENTS' });
                    resultScreenProps = {
                        title: t('enterPhoneNumber.validMobileNumber'),
                        actionText: t('general.continue'),
                        description: t('enterPhoneNumber.validMobileNumberDescription'),
                        actionFn: () => nextStepHandler({ ...FORM_STEPS.PERSONAL_DOCUMENTS, TITLE: 'customerDocuments' }),
                        showActionButton: true,
                        success: true
                    };
                    setResultScreen({ show: true, props: resultScreenProps });
                    return;
                }

                screenTracker({ screen: '2.VALIDATE_PHONE_NUMBER', screenClass: 'REQUEST_OTP' });

                nextStepHandler({
                    ...FORM_STEPS.VALIDATE_OTP,
                    PROPS: {
                        title: t('validatePhoneNumber.validateSelfMsisdn'),
                        description: `${t('validatePhoneNumber.sentCode')} 
                        ${t('validatePhoneNumber.to')} ${COUNTRY_CODE.code}
                        ${Utils.msisdnFormatter(userManager.getMsisdn())}.`
                    }
                });
            })
            .catch(({ errorCode, manager }: IRequestMsisdnError) => {
                if (SPAM_GUARD_ERRORS.includes(errorCode)) {
                    setResourceAvailabilityInformation({
                        isBlocked: manager.retrieveResourceAvailabilityInformation().isBlocked(),
                        timeLeft: manager.retrieveResourceAvailabilityInformation().getTimeLeft()
                    });
                    const timeLeft = Utils
                        .convertToMinutesColonSeconds(moment()
                            .add(manager.retrieveResourceAvailabilityInformation().getTimeLeft(), 's').unix() - moment().unix());

                    setError((prev) => ({
                        ...prev,
                        hasError: true,
                        title: t((ERRORS_DESCRIPTION[errorCode]).title)
                        || t((ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR]).title),
                        description: t((ERRORS_DESCRIPTION[errorCode])
                            .description, {
                            minutes: timeLeft.minutes,
                            seconds: timeLeft.seconds
                        })
                        || t((ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR]).description)
                    }));
                    setShowTimer(true);
                    return;
                }

                if (errorCode === ERROR_CODES.VALIDATE_MSISDN.DUPLICATED) {
                    resultScreenProps = {
                        title: t('error.customerDuplicatedPhoneNumber'),
                        description: t('error.customerDuplicatedPhoneNumberDescription'),
                        actionText: t('general.tryAgain'),
                        actionFn: () => setResultScreen((prev) => ({ ...prev, show: false })),
                        showActionButton: true
                    };
                    setResultScreen({ show: true, props: resultScreenProps });
                    return;
                }

                setError((prev) => ({
                    ...prev,
                    hasError: true,
                    title: t(Constants.ERRORS.ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title),
                    description: t(Constants.ERRORS.ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title)
                }));
            })
            .finally(() => setIsLoading(false));
    }, [msisdn]);

    useEffect(() => {
        setEventDate(moment().add(resourceAvailabilityInformation.timeLeft, 's').unix());
    }, [resourceAvailabilityInformation]);

    return (
        <>

            {isLoading && (<Loader />)}
            {!isLoading && resultScreen.show && (
                <ResultScreen {...resultScreen.props} />
            )}
            {!isLoading && !resultScreen.show && (
                <>
                    <EnterPhoneNumberScreen
                        onSubmit={handleSubmit}
                        error={!msisdn.isValid ? t('enterPhoneNumber.invalidNumber') : ''}
                    >
                        <div className="wrapper">
                            <div className="title">
                                <span>{isAgentLogged
                                    ? t('enterPhoneNumber.enterCustomerNumber')
                                    : t('enterPhoneNumber.enterNumber')}
                                </span>
                            </div>
                            <div className="subtitle">
                                <span>{isAgentLogged
                                    ? t('enterPhoneNumber.provideCustomerNumber')
                                    : t('enterPhoneNumber.provideNumber')}
                                </span>
                            </div>
                            <div className="msisdn-wrapper">
                                <div className="input-group">
                                    <div className="country-code">
                                        <CustomizedWrapper borderColor={themeProvider.inputColor} label={t('general.code')}>
                                            <DrcFlagIcon className="country-code__flag" />
                                            <span className="country-code__code">{countryCodeConfiguration.countryCode}</span>
                                        </CustomizedWrapper>
                                    </div>
                                    <Input
                                        data={msisdn}
                                        disabled={error.hasError || showTimer}
                                        handleChange={handleChange}
                                        inputMode="tel"
                                        autoFocus
                                        type="number"
                                        id="msisdn"
                                        inputLabel={t('screenLabels.phoneNumber')}
                                        grow={1}
                                        maxLength={9}
                                        onKeyDownFn={(event: any) => {
                                            if (event.which === 38 || event.which === 40) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            {showTimer && (
                                <div className="blocked__msisdn">
                                    <Timer
                                        actionFn={() => setShowTimer(false)}
                                        timeLeft={eventDate}
                                        message={{
                                            whileIsCountingMessage: t('enterPhoneNumber.blocked'),
                                            endOfTimeMessage: {
                                                text: '',
                                                actionText: ''
                                            }
                                        }}
                                        action={() => {}}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="footer">
                            {SPAM_GUARD_MECHANISM === SPAM_GUARD_MECHANISMS_ENUM.RECAPTCHA && (
                                <div className="reCaptcha-waterMark">
                                    <span>{t('enterPhoneNumber.reCaptchaWatermark.protectByLabel')} <a id="recaptcha-terms-and-conditions" target="_blank" href="https://policies.google.com/privacy" rel="noreferrer">{t('screenLabels.privacyPolicy')}</a> {t('enterPhoneNumber.reCaptchaWatermark.andLabel')}&nbsp;
                                        <a id="recaptcha-privacy-policy" target="_blank" href="https://policies.google.com/terms" rel="noreferrer">{t('enterPhoneNumber.reCaptchaWatermark.termsOfService')}</a> {t('enterPhoneNumber.reCaptchaWatermark.applyLabel')}.
                                    </span>
                                </div>
                            )}

                            <Button
                                id="continue-button"
                                disabled={
                                    !(msisdn.isValid && msisdn.input.length === 9) || showTimer
                                }
                                type="submit"
                                mode="normal"
                            >{`${t('general.continue')}`}
                            </Button>
                        </div>
                    </EnterPhoneNumberScreen>
                </>
            )}

            <Modal
                onClose={closeModal}
                open={Boolean(error.hasError)}
                title={error.title}
                description={error.description}
            />

        </>
    );
};

export default EnterPhoneNumber;
