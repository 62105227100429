import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

const ConfirmationMethodScreen = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Pixel2Rem(8)};
    padding-top: ${Pixel2Rem(24)};
    font-family: ${themeProvider.fontFamily};

    .confirmation-method__title {
        color: ${themeProvider.titleColor};
        font-size: ${themeProvider.titleSize};
        font-style: normal;
        font-family: ${themeProvider.fontBold};
        line-height: ${Pixel2Rem(34)};
    }

    .confirmation-method__description {
        color: ${themeProvider.titleColor};
        font-size: ${Pixel2Rem(16)};
        font-style: normal;
        font-weight: 400;
        line-height: ${Pixel2Rem(20)};
    }

    .confirmation-options {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin-top: ${Pixel2Rem(8)};

        .confirmation-options-item {
            display: flex;
            align-items: center;
            padding: ${Pixel2Rem(14)} ${Pixel2Rem(8)};
            border-bottom: ${Pixel2Rem(1)} solid ${themeProvider.borderColor};
            justify-content: space-between;
            gap: ${Pixel2Rem(5)};

            .confirmation-options-item-wrapper {
                display: flex;
                align-items: center;
                gap: ${Pixel2Rem(16)};
                
                & svg {
                    width: ${Pixel2Rem(32)};
                    height: ${Pixel2Rem(32)};
                    flex: 0 0 auto;
                }
    
                .confirmation-item-content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: ${Pixel2Rem(3)};
    
                    .confirmation-item-content__title {
                        font-size: ${Pixel2Rem(16)};
                        line-height: normal;
                    }
        
                    .confirmation-item-content__description {
                        color: ${themeProvider.inputColor};
                        font-size: ${Pixel2Rem(14)};
                        line-height: normal;
                    }
                }
            }

            .confirmation-options-item--indicator {
                width: ${Pixel2Rem(24)};
                height: ${Pixel2Rem(24)};
                flex: 0 0 auto;
            }
        }


    }
`;

export default ConfirmationMethodScreen;
