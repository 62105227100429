import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

const CheckboxComponent = styled.div`
        border-radius: ${Pixel2Rem(3)};
        background-color: ${themeProvider.backgroundColor};
        border: ${Pixel2Rem(1)} solid ${themeProvider.muted};
        width: ${Pixel2Rem(23)};
        height: ${Pixel2Rem(21)};
        cursor pointer;

        svg {
            height: 100%;
            width: 100%;
            transform: rotate(4deg);
        }
    }

    &.checkbox--checked {
        border-radius: ${Pixel2Rem(3)}; 
        background-color: ${themeProvider.successColor};
        border: ${Pixel2Rem(1)} solid rgba(0, 0, 0, 0.2);
    }
`;

export default CheckboxComponent;
