import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Constants, {
    DRIVING_LICENSE_REQUIRED_LENGTH,
    FALLBACK_GENDER, GENDERS
} from '../../../../Utils/Constants';
import { IFormStepProps } from '../../PageWrapper/PageWrapper';
import GeneralInformation from '../../../Components/GeneralInformation/GeneralInformation';
import DI from '../../../../Utils/DI';
import Utils from '../../../../Utils/Utils';
import {
    IUserPersonalInformation, IUserDataItem, IUserAddressInformation, IUserDataList
} from '../../../Components/GeneralInformation/general-information-types';
import useStepContext from '../../../Hooks/useStepContext';
import IDataManager, { IReviewUserDetails } from '../../../../Business/IDataManager';

export interface IDrivingLicenseData extends IUserPersonalInformation {
    drivingLicenseNr: IUserDataItem & {validateDocumentFormat: RegExp};
}

export interface IDrivingLicenseAddressInformation extends IUserAddressInformation {
    province: IUserDataList
}

const ReviewDrivingLicenseDetails: FC<IFormStepProps> = ({
    nextStepHandler
}) => {
    const { t } = useTranslation();
    const { currentFormStep } = useStepContext();
    const dataManager: IDataManager = DI.get('DataManager');
    const {
        USER_DETAILS,
        userTown
    } = useMemo((): IReviewUserDetails => dataManager.convertToReviewUserDetails(!currentFormStep?.PROPS?.visited), []);

    const [userPersonalInformation, setUserPersonalInformation] = useState<IDrivingLicenseData>({
        firstName: {
            type: 'TEXT',
            input: USER_DETAILS.getFirstName(),
            isValid: Utils.validateFieldLength(
                { value: USER_DETAILS.getFirstName(), min: 3, max: 30 }
            ),
            placeHolder: t('generalInformation.firstName'),
            requiredLength: { min: 3, max: 30 },
            acceptanceCriteria: Utils.unicodeChars
        },
        lastName: {
            type: 'TEXT',
            input: USER_DETAILS.getLastName(),
            isValid: Utils.validateFieldLength(
                { value: USER_DETAILS.getLastName(), min: 3, max: 30 }
            ),
            placeHolder: t('generalInformation.lastName'),
            requiredLength: { min: 3, max: 30 },
            acceptanceCriteria: Utils.unicodeChars
        },
        dateOfBirth: {
            type: 'DATE',
            input: USER_DETAILS.getDateOfBirth(),
            placeHolder: '12/12/2012',
            isValid: Utils.validateDate(USER_DETAILS.getDateOfBirth()),
            requiredLength: { min: 10, max: 10 },
            acceptanceCriteria: Utils.dateCharacters
        },
        gender: {
            type: 'LIST',
            list: GENDERS,
            disabled: false,
            isValid: false,
            selectedItem: FALLBACK_GENDER,
            isLabelVisible: false
        },
        citizen: {
            type: 'LIST',
            list: Constants.MARKET_GEO_INFORMATION.NATIONALITIES,
            disabled: true,
            isValid: true,
            selectedItem: Constants.MARKET_GEO_INFORMATION.NATIONALITIES
                .find((citizenship) => citizenship.key
                === `citizen.${Constants.MARKET_GEO_INFORMATION.DEFAULT_NATIONALITY_KEY}`)
        },
        drivingLicenseNr: {
            type: 'TEXT',
            input: USER_DETAILS.getDocumentNumber(),
            isValid: Utils.validateFieldLength(
                {
                    value: USER_DETAILS.getDocumentNumber(),
                    min: DRIVING_LICENSE_REQUIRED_LENGTH.min,
                    max: DRIVING_LICENSE_REQUIRED_LENGTH.max
                }
            ) && Utils.withoutSpecialChars(
                USER_DETAILS.getDocumentNumber(),
                Constants.DOCUMENT_VALIDATION_RULES.DRIVING_LICENSE
            ),
            placeHolder: t('generalInformation.drivingLicenseNr'),
            requiredLength: { min: 11, max: 14 },
            acceptanceCriteria: Utils.withoutSpecialChars,
            validateDocumentFormat: Constants.DOCUMENT_VALIDATION_RULES.DRIVING_LICENSE
        },
        placeOfBirth: {
            type: 'TEXT',
            input: USER_DETAILS.getPlaceOfBirth(),
            isValid: Utils.validateFieldLength(
                { value: USER_DETAILS.getPlaceOfBirth(), min: 3, max: 30 }
            ),
            placeHolder: t('generalInformation.placeOfBirth'),
            requiredLength: { min: 3, max: 30 },
            acceptanceCriteria: Utils.unicodeChars
        }
    });

    const [
        userAddressInformation, setUserAddressInformation
    ] = useState<IDrivingLicenseAddressInformation>({
        street: {
            type: 'TEXT',
            input: USER_DETAILS.getStreet(),
            isValid: Utils.validateFieldLength(
                { value: USER_DETAILS.getStreet(), min: 3, max: 40 }
            ),
            requiredLength: { min: 3, max: 40 },
            placeHolder: t('generalInformation.street'),
            acceptanceCriteria: Utils.addressStreetCharacters
        },
        province: {
            type: 'LIST',
            isValid: !!userTown,
            disabled: false,
            selectedItem: Utils.getProvinceById(userTown?.provinceID)
            || Constants.MARKET_GEO_INFORMATION.FALLBACK_PROVINCE,
            isLabelVisible: !!userTown,
            list: Constants.MARKET_GEO_INFORMATION.PROVINCES
        },
        town: {
            type: 'LIST',
            isValid: !!userTown,
            disabled: false,
            selectedItem: userTown || Constants.MARKET_GEO_INFORMATION.FALLBACK_TOWN,
            isLabelVisible: false,
            list: userTown ? Utils.getTownsByProvinceIDFiltered(userTown.provinceID) : []
        },
        commune: {
            type: 'TEXT',
            input: USER_DETAILS.getCommune(),
            isValid: Utils.validateFieldLength(
                { value: USER_DETAILS.getCommune(), min: 3, max: 30 }
            ),
            requiredLength: { min: 5, max: 30 },
            placeHolder: t('generalInformation.commune'),
            acceptanceCriteria: Utils.unicodeChars
        }

    });

    return (
        <>
            <GeneralInformation
                nextStepHandler={nextStepHandler}
                userPersonalInformation={userPersonalInformation}
                setUserPersonalInformation={setUserPersonalInformation}
                userAddressInformation={userAddressInformation}
                setUserAddressInformation={setUserAddressInformation}
            />
        </>
    );
};

export default ReviewDrivingLicenseDetails;
