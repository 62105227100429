import { ILivenessCheckErrorCounter } from '../../Business/IAnalyticsManager';

export const ERROR_CODES = {
    GENERIC_ERROR: 500,
    EXPIRED_SESSION: 400101,
    REQUEST_OTP: {
        INVALID_MSISDN: 40015,
        IP_BLOCKED: 40350
    },
    VALIDATE_OTP: {
        MAXIMUM_ATTEMPTS: 40376,
        BLOCKED_OTP: -40376,
        INVALID_OTP: 4017,
        OTP_EXPIRED: 400102,
        DUPLICATED_PHONE_NUMBER: 40910
    },

    CAMERA: {
        UNEXPECTED_ERROR: 1700,
        VIDEO_SIZES: 1701,
        ABORT: 1702,
        NOT_ALLOWED: 1703,
        UNSUPPORTED_ORIENTATION: 1704,
        NOT_ALLOWED_ORIENTATION_CHANGE: 1705,
        UNABLE_TO_CREATE_BLOB: 1706,
        UNABLE_TO_UPLOAD_FRAME: 1707,
        CAMERA_NOT_FOUND: 1708,
        RESOURCE_IN_USE: 1709,
        MINIMUM_REQUIREMENTS_UNAVAILABLE: 1710,
        INSECURE_CONTEXT: 1711,
        RESOURCE_DISABLED: 1712,
        GENERIC_LOCAL_FAIL: 1713,
        NOT_ENOUGH_CAMERAS: 1714
    },

    VERIFY_CHALLENGE: {
        FACE_NOT_IN_AREA_BOX: 400113,
        NOSE_NOT_IN_NOSE_BOX: 400114,
        NOSE_TRAJECTORY_INVALID: 400115,
        FACE_AND_CHALLENGE_DIFFERENT_ROTATIONS: 400116,
        INVALID_EUCLIDEAN_DISTANCE: 400117,
        FACIAL_EXPRESSION_NOT_RECOGNIZED: 400125,
        SOMETHING_WENT_WRONG: 1715
    },

    ACCOUNT_CREATION: {
        UNDERAGE: 40379
    },

    UPLOAD_DOCUMENT: {
        DOCUMENT_EXPIRED: 400111,
        FORBIDDEN_NATIONALITY: 40377,
        INSUFFICIENT_FIELDS: 400108,
        DOCUMENT_ID_NOT_FOUND: 40047,
        UNCLEAR_IMAGE: 4002,
        NORMALIZER_POINT_NOT_FOUND: 40448,
        GENERIC_DOCUMENT_IS_NOT_CLEAR: 18004,
        INVALID_DATE: 4005
    },
    VALIDATE_MSISDN: {
        UNAUTHORIZED: 40100,
        BLOCKED: 40101,
        DUPLICATED: 40102
    },
    VALIDATE_RECAPTCHA: {
        VALIDATION_FAILED: 400127,
        BLOCKED: 400128
    },
    FALLBACK_SERVICE: {
        TIMEOUT: 1716,
        REQUEST_NOT_FOUND: 4046
    },
    VALIDATE_AGENCY: {
        INACTIVE_ACCOUNT: 40045,
        UNSUPPORTED_ACCOUNT: 4031,
        NONEXISTENT_ACCOUNT: 40030,
        GENERIC_ERROR: 40050
    },
    VALIDATE_AGENT: {
        CREDENTIALS_LOCKED: 40025,
        INCORRECT_PIN: 40022,
        NONEXISTENT_OPERATOR_ID: 40029,
        UNSUPPORTED_ROLE: 4039,
        OPERATOR_ID_NOT_ACTIVE: 40333,
        GENERIC_ERROR: 40051
    },
    LIVENESS_CHECK: {
        CANT_CREATE_DETECTOR: 50017,
        UNAVAILABLE_MEDIA_API: 40017
    },
    VERIFY_CUSTOMER_IMAGE: {
        UNEXPECTED_TYPE: 400200
    },
    VALIDATE_AGENT_PIN: {
        INVALID_PIN: 40022,
        CREDENTIALS_LOCKED: 40025,
        GENERIC_FAIL: 50002
    },
    VALIDATE_CUSTOMER_ID: {
        NOT_FOUND: 4040
    },
    REQUEST_REVERSE_OTP: {
        RECOVERABLE_ERROR: 50017,
        GENERIC_ERROR: 5001
    },
    VALIDATE_REVERSE_OTP: {
        INCORRECT: 4017, // INFO: Incorrect otp
        EXPIRED: 400102, // INFO: Otp expired
        BLOCKED: 40376, // INFO: Too many incorrect attempts
        INVALID: 4040 // INFO: Invalid link id
    },

    CREATE_AGENT_AIDED_ACCOUNT: {
        ACCOUNT_CREATION_FAILED: 5001,
        BLOCKED: 403
    },
    USSD_PUSH: {
        PENDING_OPERATION: 50003
    }
};

export const DOCUMENT_NOT_CLEAR_ERRORS = [
    // This errors indicate that the provided document image is not clear
    ERROR_CODES.UPLOAD_DOCUMENT.INSUFFICIENT_FIELDS,
    ERROR_CODES.UPLOAD_DOCUMENT.DOCUMENT_ID_NOT_FOUND,
    ERROR_CODES.UPLOAD_DOCUMENT.UNCLEAR_IMAGE,
    ERROR_CODES.UPLOAD_DOCUMENT.NORMALIZER_POINT_NOT_FOUND,
    ERROR_CODES.UPLOAD_DOCUMENT.INVALID_DATE
];

export const RECOVERABLE_LIVELINESS_CHALLENGE_ERRORS = [
    ERROR_CODES.CAMERA.GENERIC_LOCAL_FAIL,
    ERROR_CODES.CAMERA.UNABLE_TO_CREATE_BLOB,
    ERROR_CODES.CAMERA.UNABLE_TO_UPLOAD_FRAME,
    ERROR_CODES.CAMERA.UNSUPPORTED_ORIENTATION,
    ERROR_CODES.CAMERA.NOT_ALLOWED_ORIENTATION_CHANGE,
    500,
    // NOTE: this error aggregates all recoverable BE provided errors
    ERROR_CODES.VERIFY_CHALLENGE.NOSE_TRAJECTORY_INVALID
];

export const RECOVERABLE_ACCOUNT_CREATION_ERRORS = [
    ERROR_CODES.ACCOUNT_CREATION.UNDERAGE
];

const {
    NONEXISTENT_ACCOUNT,
    UNSUPPORTED_ACCOUNT,
    INACTIVE_ACCOUNT
} = ERROR_CODES.VALIDATE_AGENCY;

export const VALIDATE_AGENCY_SENSITIVE_ERRORS = [
    NONEXISTENT_ACCOUNT,
    UNSUPPORTED_ACCOUNT,
    INACTIVE_ACCOUNT,
    ERROR_CODES.VALIDATE_AGENT.NONEXISTENT_OPERATOR_ID
];

const {
    NONEXISTENT_OPERATOR_ID,
    UNSUPPORTED_ROLE,
    OPERATOR_ID_NOT_ACTIVE
} = ERROR_CODES.VALIDATE_AGENT;

export const VALIDATE_AGENT_SENSITIVE_ERRORS = [
    NONEXISTENT_OPERATOR_ID,
    UNSUPPORTED_ROLE,
    OPERATOR_ID_NOT_ACTIVE
];

export const SPAM_GUARD_ERRORS = [
    ERROR_CODES.REQUEST_OTP.IP_BLOCKED,
    ERROR_CODES.VALIDATE_RECAPTCHA.BLOCKED,
    ERROR_CODES.VALIDATE_MSISDN.BLOCKED
];

export const REQUEST_REVERSE_OTP_RECOVERABLE_ERRORS = [
    ERROR_CODES.REQUEST_REVERSE_OTP.RECOVERABLE_ERROR
];

export interface IError {
    hasError?: boolean;
    title: string;
    description: string;
    callToActionText?: string,
    eventTracker?: keyof ILivenessCheckErrorCounter;
}
interface IErrors {
    [key: number] : IError;
}

export const ERRORS_DESCRIPTION: IErrors = {
    [ERROR_CODES.GENERIC_ERROR]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.please'
    },
    [ERROR_CODES.CAMERA.UNEXPECTED_ERROR]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.genericError'
    },
    [ERROR_CODES.CAMERA.VIDEO_SIZES]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.genericError'
    },
    [ERROR_CODES.CAMERA.ABORT]: {
        title: 'error.userMedia.abort',
        description: 'error.genericError'
    },
    [ERROR_CODES.CAMERA.NOT_ALLOWED]: {
        title: 'error.userMedia.accessDenied',
        description: 'error.userMedia.grantAccess'
    },
    [ERROR_CODES.CAMERA.UNSUPPORTED_ORIENTATION]: {
        title: 'error.userMedia.notSupportedOrientation',
        description: 'error.userMedia.notSupportedOrientationDescription'
    },
    [ERROR_CODES.CAMERA.NOT_ALLOWED_ORIENTATION_CHANGE]: {
        title: 'error.userMedia.notSupportedOrientation',
        description: 'error.userMedia.orientationChange',
        callToActionText: 'general.tryAgain',
        eventTracker: 'UNSUPPORTED_ORIENTATION'
    },
    [ERROR_CODES.CAMERA.UNABLE_TO_CREATE_BLOB]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.genericError'
    },
    [ERROR_CODES.CAMERA.UNABLE_TO_UPLOAD_FRAME]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.genericError'
    },
    [ERROR_CODES.CAMERA.CAMERA_NOT_FOUND]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.genericError'
    },
    [ERROR_CODES.CAMERA.RESOURCE_IN_USE]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.genericError'
    },
    [ERROR_CODES.CAMERA.MINIMUM_REQUIREMENTS_UNAVAILABLE]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.genericError'
    },
    [ERROR_CODES.CAMERA.INSECURE_CONTEXT]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.genericError'
    },
    [ERROR_CODES.CAMERA.RESOURCE_DISABLED]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.genericError'
    },
    [ERROR_CODES.CAMERA.GENERIC_LOCAL_FAIL]: {
        title: 'error.challengeValidation.challengeFailed',
        description: 'error.challengeValidation.challengeFailedDescription',
        eventTracker: 'CANNOT_DETECT_A_PERSON'
    },
    [ERROR_CODES.ACCOUNT_CREATION.UNDERAGE]: {
        title: 'error.accountCreation.underage',
        description: 'error.accountCreation.visitAnAgent'
    },
    [ERROR_CODES.EXPIRED_SESSION]: {
        title: 'error.cookieExpired.title',
        description: 'error.cookieExpired.title.message'
    },
    [ERROR_CODES.VERIFY_CHALLENGE.NOSE_TRAJECTORY_INVALID]: {
        title: 'error.challengeValidation.challengeFailed',
        description: 'error.challengeValidation.challengeFailedDescription'
    },
    [ERROR_CODES.CAMERA.NOT_ENOUGH_CAMERAS]: {
        title: 'error.userMedia.notEnoughCameras.title',
        description: 'error.userMedia.notEnoughCameras.message'
    },
    [ERROR_CODES.UPLOAD_DOCUMENT.GENERIC_DOCUMENT_IS_NOT_CLEAR]: {
        title: 'error.uploadDocument.unclear.title',
        description: 'error.uploadDocument.unclear.message',
        callToActionText: 'error.uploadDocument.unclear.buttonText'
    },
    [ERROR_CODES.UPLOAD_DOCUMENT.DOCUMENT_EXPIRED]: {
        title: 'error.uploadDocument.expired.title',
        description: 'error.uploadDocument.expired.message',
        callToActionText: 'error.uploadDocument.expired.buttonText'
    },
    [ERROR_CODES.UPLOAD_DOCUMENT.FORBIDDEN_NATIONALITY]: {
        title: 'error.uploadDocument.forbiddenNationality.title',
        description: 'error.uploadDocument.forbiddenNationality.message',
        callToActionText: 'error.uploadDocument.forbiddenNationality.buttonText'
    },
    [ERROR_CODES.VERIFY_CHALLENGE.SOMETHING_WENT_WRONG]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.challengeValidation.somethingWentWrongDescription',
        eventTracker: 'SOMETHING_WENT_WRONG'
    },
    [ERROR_CODES.VALIDATE_MSISDN.UNAUTHORIZED]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.challengeValidation.somethingWentWrongDescription'
    },
    [ERROR_CODES.VALIDATE_RECAPTCHA.VALIDATION_FAILED]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.please'
    },
    [ERROR_CODES.VALIDATE_RECAPTCHA.BLOCKED]: {
        title: 'enterPhoneNumber.errors.currentlyBlocked',
        description: 'enterPhoneNumber.errors.tryAgain'
    },
    [ERROR_CODES.FALLBACK_SERVICE.TIMEOUT]: {
        title: 'fallbackService.errors.somethingWentWrong',
        description: 'fallbackService.errors.timeout'
    },
    [ERROR_CODES.FALLBACK_SERVICE.REQUEST_NOT_FOUND]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.please'
    },
    [ERROR_CODES.VALIDATE_AGENCY.INACTIVE_ACCOUNT]: {
        title: 'validateAgency.errors.inactiveBusiness',
        description: 'validateAgency.errors.inactiveBusinessDescription'
    },
    [ERROR_CODES.VALIDATE_AGENCY.UNSUPPORTED_ACCOUNT]: {
        title: 'validateAgency.errors.unsupportedProduct',
        description: 'validateAgency.errors.unsupportedProductDescription'
    },
    [ERROR_CODES.VALIDATE_AGENCY.NONEXISTENT_ACCOUNT]: {
        title: 'validateAgency.errors.nonexistentAccount',
        description: 'validateAgency.errors.nonexistentAccountDescription'
    },
    [ERROR_CODES.VALIDATE_AGENCY.GENERIC_ERROR]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'validateAgency.errors.genericError'
    },
    [ERROR_CODES.VALIDATE_AGENT.INCORRECT_PIN]: {
        title: 'validateAgent.errors.incorrectPin',
        description: 'validateAgent.errors.incorrectPinDescription'
    },
    [ERROR_CODES.VALIDATE_AGENT.CREDENTIALS_LOCKED]: {
        title: 'validateAgent.errors.credentialsLocked',
        description: 'validateAgent.errors.credentialsLockedDescription'
    },
    [ERROR_CODES.VALIDATE_AGENT.UNSUPPORTED_ROLE]: {
        title: 'validateAgent.errors.unsupportedRole',
        description: 'validateAgent.errors.unsupportedRoleDescription'
    },
    [ERROR_CODES.VALIDATE_AGENT.NONEXISTENT_OPERATOR_ID]: {
        title: 'validateAgent.errors.nonexistentOperator',
        description: 'validateAgent.errors.nonexistentOperatorDescription'
    },
    [ERROR_CODES.VALIDATE_AGENT.OPERATOR_ID_NOT_ACTIVE]: {
        title: 'validateAgent.errors.operatorNotActive',
        description: 'validateAgent.errors.operatorNotActiveDescription'
    },
    [ERROR_CODES.VALIDATE_AGENT.GENERIC_ERROR]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'validateAgent.errors.genericError'
    },
    [ERROR_CODES.LIVENESS_CHECK.CANT_CREATE_DETECTOR]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.challengeValidation.somethingWentWrongDescription',
        eventTracker: 'SOMETHING_WENT_WRONG'
    },
    [ERROR_CODES.REQUEST_OTP.IP_BLOCKED]: {
        title: 'spamGuard.errors.resourceBlockedTitle',
        description: 'spamGuard.errors.resourceBlockedDescription'
    },
    [ERROR_CODES.VERIFY_CUSTOMER_IMAGE.UNEXPECTED_TYPE]: {
        title: 'error.apiErrors.somethingWentWrong',
        description: 'error.genericError'
    },
    [ERROR_CODES.VALIDATE_AGENT_PIN.INVALID_PIN]: {
        title: 'validateAgent.errors.incorrectPin',
        description: 'validateAgent.errors.incorrectPinDescription'
    },
    [ERROR_CODES.VALIDATE_MSISDN.DUPLICATED]: {
        title: 'error.duplicatedPhoneNumber',
        description: 'error.customerDuplicatedPhoneNumberDescription'
    },
    [ERROR_CODES.VALIDATE_MSISDN.BLOCKED]: {
        title: 'spamGuard.errors.resourceBlockedTitle',
        description: 'spamGuard.errors.resourceBlockedDescription'
    },
    [ERROR_CODES.VALIDATE_CUSTOMER_ID.NOT_FOUND]: {
        title: 'reverseOTP.customer.errors.invalidLink',
        description: 'reverseOTP.customer.errors.invalidLinkDescription'
    },
    [ERROR_CODES.VALIDATE_REVERSE_OTP.INCORRECT]: {
        title: 'reverseOTP.customer.errors.incorrectOtp',
        description: 'reverseOTP.customer.errors.incorrectOtpDescription'
    },
    [ERROR_CODES.VALIDATE_REVERSE_OTP.EXPIRED]: {
        title: 'reverseOTP.customer.errors.expiredOtp',
        description: 'reverseOTP.customer.errors.expiredOtpDescription'
    },
    [ERROR_CODES.VALIDATE_REVERSE_OTP.BLOCKED]: {
        title: 'reverseOTP.customer.errors.blockedOtp',
        description: 'reverseOTP.customer.errors.blockedOtpDescription'
    },
    [ERROR_CODES.VALIDATE_REVERSE_OTP.INVALID]: {
        title: 'reverseOTP.customer.errors.invalidLink',
        description: 'reverseOTP.customer.errors.invalidLinkDescription'
    },
    [ERROR_CODES.REQUEST_REVERSE_OTP.RECOVERABLE_ERROR]: { // TBC
        title: 'This is a recoverable error',
        description: 'By clicking the Try again button, you can try again'
    },
    [ERROR_CODES.VALIDATE_AGENT_PIN.GENERIC_FAIL]: {
        title: 'error.accountCreation.agentAidedRegistrationGenericFail',
        description: 'error.accountCreation.agentAidedRegistrationGenericFailDescription'
    },
    [ERROR_CODES.VALIDATE_AGENT_PIN.CREDENTIALS_LOCKED]: {
        title: 'validateAgent.errors.credentialsLocked',
        description: 'validateAgent.errors.credentialsLockedDescription'
    },
    [ERROR_CODES.CREATE_AGENT_AIDED_ACCOUNT.ACCOUNT_CREATION_FAILED]: {
        title: 'error.accountCreation.agentAidedRegistrationFailed',
        description: 'confirmationMethod.ussdOption.error'
    },
    [ERROR_CODES.CREATE_AGENT_AIDED_ACCOUNT.BLOCKED]: {
        title: 'spamGuard.errors.resourceBlockedTitle',
        description: 'spamGuard.errors.ussdPushBlockedDescription'
    }
};
