import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface IPrivacyPolicyItem {
    nrOfParagraphs: number;
    startPoint: number;
    titleLabel?: string;
    descriptionLabel?: string;
}

interface IProps {
    privacyPolicyItem: IPrivacyPolicyItem
}

const PrivacyPolicyItem: FC<IProps> = ({ privacyPolicyItem }) => {
    const { t } = useTranslation();
    const defaultTitleLabel = 'privacyPolicy.paragraph';
    const defaultDescriptionLabel = 'privacyPolicy.paragraphDescription';

    return (
        <>
            <div className="content">
                <h3 className="topic">{t('privacyPolicy.topic6')}</h3>
                {[...Array(privacyPolicyItem.nrOfParagraphs)].map((item, index) => (
                    <div key={item}>
                        <h5 className="paragraph">
                            {t(`${privacyPolicyItem?.titleLabel || defaultTitleLabel}${(index + 1) + privacyPolicyItem.startPoint}`)}
                        </h5>
                        <span className="paragraphDescription">
                            {t(`${privacyPolicyItem?.descriptionLabel
                                || defaultDescriptionLabel}${(index + 1) + privacyPolicyItem.startPoint}`)}
                        </span>
                    </div>
                ))}
            </div>
        </>
    );
};

export default PrivacyPolicyItem;
