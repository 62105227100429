import { FunctionComponent, SVGProps } from 'react';

/* eslint-disable no-unused-vars */
export enum ConfirmationMethodsEnum {
    SMS = 'sms',
    USSD = 'ussd'
}

export interface IAvailableConfirmationOptions {
    icon: FunctionComponent<SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }>;
    type: ConfirmationMethodsEnum;
    isEnabled: boolean;
}
