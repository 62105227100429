import styled from 'styled-components';
import Pixel2Rem from '../../../../Utils/Pixel2Rem';
import { themeProvider } from '../../../Theme/ThemeProvider';

const ValidateReverseOtpScreen = styled.form`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: ${Pixel2Rem(25)};
    flex: 1 1 auto;
    font-family: ${themeProvider.fontFamily};

    .reverseOtp {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        gap: ${Pixel2Rem(16)};
        margin-top: ${Pixel2Rem(25)};

        .reverseOtp__title {
            font-size: ${Pixel2Rem(28)};
            color: ${themeProvider.titleColor};
            line.height: ${Pixel2Rem(34)};
            font-family: ${themeProvider.fontBold};
        }

        .reverseOtp__description {
            color: ${themeProvider.darkGrey};
            font-size: ${Pixel2Rem(16)};
            line-height: ${Pixel2Rem(20)};
        }

        .reverseOtp__otp-container {
            box-sizing: border-box;
            margin-top: ${Pixel2Rem(24)};
        }
    }

    .footer {
        width: fill-available;
        padding-bottom: ${Pixel2Rem(10)};
        font-family: ${themeProvider.fontFamily};
        font-size: ${Pixel2Rem(18)};
    }
`;

export default ValidateReverseOtpScreen;
