import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import { SupportedModels } from '@tensorflow-models/face-landmarks-detection';
import * as faceMesh from '@mediapipe/face_mesh';
import { ERROR_CODES } from '../Constants/Errors';
import Constants, { LOGGER_TAGS } from '../Constants';
import Logger from '../Logger';
import {
    IConfig,
    IMediaStreamDetector,
    IMediaStreamInfo,
    IMediaStreamLoader
} from './MediaStream.types';
import LivelinessCheck from '../Constants/LivelinessCheck';
import IChallengeManager from '../../Business/IChallengeManager';
import DI from '../DI';
import { USING_MOCKS } from '../../Protocol';
import { ResourceStatusEnum } from '../../Protocol/IHttpInterface';

class MediaStreamConfig {
    private static _config: IConfig;

    private static _totalVideoInputDevices: number;

    private static _mediaStreamInfo: IMediaStreamInfo | null;

    private static _video: HTMLVideoElement;

    private static _canvas: HTMLCanvasElement;

    private static _ctx: CanvasRenderingContext2D;

    private static _faceDetector: IMediaStreamDetector;

    constructor() {
        Logger.log(LOGGER_TAGS.MEDIA_STREAM, 'Media stream initialized');
    }

    static loadConfig() {
        const map = new Map();
        Object.entries(Constants.LIVELINESS_CHECK.mediaConfig).forEach(([key, value]) => {
            map.set(key, value);
        });
        MediaStreamConfig._config = Object.fromEntries(map);
    }

    static async loadNumberOfDeviceCameras() {
        let mediaDevices: MediaDeviceInfo[];
        try {
            mediaDevices = await navigator.mediaDevices.enumerateDevices();
        } catch (e) {
            // NOTE: https://forum.flashphoner.com/threads/ios-14-safari-with-no-support-to-navigator-mediadevices-api.13408/
            // for testing locally on iOS this API will not work!
            Logger.error(LOGGER_TAGS.MEDIA_CONFIG, 'enumerateDevices error: ', e);
            mediaDevices = [];
        }
        MediaStreamConfig._totalVideoInputDevices = mediaDevices.filter((mediaDevice) => mediaDevice.kind === 'videoinput').length;
    }

    static async loadMediaStream(
        { constraints, successCallback, errorCallback } : IMediaStreamLoader
    ): Promise<void> {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            errorCallback(ERROR_CODES.LIVENESS_CHECK.UNAVAILABLE_MEDIA_API);
            return;
        }

        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
            try {
                MediaStreamConfig._mediaStreamInfo = {
                    mediaStream,
                    actualHeight: mediaStream.getVideoTracks()[0].getSettings().height
                    || LivelinessCheck.mediaConfig.VIDEO_HEIGHT,
                    actualWidth: mediaStream.getVideoTracks()[0].getSettings().width
                    || LivelinessCheck.mediaConfig.VIDEO_WIDTH
                };
                Logger.log(
                    LOGGER_TAGS.MEDIA_CONFIG,
                    `media info: actualHeight=${MediaStreamConfig._mediaStreamInfo.actualHeight}
                    actualWidth=${MediaStreamConfig._mediaStreamInfo.actualWidth}`
                );
                MediaStreamConfig._video = document.createElement('video');
                MediaStreamConfig._canvas = document.createElement('canvas');
                MediaStreamConfig._ctx = MediaStreamConfig._canvas.getContext('2d') as CanvasRenderingContext2D;
                // TODO: FIXME: the video and canvas should not be created here!');
                MediaStreamConfig._video.srcObject = MediaStreamConfig._mediaStreamInfo.mediaStream;

                await new Promise((resolve) => {
                    MediaStreamConfig._video.onloadedmetadata = () => {
                        resolve('');
                    };
                });

                const { videoWidth } = MediaStreamConfig._video;
                const { videoHeight } = MediaStreamConfig._video;
                // Must set below two lines, otherwise video element doesn't show.
                MediaStreamConfig._video.width = videoWidth;
                MediaStreamConfig._video.height = videoHeight;
                MediaStreamConfig._video.id = 'webcamVideo';
                MediaStreamConfig._video.classList.add('rotate');
                MediaStreamConfig._video.playsInline = true;
                MediaStreamConfig._video.muted = true;
                MediaStreamConfig._video.autoplay = true;

                MediaStreamConfig._canvas.width = videoWidth;
                MediaStreamConfig._canvas.height = videoHeight;
                MediaStreamConfig._canvas.id = 'overlayCanvas';
                MediaStreamConfig._canvas.classList.add('rotate');
                MediaStreamConfig._canvas.style.zIndex = '10';
                successCallback(mediaStream);
            } catch (error) {
                Logger.error(LOGGER_TAGS.MEDIA_CONFIG, 'Error initializing mediaStream', error);
                errorCallback(Constants.ERRORS.ERROR_CODES.CAMERA.VIDEO_SIZES);
            }
        } catch (error: any) {
            Logger.error(LOGGER_TAGS.MEDIA_CONFIG, 'Error requesting camera:', { error, errorName: error.name });
            let errorCode: number = 0;
            switch (error.name) {
            case 'AbortError': errorCode = Constants.ERRORS.ERROR_CODES.CAMERA.ABORT;
                break;
            case 'NotAllowedError' || 'PermissionDeniedError' || 'PermissionDismissedError':
                errorCode = Constants.ERRORS.ERROR_CODES.CAMERA.NOT_ALLOWED;
                break;

            case 'NotFoundError | DevicesNotFoundError': errorCode = Constants.ERRORS.ERROR_CODES.CAMERA.CAMERA_NOT_FOUND;
                break;

            case 'NotReadableError' || 'TrackStartError': errorCode = Constants.ERRORS.ERROR_CODES.CAMERA.RESOURCE_IN_USE;
                break;

            case 'OverconstrainedError' || 'ConstraintNotSatisfiedError':
                errorCode = Constants.ERRORS.ERROR_CODES.CAMERA.MINIMUM_REQUIREMENTS_UNAVAILABLE;
                break;

            case 'SecurityError': errorCode = Constants.ERRORS.ERROR_CODES.CAMERA.RESOURCE_DISABLED;
                break;

            case 'TypeError': errorCode = Constants.ERRORS.ERROR_CODES.CAMERA.INSECURE_CONTEXT;
                break;

            default: errorCode = Constants.ERRORS.ERROR_CODES.CAMERA.UNEXPECTED_ERROR;
            }
            errorCallback(errorCode);
        }
    }

    static createDetector() {
        const challengeManager: IChallengeManager = DI.get('ChallengeManager');
        challengeManager.setFaceDetectorReady(ResourceStatusEnum.PENDING);

        return faceLandmarksDetection.createDetector(SupportedModels.MediaPipeFaceMesh, {
            runtime: 'mediapipe',
            refineLandmarks: Constants.LIVELINESS_CHECK.mediaConfig.REFINE_LANDMARKS,
            maxFaces: Constants.LIVELINESS_CHECK.mediaConfig.MAX_FACES,
            solutionPath: USING_MOCKS
                ? `${Constants.API_ENDPOINTS.REQUEST_TENSORFLOW_FACE_MODELS_MOCKED}${faceMesh.VERSION}`
                : `${Constants.API_ENDPOINTS.REQUEST_TENSORFLOW_FACE_MODELS}${faceMesh.VERSION}`
        })
            .then((detector) => {
                this._faceDetector = {
                    detector,
                    error: null
                };
                challengeManager.setFaceDetectorReady(ResourceStatusEnum.SUCCEEDED);
            })
            .catch((error) => {
                this._faceDetector = {
                    detector: null,
                    error: {
                        code: ERROR_CODES.LIVENESS_CHECK.CANT_CREATE_DETECTOR,
                        description: error
                    }
                };
                challengeManager.setFaceDetectorReady(ResourceStatusEnum.FAILED);
            });
    }

    static closeMediaStream() {
        if (MediaStreamConfig._mediaStreamInfo?.mediaStream) {
            MediaStreamConfig._mediaStreamInfo.mediaStream.getVideoTracks()[0].stop();
        }
    }

    static getConfig(): IConfig {
        return MediaStreamConfig._config;
    }

    static getTotalVideoInputDevices(): number {
        return MediaStreamConfig._totalVideoInputDevices;
    }

    static getMediaStreamInfo(): IMediaStreamInfo | null {
        return MediaStreamConfig._mediaStreamInfo;
    }

    static getCtx(): CanvasRenderingContext2D {
        return MediaStreamConfig._ctx;
    }

    static getCanvas(): HTMLCanvasElement {
        return MediaStreamConfig._canvas;
    }

    static setCanvas(value: HTMLCanvasElement) {
        MediaStreamConfig._canvas = value;
    }

    static getVideo(): HTMLVideoElement {
        return MediaStreamConfig._video;
    }

    static setVideo(value: HTMLVideoElement) {
        MediaStreamConfig._video = value;
    }

    static getFaceDetector(): IMediaStreamDetector {
        return MediaStreamConfig._faceDetector;
    }
}

export default MediaStreamConfig;
