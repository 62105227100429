import { FC } from 'react';
import BadgeComponent, { IBadgeSizes } from './Style';

interface IProps {
    content: string | number;
    size: keyof IBadgeSizes;
    boldContent?: boolean;
    color?: string;
    backgroundColor?: string;
}

const Badge: FC<IProps> = ({
    content, size, boldContent, color, backgroundColor
}) => (
    <>
        <BadgeComponent color={color} backgroundColor={backgroundColor} size={size} boldContent={Boolean(boldContent)}>
            {content}
        </BadgeComponent>
    </>
);

export default Badge;
