import { FC, useEffect, useState } from 'react';
import CounterComponent from './Style';

interface ICounterProps {
    startAtSecond?: number;
    endOfTimeAction: Function
}

const Counter: FC<ICounterProps> = ({ startAtSecond = 0, endOfTimeAction }) => {
    const [seconds, setSeconds] = useState(startAtSecond);
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 1) {
                setSeconds(seconds - 1);
            }
            if (seconds === 1) {
                clearInterval(interval);
                setIsOpen(false);
                endOfTimeAction();
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    return (
        <>
            <CounterComponent isOpen={isOpen}>{seconds}</CounterComponent>
        </>
    );
};

export default Counter;
