import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CookiePolicyItem from './CookiePolicyItem/CookiePolicyItem';
import CookieSubtopicItem from './CookieSubtopicItem/CookieSubtopicItem';
import CookiePolicyScreen from './Style';

const CookiePolicy: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <CookiePolicyScreen>
                <div className="title">
                    <span>{t('screenLabels.cookiePolicy')}</span>
                </div>

                {[...Array(4)]
                    .map((item, index) => (
                        <CookiePolicyItem key={item} topicIndex={index + 1} />)
                    )}

                {[...Array(4)]
                    .map((item, index) => (
                        <CookieSubtopicItem
                            key={item}
                            subTopicIndex={index + 1}
                        />
                    ))}
                <CookiePolicyItem topicIndex={5} />
            </CookiePolicyScreen>
        </>
    );
};

export default CookiePolicy;
