export const SuccessStatusCodes = {
    200: 200,
    201: 201,
    204: 204
} as const;

export type SuccessStatusCodesKeys = typeof SuccessStatusCodes[keyof typeof SuccessStatusCodes];

export enum ErrorStatusEnum {
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401
}

export enum CookiesEnum {
    ENCRYPTION_COOKIE = 'COOKIE_MPESA_EKYC'
}
