import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

interface IProps {
    withBackdrop?: boolean
}

const LoaderComponent = styled.div<IProps>`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${({ withBackdrop }) => (withBackdrop ? themeProvider.backgroundColor : themeProvider.primaryColor)};
    background-color: ${({ withBackdrop }) => (withBackdrop ? 'rgba(0, 0, 0, .6)' : themeProvider.backgroundColor)};

    .loader {
        height: ${Pixel2Rem(90)};
        width: ${Pixel2Rem(90)};
    }

    .loader__description {
        max-width: ${Pixel2Rem(212)};
        max-height: ${Pixel2Rem(46)};
        min-width: ${Pixel2Rem(105)};
        margin-top: ${Pixel2Rem(20)};
        font-family: 'VodafoneR';
        font-size: ${Pixel2Rem(18)};
        letter-spacing: 0;
        font-weight: 500;
        line-height: ${Pixel2Rem(23)};
        text-align: center;
    }
`;

export default LoaderComponent;
