import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

const PersonalDocumentsScreen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .wrapper {
        width: fill-available;
        margin-top: ${Pixel2Rem(24)};
    }

    .title {
        color: ${themeProvider.titleColor};
        font-family: ${themeProvider.fontBold};
        font-size: ${Pixel2Rem(28)};
        font-weight: bold;
        letter-spacing: 0;
        line-height: ${Pixel2Rem(34)};
        margin-bottom: ${Pixel2Rem(10)};
    }

    .subtitle {
        color: ${themeProvider.titleColor};
        font-family: ${themeProvider.fontFamily};
        font-size: ${Pixel2Rem(16)};
        letter-spacing: 0;
        line-height: ${Pixel2Rem(20)};
        margin-bottom: ${Pixel2Rem(35)};
    }

    .personal-documents {
        display: flex;
        flex-direction: column;
        align-items: start;

        .personal-document-item {
          margin-bottom: ${Pixel2Rem(15)};
          width: fill-available;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding-bottom: ${Pixel2Rem(20)};
          padding: 0 ${Pixel2Rem(16)} ${Pixel2Rem(20)} ${Pixel2Rem(5)};
          border-bottom: ${Pixel2Rem(1)} solid ${themeProvider.personalDocumentsSeparator};
          cursor: pointer;

          .personal-document-item__icon {
            margin-right: ${Pixel2Rem(16)};
            height: ${Pixel2Rem(32)};
            width: ${Pixel2Rem(32)};
          }

          .personal-document-item__description {
            flex-grow: 1;
            min-height: ${Pixel2Rem(20)};
            color: ${themeProvider.textColor};
            font-family: ${themeProvider.fontFamily};
            font-size: ${Pixel2Rem(18)};
            letter-spacing: 0;
            line-height: ${Pixel2Rem(20)};
          }

          .personal-document--selector {
            align-self: end;
          }
        }
      }
`;
export default PersonalDocumentsScreen;
