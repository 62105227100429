import { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import Constants from '../../Utils/Constants';
import DI from '../../Utils/DI';
import Utils from '../../Utils/Utils';
import IHttpInterface from '../IHttpInterface';
import { ErrorStatusEnum } from '../protocol-constants';
import IInterceptorsMap, {
    IInterceptor,
    InterceptorDataType,
    InterceptorsEnum,
    InterceptorTypeEnum,
    ResponseInterceptorDataType
} from './interceptors-types.ts';
import EncryptionCookieRenewalInterceptor from './Request/EncryptionCookieRenewalInterceptor';
import InternetConnectionInterceptor from './Response/InternetConnectionInterceptor';

class InterceptorsMap implements IInterceptorsMap {
    private _interceptors: Map<InterceptorsEnum, IInterceptor>;

    constructor() {
        this._interceptors = new Map();
    }

    private get httpInterface(): IHttpInterface {
        return DI.get('HttpInterface');
    }

    subscribeInterceptor(interceptorKey: InterceptorsEnum, interceptor: IInterceptor): void {
        this._interceptors.set(interceptorKey, interceptor);
    }

    setCurrentInterceptor(data: InterceptorDataType, interceptorType: InterceptorTypeEnum): void {
        const isResponseInterceptor = interceptorType === InterceptorTypeEnum.RESPONSE;

        let interceptorKey: InterceptorsEnum | undefined;
        let interceptorFn: (() => void) | undefined;

        if (isResponseInterceptor) {
            interceptorKey = this.convertToResponseInterceptorKey(data as ResponseInterceptorDataType);
            interceptorFn = interceptorKey && this._interceptors.get(interceptorKey)?.interceptorFn;
            this.httpInterface.setResponseInterceptor(interceptorFn);
        } else {
            interceptorKey = this.convertToRequestInterceptorKey(data as AxiosRequestConfig);
            interceptorFn = interceptorKey && this._interceptors.get(interceptorKey)?.interceptorFn;
            this.httpInterface.setRequestInterceptor(interceptorFn);
        }
    }

    /*
         * Method to return the interceptor key that is going to be used to index the correct response interceptor depending of each case
         * @returns {InterceptorEnum | undefined} a Interceptor key or undefined
         * @param {ResponseInterceptorDataType} response - the response returned by the server / client,
     */
    private convertToResponseInterceptorKey(response: ResponseInterceptorDataType): InterceptorsEnum | undefined {
        switch (true) {
        case !response.status && (/Network Error/).test((response as AxiosError).message):
            InternetConnectionInterceptor.setIsNetworkAvailable(false); // INFO: As per the current behavior,
            // when the network becomes available again, the application reloads(1), therefore the property is automatically set to true again.
            // If (1) ever changes, it will be necessary to manually set the property back to its initial value.
            return InterceptorsEnum.INTERNET_CONNECTION;
        case response.status === ErrorStatusEnum.BAD_REQUEST
        && Utils.convertToErrorCode((response as AxiosResponse).data.errorCode) === Constants.ERRORS.ERROR_CODES.EXPIRED_SESSION:
        case response.config?.url !== Constants.API_ENDPOINTS.SESSION && response.status === ErrorStatusEnum.UNAUTHORIZED:
            return InterceptorsEnum.ENCRYPTION_SESSION_EXPIRED;
        default: return undefined;
        }
    }

    /*
         * Method to return the interceptor key that is going to be used to index the correct request interceptor depending of each case
         * @returns {InterceptorEnum | undefined} a Interceptor key or undefined
         * @param {AxiosRequestConfig} requestConfig - the request being sent by the client
     */
    private convertToRequestInterceptorKey(requestConfig: AxiosRequestConfig): InterceptorsEnum | undefined {
        switch (true) {
        case EncryptionCookieRenewalInterceptor.hasCookieExpired() && requestConfig?.url !== Constants.API_ENDPOINTS.PUBLIC_KEY_NEGOTIATION:
            return InterceptorsEnum.ENCRYPTION_SESSION_RENEWAL;
        default: return undefined;
        }
    }
}

export default new InterceptorsMap();
