import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

interface IProps {
    error: string;
}

const EnterPhoneNumberScreen = styled.form<IProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;

    .wrapper {
        width: fill-available;
        margin: ${Pixel2Rem(24)} auto auto 0;
        margin-bottom: ${Pixel2Rem(20)};

    }

    .msisdn-wrapper {
        &::after {
            position: absolute;
            content: '${(props) => props.error}';
            color: ${themeProvider.errorColor};
            height: ${Pixel2Rem(18)};
            left: ${Pixel2Rem(135)};
            top: unset;
            font-family: ${themeProvider.fontFamily};
            font-size: ${Pixel2Rem(14)};
            letter-spacing: 0;
            line-height: ${Pixel2Rem(18)};
        }
    }

    .title {
        color: ${themeProvider.titleColor};
        font-family: ${themeProvider.fontBold};
        font-size: ${Pixel2Rem(28)};
        font-weight: bold;
        letter-spacing: 0;
        line-height: ${Pixel2Rem(33)};
        margin-bottom: ${Pixel2Rem(10)};
    }

    .subtitle {
        color: ${themeProvider.titleColor};
        font-family: ${themeProvider.fontFamily};
        font-size: ${Pixel2Rem(16)};
        letter-spacing: 0;
        line-height: ${Pixel2Rem(20)};
        margin-bottom: ${Pixel2Rem(60)};
    }

    .input-group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        position: relative;

        .country-code {
            margin-right: ${Pixel2Rem(10)};

            .country-code__flag {
                margin-right: ${Pixel2Rem(6)};
            }

            .country-code__code {
                height: ${Pixel2Rem(24)};
                width: ${Pixel2Rem(41)};
                color: ${themeProvider.inputColor};
                font-family: ${themeProvider.fontFamily};
                font-size: ${Pixel2Rem(16)};
                letter-spacing: 0;
                line-height: ${Pixel2Rem(24)};
            }
        }
    }

    .blocked__msisdn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: ${Pixel2Rem(26)};
        font-size: ${Pixel2Rem(16)};
        font-family: ${themeProvider.fontFamily};
        text-align: center;
        color: ${themeProvider.muted};
    }

    .footer {

        width: fill-available;
        justify-content: center;
        align-self: center;
        padding-bottom: ${Pixel2Rem(10)};
        margin-top: ${Pixel2Rem(76)};
        font-family: ${themeProvider.fontFamily};

        .reCaptcha-waterMark {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-bottom: ${Pixel2Rem(20)};
            font-size: ${Pixel2Rem(15)};
            line-height: ${Pixel2Rem(20)};


            a {
                color: ${themeProvider.primaryColor};
            }
        }
    }
`;

export default EnterPhoneNumberScreen;
