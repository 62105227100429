import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Components/Button/Button';
import StarRatingScreen from './Style';
import Loader from '../../Components/Loader/Loader';
import IRatingManager from '../../../Business/IRatingManager';
import DI from '../../../Utils/DI';
import StarsRating from '../../Components/StarsRating/StarsRating';
import { themeProvider } from '../../Theme/ThemeProvider';
import ResultScreen from '../../Components/ResultScreen/ResultScreen';

const Rating: FC = () => {
    const ratingManager: IRatingManager = DI.get('RatingManager');
    const [isRatingComplete, setIsRatingComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ratingResult, setRatingResult] = useState(0);
    const [error, setError] = useState(false);

    const { t } = useTranslation();

    const ratingResultOutput: string[] = [
        'explanation',
        'notSatisfiedAtAll',
        'notSatisfied',
        'neutral',
        'satisfied',
        'verySatisfied'
    ];

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setIsLoading(true);
        ratingManager.submitRatingResult(ratingResult)
            .then(() => setIsRatingComplete(true))
            .catch(() => setError(true))
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            {isLoading && <Loader />}
            {!isRatingComplete && !isLoading && !error ? (

                <StarRatingScreen onSubmit={handleSubmit}>
                    <div className="wrapper">
                        <div className="title">
                            <span>{t('general.rateExperience')}</span>
                        </div>
                        <div className="subtitle">
                            <span>{t('starRating.rateYourExperience')}</span><br />
                            <span>{t('starRating.helpImprovement')}</span>
                        </div>
                    </div>

                    <div className="rating">
                        <div className="rating__stars">
                            <StarsRating
                                handleRating={setRatingResult}
                                emptyFeedbackColor={themeProvider.emptyStarRating}
                                averageFeedbackColor={themeProvider.averageStarRating}
                                satisfiedFeedbackColor={themeProvider.satisfiedStarRating}
                            />
                        </div>
                        <div className="rating__feedback">
                            <span className={ratingResult >= 4
                                ? 'rating__satisfied-star'
                                : ratingResult > 0 ? 'rating__average-star' : 'rating__empty-star'}
                            >
                                {t(`starRating.ratingOptions.${ratingResultOutput[ratingResult]}`)}
                            </span>
                        </div>
                    </div>
                    <div className="footer">
                        <Button
                            disabled={ratingResult <= 0}
                            type="submit"
                            mode="normal"
                            id="rate-experience-button"
                        >{`${t('starRating.rate')}`}
                        </Button>
                    </div>
                </StarRatingScreen>
            )
                : (
                    <ResultScreen
                        success={error}
                        title={error ? t('error.apiErrors.somethingWentWrong') : t('general.thankYou')}
                        description={error ? t('error.genericError') : t('starRating.feedbackImportance')}
                        actionText={error ? t('general.tryAgain') : t('general.complete')}
                    />
                )}
        </>
    );
};

export default Rating;
