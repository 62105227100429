import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

interface IProps {
    asFormStep: boolean; // INFO: Indicates that the Screen is being displayed as a form step and the horizontal padding doesn't need to be applied
}

const TermsAndConditionsScreen = styled.div<IProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${({ asFormStep }) => !asFormStep && `
        padding-left: ${Pixel2Rem(24)};
        padding-right: ${Pixel2Rem(24)};
    }
    `};
    padding-top: ${Pixel2Rem(24)};
    padding-bottom: ${Pixel2Rem(24)};
    height: 100%;
    flex: 1 1 auto;
    

    .container {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    .title {
        color: ${themeProvider.titleColor};
        font-family: ${themeProvider.fontBold};
        font-size: ${Pixel2Rem(28)};
        font-weight: bold;
        letter-spacing: 0;
        line-height: ${Pixel2Rem(33)};
    }

    .content {
        margin: ${Pixel2Rem(24)} 0;
        white-space: pre-line;
        color: ${themeProvider.titleColor};
        font-family: ${themeProvider.fontFamily};
        font-size: ${Pixel2Rem(16)};
        letter-spacing: 0;
        line-height: ${Pixel2Rem(20)};
    }
`;
export default TermsAndConditionsScreen;
