import {
    FC, FunctionComponent, SVGProps, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import DropdownItem from './DropdownItem/DropdownItem';
import DropdownComponent, { ItemToggler } from './Style';
import { ReactComponent as ChevronDownIcon } from '../../Assets/icons/ic_chevron_down.svg';
import { ReactComponent as ChevronUpIcon } from '../../Assets/icons/ic_chevron_up.svg';
import { themeProvider } from '../../Theme/ThemeProvider';
import Constants from '../../../Utils/Constants';
import useStepContext from '../../Hooks/useStepContext';
import ClickAwayListener from '../ClickAwayListener/ClickAwayListener';

export interface IDropdownItem { // TODO: improve this interface
    id: number,
    key: string,
    description: string,
    // Note that the description property MUST ONLY be provided
    // if the list to which the item belongs to  must be ordered following a given rule
    icon?: FunctionComponent<SVGProps<
    SVGSVGElement
  > & { title?: string }>
}
interface IProps {
    items: IDropdownItem[];
    onSelect: (item: IDropdownItem) => void;
    isInput?: boolean,
    defaultItem: IDropdownItem,
    disabled: boolean,
    identifier?: string,
    isDesktop?: boolean;
}

const Dropdown: FC<IProps> = ({
    items, onSelect, isInput, defaultItem, disabled, identifier, isDesktop
}) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const selectItemHandler = (selectedItem: any) => {
        onSelect(selectedItem);
        setIsOpen(!isOpen);
    };

    const toggleDropdown = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    return (
        <>
            <ItemToggler
                isInput={isInput}
                labelColor={disabled ? themeProvider.inputColor : themeProvider.darkGrey}
                onClick={toggleDropdown}
                className="item-toggler"
                role="button"
                tabIndex={0}
                id={`${identifier}-dropdown-toggler`.replace(/\s+/g, '').toLowerCase()}
                isDesktop={isDesktop}
            >
                <span className="item__description">
                    {t(defaultItem.key)}
                </span>
                {!disabled && (
                    <>{(isInput && isOpen)
                        ? <ChevronUpIcon className="item-toggler__chevron" />
                        : <ChevronDownIcon className="item-toggler__chevron" />}
                    </>
                )}
            </ItemToggler>
            {isOpen && (
                <ClickAwayListener onClick={toggleDropdown}>
                    <DropdownComponent
                        isInput={isInput}
                    >
                        {items.map((item) => (
                            <span
                                id={`dropodwn-item-${item.key}`}
                                key={item.key}
                                role="button"
                                tabIndex={0}
                                onClick={() => selectItemHandler(item)}
                            >
                                <DropdownItem
                                    item={item}
                                    selectedOption={defaultItem}
                                />
                            </span>
                        ))}
                    </DropdownComponent>
                </ClickAwayListener>
            )}
        </>
    );
};
export default Dropdown;
