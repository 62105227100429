import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import VoterCardFrontImage from '../../Assets/images/voter_card_2.png';
import TakeDocumentPhoto, { ITakeDocumentPhotoInstructions } from '../../Components/TakeDocumentPhoto/TakeDocumentPhoto';
import DI from '../../../Utils/DI';
import Constants, { DOCUMENT_ORIENTATION, DOCUMENT_TYPES } from '../../../Utils/Constants';
import { IFormStepProps } from '../PageWrapper/PageWrapper';
import IDocumentManager from '../../../Business/IDocumentManager';
import IUserManager from '../../../Business/UserManager/IUserManager';
import IChallengeManager from '../../../Business/IChallengeManager';
import useGaEventTracker from '../../Hooks/useGaEventTracker';
import IAnalyticsManager from '../../../Business/IAnalyticsManager';
import useStepContext from '../../Hooks/useStepContext';
import Utils from '../../../Utils/Utils';
import { ISteps } from '../../../Utils/Constants/FormSteps';
import ISessionManager from '../../../Business/ISessionManager';

const VoterCardV2Front: FC<IFormStepProps> = ({ nextStepHandler }) => {
    const { t } = useTranslation();

    const documentManager: IDocumentManager = DI.get('DocumentManager');
    const userManager: IUserManager = DI.get('UserManager');
    const challengeManager: IChallengeManager = DI.get('ChallengeManager');
    const { screenTracker, eventTracker } = useGaEventTracker();

    const takeDocumentPhotoInstructions: ITakeDocumentPhotoInstructions = {
        key: 'voterCardFrontV2',
        instructionsLength: 4
    };

    const analyticsManager: IAnalyticsManager = DI.get('AnalyticsManager');
    const { currentFormStep } = useStepContext();
    const sessionManager: ISessionManager = DI.get('SessionManager');
    const isAgentLogged: boolean | undefined = useMemo(() => sessionManager.getSession()?.getIsValid(), []);

    const onChangeFunction = (file: File) => {
        documentManager.processDocumentData(
            String(userManager.getMsisdn()),
            file,
            DOCUMENT_TYPES.VOTER_CARD_2.toLowerCase(),
            DOCUMENT_ORIENTATION.FRONT
        )
            .then(
                () => {
                    const elapsedTime = moment()
                        .subtract(moment.unix(analyticsManager.getDocumentUploadInitTime()).unix(), 'milliseconds').format('mm:ss');
                    eventTracker({
                        name: 'OCR',
                        objectValue: {
                            SUCCESS: `TOC: ${currentFormStep.KEY} - ${Utils.getTimeInterval(elapsedTime)}`
                        }
                    });

                    eventTracker({
                        name: 'OCR',
                        objectValue: {
                            SUCCESS: `ATTEMPTS - ${currentFormStep.KEY} - ${analyticsManager.getDocumentUploadAttemptsCounter()}`
                        }
                    });
                    analyticsManager.setDocumentUploadAttemptsCounter(1);

                    if (challengeManager.retrieveCapturedSelfie()) {
                        screenTracker({ screen: '6.REVIEW_DETAILS', screenClass: 'GENERAL_FORM' });
                        const REVIEW_DOCUMENT = `REVIEW_${documentManager
                            .getProcessedDocuments()[0].documentType.toUpperCase()}` as keyof ISteps;
                        nextStepHandler(Constants.FORM_STEPS[REVIEW_DOCUMENT]);
                    } else {
                        screenTracker({ screen: '5.LIVENESS_CHECK', screenClass: 'LIVENESS_CHECK' });
                        if (isAgentLogged) {
                            nextStepHandler(Constants.FORM_STEPS.CUSTOMER_VALIDATION);
                            return;
                        }
                        nextStepHandler(Constants.FORM_STEPS.SELFIE);
                    }
                },

                () => analyticsManager.setDocumentUploadAttemptsCounter(analyticsManager
                    .getDocumentUploadAttemptsCounter() + 1)

            );
    };

    return (
        <>
            <TakeDocumentPhoto
                title={t('voterCardFrontV2.message')}
                image={VoterCardFrontImage}
                instructionText={t('takePhoto.moveAndCenterDocument')}
                captureTitle={t('voterCardFrontV2.captureTitle')}
                validateTitle={t('voterCardFrontV2.validateTitle')}
                buttonText={t('voterCardFrontV2.buttonText')}
                onChangeFn={onChangeFunction}
                takeDocumentPhotoInstructions={takeDocumentPhotoInstructions}
            />
        </>
    );
};

export default VoterCardV2Front;
