import {
    FC, useEffect,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import customerRegistrationManager from '../../../Business/RegistrationManager/CustomerRegistrationManager';
import { APP_ROUTES } from '../../../Utils/Constants';
import { ERRORS_DESCRIPTION, ERROR_CODES, IError } from '../../../Utils/Constants/Errors';
import FormSteps from '../../../Utils/Constants/FormSteps';
import Button from '../../Components/Button/Button';
import Loader from '../../Components/Loader/Loader';
import ResultScreen from '../../Components/ResultScreen/ResultScreen';
import useStepContext from '../../Hooks/useStepContext';
import TermsAndConditionsScreen from './Style';

const TermsAndConditions: FC = () => {
    const { t } = useTranslation();
    const [{ hasError, title, description }, setError] = useState<IError>({
        hasError: false,
        title: ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title,
        description: ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description
    });
    const { setCurrentFormStep: nextStepHandler, currentFormStep } = useStepContext();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const idParameter = customerRegistrationManager.getCustomerIRegistrationId() || searchParams.get('id');
    const [isLoading, setIsLoading] = useState<boolean>(!!idParameter || !!currentFormStep?.PROPS?.idParameter);
    const [{ showButton, disableButton }, setAcceptButton] = useState({
        showButton: idParameter,
        disableButton: true
    });

    const validateCustomerID = (id: string) => {
        customerRegistrationManager.validateCustomerId(id)
            .then(() => setAcceptButton((prev) => ({ ...prev, disableButton: false })))
            .catch((errorCode) => setError((prev) => ({
                hasError: true,
                title: ERRORS_DESCRIPTION[errorCode]?.title || prev.title,
                description: ERRORS_DESCRIPTION[errorCode]?.description || prev.description
            })))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        const queryParam = idParameter || currentFormStep?.PROPS?.idParameter;
        if (queryParam) {
            validateCustomerID(queryParam);
        }
    }, []);

    return (
        <>
            {isLoading
                ? <Loader description={t('general.justAMoment')} />
                : (
                    <TermsAndConditionsScreen asFormStep={location.pathname === APP_ROUTES.CONSENT_REGISTRATION}>
                        <div className="container">
                            <div className="title">
                                <span>{t('termsAndConditions.title')}</span>
                            </div>

                            <div className="content">
                                {[...Array(18)].map((_, index) => (
                                    <div key={index}>
                                        <h3><b>{t(`termsAndConditions.topic${index + 1}`)}</b></h3>
                                        <span>{t(`termsAndConditions.description${index + 1}`)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {showButton && (
                            <Button
                                id="accept-terms-and-conditions-button"
                                mode="normal"
                                disabled={disableButton}
                                action={() => {
                                    customerRegistrationManager.setCustomerIRegistrationId(idParameter!);
                                    nextStepHandler({
                                        ...FormSteps.CUSTOMER_REVERSE_OTP,
                                        PROPS: { idParameter }
                                    });
                                    history.push(APP_ROUTES.CONSENT_REGISTRATION);
                                }}
                            >{t('reverseOTP.customer.acceptT&Cs')}
                            </Button>
                        )}
                    </TermsAndConditionsScreen>
                )}

            {hasError && (
                <ResultScreen
                    title={t(title)}
                    description={t(description)}
                    fullscreen
                />
            )}
        </>
    );
};

export default TermsAndConditions;
