import { FC, ReactNode } from 'react';
import CardComponent from './Style';

interface IProps {
    cardLabel: string,
    distanceFromLabelToContent: number;
    children: ReactNode;
}

const Card: FC<IProps> = (props) => (
    <>
        <CardComponent
            distanceFromLabelToContent={props.distanceFromLabelToContent}
        >
            <div className="card__label">
                <span>{props.cardLabel}</span>
            </div>
            <div className="content">
                {props.children}
            </div>
        </CardComponent>
    </>
);

export default Card;
