import styled from 'styled-components';
import Pixel2Rem from '../../../../../Utils/Pixel2Rem';
import { themeProvider } from '../../../../Theme/ThemeProvider';

const ValidateCustomerScreen = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    gap: ${Pixel2Rem(50)};
    font-family: ${themeProvider.fontFamily};
    font-size: ${Pixel2Rem(16)};

    .validate-customer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
        gap: ${Pixel2Rem(20)};
        text-align: center;

        .validate-customer__icon {
            width: ${Pixel2Rem(120)};
            height: ${Pixel2Rem(120)};
        }

        .validate_customer__code {
            font-family: ${themeProvider.fontBold};
            font-size: ${Pixel2Rem(24)};
            line-height: ${Pixel2Rem(30)};
            letter-spacing: ${Pixel2Rem(4)};
        }

        .validate-customer__expiration-time {
            color: ${themeProvider.muted};
            div {
                display: inline-block;
            }
        }

        .validate-customer__instructions {
            color: ${themeProvider.darkGrey};
        }
    }

    .footer {
        width: fill-available;
        padding-bottom: ${Pixel2Rem(10)};
        font-family: ${themeProvider.fontFamily};
    }
`;

export default ValidateCustomerScreen;
