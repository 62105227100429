import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

const StarRatingScreen = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    width: fill-available;

    .wrapper {
        flex-grow: 1;
        width: fill-available;
        margin: ${Pixel2Rem(24)} 0 0 0;

        .title {
            color: ${themeProvider.titleColor};
            font-family: ${themeProvider.fontBold};
            font-size: ${Pixel2Rem(28)};
            font-weight: bold;
            letter-spacing: 0;
            line-height: ${Pixel2Rem(33)};
            margin-bottom: ${Pixel2Rem(10)};
        }

        .subtitle {
            color: ${themeProvider.titleColor};
            font-family: ${themeProvider.fontFamily};
            font-size: ${Pixel2Rem(16)};
            letter-spacing: 0;
            line-height: ${Pixel2Rem(20)};
        }
    }

        .rating {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            align-items: center;
            width: fill-available;
            margin-top: -${Pixel2Rem(24)};

            .rating__stars {
                display: flex;
                justify-content: center;
                height: fit-content;
                width: fill-available;
            }

            .rating__empty-star {
                font-family: ${themeProvider.fontFamily};
                color: ${themeProvider.emptyStarRating};
            }

            .rating__average-star {
                color: ${themeProvider.averageStarRating};
            }

            .rating__satisfied-star {
                color: ${themeProvider.satisfiedStarRating};
            }

            .rating__feedback {
                display: flex;
                justify-content: center;
                min-height: ${Pixel2Rem(50)};
                text-align: center;
                font-family: ${themeProvider.fontBold};
                font-size: ${Pixel2Rem(16)};
                line-height: ${Pixel2Rem(22)};
            }
        }

    .footer {
        width: fill-available;
        justify-content: center;
        align-self: center;
        padding-bottom: ${Pixel2Rem(10)};
    }
`;

export default StarRatingScreen;
