import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFormStepProps } from '../../PageWrapper/PageWrapper';
import DI from '../../../../Utils/DI';
import GeneralInformation from '../../../Components/GeneralInformation/GeneralInformation';
import Utils from '../../../../Utils/Utils';
import Constants, { FALLBACK_GENDER, GENDERS } from '../../../../Utils/Constants';
import {
    IUserPersonalInformation,
    IUserDataItem,
    IUserAddressInformation,
    IUserDataList
} from '../../../Components/GeneralInformation/general-information-types';
import useStepContext from '../../../Hooks/useStepContext';
import IDataManager, { IReviewUserDetails } from '../../../../Business/IDataManager';

export interface IPassportData extends IUserPersonalInformation {
    passportNr: IUserDataItem & {validateDocumentFormat: RegExp};
}

export interface IPassportAddressInformation extends IUserAddressInformation {
    province: IUserDataList
}

const ReviewPassportDetails: FC<IFormStepProps> = ({ nextStepHandler }) => {
    const { t } = useTranslation();
    const { currentFormStep } = useStepContext();
    const dataManager: IDataManager = DI.get('DataManager');

    const {
        USER_DETAILS,
        userGender,
        userProvince,
        listOfTowns,
        userTown
    } = useMemo((): IReviewUserDetails => dataManager.convertToReviewUserDetails(!currentFormStep?.PROPS?.visited), []);

    const [userPersonalInformation, setUserPersonalInformation] = useState<IPassportData>({
        firstName: {
            type: 'TEXT',
            input: USER_DETAILS.getFirstName(),
            isValid: Utils.validateFieldLength(
                { value: USER_DETAILS.getFirstName(), min: 3, max: 30 }
            ),
            placeHolder: t('generalInformation.firstName'),
            requiredLength: { min: 3, max: 30 },
            acceptanceCriteria: Utils.unicodeChars
        },
        lastName: {
            type: 'TEXT',
            input: USER_DETAILS.getLastName(),
            isValid: Utils.validateFieldLength(
                { value: USER_DETAILS.getLastName(), min: 3, max: 30 }
            ),
            placeHolder: t('generalInformation.lastName'),
            requiredLength: { min: 3, max: 30 },
            acceptanceCriteria: Utils.unicodeChars
        },
        dateOfBirth: {
            type: 'DATE',
            input: USER_DETAILS.getDateOfBirth(),
            placeHolder: '12/12/2012',
            isValid: Utils.validateDate(USER_DETAILS.getDateOfBirth()),
            requiredLength: { min: 10, max: 10 },
            acceptanceCriteria: Utils.dateCharacters
        },
        gender: {
            type: 'LIST',
            list: GENDERS,
            disabled: false,
            isValid: !!userGender,
            selectedItem: userGender || FALLBACK_GENDER,
            isLabelVisible: !!userGender
        },
        citizen: {
            type: 'LIST',
            list: Constants.MARKET_GEO_INFORMATION.NATIONALITIES,
            disabled: true,
            isValid: true,
            selectedItem: Constants.MARKET_GEO_INFORMATION.NATIONALITIES
                .find((citizenship) => citizenship.key
                === `citizen.${Constants.MARKET_GEO_INFORMATION.DEFAULT_NATIONALITY_KEY}`)
        },
        passportNr: {
            type: 'TEXT',
            input: USER_DETAILS.getDocumentNumber(),
            isValid: Utils.validateFieldLength(
                { value: USER_DETAILS.getDocumentNumber(), min: 9, max: 9 }
            ) && Utils.withoutSpecialChars(USER_DETAILS.getDocumentNumber(),
                Constants.DOCUMENT_VALIDATION_RULES.PASSPORT
            ),
            placeHolder: t('generalInformation.passportNr'),
            requiredLength: { min: 9, max: 9 },
            acceptanceCriteria: Utils.withoutSpecialChars,
            validateDocumentFormat: Constants.DOCUMENT_VALIDATION_RULES.PASSPORT
        },
        placeOfBirth: {
            type: 'TEXT',
            input: USER_DETAILS.getPlaceOfBirth(),
            isValid: Utils.validateFieldLength(
                { value: USER_DETAILS.getPlaceOfBirth(), min: 3, max: 30 }
            ),
            placeHolder: t('generalInformation.placeOfBirth'),
            requiredLength: { min: 3, max: 30 },
            acceptanceCriteria: Utils.unicodeChars
        }
    });

    const [
        userAddressInformation, setUserAddressInformation
    ] = useState<IPassportAddressInformation>({
        street: {
            type: 'TEXT',
            input: USER_DETAILS.getStreet(),
            isValid: Utils.validateFieldLength(
                { value: USER_DETAILS.getStreet(), min: 3, max: 40 }
            ),
            requiredLength: { min: 3, max: 40 },
            placeHolder: t('generalInformation.street'),
            acceptanceCriteria: Utils.addressStreetCharacters
        },
        province: {
            type: 'LIST',
            isValid: !!userProvince || !!userTown,
            disabled: false,
            selectedItem: userProvince
            || Utils.getProvinceById(userTown?.provinceID)
            || Constants.MARKET_GEO_INFORMATION.FALLBACK_PROVINCE,
            isLabelVisible: !!userProvince || !!userTown,
            list: Constants.MARKET_GEO_INFORMATION.PROVINCES
        },
        town: {
            type: 'LIST',
            isValid: (
                !!userTown
                && (userProvince
                    ? Utils.getTownsByProvinceID(userProvince?.id).includes(userTown)
                    : Utils.getProvinceById(userTown.provinceID)?.id === userTown.provinceID)
            ),
            disabled: false,
            selectedItem: userTown || Constants.MARKET_GEO_INFORMATION.FALLBACK_TOWN,
            isLabelVisible: !!userTown,
            list: userProvince
                ? listOfTowns : Utils.getTownsByProvinceIDFiltered(userTown?.provinceID)
        },
        commune: {
            type: 'TEXT',
            input: USER_DETAILS.getCommune(),
            isValid: Utils.validateFieldLength(
                { value: USER_DETAILS.getCommune(), min: 3, max: 30 }
            ),
            requiredLength: { min: 3, max: 30 },
            acceptanceCriteria: Utils.unicodeChars,
            placeHolder: t('generalInformation.commune')
        }

    });

    return (
        <>
            <GeneralInformation
                nextStepHandler={nextStepHandler}
                userPersonalInformation={userPersonalInformation}
                setUserPersonalInformation={setUserPersonalInformation}
                userAddressInformation={userAddressInformation}
                setUserAddressInformation={setUserAddressInformation}
            />
        </>
    );
};

export default ReviewPassportDetails;
