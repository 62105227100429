import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VoterCardIcon } from '../../Assets/icons/ic_voter_id.svg';
import { ReactComponent as PassportIcon } from '../../Assets/icons/ic_passport.svg';
import { ReactComponent as ChevronRight } from '../../Assets/icons/ic_chevron_right.svg';
import Constants, { DOCUMENT_TYPES } from '../../../Utils/Constants';
import DI from '../../../Utils/DI';
import { IFormStepProps } from '../PageWrapper/PageWrapper';
import PersonalDocumentsScreen from './Style';
import IDocumentManager from '../../../Business/IDocumentManager';
import useGaEventTracker from '../../Hooks/useGaEventTracker';
import IAnalyticsManager from '../../../Business/IAnalyticsManager';
import { ISteps } from '../../../Utils/Constants/FormSteps';
import ISessionManager from '../../../Business/ISessionManager';

const PersonalDocuments: FC<IFormStepProps> = ({ nextStepHandler }) => {
    const { t } = useTranslation();
    const { screenTracker } = useGaEventTracker();
    const analyticsManager: IAnalyticsManager = DI.get('AnalyticsManager');
    const sessionManager: ISessionManager = DI.get('SessionManager');

    const documents = [
        { icon: <VoterCardIcon />, description: DOCUMENT_TYPES.VOTER_CARD_1 },
        { icon: <VoterCardIcon />, description: DOCUMENT_TYPES.VOTER_CARD_2 },
        { icon: <PassportIcon />, description: DOCUMENT_TYPES.PASSPORT }
        // NOTE: Currently the driving license is being hidden
        // since the DRC market doesn't have valid driving licenses
        // { icon: <DrivingLicenseIcon />, description: DOCUMENT_TYPES.DRIVING_LICENSE }
    ];

    const selectDocumentHandler = (document: string) => {
        screenTracker({ screen: '4.OCR', screenClass: document });
        analyticsManager.setTemporarilySelectedDocument(document);
        const selectedDocument = `${document}_FRONT` as keyof ISteps;
        nextStepHandler(Constants.FORM_STEPS[selectedDocument]);
    };

    useEffect(() => {
        const documentManager: IDocumentManager = DI.get('DocumentManager');
        documentManager.clearProcessedDocuments();
    }, []);

    return (
        <>
            <PersonalDocumentsScreen>
                <div className="wrapper">
                    <div className="title">
                        <span>
                            {sessionManager?.getSession()?.getIsValid()
                                ? t('personalDocuments.selectCustomerDocument')
                                : t('personalDocuments.selectSelfDocument')}
                        </span>
                    </div>
                    <div className="subtitle">
                        <span>
                            {t('personalDocuments.chooseDocument')}
                        </span>
                    </div>
                    <ul className="personal-documents">
                        {documents.map((document) => (
                            <li
                                key={document.description}
                                className="personal-document-item"
                                onClick={() => selectDocumentHandler(document.description)}
                                id={`personal-document-${document.description}`}
                            >
                                <div className="personal-document-item__icon">
                                    {document.icon}
                                </div>
                                <div className="personal-document-item__description">
                                    {t(`personalDocuments.${document.description}`)}
                                </div>
                                <ChevronRight />
                            </li>
                        ))}
                    </ul>
                </div>
            </PersonalDocumentsScreen>
        </>
    );
};
export default PersonalDocuments;
