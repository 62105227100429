import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    topicIndex: number;
    link?: string
}

const CookiePolicyItem: FC<IProps> = ({ topicIndex, link }) => {
    const { t } = useTranslation();

    return (
        <div className="cookie-policy-item">
            <h3 className="topic">{t(`cookiePolicy.topic${topicIndex}`)}</h3>&nbsp;
            <span className="description">
                {t(`cookiePolicy.description${topicIndex}`)}
            </span>
        </div>
    );
};

export default CookiePolicyItem;
