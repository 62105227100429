import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

const TakePhotoScreen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .wrapper {
      
      .title {
        margin: ${Pixel2Rem(24)} auto;
        color: ${themeProvider.titleColor};
        font-family: ${themeProvider.fontBold};
        text-align: flex-start;
        font-size: ${Pixel2Rem(22)};
        line-height: ${Pixel2Rem(26)};
    }

    .instructions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        height: fit-content;
        margin-bottom: ${Pixel2Rem(24)};

        .instructions-item {
          display: flex;
          align-items: baseline;
          margin-bottom: ${Pixel2Rem(16)};
        }

          .instructions__badge {
            display: inline-block;
            margin: 0 ${Pixel2Rem(16)} 0 0;
          }

          .instructions__text {
            font-family: ${themeProvider.fontFamily};
            font-size: ${Pixel2Rem(16)};
            line-height: ${Pixel2Rem(22)};
            letter-spacing: normal;
          }
      }

    .selfie {
        display: flex;
        justify-content: center;
        width: fill-available;
        margin-bottom: ${Pixel2Rem(40)};
        height: 290px;
    }

    .agentSelfie {
      display: flex;
        justify-content: center;
        width: 250px;
        height: 300px;
        margin: auto;
    }

    .a {
      width: 32.5px;
      height: 32.5px;
    }

    .image {
        width: 100%;
    }
    }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        font-family: ${themeProvider.fontFamily};

        .footer__button {
            box-sizing: border-box;
            margin-bottom: ${Pixel2Rem(20)};
            width: 100%;
        }
    }
`;

export default TakePhotoScreen;
