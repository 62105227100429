import Axios, {
    AxiosInstance,
    AxiosResponse,
    InternalAxiosRequestConfig
} from 'axios';
import Logger from '../Utils/Logger';
import IHttpInterface, {
    HttpMethods,
    IHttpRequestConfig
} from './IHttpInterface';
import Constants, { LOGGER_TAGS } from '../Utils/Constants';
import { SuccessStatusCodesKeys } from './protocol-constants';
import InterceptorsMap from './Interceptors/InterceptorsMap';
import { InterceptorsEnum, InterceptorTypeEnum } from './Interceptors/interceptors-types.ts';
import InternetConnectionInterceptor from './Interceptors/Response/InternetConnectionInterceptor';
import ExpiredSessionInterceptor from './Interceptors/Response/ExpiredSessionInterceptor';
import EncryptionCookieRenewal from './Interceptors/Request/EncryptionCookieRenewalInterceptor';

class HttpInterface implements IHttpInterface {
    private axios: AxiosInstance;

    private _requestInterceptFn?: Function;

    private _responseInterceptFn?: Function;

    private _successStatusCodes: SuccessStatusCodesKeys[] = [200, 201, 204];

    constructor() {
        this.axios = Axios.create({
            baseURL: Constants.API_ENDPOINTS.BASE_URL,
            timeout: Number(Constants.API_TIMEOUT),
            validateStatus: () => true,
            headers: {
                // * Any default headers should be included here
            }
        });

        // INFO: Subscribe all interceptors that are going to be used to the interceptors "store"
        InterceptorsMap.subscribeInterceptor(InterceptorsEnum.INTERNET_CONNECTION, InternetConnectionInterceptor);
        InterceptorsMap.subscribeInterceptor(InterceptorsEnum.ENCRYPTION_SESSION_EXPIRED, ExpiredSessionInterceptor);
        InterceptorsMap.subscribeInterceptor(InterceptorsEnum.ENCRYPTION_SESSION_RENEWAL, EncryptionCookieRenewal);

        // Intercept requests to allow refreshing the JWT
        this.axios.interceptors.request.use((request) => this.handleRequestIntercept(request));

        // Intercept responses to allow handling global errors
        this.axios.interceptors.response.use(
            (response) => this.handleResponseIntercept(response), // INFO: Catches cases where the response from the server is available
            (error) => this.handleResponseIntercept(error) // INFO: Catches cases where the response from the server is not available due to some issue (eg: Network error)
        );

        Logger.log(LOGGER_TAGS.HTTP_INTERFACE, `${LOGGER_TAGS.HTTP_INTERFACE} created.`);
    }

    setRequestInterceptor(interceptFn?: Function | undefined): void {
        this._requestInterceptFn = interceptFn;
    }

    setResponseInterceptor(interceptFn?: Function | undefined): void {
        this._responseInterceptFn = interceptFn;
    }

    send(request: IHttpRequestConfig): Promise<any> {
        Logger.log(LOGGER_TAGS.HTTP_INTERFACE, `Sending ${request.httpMethod} request... ${request.relativeUrl}`, request);
        this.processRequestConfig(request);

        switch (request.httpMethod) {
        case HttpMethods.GET:
            return this.axios.get(request.relativeUrl, request);
        case HttpMethods.POST:
            return this.axios.post(request.relativeUrl, request.data, request);
        case HttpMethods.DELETE:
            return this.axios.delete(request.relativeUrl, request);
        case HttpMethods.PUT:
            return this.axios.put(request.relativeUrl, request.data, request);
        default:
            Logger.error(LOGGER_TAGS.HTTP_INTERFACE, 'Unsupported HTTP Method.', request);
            return Promise.reject();
        }
    }

    setAuthValue(authValue?: string) {
        throw new Error('Method not implemented.');
    }

    private async handleRequestIntercept(requestConfig: InternalAxiosRequestConfig): Promise<any> {
        InterceptorsMap.setCurrentInterceptor(requestConfig, InterceptorTypeEnum.REQUEST);
        if (this._requestInterceptFn) {
            return new Promise(async (resolve, reject) => {
                try {
                    await this._requestInterceptFn?.();
                    resolve(requestConfig);
                } catch (error) {
                    reject(error);
                }
            });
        }
        return Promise.resolve(requestConfig);
    }

    private handleResponseIntercept(response: AxiosResponse): AxiosResponse {
        InterceptorsMap.setCurrentInterceptor(response, InterceptorTypeEnum.RESPONSE);
        if (response && this._responseInterceptFn) {
            Logger.error(LOGGER_TAGS.HTTP_INTERFACE, `Error status code intercepted: ${response.status}`, response);
            this._responseInterceptFn();
        }
        return response;
    }

    private processRequestConfig(requestConfig: IHttpRequestConfig): void {
        if (requestConfig.useAuthentication === false) {
            requestConfig.transformRequest = (data: any, headers: any) => {
                delete headers.Authorization;
            };
        }

        if (requestConfig.pendingOperation) {
            const cancelTokenSource = Axios.CancelToken.source();
            requestConfig.cancelToken = cancelTokenSource.token;

            requestConfig.pendingOperation.cancelImplementation = () => {
                cancelTokenSource.cancel();
            };
        }
    }

    getSuccessStatusCodes(): SuccessStatusCodesKeys[] {
        return this._successStatusCodes;
    }

    setSuccessStatusCodes(value: SuccessStatusCodesKeys[]): void {
        this._successStatusCodes = value;
    }
}

export default HttpInterface;
