import { FC, useState } from 'react';
import CheckboxComponent from './Style';
import { ReactComponent as CheckIcon } from '../../Assets/icons/ic_check_white.svg';

const Checkbox: FC<{ checked: boolean, onChangeFn?: Function, id: string}> = ({ checked, onChangeFn, id }) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handler = (checked: boolean) => {
        setIsChecked((prev) => !prev);
        if (onChangeFn) {
            onChangeFn(!checked);
        }
    };

    return (
        <CheckboxComponent id={id} className={isChecked ? 'checkbox--checked' : ''} onClick={() => handler(isChecked)}>
            <CheckIcon />
        </CheckboxComponent>
    );
};

export default Checkbox;
