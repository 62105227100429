import { FC, useLayoutEffect, useRef } from 'react';
import Lottie from 'lottie-web';
import LoaderComponent from './Style';
import vodafoneRedLoaderIcon from '../../Assets/loaders/vodafone_red_loader.json';

interface IProps {
    description?: string
    withBackdrop?: boolean;
    animation?: object;
}

const Loader: FC<IProps> = ({ description, withBackdrop, animation = vodafoneRedLoaderIcon }) => {
    const animationRef = useRef<any>(null);

    useLayoutEffect(() => {
        Lottie.loadAnimation({
            loop: true,
            animationData: animation,
            container: animationRef.current,
            renderer: 'svg'
        });
    }, [animation]);

    return (
        <>
            <LoaderComponent
                withBackdrop={withBackdrop}
            >
                <div className="loader" ref={animationRef} />
                <div className="loader__description">
                    <span>
                        {description}
                    </span>
                </div>
            </LoaderComponent>
        </>
    );
};

export default Loader;
