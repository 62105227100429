import React, { Suspense } from 'react';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './Utils/translation';
import { StepContextProvider } from './UI/Context/StepContext';
import Loader from './UI/Components/Loader/Loader';
import Constants, { LOGGER_TAGS, SPAM_GUARD_MECHANISM, SPAM_GUARD_MECHANISMS_ENUM } from './Utils/Constants';
import { USING_MOCKS } from './Protocol';

// eslint-disable-next-line no-console
console.log(`${LOGGER_TAGS.APP_VERSION} | App version: ${process.env.REACT_APP_VERSION}`);

const App = React.lazy(() => import('./App'));

if (SPAM_GUARD_MECHANISM === SPAM_GUARD_MECHANISMS_ENUM.RECAPTCHA) {
    const script = document.createElement('script');
    script.src = USING_MOCKS
        ? Constants.API_ENDPOINTS.REQUEST_RECAPTCHA_MOCKED
        : Constants.API_ENDPOINTS.REQUEST_RECAPTCHA;
    document.head.appendChild(script);
}

ReactDOM.render(
    <React.StrictMode>
        <StepContextProvider>
            <Suspense fallback={<Loader />}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Suspense>
        </StepContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
