import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

const CookiePolicyScreen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${Pixel2Rem(24)} ${Pixel2Rem(24)};
    font-family: ${themeProvider.fontFamily};

    .title {
        color: ${themeProvider.titleColor};
        font-size: ${Pixel2Rem(28)};
        font-family: ${themeProvider.fontBold};
        letter-spacing: 0;
        line-height: ${Pixel2Rem(33)};
    }

    .cookie-policy-item {
        width: 100%;
        margin: ${Pixel2Rem(15)} 0;
        white-space: pre-line;

        .topic {
            margin: 0 0 ${Pixel2Rem(10)} 0;
            font-weight: bold;
        }
    }

    .cookie-sub-topic-item {
        margin ${Pixel2Rem(10)} 0;
        .topic {
            font-family: ${themeProvider.fontBold};
        }

        .description {
            word-break: break-word;
        }
    }

    .content {
        margin: ${Pixel2Rem(24)} 0;
        text-align: justify;
        white-space: pre-line;
        color: ${themeProvider.titleColor};
        font-family: ${themeProvider.fontFamily};
        font-size: ${Pixel2Rem(16)};
        letter-spacing: 0;
        line-height: ${Pixel2Rem(20)};
    }
`;
export default CookiePolicyScreen;
