import {
    IValidateCustomerIdResponse,
    IValidateAgentPinResponse,
    IValidateReverseOtpResponse,
    ISendReverseOtpResponse,
    ConsentOtpStatusEnum,
    ISendUssdPushResponse
} from '../../Service/IRegistrationService';
import {
    IValidateSessionResponse,
    IValidateAgencyResponse,
    IValidateAgentResponse
} from '../../Service/ISessionService';
import { IReCaptchaResponse } from '../../Service/IReCaptchaService';
import Constants from '../../Utils/Constants';
import { IFaceBox, IFaceBoxDimensions } from '../../Utils/Constants/LivelinessCheck';
import Utils from '../../Utils/Utils';
import { ERROR_CODES } from '../../Utils/Constants/Errors';
import {
    BeGlobalStatusEnum,
    InternalResourceStatusEnum,
    ResourceStatusEnum
} from '../../Protocol/IHttpInterface';
import { IQueryCustomerInfoResponse } from '../../Service/IUserService';
import { LivenessCheckRotationDirections } from '../../Business/data/ChallengeDetails';

const REQUEST_OTP_SUCCESS = true;
const VALIDATE_OTP_SUCCESS = true;
const UPLOAD_DOCUMENT_SUCCESS = true;
const START_CHALLENGE_SUCCESS = true;
const VERIFY_CHALLENGE_SUCCESS = true;
const REQUEST_ACCOUNT_CREATION_SUCCESS = true;
const SUBMIT_RATING_SUCCESS = true;
const VALIDATE_RECAPTCHA_SUCCESS = true;
const FALLBACK_SUCCESS = true;
const VALIDATE_AGENCY_SUCCESS = true;
const VALIDATE_AGENT_SUCCESS = true;
const GOOGLE_ANALYTICS_EVENTS_SUCCESS = true;
const SESSION_SUCCESS = true;
const LOGOUT_SUCCESS = true;
const VERIFY_CUSTOMER_BY_AGENT_SUCCESS = true;
const QUERY_CUSTOMER_INFO_SUCCESS = true;
const VALIDATE_AGENT_PIN_SUCCESS = true;
const START_AGENT_FLOW_SUCCESS = true;
const VALIDATE_CUSTOMER_ID_SUCCESS = true;
const VALIDATE_REVERSE_OTP_SUCCESS = true;
const SEND_REVERSE_OTP_SUCCESS = true;
const SEND_USD_PUSH_SUCCESS = true;

const VALIDATE_RECAPTCHA_SUCCESSFUL: IReCaptchaResponse = {
    requestId: Utils.generateRandomString(24),
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    validateRecaptchaResponse: {
        requestId: Utils.generateRandomString(24),
        status: ResourceStatusEnum.SUCCEEDED,
        data: {
            status: InternalResourceStatusEnum.SUCCESS,
            remainingTime: 200
        }
    }
};

const VALIDATE_RECAPTCHA_FAILED: IReCaptchaResponse = {
    requestId: Utils.generateRandomString(24),
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    validateRecaptchaResponse: {
        requestId: Utils.generateRandomString(24),
        status: ResourceStatusEnum.FAILED,
        error: {
            message: 'Recaptcha validation has failed.',
            errorCode: '400-127',
            errorSource: 'TPE',
            additionalFields: {}
        }
    }
};

const VALIDATE_AGENCY_SUCCESSFUL: IValidateAgencyResponse = {
    requestId: Utils.generateRandomString(24),
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    validateAgencyResponse: {
        requestId: `${Utils.generateRandomString(24)}.0`,
        status: ResourceStatusEnum.SUCCEEDED,
        data: {
            validated: true,
            agencyName: 'Walktest'
        }
    }
};

const VALIDATE_AGENCY_FAILED: IValidateAgencyResponse = {
    requestId: Utils.generateRandomString(24),
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    validateAgencyResponse: {
        requestId: `${Utils.generateRandomString(24)}.0`,
        status: ResourceStatusEnum.FAILED,
        error: {
            message: 'Invalid receiver',
            errorCode: '400-29',
            errorSource: 'TPE',
            additionalFields: {
                g2ErrorDescription: 'Referall invalid.'
            }
        }
    }
};

const VALIDATE_AGENT_SUCCESSFUL: IValidateAgentResponse = {
    requestId: Utils.generateRandomString(24),
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    validateAgentResponse: {
        requestId: `${Utils.generateRandomString(24)}.0`,
        status: ResourceStatusEnum.SUCCEEDED,
        data: {
            validated: true,
            agentName: 'John Doe'
        }
    }
};

const VALIDATE_AGENT_FAILED: IValidateAgentResponse = {
    requestId: Utils.generateRandomString(24),
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    validateAgentResponse: {
        requestId: `${Utils.generateRandomString(24)}.0`,
        status: ResourceStatusEnum.FAILED,
        error: {
            message: 'Invalid Data.',
            errorCode: String(ERROR_CODES.VALIDATE_AGENT.INCORRECT_PIN),
            errorSource: 'TPE',
            additionalFields: {}
        }
    }
};

const VERIFY_CUSTOMER_BY_AGENT_SUCCESSFUL = {
    requestId: '8TAxfHqEQ+KY/ntx39KBWA',
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    verifyFaceDetectionResponse: {
        requestId: '8TAxfHqEQ+KY/ntx39KBWA.0',
        status: ResourceStatusEnum.SUCCEEDED,
        data: {
            status: ResourceStatusEnum.SUCCEEDED
        }
    }
};

const VERIFY_CUSTOMER_BY_AGENT_FAILED = {
    requestId: '1tWxmFDnStGW7R4F9uWxzg',
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    verifyFaceDetectionResponse: {
        requestId: '1tWxmFDnStGW7R4F9uWxzg.0',
        status: ResourceStatusEnum.FAILED,
        error: {
            message: 'Bad request',
            errorCode: '400-103',
            errorSource: 'TPE',
            additionalFields: {}
        }
    }
};

const GOOGLE_ANALYTICS_EVENTS_SUCCESSFUL = {};

const GOOGLE_ANALYTICS_EVENTS_FAILED = {
    errorSource: 'WIE',
    httpCode: 400,
    internalCode: 0,
    businessCode: '400-0',
    message: 'Bad Request',
    additionalFieldsMap: {
        errorDescription: 'Bad Request'
    }
};

const SESSION_SUCCESSFUL: IValidateSessionResponse = {
    requestId: '8TAxfHqEQ+KY/ntx39KBWA',
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    sessionResponse: {
        requestId: '8TAxfHqEQ+KY/ntx39KBWA.0',
        status: ResourceStatusEnum.SUCCEEDED,
        data: {
            agentInfo: {
                AGENCY_NAME: 'M Pesa Product Test SA I',
                AGENT_NAME: 'Super Agent Test super Agent Test'
            }
        }
    }
};

const SESSION_FAILED: IValidateSessionResponse = {
    requestId: '1tWxmFDnStGW7R4F9uWxzg',
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    sessionResponse: {
        requestId: '1tWxmFDnStGW7R4F9uWxzg.0',
        status: ResourceStatusEnum.FAILED,
        error: {
            message: 'Unauthorized.',
            errorCode: '401-0',
            errorSource: 'WIE',
            additionalFields: {}
        }
    }
};

const LOGOUT_SUCCESSFUL = {};

const LOGOUT_FAILED = {};

const QUERY_CUSTOMER_INFO_SUCCESSFUL: IQueryCustomerInfoResponse = {
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    requestId: Utils.generateRandomString(22),
    validateMsisdnResponse: {
        requestId: Utils.generateRandomString(22),
        status: ResourceStatusEnum.SUCCEEDED,
        data: {
            exists: false
        }
    }
};

const QUERY_CUSTOMER_INFO_FAILED: IQueryCustomerInfoResponse = {
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    requestId: Utils.generateRandomString(22),
    validateMsisdnResponse: {
        requestId: Utils.generateRandomString(22),
        status: ResourceStatusEnum.FAILED,
        error: {
            message: 'Msisdn already exists in another registration.',
            errorCode: String(ERROR_CODES.VALIDATE_OTP.DUPLICATED_PHONE_NUMBER),
            errorSource: 'WIE',
            additionalFields: {}
        }

    }
};

const VALIDATE_AGENT_PIN_SUCCESSFUL: IValidateAgentPinResponse = {
    requestId: Utils.generateRandomString(24),
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    confirmRegistrationResponse: {
        requestId: Utils.generateRandomString(24),
        status: ResourceStatusEnum.SUCCEEDED,
        data: {
            valid: true
        }
    }
};

const VALIDATE_AGENT_PIN_FAILED: IValidateAgentPinResponse = {
    requestId: Utils.generateRandomString(24),
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    confirmRegistrationResponse: {
        requestId: Utils.generateRandomString(24),
        status: ResourceStatusEnum.FAILED,
        error: {
            message: 'Credentials are locked',
            errorCode: String(ERROR_CODES.VALIDATE_AGENT_PIN.GENERIC_FAIL),
            errorSource: '',
            additionalFields: {}
        }
    }
};

const FALLBACK_SUCCESSFUL = QUERY_CUSTOMER_INFO_SUCCESSFUL;

const FALLBACK_FAILED = VALIDATE_AGENT_PIN_FAILED;

const START_AGENT_FLOW_SUCCESSFUL = {};

const START_AGENT_FLOW_FAILED = {
    requestID: Utils.generateRandomString(24),
    globalStatus: BeGlobalStatusEnum.FAILED,
    error: {
        errorCode: '400-0'
    }
};

const VALIDATE_CUSTOMER_ID_SUCCESSFUL: IValidateCustomerIdResponse = {
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    requestId: Utils.generateRandomString(24),
    consentResponse: {
        requestId: Utils.generateRandomString(24),
        status: ResourceStatusEnum.SUCCEEDED,
        data: {
            status: ConsentOtpStatusEnum.CONSENTED
        }
    }
};
const SEND_REVERSE_OTP_SUCCESSFUL: ISendReverseOtpResponse = {
    requestId: Utils.generateRandomString(24),
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    reverseOtpCodeResponse: {
        requestId: Utils.generateRandomString(24),
        status: ResourceStatusEnum.SUCCEEDED,
        data: {
            status: InternalResourceStatusEnum.BLOCKED,
            reverseOtpCode: 'JACA',
            timeLeftForResend: 7,
            timeLeftForExpiration: 3,
            uniqueIdentifier: 'mocked',
            timeLeft: 2
        }
    }
};

const VALIDATE_CUSTOMER_ID_FAILED: IValidateCustomerIdResponse = {
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    requestId: Utils.generateRandomString(24),
    consentResponse: {
        requestId: Utils.generateRandomString(24),
        status: ResourceStatusEnum.FAILED,
        error: {
            errorCode: String(ERROR_CODES.VALIDATE_CUSTOMER_ID.NOT_FOUND),
            message: 'Invalid ID',
            additionalFields: {},
            errorSource: 'WP'
        }
    }
};

const VALIDATE_REVERSE_OTP_SUCCESSFUL: IValidateReverseOtpResponse = {
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    requestId: Utils.generateRandomString(24),
    validateOtpCodeResponse: {
        requestId: Utils.generateRandomString(24),
        status: ResourceStatusEnum.SUCCEEDED,
        data: {
            status: InternalResourceStatusEnum.SUCCESS
        }
    }
};

const VALIDATE_REVERSE_OTP_FAILED: IValidateReverseOtpResponse = {
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    requestId: Utils.generateRandomString(24),
    validateOtpCodeResponse: {
        requestId: Utils.generateRandomString(24),
        status: ResourceStatusEnum.FAILED,
        error: {
            errorSource: 'WIE',
            errorCode: String(ERROR_CODES.VALIDATE_REVERSE_OTP.INCORRECT),
            additionalFields: {},
            message: 'Something went wrong'
        }
    }
};

const SEND_REVERSE_OTP_FAILED: ISendReverseOtpResponse = {
    requestId: Utils.generateRandomString(24),
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    reverseOtpCodeResponse: {
        requestId: Utils.generateRandomString(24),
        status: ResourceStatusEnum.FAILED,
        error: {
            message: 'Failed to validate PIN',
            errorCode: String(ERROR_CODES.VALIDATE_AGENT.CREDENTIALS_LOCKED),
            errorSource: '',
            additionalFields: {}
        }
    }
};

const SEND_USSD_PUSH_SUCCESSFUL: ISendUssdPushResponse = {
    requestId: Utils.generateRandomString(24),
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    UssdResponse: {
        requestId: Utils.generateRandomString(24),
        status: ResourceStatusEnum.SUCCEEDED,
        data: {
            status: InternalResourceStatusEnum.BLOCKED,
            timeLeft: 3
        }
    }
};

const SEND_USSD_PUSH_FAILED: ISendUssdPushResponse = {
    requestId: Utils.generateRandomString(24),
    globalStatus: BeGlobalStatusEnum.COMPLETED,
    UssdResponse: {
        requestId: Utils.generateRandomString(24),
        status: ResourceStatusEnum.FAILED,
        error: {
            errorCode: String(ERROR_CODES.CREATE_AGENT_AIDED_ACCOUNT.ACCOUNT_CREATION_FAILED),
            errorSource: 'WIE',
            message: '',
            additionalFields: {}
        }
    }
};

const MockData = {
    REQUEST_OTP: REQUEST_OTP_SUCCESS
        ? {
            requestId: Utils.generateRandomString(24),
            globalStatus: BeGlobalStatusEnum.COMPLETED,
            otpCodeResponse: {
                requestId: `${Utils.generateRandomString(24)}.0`,
                status: ResourceStatusEnum.SUCCEEDED,
                data: {
                    status: InternalResourceStatusEnum.SUCCESS,
                    timeLeft: 4
                }
            }
        }
        : {
            requestId: Utils.generateRandomString(24),
            globalStatus: BeGlobalStatusEnum.COMPLETED,
            otpCodeResponse: {
                requestId: `${Utils.generateRandomString(24)}.0`,
                status: ResourceStatusEnum.FAILED,
                error: {
                    message: 'Maximum attempts reached in validate OTP',
                    errorCode: ERROR_CODES.VALIDATE_OTP.INVALID_OTP,
                    errorSource: 'WIE',
                    additionalFields: {}
                }
            }
        },

    VALIDATE_OTP: VALIDATE_OTP_SUCCESS
        ? {
            requestId: Utils.generateRandomString(24),
            globalStatus: BeGlobalStatusEnum.COMPLETED,
            validateOtpCodeResponse: {
                requestId: `${Utils.generateRandomString(24)}.0`,
                status: ResourceStatusEnum.SUCCEEDED,
                data: {
                    status: InternalResourceStatusEnum.SUCCESS,
                    timeLeft: 3
                }
            }
        }
        : {
            requestId: Utils.generateRandomString(24),
            globalStatus: BeGlobalStatusEnum.COMPLETED,
            validateOtpCodeResponse: {
                requestId: `${Utils.generateRandomString(24)}.0`,
                status: ResourceStatusEnum.FAILED,
                error: {
                    message: 'Maximum attempts reached in validate OTP',
                    errorCode: ERROR_CODES.VALIDATE_OTP.DUPLICATED_PHONE_NUMBER,
                    errorSource: 'WIE',
                    additionalFields: {}
                }
            }
        },

    PUBLIC_KEY_NEGOTIATION: {
        publicKeyResponse: {
            data: {
                // eslint-disable-next-line max-len
                serverPublicKey: '-----BEGIN PGP PUBLIC KEY BLOCK-----\n\nxsBNBGGTcRQBCADGoMEm9cKF38zrfvVL8nbk2fcIy7mM0lI+JCrZ4/3Z4DCA\nSP7V2lfwFj60rozez2RaevWId1hOEo/OypVU6cyW4e5bZGf3kwQ+85tYfoX0\nVBlIybc3N9x+76dzCLSdScmkiXCTNVtVCOTa+BXYWPL0CMq2ZOMns9aUCkUJ\nWivtPwVbJGFYRlfS+oqPaWrOjJCVBuNYKPO54bgtJ9XH0ANYpqwh8AfLb9uP\ndFMpD/Bq8VVUeoQFmCYY5L552S/JupA6CXGJhKuMJWM5BsQj82drmI/Ka4Fq\nfB5s6Jk0/1hWcM45aVzwSYXc8Rd3IzlpEjJmsSxhi9t8It38QHdBQoDJABEB\nAAHNHUVTZnVJbUlJIDw4TGc3TFc1S0BlbWFpbC5jb20+wsCKBBABCAAdBQJh\nk3EUBAsJBwgDFQgKBBYAAgECGQECGwMCHgEAIQkQjw/EnjcMGJEWIQQ7f/sI\nyLVSA69iQ5mPD8SeNwwYkR5kCAC8fwqSww+Rl6oPjYnJ4gVGL2Q4E8eW3aZk\nHOhLdeCDrFOgqhwd6EcGWNSTVsFTNKRHc5UxtMvCjHEGCjByOM6CgQIiskRo\nayLqHpbFQZnzp10GMLac+8PRuIj6BxMeV3e8PWSxY9vix2Cx0owu48Woa+ij\n/6/0ZzxI72wOTWXrbYheK0RJl4PyMk9AbR/7DOSrfk5M+PhMV1QrT138mQg6\nML5H7OxYvW38N+MJktYK73JUP9HXSbA0o+TJBugz8ISQlS54DvdTNVmYs5aL\ngG1xKR/dJgZtBL8oMKaHtt2Y2+PrDhJl+6x0/XiQgdAEws2KanSgsCGNxGnI\nO+YMfMDIzsBNBGGTcRQBCACaWhcHmdzrDxmon3lQKd8tjiH9MUQ5eFs8VHT0\nEg+ckFkRcqVgWniBspy9Q0cPMafxmeGm8vUGJz6uBCqTzWczV9BLmx9J3yFL\nSUX2uq8BUBjbks6u4z2LCxVl5HJY2f0snCw+WHZ2yIUgulcfc3spE9qU4SGe\nXA9Wi7SycVSo+LtTBCI4LtgSMosz+dIzG1Fg+V2oz5shmNSjw5DBBaSmZ8l5\nc3lULn0FcS0syTOAgPl2BySNg1M+7/YvxqxddAHWiKGv18aPzsRihhb2Pjsj\nJNX3DOTJBDYSQf55WFRRVcKKkjbnoAhVPSROVqldZX/QSkQT+SLZJ8hSGGzy\ntEBPABEBAAHCwHYEGAEIAAkFAmGTcRQCGwwAIQkQjw/EnjcMGJEWIQQ7f/sI\nyLVSA69iQ5mPD8SeNwwYkUQ2B/4ivVziwAFvxrOrV+gD7bN25Ys31Jt8LN5b\n97ro+ShsRui+If1iOyJDXx6/4LoqQFUK4aaZJbDdPh1TSZcU6/WQR0aqoQPM\nBqeEuAD0J+dnSRLtPGcFCAKYVmMklqNeiTkSV4Z8uLOX1ev2c3G38w7WeW1P\ntzbC0wFAAPQqs3V6sy127PFVc+a+Y6KhCKhMAenpd+9MgR34JgqAGYL5jVAk\nPOkVhDzSixwwugjUSOKjVsJxyFFU/jI7LsEC0+YOUfsuD/909CNR9jvl2QaE\nd8ykE0jSzRdHJ45EHtvLxiYgK0MrrPbi9fsp9BlC0nphMKkJAhWT9z+uCXVH\n5JqFHN4V\n=I/cs\n-----END PGP PUBLIC KEY BLOCK-----\n',
                serverIdentifier: 'serverIdentifier<email@gmail.com>'
            }
        }
    },
    UPLOAD_DOCUMENT_FRONT: UPLOAD_DOCUMENT_SUCCESS
        ? {
            requestId: Utils.generateRandomString(24),
            globalStatus: BeGlobalStatusEnum.COMPLETED,
            uploadDocumentIdResponse: {
                requestId: `${Utils.generateRandomString(24)}.0`,
                status: 'SUCCEEDED',
                data: {
                    identifiedFields: {
                        DOCUMENT_NUMBER: '12345678933',
                        GENDER: 'M',
                        PLACE_OF_BIRTH: 'LUBUMBASHI',
                        LAST_NAME: 'MINGA',
                        DATE_OF_BIRTH: '12/10/1976',
                        FIRST_NAME: 'PEMBI',
                        ADDRESS_TOWN: 'BUNIA VILLE',
                        ADDRESS_PROVINCE: 'ITURI',
                        ADDRESS_STREET: 'RANDOM',
                        ADDRESS_COMMUNE: 'RANDOM'

                    }
                }
            }
        } : {
            requestId: Utils.generateRandomString(24),
            globalStatus: BeGlobalStatusEnum.COMPLETED,
            uploadDocumentIdResponse: {
                requestId: `${Utils.generateRandomString(24)}.0`,
                status: 'FAILED',
                error: {
                    message: 'File extension not permitted.',
                    errorCode: ERROR_CODES.UPLOAD_DOCUMENT.FORBIDDEN_NATIONALITY,
                    errorSource: 'WIE',
                    additionalFields: {}
                }
            }
        },

    UPLOAD_DOCUMENT_BACK: {
        requestId: Utils.generateRandomString(24),
        globalStatus: BeGlobalStatusEnum.COMPLETED,
        uploadDocumentIdResponse: {
            requestId: `${Utils.generateRandomString(24)}.0`,
            status: 'SUCCEEDED',
            data: {
                identifiedFields: {
                    ADDRESS_TOWN: 'BUNIA',
                    ADDRESS_STREET: '122 AV.N° FAMBUEAU',
                    ADDRESS_COMMUNE: 'GOMBE',
                    ADDRESS_PROVINCE: ''
                }
            }
        }

    },

    START_CHALLENGE: START_CHALLENGE_SUCCESS
        ? {
            requestId: Utils.generateRandomString(24),
            globalStatus: BeGlobalStatusEnum.COMPLETED,
            startLivenessDetectionResponse: {
                requestId: Utils.generateRandomString(24),
                status: 'SUCCEEDED',
                data: {}
            }
        } : {
            requestId: Utils.generateRandomString(24),
            globalStatus: BeGlobalStatusEnum.COMPLETED,
            startLivenessDetectionResponse: {
                requestId: Utils.generateRandomString(24),
                status: 'FAILED',
                error: {
                    message: 'Bad request',
                    errorCode: '400-00',
                    errorSource: 'TPE',
                    additionalFields: {}
                }
            }
        },

    VERIFY_CHALLENGE: VERIFY_CHALLENGE_SUCCESS
        ? {
            requestId: Utils.generateRandomString(24),
            globalStatus: BeGlobalStatusEnum.COMPLETED,
            verifyLivenessDetectionResponse: {
                requestId: Utils.generateRandomString(24),
                status: 'SUCCEEDED',
                data: {
                    status: 'SUCCEEDED'
                }
            }
        } : {
            requestId: Utils.generateRandomString(24),
            globalStatus: BeGlobalStatusEnum.COMPLETED,
            verifyLivenessDetectionResponse: {
                requestId: Utils.generateRandomString(24),
                status: 'FAILED',
                error: {
                    message: 'Nose trajectory invalid',
                    errorCode: Constants.ERRORS.ERROR_CODES.VERIFY_CHALLENGE.SOMETHING_WENT_WRONG,
                    errorSource: 'TPE',
                    additionalFields: {}
                }
            }
        },

    REQUEST_ACCOUNT_CREATION: REQUEST_ACCOUNT_CREATION_SUCCESS
        ? {
            requestId: Utils.generateRandomString(24),
            globalStatus: BeGlobalStatusEnum.COMPLETED,
            submitRegistrationResponse: {
                requestId: `${Utils.generateRandomString(24)}.0`,
                status: ResourceStatusEnum.SUCCEEDED,
                data: {
                    status: ResourceStatusEnum.SYSTEM_APPROVING
                },
                error: {
                    errorCode: '404-0',
                    additionalFields: {},
                    message: 'Hammer'
                }
            }
        }
        : {
            requestId: Utils.generateRandomString(24),
            globalStatus: BeGlobalStatusEnum.COMPLETED,
            submitRegistrationResponse: {
                requestId: `${Utils.generateRandomString(24)}.0`,
                status: 'FAILED',
                error: {
                    message: 'Registration expired.',
                    errorCode: ERROR_CODES.ACCOUNT_CREATION.UNDERAGE,
                    errorSource: 'WIE',
                    additionalFields: {}
                }
            }
        },
    SUBMIT_RATING: SUBMIT_RATING_SUCCESS
        ? {
            requestId: 'Q4gv2GtfQAeXkiVDpdPleA',
            globalStatus: BeGlobalStatusEnum.COMPLETED,
            storeRatingResponse: {
                requestId: 'Q4gv2GtfQAeXkiVDpdPleA.0',
                status: 'SUCCEEDED',
                data: {
                    accepted: 'true'
                }
            }
        }
        : {
            requestId: 'llCzSdVVRb6BOqSxHDed4A',
            globalStatus: BeGlobalStatusEnum.COMPLETED,
            storeRatingResponse: {
                requestId: 'llCzSdVVRb6BOqSxHDed4A.0',
                status: 'FAILED',
                error: {
                    message: 'Cookie is expired',
                    errorCode: ERROR_CODES.ACCOUNT_CREATION.UNDERAGE,
                    errorSource: 'WIE',
                    additionalFields: {}
                }
            }
        },

    VALIDATE_RECAPTCHA: VALIDATE_RECAPTCHA_SUCCESS
        ? VALIDATE_RECAPTCHA_SUCCESSFUL
        : VALIDATE_RECAPTCHA_FAILED,

    VALIDATE_AGENCY: VALIDATE_AGENCY_SUCCESS
        ? VALIDATE_AGENCY_SUCCESSFUL
        : VALIDATE_AGENCY_FAILED,

    VALIDATE_AGENT: VALIDATE_AGENT_SUCCESS
        ? VALIDATE_AGENT_SUCCESSFUL
        : VALIDATE_AGENT_FAILED,

    FALLBACK: FALLBACK_SUCCESS
        ? FALLBACK_SUCCESSFUL
        : FALLBACK_FAILED,

    GA_EVENTS: GOOGLE_ANALYTICS_EVENTS_SUCCESS
        ? GOOGLE_ANALYTICS_EVENTS_SUCCESSFUL
        : GOOGLE_ANALYTICS_EVENTS_FAILED,

    SESSION: SESSION_SUCCESS
        ? SESSION_SUCCESSFUL
        : SESSION_FAILED,

    LOGOUT: LOGOUT_SUCCESS
        ? LOGOUT_SUCCESSFUL
        : LOGOUT_FAILED,

    VERIFY_CUSTOMER_BY_AGENT: VERIFY_CUSTOMER_BY_AGENT_SUCCESS
        ? VERIFY_CUSTOMER_BY_AGENT_SUCCESSFUL
        : VERIFY_CUSTOMER_BY_AGENT_FAILED,

    QUERY_CUSTOMER_INFO: QUERY_CUSTOMER_INFO_SUCCESS
        ? QUERY_CUSTOMER_INFO_SUCCESSFUL
        : QUERY_CUSTOMER_INFO_FAILED,

    VALIDATE_AGENT_PIN: VALIDATE_AGENT_PIN_SUCCESS
        ? VALIDATE_AGENT_PIN_SUCCESSFUL
        : VALIDATE_AGENT_PIN_FAILED,

    START_AGENT_FLOW: START_AGENT_FLOW_SUCCESS
        ? START_AGENT_FLOW_SUCCESSFUL
        : START_AGENT_FLOW_FAILED,

    VALIDATE_CUSTOMER_ID: VALIDATE_CUSTOMER_ID_SUCCESS
        ? VALIDATE_CUSTOMER_ID_SUCCESSFUL
        : VALIDATE_CUSTOMER_ID_FAILED,

    VALIDATE_REVERSE_OTP: VALIDATE_REVERSE_OTP_SUCCESS
        ? VALIDATE_REVERSE_OTP_SUCCESSFUL
        : VALIDATE_REVERSE_OTP_FAILED,

    SEND_REVERSE_OTP: SEND_REVERSE_OTP_SUCCESS
        ? SEND_REVERSE_OTP_SUCCESSFUL
        : SEND_REVERSE_OTP_FAILED,

    SEND_USSD_PUSH: SEND_USD_PUSH_SUCCESS
        ? SEND_USSD_PUSH_SUCCESSFUL
        : SEND_USSD_PUSH_FAILED
};

export const getAreaBox = (imageWidth: number, imageHeight: number) => {
    const boxConfig: keyof IFaceBox = imageWidth > imageHeight ? 'IOS' : 'ANDROID';
    const {
        AREA_BOX_HEIGHT_RATIO: heightRatio,
        AREA_BOX_WIDTH_RATIO: widthRatio,
        AREA_BOX_ASPECT_RATIO: aspectRatio
    }: IFaceBoxDimensions = Constants.LIVELINESS_CHECK.boxConfig.FACE_BOX[boxConfig];

    const areaHeight = imageHeight * heightRatio;
    const areaWidth = Utils.min(
        imageWidth * widthRatio,
        imageHeight * aspectRatio
    );
    const areaLeft = imageWidth / 2 - areaWidth / 2;
    const areaTop = (imageHeight / 2 - areaHeight / 2) + (imageHeight * 0.09);
    return {
        left: areaLeft,
        top: areaTop,
        height: areaHeight,
        width: areaWidth,
        xMax: 0,
        yMax: 0
    };
};

export const getNoseBox = (imageWidth: number, imageHeight: number) => {
    const {
        left: faceBoxLeft,
        top: faceBoxTop,
        height: faceBoxHeight,
        width: faceBoxWidth
    } = getAreaBox(imageWidth, imageHeight);

    const noseBoxWidth = faceBoxWidth / Constants.LIVELINESS_CHECK.boxConfig.ROTATE_FACE_DIVISION_FACTOR;

    const noseBoxLeftPosition = (faceBoxLeft + faceBoxWidth) - noseBoxWidth;

    const noseBoxRightPosition = faceBoxLeft;

    const noseBoxPositions = [noseBoxLeftPosition, noseBoxRightPosition];

    const positionIndex = Math.floor(Math.random() * noseBoxPositions.length);

    const left = noseBoxPositions[positionIndex];

    const rotationDirection = left === faceBoxLeft ? LivenessCheckRotationDirections.RIGHT : LivenessCheckRotationDirections.LEFT;

    return {
        left,
        top: faceBoxTop,
        width: noseBoxWidth,
        height: faceBoxHeight,
        xMax: 0,
        yMax: 0,
        rotationDirection
    };
};

export default MockData;
