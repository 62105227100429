import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

const OtpWrapperComponent = styled.div`
.otp {
    display: flex;
    justify-content: center;
    margin-bottom: ${Pixel2Rem(18)};
    gap: ${Pixel2Rem(6)};
    

    .otp__digit {
        width: ${Pixel2Rem(42)};
        height: ${Pixel2Rem(48)};
        box-sizing: border-box;
        font: ${Pixel2Rem(30)}/1 ${themeProvider.fontFamily};
        border: ${Pixel2Rem(1)} solid ${themeProvider.inputColor};
        border-radius: ${Pixel2Rem(6)};
        padding: 0;
        text-align: center;
        color: ${themeProvider.muted};
        -webkit-text-security: disc;

        &:focus {
            outline: none;
            border: ${Pixel2Rem(1.6)} solid ${themeProvider.successColor};
            background: none;
            box-sizing: border-box;
        }
    }
}
`;

export default OtpWrapperComponent;
