import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ResultScreen from '../../Components/ResultScreen/ResultScreen';
import useStepContext from '../../Hooks/useStepContext';

const Confirmation: FC = () => {
    const { currentFormStep } = useStepContext();
    const { t } = useTranslation();
    const {
        success,
        title,
        description,
        actionFn,
        actionText,
        showActionButton,
        fullscreen
    } = currentFormStep.PROPS;

    return (
        <>
            <ResultScreen
                success={success}
                title={t(title)}
                description={t(description)}
                actionText={t(actionText)}
                actionFn={actionFn}
                showActionButton={showActionButton}
                fullscreen={fullscreen}
            />
        </>
    );
};

export default Confirmation;
