import React, {
    FC, useEffect, useLayoutEffect, useMemo, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './Style';
import ArrowLeftPictureIcon from '../../../Assets/icons/ic_arrow_left.svg';
import Constants from '../../../../Utils/Constants';
import { IError } from '../../../../Utils/Constants/Errors';
import MediaStreamConfig from '../../../../Utils/MediaStream/MediaStream';
import { ReactComponent as InstructionIcon } from '../../../Assets/icons/ic_face_state.svg';
import ConfirmPhoto from '../ConfirmPhoto/ConfirmPhoto';
import ResultScreen from '../../ResultScreen/ResultScreen';
import ITakeCustomerPhotoWithCameraProps from '../take-customer-photo-with-camera-types';

const TakeCustomerPhotoWithCamera: FC<ITakeCustomerPhotoWithCameraProps> = ({
    validateTitle,
    instructionText,
    onChangeFn,
    onDismiss,
    instructionIcon,
    data
}) => {
    const { t } = useTranslation();
    const videoRef = useRef<HTMLVideoElement>(null);
    const photoFrameRef = useRef<HTMLDivElement>(null);
    const [dataURI, setDataURI] = useState<string>(data);
    const [hasPhotoTaken, setHasPhotoTaken] = useState<boolean>(Boolean(dataURI));
    const [showCameraAccessError, setShowCameraAccessError] = useState<boolean>(false);
    const hiddenCanvas = document.createElement('canvas');
    const [errorCode, setErrorCode] = useState<number>(
        Constants.ERRORS.ERROR_CODES.CAMERA.NOT_ALLOWED
    );

    useEffect(() => {
        if (data) {
            setDataURI(data);
            setHasPhotoTaken(true);
        }
    }, [data]);

    const errorMessage = useMemo((): IError => ({
        title: t((Constants.ERRORS.ERRORS_DESCRIPTION[errorCode] as IError).title),
        description: t((Constants.ERRORS.ERRORS_DESCRIPTION[errorCode] as IError).description)
    }), [errorCode]);

    useEffect(() => {
        getVideo();
        return () => {
            MediaStreamConfig.closeMediaStream();
        };
    }, [hasPhotoTaken]);

    const getVideo = () => {
        setShowCameraAccessError(false);
        if (videoRef.current) {
            const videoEl: HTMLVideoElement = videoRef.current;
            askForCameraPermission(
                (mediaStream: MediaStream) => {
                    videoEl.srcObject = mediaStream;
                    videoEl.play();
                    setShowCameraAccessError(false);
                },
                (errorCode) => {
                    setErrorCode(errorCode);
                    setShowCameraAccessError(true);
                });
        }
    };

    const askForCameraPermission = (
        successCallback: (stream: MediaStream) => void,
        errorCallback: (codeError: number) => void
    ) => {
        const constraints = {
            audio: false,
            video: {
                width: { ideal: 1920 },
                height: { ideal: 1080 },
                facingMode: 'environment'
            }
        };
        MediaStreamConfig.loadMediaStream({ constraints, successCallback, errorCallback });
    };

    const resetPhoto = () => {
        const ctx = hiddenCanvas.getContext('2d');
        if (ctx) {
            ctx.canvas.hidden = false;
            hiddenCanvas.width = 1;
            hiddenCanvas.height = 1;
            ctx.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
            setDataURI('');
            setHasPhotoTaken(false);
        }
    };

    const takePhotoHandler = () => {
        const video = videoRef.current;

        if (video) {
            const videoP = video.getBoundingClientRect();

            const {
                width: videoWidth,
                height: videoHeight
            } = videoP;

            hiddenCanvas.width = videoWidth;
            hiddenCanvas.height = videoHeight;
            const ctx = hiddenCanvas.getContext('2d');
            ctx!.drawImage(video, 0, 0, hiddenCanvas.width, hiddenCanvas.height);
            setDataURI(hiddenCanvas.toDataURL('image/png'));
            setHasPhotoTaken(true);
        }
    };

    const confirmPhoto = () => {
        if (dataURI) {
            onChangeFn(dataURI);
            setDataURI('');
            setHasPhotoTaken(false);
        }
    };

    const [InstructionI, setInstructionI] = useState<React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }>>(InstructionIcon);

    useLayoutEffect(() => {
        setInstructionI(instructionIcon);
    }, []);

    return (
        <>
            {!showCameraAccessError && (
                <S.Container>
                    {!hasPhotoTaken && !dataURI && (
                        <S.VideoContainer>
                            <div className="icon-back">
                                <S.CloseButton
                                    icon={ArrowLeftPictureIcon}
                                    onClick={() => onDismiss()}
                                />
                            </div>
                            <S.Video playsInline muted autoPlay ref={videoRef} />
                            <S.FrameWrapper>
                                <S.PhotoFrame ref={photoFrameRef}>
                                    <div className="borders">
                                        <p />
                                    </div>
                                </S.PhotoFrame>
                            </S.FrameWrapper>
                            <div className="instructions">
                                <div className="instructions__icon">
                                    <InstructionI />
                                </div>
                                <div className="instructions__description">
                                    <span>{instructionText}</span>
                                </div>
                            </div>
                            <S.Controllers>
                                <S.TakePhotoButton onClick={takePhotoHandler} />
                            </S.Controllers>
                        </S.VideoContainer>
                    )}
                    {hasPhotoTaken && dataURI && (
                        <ConfirmPhoto
                            photo={dataURI}
                            title={validateTitle}
                            confirmText={t('takePhoto.submitPhoto')}
                            confirmFn={() => confirmPhoto()}
                            retakeText={t('takePhoto.retakePhoto')}
                            retakeFn={() => resetPhoto()}
                        />
                    )}

                </S.Container>
            )}

            {showCameraAccessError && (
                <ResultScreen
                    success={false}
                    title={errorMessage.title}
                    description={errorMessage.description}
                    actionText={t('general.tryAgain')}
                    actionFn={() => window.location.reload()}
                />
            )}

        </>
    );
};

export default TakeCustomerPhotoWithCamera;
