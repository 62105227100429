import { FC } from 'react';
import { themeProvider } from '../../../Theme/ThemeProvider';
import Button from '../../Button/Button';
import ConfirmPhotoComponent from './Style';

interface IConfirmPhotoProps {
    title: string;
    photo: string;
    confirmText: string;
    confirmFn: () => void;
    retakeText: string;
    retakeFn: () => void;
}

const ConfirmPhoto: FC <IConfirmPhotoProps> = ({
    title, photo, confirmText, confirmFn, retakeText, retakeFn
}) => (
    <ConfirmPhotoComponent url={photo}>
        <div className="confirm-photo__title">
            <span>{title}</span>
        </div>
        <div className="captured-photo-wrapper">
            <img className="captured-photo__image" src={photo} alt="" />
        </div>
        <div className="footer">
            <div className="footer__confirm-button">
                <Button
                    mode="normal"
                    action={confirmFn}
                    id="confirm-photo-button"
                >{confirmText}
                </Button>
            </div>

            <div className="footer__retake-button">
                <Button
                    action={retakeFn}
                    mode="flat"
                    textColor={themeProvider.textColor}
                    id="retake-photo-button"
                >{retakeText}
                </Button>
            </div>
        </div>
    </ConfirmPhotoComponent>
);

export default ConfirmPhoto;
