import styled from 'styled-components';
import Pixel2Rem from '../../../../Utils/Pixel2Rem';
import { themeProvider } from '../../../Theme/ThemeProvider';

interface IConfirmPhotoComponentProps {
    url: string;
}

const ConfirmPhotoComponent = styled.div<IConfirmPhotoComponentProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    .confirm-photo__title {
        font-family: ${themeProvider.fontBold};
        font-size: ${Pixel2Rem(24)};
        margin: ${Pixel2Rem(40)} 0 0 0;
    }
    

    .captured-photo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: ${Pixel2Rem(40)} 0;
        height: 50%;
        width: 60%;
        border-radius: 100%;
        overflow: hidden;

        .captured-photo-wrapper__image {
            max-width: 100%;
            height: auto;
        }
        
        .captured-photo__image {
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: ${Pixel2Rem(250)};
            height: ${Pixel2Rem(365)};
            border-radius: 100%;

        }
    }

    .footer {
        width: 100%;
        margin-bottom: ${Pixel2Rem(20)};
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        font-family: ${themeProvider.fontFamily};
        font-size: ${Pixel2Rem(18)};
        line-height: ${Pixel2Rem(24)};

        .footer__confirm-button {
            width: 100%;
        }

        .footer__retake-button {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: ${Pixel2Rem(8)};
        }
    }
`;

export default ConfirmPhotoComponent;
