import { FC, ReactNode } from 'react';
import ButtonComponent from './Style';

interface IProps {
    action?: any;
    mode: 'flat' | 'normal';
    children?: ReactNode;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset' | undefined;
    hasShadow?: boolean;
    textColor?: string;
    id: string;
}
const Button: FC<IProps> = ({
    action,
    mode,

    disabled,
    type,
    hasShadow = false,
    textColor = '',
    id,
    children
}) => (
    <ButtonComponent
        hasShadow={hasShadow}
        onClick={action}
        mode={mode}
        type={type}
        disabled={disabled}
        textColor={textColor}
        id={id}
    >
        {children}
    </ButtonComponent>
);

export default Button;
