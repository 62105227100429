import {
    FC, ReactElement, useEffect, useRef, useState
} from 'react';
import lottie from 'lottie-web';
import Badge from '../Badge/Badge';
import Button from '../Button/Button';
import TakePhotoScreen from './Style';

interface ITakePhotoProps {
    title: string;
    media: IMediaResource,
    buttonText: string;
    actionFn: () => void;
    isReady?: boolean;
    instructions: ILivenessCheckInstructions[];
    id: string;
}

export interface IMediaResource {
    image?: { src: string },
    animation?: { src: object }
}

export interface ILivenessCheckInstructions {
    instruction: ReactElement;
}

const TakePhoto: FC<ITakePhotoProps> = ({
    title,
    media,
    buttonText,
    actionFn,
    isReady = true,
    instructions,
    id
}) => {
    const [isAnimationCompleted, setIsAnimationCompleted] = useState(!media?.animation);

    const handleAnimationCompletion = () => {
        if (media.animation) {
            setIsAnimationCompleted(true);
        }
    };

    const mediaAnimationRef = useRef<any>(null);

    useEffect(() => {
        const animation = lottie.loadAnimation({
            container: mediaAnimationRef.current,
            renderer: 'canvas',
            loop: false,
            autoplay: true,
            animationData: media.animation?.src
        });

        animation.addEventListener('complete', handleAnimationCompletion);
        return () => animation.removeEventListener('complete', handleAnimationCompletion);
    }, []);

    return (
        <>
            <TakePhotoScreen>
                <div className="wrapper">
                    <h1 className="title">{title}</h1>
                    <div className="instructions">
                        {instructions.map((entry, index) => (
                            <div key={`${entry.instruction}${index}`} className="instructions-item">
                                <div className="instructions__badge">
                                    <Badge boldContent size="MEDIUM" content={index + 1} />
                                </div>
                                <span className="instructions__text">
                                    {entry.instruction}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className={media.animation ? 'selfie' : 'agentSelfie'}>
                        {media.image && <img className="image" src={media.image.src} alt="" />}
                        {media.animation && <div className="image" ref={mediaAnimationRef} />}

                    </div>

                </div>
                <div className="footer">
                    <div className="footer__button">
                        <Button
                            action={actionFn}
                            mode="normal"
                            disabled={!(isReady && isAnimationCompleted)}
                            id={id}
                        >{buttonText}
                        </Button>
                    </div>
                </div>
            </TakePhotoScreen>
        </>
    );
};

export default TakePhoto;
