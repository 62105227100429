import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

const ValidatePhoneNumberScreen = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    
    .wrapper {
        width: fill-available;
        margin-top: ${Pixel2Rem(24)};
    }

    .title {
        color: ${themeProvider.titleColor};
        font-family: ${themeProvider.fontBold};
        font-size: ${Pixel2Rem(28)};
        font-weight: bold;
        letter-spacing: 0;
        line-height: ${Pixel2Rem(34)};
        margin-bottom: ${Pixel2Rem(10)};
    }

    .subtitle {
        color: ${themeProvider.titleColor};
        font-family: ${themeProvider.fontFamily};
        font-size: ${Pixel2Rem(16)};
        letter-spacing: 0;
        line-height: ${Pixel2Rem(20)};
        margin-bottom: ${Pixel2Rem(54)};
    }

    .error {
        color: ${themeProvider.muted};
        font-family: ${themeProvider.fontFamily};
        font-size: ${Pixel2Rem(16)};
        letter-spacing: 0;
        line-height: ${Pixel2Rem(18)};
        text-align: center;
        padding: 0 ${Pixel2Rem(24)};
    }

    .footer {
        width: fill-available;
        justify-content: center;
        align-self: center;
        padding-bottom: ${Pixel2Rem(10)};
        margin-top: ${Pixel2Rem(76)};
    }
`;

export default ValidatePhoneNumberScreen;
