import i18next from 'i18next';
import { InternalResourceStatusEnum, ResourceStatusEnum } from '../../Protocol/IHttpInterface';
import {
    ConsentOtpStatusEnum,
    IDocumentType,
    ISubmitRegistrationRequest,
    ISubmitRegistrationResponseData,
    IValidateCustomerIdResponseData,
    IValidateReverseOtpRequest
} from '../../Service/IRegistrationService';
import Constants, { ISOLanguageCodeEnum, LOGGER_TAGS } from '../../Utils/Constants';
import { ERROR_CODES } from '../../Utils/Constants/Errors';
import Logger from '../../Utils/Logger';
import Utils from '../../Utils/Utils';
import RegistrationManager from './RegistrationManager';

class CustomerRegistrationManager extends RegistrationManager {
    private _customerIRegistrationId!: string;

    createAccount(): Promise<any> {
        const registrationData = this.userManager.getUserInformation();
        const documentType = this.removeDocumentTypeVersion(this.documentManager
            .getProcessedDocuments()[0].documentType).toUpperCase() as IDocumentType;
        Logger.log(LOGGER_TAGS.CUSTOMER_REGISTRATION_MANAGER, 'Request to create account initiated', registrationData);
        const submitRegistrationRequest: ISubmitRegistrationRequest = {
            relativeUrl: Constants.API_ENDPOINTS.CUSTOMER.ACCOUNT_CREATION,
            data: {
                msisdn: this.userManager.getMsisdn(),
                resource: {
                    language: ISOLanguageCodeEnum[i18next.language.toUpperCase() as keyof typeof ISOLanguageCodeEnum],
                    ...registrationData,
                    gender: String(this.dataManager.convertToGender(String(registrationData?.gender)))[0].toUpperCase(),
                    documentType
                }
            }
        };
        const promise = this.registrationService
            .requestAccountCreation(submitRegistrationRequest)
            .then(({ status }: ISubmitRegistrationResponseData) => {
                if (status === ResourceStatusEnum.SYSTEM_APPROVING) {
                    Logger.log(LOGGER_TAGS.CUSTOMER_REGISTRATION_MANAGER, 'Successfully requested account creation', status);
                    return Promise.resolve();
                }
                Logger.error(LOGGER_TAGS.CUSTOMER_REGISTRATION_MANAGER, 'Failed to request account creation on BE');
                return Promise.reject(ERROR_CODES.GENERIC_ERROR);
            },
            (errorCode) => {
                Logger.error(LOGGER_TAGS.CUSTOMER_REGISTRATION_MANAGER, 'Failed to request account creation', errorCode);
                this._requestSubmissionStatus = {
                    isCreated: false,
                    errorCode: Utils.convertToErrorCode(errorCode)
                };
                return Promise
                    .reject(Utils.convertToErrorCode(errorCode));
            });

        return promise;
    }

    validateCustomerId(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.registrationService.validateCustomerId({ id })
                .then((response: IValidateCustomerIdResponseData) => {
                    const { status } = response;
                    if (status === ConsentOtpStatusEnum.VALID) {
                        Logger.log(LOGGER_TAGS.CUSTOMER_REGISTRATION_MANAGER, 'Successfully validated ID', response);
                        resolve(status);
                    } else {
                        Logger.error(LOGGER_TAGS.CUSTOMER_REGISTRATION_MANAGER, 'Failed to validate ID: Invalid ID', response);
                        reject(ERROR_CODES.VALIDATE_CUSTOMER_ID.NOT_FOUND);
                    }
                })
                .catch((error) => {
                    Logger.error(LOGGER_TAGS.CUSTOMER_REGISTRATION_MANAGER, 'Failed to validate ID', error);
                    reject(Utils.convertToErrorCode(error?.errorCode) || ERROR_CODES.GENERIC_ERROR);
                });
        });
    }

    validateReverseOtp({ otp, uniqueIdentifier }: IValidateReverseOtpRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            this.registrationService.validateReverseOtp({ otp, uniqueIdentifier })
                .then(({ data }) => {
                    if (data?.status === InternalResourceStatusEnum.SUCCESS) {
                        Logger.log(LOGGER_TAGS.CUSTOMER_REGISTRATION_MANAGER, 'Validated reverse otp', data);
                        resolve('');
                        return;
                    }
                    if (data?.status === InternalResourceStatusEnum.BLOCKED) {
                        Logger.error(LOGGER_TAGS.CUSTOMER_REGISTRATION_MANAGER, 'User is blocked', data);
                        reject(ERROR_CODES.VALIDATE_REVERSE_OTP.BLOCKED);
                    }
                })
                .catch((error) => {
                    Logger.error(
                        LOGGER_TAGS.CUSTOMER_REGISTRATION_MANAGER,
                        'Failed to validate reverse OTP',
                        error?.message || error
                    );
                    reject(Utils.convertToErrorCode(error?.errorCode) || ERROR_CODES.GENERIC_ERROR);
                });
        });
    }

    getCustomerIRegistrationId(): string {
        return this._customerIRegistrationId;
    }

    setCustomerIRegistrationId(value: string) {
        this._customerIRegistrationId = value;
    }
}

export default new CustomerRegistrationManager();
