import { FC } from 'react';
import Input from '../Input/Input';

interface IProps {
    id: string
    handleChange: any,
    inputLabel: string,
    data: any,
    maxLength: number
}

const TextField:FC<IProps> = ({
    id, handleChange, inputLabel, data, maxLength
}) => (
    <>
        <Input
            inputLabel={inputLabel}
            type="text"
            data={data}
            id={id}
            handleChange={handleChange}
            maxLength={maxLength}
        />
    </>
);

export default TextField;
