import { FC, useMemo, useState } from 'react';
import { t } from 'i18next';
import TakeDocumentPhotoWithCameraW from '../../../Components/TakeCustomerPhotoWithCamera/TakePhoto/TakeCustomerPhotoWithCamera';
import { ReactComponent as FrontalPictureIcon } from '../../../Assets/icons/ic_face_state.svg';

import useStepContext from '../../../Hooks/useStepContext';
import { ISteps } from '../../../../Utils/Constants/FormSteps';
import useGaEventTracker from '../../../Hooks/useGaEventTracker';
import IDocumentManager from '../../../../Business/IDocumentManager';
import DI from '../../../../Utils/DI';
import Constants from '../../../../Utils/Constants';
import IChallengeManager, { PictureSideEnum } from '../../../../Business/IChallengeManager';
import Loader from '../../../Components/Loader/Loader';
import IValidateFace, { VALIDATION_STEPS, IValidateFaceProps } from './validate-face.types';
import ISessionManager from '../../../../Business/ISessionManager';

const ValidateFace: FC<IValidateFaceProps> = ({ handleValidationStep }) => {
    const challengeManager: IChallengeManager = DI.get('ChallengeManager');

    const { setCurrentFormStep: nextStepHandler } = useStepContext();
    const { screenTracker } = useGaEventTracker();
    const documentManager: IDocumentManager = DI.get('DocumentManager');
    const SELECTED_DOCUMENT = documentManager.getProcessedDocuments()[0].documentType.toUpperCase();

    const faceValidationProps: IValidateFace = {
        validateTitle: 'takePhoto.selfie.livenessCheckInstructions.agent.frontPhoto',
        instruction: 'takePhoto.selfie.livenessCheckInstructions.agent.frontPhotoInstruction',
        step: PictureSideEnum.FRONTAL,
        icon: FrontalPictureIcon,
        picture: challengeManager.retrieveCapturedSelfie()
    };

    const [isLoading, setIsLoading] = useState(false);
    const sessionManager: ISessionManager = DI.get('SessionManager');
    const isAgentLogged: boolean | undefined = useMemo(() => sessionManager.getSession()?.getIsValid(), []);

    const handleSubmit = (image: string) => {
        setIsLoading(true);

        challengeManager.verifyCustomer(image, PictureSideEnum.FRONTAL)
            .then(() => {
                screenTracker({ screen: '6.REVIEW_DETAILS', screenClass: 'GENERAL_FORM' });
                const documentToReview = `REVIEW_${SELECTED_DOCUMENT}` as keyof ISteps;
                nextStepHandler({ ...Constants.FORM_STEPS[documentToReview], PROPS: { isAgentLogged } });
            })
            .catch((errorCode) => {
                handleValidationStep(VALIDATION_STEPS.ERROR, errorCode);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            {!isLoading
                ? (
                    <TakeDocumentPhotoWithCameraW
                        validateTitle={t(faceValidationProps.validateTitle)}
                        onChangeFn={(image: any) => handleSubmit(image)}
                        onDismiss={() => handleValidationStep(VALIDATION_STEPS.INIT)}
                        instructionIcon={faceValidationProps.icon}
                        instructionText={t(faceValidationProps.instruction)}
                        data={faceValidationProps.picture}
                    />
                )
                : <Loader description={t('takePhoto.selfie.agentInstructions.justAMoment')} />}

        </>
    );
};

export default ValidateFace;
