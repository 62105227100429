import { BoundingBox } from '@tensorflow-models/face-landmarks-detection/dist/shared/calculators/interfaces/shape_interfaces';

export type RotationType = BoundingBox & { rotationDirection: LivenessCheckRotationDirections };

export enum LivenessCheckRotationDirections {
    RIGHT = 'RIGHT',
    LEFT = 'LEFT'
}

interface ChallengeDetails {
    readonly areaBox: BoundingBox;
    readonly noseBox: RotationType;
}

export default ChallengeDetails;
