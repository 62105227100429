import {
    FC, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import ChallengeDetails from '../../../../Business/data/ChallengeDetails';
import DI from '../../../../Utils/DI';
import Loader from '../../../Components/Loader/Loader';
import TakePhoto from '../../../Components/TakePhoto/TakePhoto';
import Utils from '../../../../Utils/Utils';
import Constants, { BrowserCameraPermissionNameEnum, LOGGER_TAGS, SCREEN_ORIENTATION } from '../../../../Utils/Constants';
import IAnalyticsManager from '../../../../Business/IAnalyticsManager';
import { ERROR_CODES } from '../../../../Utils/Constants/Errors';
import Logger from '../../../../Utils/Logger';
import IChallengeManager from '../../../../Business/IChallengeManager';
import ISessionManager from '../../../../Business/ISessionManager';
import MediaStreamConfig from '../../../../Utils/MediaStream/MediaStream';
import IInitChallengeProps from './init-challenge-types';
import { VALIDATION_STEPS } from '../ValidateFace/validate-face.types';

const InitChallenge:FC<IInitChallengeProps> = ({
    handleError, handleValidationProcess, ready, title, media, buttonText, instructions
}) => {
    const challengeManager: IChallengeManager = DI.get('ChallengeManager');
    const analyticsManager: IAnalyticsManager = DI.get('AnalyticsManager');
    const sessionManager: ISessionManager = DI.get('SessionManager');
    const [isLoading, setIsLoading] = useState(false);
    const isAgentLogged: boolean | undefined = useMemo(() => sessionManager.getSession()?.getIsValid(), []);
    const { t } = useTranslation();
    challengeManager.clearCapturedFrames();

    const startChallenge = async () => {
        if (!Utils.isOrientation(SCREEN_ORIENTATION.PORTRAIT)) {
            handleError(Constants.ERRORS.ERROR_CODES.CAMERA.NOT_ALLOWED_ORIENTATION_CHANGE);
            return;
        }
        if (!isAgentLogged) {
            try {
                const a = BrowserCameraPermissionNameEnum[Utils.getBrowserName()];
                const permissionCameraName = a as unknown as PermissionName;

                const response = await navigator.permissions
                    .query({ name: permissionCameraName });
                if (response.state === 'denied') {
                    Logger.error(LOGGER_TAGS.UI, 'Camera permission disabled at browser level', ERROR_CODES.CAMERA.NOT_ALLOWED);
                    handleError(ERROR_CODES.CAMERA.NOT_ALLOWED);
                    return;
                }
                challengeManager.startChallenge()
                    .then((challengeDetails: ChallengeDetails) => {
                        analyticsManager.setAttemptsCounter(analyticsManager.getAttemptsCounter() + 1);
                        handleValidationProcess(challengeDetails);
                    })
                    .catch((errorCode) => handleError(errorCode));
                return;
            } catch (error) {
                Logger.error(LOGGER_TAGS.UI, 'Unexpected error querying camera permission state', error);
                handleError(ERROR_CODES.CAMERA.UNEXPECTED_ERROR);
            }
        }
        handleValidationProcess(VALIDATION_STEPS.VALIDATION);
        setIsLoading(false);
    };

    const startLivenessCheck = (): void => {
        setIsLoading(true);
        const mediaStreamDetector = MediaStreamConfig.getFaceDetector();
        if (challengeManager.isFaceDetectorReady() === 'SUCCEEDED') {
            startChallenge();
        } else if (challengeManager.isFaceDetectorReady() === 'PENDING') {
            setTimeout(startLivenessCheck, 1500);
        } else {
            Logger.error(LOGGER_TAGS.UI, 'Error creating detector', mediaStreamDetector?.error?.code);
            handleError(mediaStreamDetector?.error?.code || ERROR_CODES.GENERIC_ERROR);
        }
    };

    return (
        <>
            {isLoading ? <Loader /> : (
                <TakePhoto
                    title={t(title)}
                    media={media}
                    buttonText={t(buttonText)}
                    actionFn={startLivenessCheck}
                    isReady={ready}
                    instructions={instructions}
                    id="start-challenge-button"
                />
            )}

        </>
    );
};

export default InitChallenge;
