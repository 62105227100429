import { AxiosRequestConfig } from 'axios';
import PendingOperation from '../Utils/PendingOperation';

export enum HttpMethods {
    GET,
    POST,
    DELETE,
    PUT
}

export interface IHttpRequestConfig<T = any> extends AxiosRequestConfig {
    relativeUrl: string;
    httpMethod: HttpMethods;
    data?: T;
    useAuthentication?: boolean; // * Default "true"
    pendingOperation?: PendingOperation;
}

interface IHttpInterface {
    send(request: IHttpRequestConfig): Promise<any>;
    setAuthValue(authValue?: string): void;
    setRequestInterceptor(interceptFn?: Function): void; // INFO: Function triggered when a request is intercepted
    setResponseInterceptor(interceptFn?: Function): void; // INFO: Function triggered when a response is intercepted
    getSuccessStatusCodes(): number[];
    setSuccessStatusCodes(value: number[]): void;
}

export enum BeGlobalStatusEnum {
    COMPLETED = 'COMPLETED',
    PENDING = 'PENDING',
    FAILED = 'FAILED'
}

export enum ResourceStatusEnum {
    SUCCEEDED = 'SUCCEEDED',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    SYSTEM_APPROVING = 'SYSTEM_APPROVING'
}

export enum InternalResourceStatusEnum {
    SUCCESS = 'SUCCESS',
    BLOCKED = 'BLOCKED',
    FAILED = 'FAILED',
}
export interface IBEResponse {
    globalStatus: BeGlobalStatusEnum;
    requestId: string;
}
export interface IBEResponseData<T = {}, K = {}> {
    data?: T;
    error?: IBeError;
    requestId: string;
    status: ResourceStatusEnum;
}

export interface IBeError {
    message: string,
        errorCode: string,
        errorSource: string,
        additionalFields: any;
}

export default IHttpInterface;
