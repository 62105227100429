import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../Input/Input';
import { ErrorMessageWrapper, SmallErrorMessage } from './Style';

interface IProps {
    id: string
    handleChange: any,
    inputLabel: string,
    data: any,
    maxLength: number
}

const DateField:FC<IProps> = ({
    id, handleChange, inputLabel, data, maxLength
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Input
                data={data}
                inputLabel={inputLabel}
                type="text"
                id={id}
                handleChange={handleChange}
                autoComplete="off"
                maxLength={maxLength}
            />
            <ErrorMessageWrapper>
                {!data.isValid && <SmallErrorMessage>{t('error.invalid-age')}</SmallErrorMessage>}
            </ErrorMessageWrapper>
        </>
    );
};
export default DateField;
