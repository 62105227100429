import { FC } from 'react';
import ResultScreenWrapper from './Style';
import Button from '../Button/Button';
import { ReactComponent as ErrorIcon } from '../../Assets/icons/ic_warning.svg';
import { ReactComponent as SuccessIcon } from '../../Assets/icons/ic_validated.svg';
import { IResultScreenProps } from './result-screen-types';

/**
 * Returns a React Component to display a result screen, the result can either be an error or a success case.
 * Props types definition under ./result-screen-types
 * @returns {JSX.Element}
 */

const ResultScreen: FC<IResultScreenProps> = ({
    success,
    title,
    description,
    actionFn = () => {},
    actionText,
    showActionButton,
    disableActionButton,
    fullscreen
}) => (
    <ResultScreenWrapper
        showActionButton={showActionButton}
        isFullscreen={fullscreen}
    >
        <div className="result">
            {success ? <SuccessIcon className="result__icon" /> : <ErrorIcon className="result__icon" />}
            <h1 className="result__title">{title}</h1>
            <p className="result__description">{description}</p>
        </div>
        <div className="footer">
            <div className="footer__button">
                <Button
                    action={() => actionFn()}
                    mode="normal"
                    disabled={Boolean(disableActionButton)}
                    id="result-screen-button"
                >{actionText}
                </Button>
            </div>
        </div>
    </ResultScreenWrapper>
);
export default ResultScreen;
