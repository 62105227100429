import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';
import EditPictureIcon from '../../Assets/icons/ic_circle_edit.svg';

const GeneralInformationScreen = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    img {
        border-radius: ${Pixel2Rem(6)};
    }

    .wrapper {
        width: fill-available;
        margin: ${Pixel2Rem(24)} 0 ${Pixel2Rem(60)} 0;
    }

    .title {
        min-height: ${Pixel2Rem(31)};
        color: ${themeProvider.titleColor};
        font-family: ${themeProvider.fontBold};
        font-size: ${Pixel2Rem(28)};
        font-weight: bold;
        letter-spacing: 0;
        line-height: ${Pixel2Rem(33)};
        margin-bottom: ${Pixel2Rem(10)};
    }

    .subtitle {
        height: ${Pixel2Rem(40)};
        color: ${themeProvider.titleColor};
        font-family: ${themeProvider.fontFamily};
        font-size: ${Pixel2Rem(16)};
        letter-spacing: 0;
        line-height: ${Pixel2Rem(20)};
        margin-bottom: ${Pixel2Rem(32)};
    }

    .phone-number {
        height: ${Pixel2Rem(22)};
        min-width: ${Pixel2Rem(153)};
        margin-top: ${Pixel2Rem(8)};
        color: ${themeProvider.textColor};
        font-family: ${themeProvider.fontBold};
        font-size: ${Pixel2Rem(20)};
        font-weight: bold;
        letter-spacing: 0;
        line-height: ${Pixel2Rem(23)};
    }

    .user-data {
        list-style-type: none;
    }

    .user-data__item {
        margin: ${Pixel2Rem(24)} 0;
    }

    .attached-photos {
        display: flex;
        flex-direction: column;

        .attached-photos__img {
            max-width: 100%;
            max-height: 100%;
        }

        .personal-document {
            display: flex;
            justify-content: start;

            .personal-document-img {
                display: inline-block;
                position: relative;
                height: ${Pixel2Rem(83)};
                margin: ${Pixel2Rem(6)} ${Pixel2Rem(16)} 0 0;

            }

            .personal-document--front::before {
                content: url(${EditPictureIcon});
                position: absolute;
                cursor: pointer;
                top: -${Pixel2Rem(10)};
                right: -${Pixel2Rem(7)};
                filter: drop-shadow(${Pixel2Rem(5)} -${Pixel2Rem(2)} ${Pixel2Rem(8)} ${themeProvider.editIconShadow});
            }
        }

        .selfie {
            margin-top: ${Pixel2Rem(16)};
            position: relative;

            .selfie__img {
                width: fill-available;
                max-width: 100%;
                height: 100%;      
            }

            &::before {
                content: url(${EditPictureIcon});
                position: absolute;
                cursor: pointer;
                top: -${Pixel2Rem(10)};
                right: -${Pixel2Rem(7)};
                filter: drop-shadow(${Pixel2Rem(5)} -${Pixel2Rem(2)} ${Pixel2Rem(8)} rgba(130,130,130,.5));
            }
        }
    }

    .customer-images-container {
        display: flex;
        flex-direction: column;

        .secondary-images {
            display: flex;
            justify-content: space-between;

            .secondary-images__picture {
                margin-top: 1rem;
                position: relative;
                border-radius: ${Pixel2Rem(6)};

                &::before {
                    content: url(${EditPictureIcon});
                    position: absolute;
                    cursor: pointer;
                    top: -${Pixel2Rem(10)};
                    right: -${Pixel2Rem(7)};
                    filter: drop-shadow(${Pixel2Rem(5)} -${Pixel2Rem(2)} ${Pixel2Rem(8)} rgba(130,130,130,.5));
                }

                .secondary-images__img {
                    max-width: 100%;
                    max-height: 100%;
                    width: ${Pixel2Rem(165)};
                    height: auto;
                }

                &.secondary-images__frontal-image {
                    margin-right: ${Pixel2Rem(12)};
                }
            }
        }
    }

    .agent-confirmation {
        display: flex;
        align-items: flex-start;
        margin: ${Pixel2Rem(10)} auto ${Pixel2Rem(30)} auto;

        .confirmation__description {
            margin-left: ${Pixel2Rem(10)};
            font-size: ${Pixel2Rem(16)};
            font-family: ${themeProvider.fontFamily};
            color: ${themeProvider.agentConfirmationText};
        }
    }

    .footer {
        width: fill-available;
        justify-content: center;
        align-self: center;
        padding-bottom: ${Pixel2Rem(24)};
        position: fixed;
        bottom: 0;
        right: 0;
        inset: auto 0 0;
        padding: 0 ${Pixel2Rem(24)} ${Pixel2Rem(10)} ${Pixel2Rem(24)};
        background-color: ${themeProvider.backgroundColor};
        z-index: 101;
        overflow-y: hidden;
        scroll-behavior: unset;
    }
`;

export default GeneralInformationScreen;
