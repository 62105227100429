import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

interface IProps {
    open: boolean;
}
const DialogComponent = styled.div<IProps>`
    display: ${(props) => (props.open ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100vh;
    width: 100%;
    z-index: 102;

    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.32);
        z-index: 10;
    }

    .dialog-view {
        max-width: ${Pixel2Rem(296)};
        min-height: ${Pixel2Rem(165)};
        height: fit-content;
        background-color: ${themeProvider.backgroundColor};
        max-height: 100%;
        align-items: center;
        text-align: center;
        z-index: 100;
        border-radius: ${Pixel2Rem(6)};
        filter: drop-shadow(${Pixel2Rem(2)} ${Pixel2Rem(4)} ${Pixel2Rem(6)} rgba(0, 0, 0, .4));
    }

    .dialog-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dialog-content__close-icon {
            align-self: flex-end;
            padding: ${Pixel2Rem(14)} ${Pixel2Rem(14)} 0 ${Pixel2Rem(14)};
        }

        .dialog-body {
            padding: 0 ${Pixel2Rem(24)};
            margin-bottom: ${Pixel2Rem(26)};

            .dialog-content__title {
                color: ${themeProvider.textColor};
                font-family: 'VodafoneR';
                font-size: ${Pixel2Rem(18)};
                font-weight: bold;
                letter-spacing: ${Pixel2Rem(0.17)};
                line-height: ${Pixel2Rem(24)};
                text-align: center;
            }

            .dialog-content__description {
                color: rgba(0, 0, 0, 0.6);
                font-family: 'VodafoneR';
                font-size: ${Pixel2Rem(16)};
                letter-spacing: 0;
                line-height: ${Pixel2Rem(20)};
                text-align: center;
            }

            .actions {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .actions__button {
                    padding: 8px 16px;
                    min-width: ${Pixel2Rem(118)};
                    margin-top: ${Pixel2Rem(28)};
                    color: ${themeProvider.backgroundColor};
                    font-family: ${themeProvider.fontFamily};
                    font-size: ${Pixel2Rem(18)};
                    letter-spacing: 0;
                    line-height: ${Pixel2Rem(24)};
                    text-align: center;
                    outline: none;
                    border: none;
                    border-radius: ${Pixel2Rem(6)};
                }

                .actions__cancel-action {
                    background-color: ${themeProvider.darkGrey};
                }

                .actions__confirm-action {
                    background-color: ${themeProvider.primaryColor};
                }
            }
        }
    }
`;

export default DialogComponent;
