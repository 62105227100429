import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DrivingLicenseFrontImage from '../../Assets/images/driving_license_front.png';
import TakeDocumentPhoto, { ITakeDocumentPhotoInstructions } from '../../Components/TakeDocumentPhoto/TakeDocumentPhoto';
import DI from '../../../Utils/DI';
import Constants, { DOCUMENT_ORIENTATION, DOCUMENT_TYPES } from '../../../Utils/Constants';
import { IFormStepProps } from '../PageWrapper/PageWrapper';
import IDocumentManager from '../../../Business/IDocumentManager';
import IUserManager from '../../../Business/UserManager/IUserManager';
import useGaEventTracker from '../../Hooks/useGaEventTracker';
import IAnalyticsManager from '../../../Business/IAnalyticsManager';
import useStepContext from '../../Hooks/useStepContext';
import Utils from '../../../Utils/Utils';

const DrivingLicenseFront: FC<IFormStepProps> = ({ nextStepHandler }) => {
    const { t } = useTranslation();

    const documentManager: IDocumentManager = DI.get('DocumentManager');
    const userManager: IUserManager = DI.get('UserManager');
    const { eventTracker } = useGaEventTracker();
    const analyticsManager: IAnalyticsManager = DI.get('AnalyticsManager');
    const { currentFormStep } = useStepContext();
    const takeDocumentPhotoInstructions: ITakeDocumentPhotoInstructions = {
        key: 'drivingLicenseFront',
        instructionsLength: 3
    };

    const onChangeFunction = (file: File) => {
        documentManager.processDocumentData(
            String(userManager.getMsisdn()),
            file,
            DOCUMENT_TYPES.DRIVING_LICENSE,
            DOCUMENT_ORIENTATION.FRONT
        )
            .then(
                () => {
                    const elapsedTime = moment()
                        .subtract(moment.unix(analyticsManager.getDocumentUploadInitTime()).unix(), 'milliseconds').format('mm:ss');
                    eventTracker({
                        name: 'OCR',
                        objectValue: {
                            SUCCESS: `TOC: ${currentFormStep.KEY} - ${Utils.getTimeInterval(elapsedTime)}`
                        }
                    });
                    eventTracker({
                        name: 'OCR',
                        objectValue: {
                            SUCCESS: `ATTEMPTS - ${currentFormStep.KEY} - ${analyticsManager.getDocumentUploadAttemptsCounter()}`
                        }
                    });
                    analyticsManager.setDocumentUploadAttemptsCounter(1);

                    nextStepHandler(Constants.FORM_STEPS.DRIVING_LICENSE_BACK);
                },
                () => analyticsManager.setDocumentUploadAttemptsCounter(analyticsManager
                    .getDocumentUploadAttemptsCounter() + 1)
            );
    };

    return (
        <>
            <TakeDocumentPhoto
                title={t('drivingLicenseFront.message')}
                image={DrivingLicenseFrontImage}
                instructionText={t('takePhoto.moveAndCenterDocument')}
                captureTitle={t('drivingLicenseFront.captureTitle')}
                validateTitle={t('drivingLicenseFront.validateTitle')}
                buttonText={t('drivingLicenseFront.buttonText')}
                onChangeFn={onChangeFunction}
                takeDocumentPhotoInstructions={takeDocumentPhotoInstructions}
            />
        </>
    );
};

export default DrivingLicenseFront;
