/* eslint-disable no-unused-vars */
import { ReactComponent as FrFlagIcon } from '../UI/Assets/icons/ic_flag_fr.svg';
import { ReactComponent as EnFlagIcon } from '../UI/Assets/icons/ic_flag_en.svg';
import { IDropdownItem } from '../UI/Components/Dropdown/Dropdown';
import LivelinessCheck from './Constants/LivelinessCheck';
import FormSteps from './Constants/FormSteps';

import Nationalities from './Constants/Nationalities';
import {
    DOCUMENT_NOT_CLEAR_ERRORS,
    ERRORS_DESCRIPTION,
    ERROR_CODES,
    RECOVERABLE_ACCOUNT_CREATION_ERRORS,
    RECOVERABLE_LIVELINESS_CHALLENGE_ERRORS
} from './Constants/Errors';

export const IS_AGENT_AIDED_FLOW_ENABLED = process.env.REACT_APP_ENABLE_AGENT_AIDED_FLOW === 'true';

export const BYPASS_LIVENESS_CHECK = process.env.REACT_APP_BYPASS_LIVENESS_CHECK === 'true';

export const SPAM_GUARD_MECHANISM = process.env.REACT_APP_SPAM_GUARD_MECHANISM;

export const ALLOW_DESKTOP_ACCESS = process.env.REACT_APP_ALLOW_DESKTOP_ACCESS === 'true';

export const ENVIRONMENT = process.env.REACT_APP_ENV;

export enum SPAM_GUARD_MECHANISMS_ENUM {
    RECAPTCHA = 'RECAPTCHA',
    IP_BLOCKER = 'IP_BLOCKER'
}

export enum LOGGER_TAGS {
    UI = 'UI',
    USER_MANAGER = 'UserManager',
    USER_SERVICE = 'UserService',
    DATA_MANAGER = 'DataManager',
    DOCUMENT_SERVICE = 'DocumentService',
    DOCUMENT_MANAGER = 'DocumentManager',
    HTTP_INTERFACE_MOCK = 'HttpInterfaceMock',
    HTTP_INTERFACE = 'HttpInterface',
    ENCRYPTION_SERVICE = 'EncryptionService',
    PENDING_OPERATION = 'PendingOperation',
    EVENT_EMITTER = 'EventEmitter',
    IMAGE_RESIZER = 'ImageResizer',
    CHALLENGE_MANAGER = 'ChallengeManager',
    CHALLENGE_SERVICE = 'ChallengeService',
    CHALLENGE_PROCESSOR_MANAGER = 'ChallengeProcessorManager',
    STATE_MANAGER = 'StateManager',
    MEDIA_CONFIG = 'MediaConfig',
    REGISTRATION_MANAGER = 'RegistrationManager',
    REGISTRATION_SERVICE = 'RegistrationService',
    SESSION_EXPIRED_EVENT = 'SessionExpired',
    KEY_EXCHANGE_COMPLETED = 'keyExchangedCompleted',
    INTERNET_CONNECTION_INTERCEPTOR = 'InternetConnectionInterceptor',
    NOT_ENOUGH_CAMERAS_EVENT = 'NotEnoughCameras',
    HANDLE_DOCUMENT_UPLOAD_EVENT = 'HandleDocumentUpload',
    HANDLE_DOCUMENT_UPLOAD_ERROR_EVENT = 'HandleDocumentUploadError',
    UTILS = 'Utils',
    RATING_SERVICE = 'RatingService',
    RATING_MANAGER = 'RatingManager',
    RECAPTCHA_MANAGER = 'ReCaptchaManager',
    RECAPTCHA_SERVICE = 'ReCaptchaService',
    ANALYTICS_MANAGER = 'AnalyticsManager',
    FALLBACK_SERVICE = 'FallbackService',
    FALLBACK_RESOLVER = 'FallbackResolver',
    FALLBACK_REJECTOR = 'FallbackRejector',
    SESSION_MANAGER = 'SessionManager',
    SESSION_SERVICE = 'SessionService',
    MEDIA_STREAM = 'MediaStream',
    GA_TRACKER_SERVICE = 'GATrackerService',
    GA_TRACKER_MANAGER = 'GATrackerManager',
    RESULT_SCREEN_HANDLER = 'ResultScreenHandler',
    STATE_MODEL = 'StateModel',
    APP_VERSION = 'APP_VERSION',
    CUSTOMER_MANAGER = 'CustomerManager',
    AGENT_MANAGER = 'AgentManager',
    CUSTOMER_RECPATCHA_MANAGER = 'RecaptchaCustomer',
    AGENT_RECAPTCHA_MANAGER = 'RecaptchaAgent',
    CROP_AREA_HANDLER = 'getCroppedArea',
    AGENT_REGISTRATION_MANAGER = 'AgentRegistrationManager',
    CUSTOMER_REGISTRATION_MANAGER = 'CustomerRegistrationManager',
    RESET_FLOW_EVENT_HANDLER = 'ResetFlow',
    HANDLE_POLING_RESULT = 'HandleCustomerAction',
    EXPIRED_SESSION_INTERCEPTOR = 'ExpiredSessionInterceptor'
}

export const MAX_IMAGE_SIZE = 4 * 1024 * 1024; // 4Mb in bytes

export const AVAILABLE_LANGUAGES: IDropdownItem[] = [
    {
        id: 1,
        icon: FrFlagIcon,
        key: 'FR',
        description: 'FR'
    },

    {
        id: 2,
        icon: EnFlagIcon,
        key: 'EN',
        description: 'EN'
    }
];

export const GENDERS: IDropdownItem[] = [
    {
        id: 1,
        key: 'gender.male',
        description: 'gender.male'
    },

    {
        id: 2,
        key: 'gender.female',
        description: 'gender.female'
    }
];

export const FALLBACK_GENDER = { id: -0, key: 'generalInformation.fallbackGender', description: '' };

export enum APP_ROUTES {
    HOME = '/home',
    TERMS_AND_CONDITIONS = '/terms-and-conditions',
    REGISTRATION = '/registration',
    COOKIE_POLICY = '/cookie-policy',
    PRIVACY_POLICY = '/privacy-policy',
    LOGIN = '/login',
    CONSENT_REGISTRATION = '/consent-registration'
}

export const COUNTRY_CODE = {
    code: '+243',
    validPrefixesRegex: new RegExp('^(8[123])[0-9]*$'),
    initialNumberRegex: new RegExp('^(8)$')
};

const documentNrValidationRules = {
    DRIVING_LICENSE: /^[AA]{2}[A-Z0-9]{1,14}$/, // TBC
    VOTER_CARD: /[0-9]$/, // TBC
    PASSPORT: /^[OP]{2}[0-9]{1,7}$/
};

export const DOCUMENT_TYPES = {
    PASSPORT: 'PASSPORT',
    VOTER_CARD_1: 'VOTER_CARD_1',
    VOTER_CARD_2: 'VOTER_CARD_2',
    DRIVING_LICENSE: 'DRIVING_LICENSE'
};

export const DOCUMENT_ORIENTATION = {
    FRONT: 'FRONT',
    BACK: 'BACK'
};

export enum SCREEN_ORIENTATION {
    PORTRAIT = 'portrait',
    LANDSCAPE = 'landscape'
}

export enum OTP_OPERATION_TYPE {
    REGISTRATION = 'REGISTRATION',
    STATUS = 'STATUS'
}

export enum MSISDN_OPERATION_TYPE {
    CAPTCHA = 'REQUEST_OTP'
}

export const RESEND_CODE_INTERVAL_IN_SECONDS = 60;
export const BLOCKED_IP_INTERVAL_IN_SECONDS = 15 * 60;
export const RESEND_RECAPTCHA_INTERVAL = 5 * 60;
export const DEFAULT_OTP_NUM_OF_DIGITS = 6;
export const REVERSE_OTP_NUM_OF_DIGITS = 4;
export const RESEND_REVERSE_OTP_INTERVAL_IN_SECONDS = 60;
export const REVERSE_OTP_EXPIRATION_INTERVAL_IN_SECONDS = 5 * 60;
export const START_CUSTOMER_ACTION_POLLING_WAITING_TIME_IN_MILLISECONDS = 3 * 1000; // TBD

export enum REGISTRATION_TYPE {
    SELF = 'REGISTRATION',
    AGENT ='LOGIN'
}

const Constants = {
    API_ENDPOINTS: {
        BASE_URL: process.env.REACT_APP_BASE_URL,
        PUBLIC_KEY_NEGOTIATION: 'server/public-key',
        AGENT: {
            QUERY_CUSTOMER_INFO: 'agent/msisdn-validation',
            START_AGENT_FLOW: 'agent/registration/start',
            SEND_REVERSE_OTP: 'agent/registration/reverse-otp',
            ACCOUNT_CREATION: 'agent/registration/submit',
            REQUEST_REVERSE_OTP: 'agent/registration/reverse-otp',
            QUERY_CONSENT_TERMS_AND_CONDITIONS_ID_STATUS: 'consent',
            VALIDATE_REVERSE_OTP: 'agent/registration/reverse-otp/validate',
            VALIDATE_PIN: 'agent/registration/confirm',
            SEND_USSD_PUSH: 'agent/registration/ussd'
        },
        CUSTOMER: {
            QUERY_CUSTOMER_INFO: 'msisdn-validation',
            QUERY_CONSENT_TERMS_AND_CONDITIONS_ID_STATUS: 'consent',
            ACCOUNT_CREATION: 'registration'
        },
        REQUEST_CUSTOMER_OTP: 'otp',
        REQUEST_OTP_BY_AGENT: 'agent/registration/otp',
        VALIDATE_CUSTOMER_OTP: 'otp/validate',
        VALIDATE_AGENT_OTP: 'agent/otp/validate',
        REQUEST_CUSTOMER_UPLOAD_DOCUMENT: 'document-id',
        REQUEST_AGENT_UPLOAD_DOCUMENT: 'agent/registration/document-id',
        START_CHALLENGE: 'liveness-detection/start',
        VERIFY_CHALLENGE: 'liveness-detection/verify',
        SUBMIT_RATING: 'registration/rating',
        VALIDATE_CUSTOMER_RECAPTCHA: 'recaptcha',
        VALIDATE_AGENT_RECAPTCHA: 'agent/registration/recaptcha',
        FALLBACK: 'fallback',
        VALIDATE_AGENCY: 'agent/agency/validate',
        VALIDATE_AGENT: 'agent/validate',
        AGENT_OTP: 'agent/otp/validate',
        SESSION: 'agent/session',
        RESEND_AGENT_OTP: 'agent/otp',
        REQUEST_RECAPTCHA: `${process.env.REACT_APP_HOSTNAME}google/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`,
        REQUEST_RECAPTCHA_MOCKED: `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`,
        REQUEST_TENSORFLOW_FACE_MODELS: `${process.env.REACT_APP_HOSTNAME}cdnjsdelivr/npm/@mediapipe/face_mesh@`,
        REQUEST_TENSORFLOW_FACE_MODELS_MOCKED: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh@',
        GA_EVENTS: 'google-analytics/event/push',
        LOGOUT: 'agent/logout',
        VERIFY_CHALLENGE_BY_AGENT: 'agent/registration/liveness-detection'
    },
    API_TIMEOUT: process.env.REACT_APP_API_TIMEOUT,
    RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
    HOSTNAME: process.env.REACT_APP_HOSTNAME,
    LIVELINESS_CHECK: LivelinessCheck,
    FORM_STEPS: FormSteps,
    ERRORS: {
        ERROR_CODES,
        ERRORS_DESCRIPTION,
        RECOVERABLE_ACCOUNT_CREATION_ERRORS,
        RECOVERABLE_LIVELINESS_CHALLENGE_ERRORS,
        DOCUMENT_NOT_CLEAR_ERRORS
    },
    MARKET_GEO_INFORMATION: Nationalities,
    DOCUMENT_CAPTURE: {
        CROP_BOX_MAX_WIDTH: 700,
        CROP_BOX_MAX_HEIGHT: 400,
        CROP_BOX_WIDTH_TO_HEIGHT_RATIO: 0.6
    },
    DOCUMENT_VALIDATION_RULES: documentNrValidationRules,
    LOG_LEVEL: process.env.REACT_APP_LOG_LEVEL,
    GA_KEY: String(process.env.REACT_APP_GA_KEY),
    FIREBASE_API_KEY: String(process.env.REACT_APP_FIREBASE_API_KEY),
    FIREBASE_AUTH_DOMAIN: String(process.env.REACT_APP_FIREBASE_AUTH_DOMAIN),
    FIREBASE_PROJECT_ID: String(process.env.REACT_APP_FIREBASE_PROJECT_ID),
    FIREBASE_STORAGE_BUCKET: String(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET),
    FIREBASE_MESSAGE_SENDER_ID: String(process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID),
    FIREBASE_APP_ID: String(process.env.REACT_APP_FIREBASE_APP_ID),
    FIREBASE_MEASUREMENT_ID: String(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID)
};

export enum INTERNET_CONNECTION_STATUS {
    ONLINE= 'online',
    OFFLINE = 'offline'
}

export const MIN_REQUIRED_NR_OF_CAMERAS = 1;

export const SCREENS_WITHOUT_ARROW_BACK = [
    FormSteps.PERSONAL_DOCUMENTS.KEY,
    FormSteps.CONFIRMATION.KEY
];

export enum CHALLENGE_INSTRUCTIONS {
    NO_FACE_DETECTED = 'lookAtTheCamera',
    SMILE = 'smileAtTheCamera',
    POSITION_FACE = 'positionFace',
    POSITION_NOSE = 'positionNose',
    MOVE_CLOSER = 'moveCloser',
    STOP_SMILING = 'stopSmiling'
}

export interface IDocumentRequiredLength {
    max: number;
    min: number;
}

export const DRIVING_LICENSE_REQUIRED_LENGTH: IDocumentRequiredLength = {
    max: 14,
    min: 11
};
export const TIME_TO_START_CHALLENGE_IN_MILLISECONDS = 3000;

// This are steps where no crucial information has been saved yet
export const NON_CRITICAL_STEPS: string[] = [
    Constants.FORM_STEPS.ENTER_PHONE_NUMBER.KEY,
    Constants.FORM_STEPS.TERMS_AND_CONDITIONS.KEY,
    Constants.FORM_STEPS.COOKIE_POLICY.KEY,
    Constants.FORM_STEPS.PRIVACY_POLICY.KEY,
    Constants.FORM_STEPS.ONBOARDING.KEY,
    Constants.FORM_STEPS.VALIDATE_AGENCY.KEY
];

export const FALLBACK_LIMIT_IN_MILLISECONDS = 60000;

export const FALLBACK_INTERVAL_IN_MILLISECONDS = 5000;

export enum ISOLanguageCodeEnum {
    EN = 'en',
    FR = 'fr'
}

export enum BrowserCameraPermissionNameEnum {
    CHROME = 'camera',
    FIREFOX = 'midi',
    SAFARI = 'camera',
    EDGE = 'camera',
    IE = 'camera',
    OPERA = 'camera',
    UNKNOWN = 'unknown'
}

export enum EnvironmentEnum {
    DEV = 'DEV',
    DEV_AUTOMATION = 'DEV',
    UAT = 'UAT',
    OAT = 'OAT',
    PROD = 'PROD'
}

export const AGENT_FALLBACK_NAME = 'MP';

export default Constants;
