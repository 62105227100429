import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

const SmallErrorMessage = styled.span`
  font-family: "VodafoneR";
  font-size: ${Pixel2Rem(14)};
  color: ${themeProvider.errorColor};
  margin-left: ${Pixel2Rem(5)};
`;

const ErrorMessageWrapper = styled.div`
  height: ${Pixel2Rem(7)};
`;

export { SmallErrorMessage, ErrorMessageWrapper };
