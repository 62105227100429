import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Utils from '../../../Utils/Utils';
import { themeProvider } from '../../Theme/ThemeProvider';
import CustomizedWrapper from '../CustomizedWrapper/CustomizedWrapper';
import DateField from '../DateField/DateField';
import Dropdown from '../Dropdown/Dropdown';
import {
    IUserPersonalInformation, IUserAddressInformation, IUserDataItem, IUserDataList
} from '../GeneralInformation/general-information-types';
import NumberField from '../NumberField/NumberField';
import TextField from '../TextField/TextField';

interface IProps {
    data: IUserPersonalInformation | IUserAddressInformation,
    setData: Dispatch<SetStateAction<any>>,
}

const FieldRenderer: FC<IProps> = ({
    data,
    setData
}) => {
    const documentNrFields = ['voterNr', 'passportNr', 'drivingLicenseNr'];

    const validateFieldInputRule = (name: string, dataProperty: IUserDataItem, value: string) => {
        if (documentNrFields.includes(name)) { // checks if the current field is a documentNr field
            if (dataProperty.acceptanceCriteria(value, dataProperty.validateDocumentFormat)) { // check the regex for the specified document(regex in constants)
                return dataProperty.acceptanceCriteria(value, dataProperty.validateDocumentFormat);
            }

            return (value.length <= dataProperty.requiredLength.max && Utils.withoutSpecialChars(value));
        }

        return dataProperty.acceptanceCriteria(value);
    };
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        const dataProperty: IUserDataItem = data[name];

        let isValid: boolean = true;
        if (dataProperty.type === 'DATE') {
            isValid = Utils.validateDate(value);
        } else {
            const fieldLengthValidation = Utils.validateFieldLength(
                {
                    value,
                    min: dataProperty.requiredLength.min,
                    max: dataProperty.requiredLength.max
                }
            );

            isValid = documentNrFields.includes(name) // checks if the current field is a documentNr field
                ? dataProperty.acceptanceCriteria(value, dataProperty.validateDocumentFormat) && fieldLengthValidation
                : fieldLengthValidation;
        }

        setData((prev: { [key: string]: any; }) => {
            if (prev[name]?.input?.length > value?.length || validateFieldInputRule(name, dataProperty, value)) {
                return {
                    ...prev,
                    [name]: {
                        ...prev[name],
                        ...{
                            input: value,
                            isValid
                        }
                    }
                };
            }
            return prev;
        });
    };

    const selectItemHandler = (item: any) => {
        const dataProperty = item?.key.substring(0, item?.key.indexOf('.')); //* get the field that is being updated
        if (dataProperty === 'province') {
            setData((prev: any) => ({
                ...prev,
                [dataProperty]: {
                    ...prev[dataProperty],
                    ...{
                        selectedItem: item,
                        isValid: true,
                        isLabelVisible: true
                    }
                },
                town: {
                    ...prev.town,
                    ...{
                        list: Utils.getTownsByProvinceIDFiltered(item.id),
                        isValid: item.id === data.town.selectedItem.provinceID
                    }
                }
            }));
        } else {
            setData((prev: any) => ({
                ...prev,
                [dataProperty]: {
                    ...prev[dataProperty],
                    ...{
                        selectedItem: item,
                        isValid: true,
                        isLabelVisible: true
                    }
                }
            }));
        }
    };

    const { t } = useTranslation();

    const renderField = (item: IUserDataItem & IUserDataList, key: string) => {
        //* Render different types of inputs based on the type of the field
        const {
            disabled, isLabelVisible, list, selectedItem, type, isValid
        } = item;
        const inputLabel = t(`generalInformation.${key}`);
        const maxLength = item?.requiredLength?.max;
        switch (type) {
        case 'NUMBER':
            return (
                <NumberField
                    data={item}
                    id={key}
                    inputLabel={inputLabel}
                    handleChange={handleChange}
                    maxLength={maxLength}
                />
            );
        case 'TEXT':
            return (
                <TextField
                    data={item}
                    id={key}
                    handleChange={handleChange}
                    inputLabel={inputLabel}
                    maxLength={maxLength}
                />
            );

        case 'DATE':
            return (
                <>
                    <DateField
                        data={item}
                        id={key}
                        handleChange={handleChange}
                        inputLabel={inputLabel}
                        maxLength={maxLength}
                    />
                </>
            );

        case 'LIST':
            return (
                <CustomizedWrapper
                    label={inputLabel}
                    borderColor={disabled ? themeProvider.inputColor
                        : isValid ? themeProvider.darkGrey : themeProvider.errorColor}
                    isLabelVisible={isLabelVisible}
                >
                    <Dropdown
                        defaultItem={selectedItem}
                        isInput
                        items={list}
                        onSelect={selectItemHandler}
                        disabled={disabled}
                        identifier={key}
                    />
                </CustomizedWrapper>
            );

        default:
            return (
                <TextField
                    data={item}
                    id={key}
                    handleChange={handleChange}
                    inputLabel={inputLabel}
                    maxLength={maxLength}
                />
            );
        }
    };

    return (
        <>
            {Object.entries(data).map(([key, value]) => (
                <li className="user-data__item" key={key}>
                    {renderField(value, key)}
                </li>
            ))}
        </>
    );
};

export default FieldRenderer;
