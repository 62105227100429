import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18Http from 'i18next-http-backend';

i18n.use(initReactI18next)
    .use(i18Http)
    .init({
        lng: 'FR',
        fallbackLng: 'FR',
        interpolation: {
            escapeValue: false
        }
    });
