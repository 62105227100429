import styled from 'styled-components';
import Pixel2Rem from '../../../../Utils/Pixel2Rem';
import { themeProvider } from '../../../Theme/ThemeProvider';

const ValidateAgentScreen = styled.form`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .operator-id-wrapper {
        input[type=number] {
            -webkit-text-security: disc;
        }

        .operator-id-wrapper__title {
            font-family: ${themeProvider.fontBold} !important;
            font-style: normal;
            font-size: ${Pixel2Rem(28)};
            line-height: ${Pixel2Rem(32)};
            color: ${themeProvider.titleColor};
        }

        .operator-id-wrapper__description {
            font-family: ${themeProvider.fontFamily};
            font-style: normal;
            font-weight: 400;
            font-size: ${Pixel2Rem(18)};
            line-height: ${Pixel2Rem(20)};
            color: ${themeProvider.darkGrey};
        }

        .operator-id-wrapper__input {
            margin: ${Pixel2Rem(40)} auto ${Pixel2Rem(33)} auto;
        }

    }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        font-family: ${themeProvider.fontFamily};

        .footer__button {
            box-sizing: border-box;
            margin-bottom: ${Pixel2Rem(20)};
            width: 100%;
        }
    }
`;

export default ValidateAgentScreen;
