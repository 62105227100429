import Logger from './Logger';
import { LOGGER_TAGS } from './Constants';

abstract class EventEmitter {
    private static LOG_TAG: string = 'EventEmitter';

    private static events: Map<string, Function[]> = new Map();

    static subscribe(evName: string, callback: Function): void {
        const callbacks = EventEmitter.events.get(evName) || [];
        callbacks.push(callback);
        EventEmitter.events.set(evName, callbacks);
        Logger.log(
            LOGGER_TAGS.EVENT_EMITTER,
            `Event subscribed: ${evName}`,
            callback
        );
    }

    static unsubscribe(evName: string, callback: Function): void {
        const callbacks = EventEmitter.events.get(evName) || [];

        for (let i = 0; i < callbacks.length; i++) {
            if (callbacks[i] === callback) {
                callbacks.splice(i, 1);
                Logger.log(
                    LOGGER_TAGS.EVENT_EMITTER,
                    `Event listener unsubscribed: ${evName}`,
                    callback
                );
                return;
            }
        }
    }

    static dispatch(evName: string, asynch?: boolean, data?: any): void {
        const callbacks = EventEmitter.events.get(evName) || [];

        Logger.log(
            LOGGER_TAGS.EVENT_EMITTER,
            `Event dispatched to ${callbacks.length} listeners: ${evName}`,
            data
        );

        if (!asynch) {
            callbacks.forEach((callback) => {
                callback(data);
            });
        } else {
            setTimeout(() => {
                callbacks.forEach((callback) => {
                    callback(data);
                });
            }, 0);
        }
    }
}

export default EventEmitter;
