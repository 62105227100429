import {
    FC, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { IFormStepProps } from '../PageWrapper/PageWrapper';
import InitChallenge from './InitChallenge/InitChallenge';
import Loader from '../../Components/Loader/Loader';
import ChallengeManager from '../../../Business/ChallengeManager';
import ChallengeDetails from '../../../Business/data/ChallengeDetails';
import Constants from '../../../Utils/Constants';
import DI from '../../../Utils/DI';
import { ERRORS_DESCRIPTION, ERROR_CODES, IError } from '../../../Utils/Constants/Errors';
import Challenge from './Challenge/Challenge';
import IChallengeManager from '../../../Business/IChallengeManager';
import IAnalyticsManager from '../../../Business/IAnalyticsManager';
import useGaEventTracker from '../../Hooks/useGaEventTracker';
import { ISteps } from '../../../Utils/Constants/FormSteps';
import { LivenessCheckConstraints } from '../../../Utils/Constants/LivelinessCheck';
import MediaStreamConfig from '../../../Utils/MediaStream/MediaStream';
import IDocumentManager from '../../../Business/IDocumentManager';
import instructionAnimation from '../../Assets/animations/livenessCheckInstruction.json';
import { ILivenessCheckInstructions } from '../../Components/TakePhoto/TakePhoto';
import { VALIDATION_STEPS } from './ValidateFace/validate-face.types';
import useStepContext from '../../Hooks/useStepContext';
import ResultScreen from '../../Components/ResultScreen/ResultScreen';

const Selfie: FC<IFormStepProps> = ({ nextStepHandler }) => {
    const documentManager: IDocumentManager = DI.get('DocumentManager');
    const challengeManager: IChallengeManager = DI.get('ChallengeManager');
    const analyticsManager: IAnalyticsManager = DI.get('AnalyticsManager');
    const SELECTED_DOCUMENT = documentManager.getProcessedDocuments()[0].documentType.toUpperCase();
    const [challengeDetails, setChallengeDetails] = useState({});
    const [success, setSuccess] = useState<boolean>(false);
    const { currentFormStep } = useStepContext();
    const [step, setStep] = useState<number>(currentFormStep.PROPS?.initialStep || VALIDATION_STEPS.INIT);
    const [mediaStreamReady, setMediaStreamReady] = useState(false);
    const { t } = useTranslation();
    const { eventTracker, screenTracker } = useGaEventTracker();

    const [errorCode, setErrorCode] = useState<number>(
        Constants.ERRORS.ERROR_CODES.CAMERA.UNEXPECTED_ERROR
    );
    const errorMessage: IError = {
        title: ERRORS_DESCRIPTION[errorCode]?.title
        || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title,
        description: ERRORS_DESCRIPTION[errorCode]?.description
        || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description
    };
    const [disableButton, setDisableButton] = useState(false);

    const onOrientationChange = (angle: number) => {
        setStep((prev) => {
            setDisableButton(angle !== 0);
            if (angle !== 0 && prev !== 4) {
                analyticsManager.updateLivenessCheckErrorCounter('UNSUPPORTED_ORIENTATION');
                onFail(Constants.ERRORS.ERROR_CODES.CAMERA.NOT_ALLOWED_ORIENTATION_CHANGE);
            }
            return prev;
        });
    };

    useEffect(() => {
        if (step === 1) {
            MediaStreamConfig.loadMediaStream({
                constraints: LivenessCheckConstraints,
                successCallback: () => setMediaStreamReady(true),
                errorCallback: (errorCode) => {
                    setErrorCode(errorCode);
                    setStep(VALIDATION_STEPS.ERROR);
                }
            }
            );
        } else if (step !== 2) {
            MediaStreamConfig.closeMediaStream();
        }
    }, [step]);

    const onChallengeDetails = (challengeDetails: ChallengeDetails | VALIDATION_STEPS): void => {
        setChallengeDetails(challengeDetails);
        setStep(VALIDATION_STEPS.VALIDATION);
    };

    const onLocalSuccess = (challenge: ChallengeManager): void => {
        MediaStreamConfig.closeMediaStream();
        setStep(VALIDATION_STEPS.LOADING);

        challenge.verifyChallenge()
            .then(
                (success: boolean) => {
                    eventTracker({
                        name: 'LIVENESS_CHECK',
                        objectValue: {
                            SUCCESS: `ATTEMPTS: ${analyticsManager.getAttemptsCounter()}`
                        }
                    });
                    setSuccess(success);
                    setStep(VALIDATION_STEPS.SUCCESS);
                },
                (errorCode) => onFail(errorCode)
            )
            .finally(() => MediaStreamConfig.closeMediaStream());
    };

    const onFail = (errorCode: number): void => {
        setSuccess(false);
        challengeManager.clearCapturedFrames();
        setErrorCode(errorCode);
        analyticsManager
            .updateLivenessCheckErrorCounter(
                Constants.ERRORS.ERRORS_DESCRIPTION[errorCode].eventTracker!
            );
        if (Constants.ERRORS.ERRORS_DESCRIPTION[errorCode].eventTracker) {
            eventTracker({
                name: 'LIVENESS_CHECK',
                objectValue: {
                    FAILURE: Constants.ERRORS.ERRORS_DESCRIPTION[errorCode].eventTracker as string
                }

            });
        }
        setStep(VALIDATION_STEPS.ERROR);
        setMediaStreamReady(false);
    };

    const onRestart = (): void => {
        setMediaStreamReady(false);
        if (errorCode === ERROR_CODES.LIVENESS_CHECK.CANT_CREATE_DETECTOR) {
            window.location.reload();
            return;
        }
        setStep(VALIDATION_STEPS.INIT);
    };

    useEffect(() => {
        MediaStreamConfig.loadConfig();
        window.addEventListener('orientationchange', () => onOrientationChange(window.orientation));
        return () => {
            MediaStreamConfig.closeMediaStream();
            window.removeEventListener('orientationchange', () => onOrientationChange(window.orientation));
        };
    }, []);

    const customerInstructions: ILivenessCheckInstructions[] = [
        {
            instruction: t('takePhoto.selfie.instructions.removeFacialAccessories')
        },
        {
            instruction: (
                <>
                    <div>
                        <span>{t('takePhoto.selfie.instructions.dontMoveDevice')}</span>
                        <span><strong>{t('takePhoto.selfie.instructions.greenBox')}</strong>&nbsp;</span>
                        <span>{t('takePhoto.selfie.instructions.and')}&nbsp;</span>
                        <span><strong>{t('takePhoto.selfie.instructions.purpleBox')}</strong></span>

                    </div>
                </>
            )
        },

        {
            instruction: (
                <>
                    <div>
                        <span><strong>{t('takePhoto.selfie.instructions.stopSmiling')}&nbsp;</strong></span>
                        <span>{t('takePhoto.selfie.instructions.moveNose')}&nbsp;</span>
                        <span><strong>{t('takePhoto.selfie.instructions.yellowBox')}</strong></span>

                    </div>
                </>
            )
        }
    ];

    (window as any).livelinessCheckCurrentStep = step;
    return (
        <>
            {step === VALIDATION_STEPS.INIT && (
                <InitChallenge
                    ready={mediaStreamReady}
                    handleError={onFail}
                    handleValidationProcess={onChallengeDetails}
                    title={t('takePhoto.selfie.validateYourIdentity')}
                    media={{ animation: { src: instructionAnimation } }}
                    buttonText={t('homePage.letsStart')}
                    instructions={customerInstructions}
                />
            )}

            {step === VALIDATION_STEPS.VALIDATION && (
                <Challenge
                    details={challengeDetails as ChallengeDetails}
                    onLocalSuccessHandler={onLocalSuccess}
                    onFailHandler={onFail}
                    backButtonHandler={onRestart}
                />
            )}

            {step === VALIDATION_STEPS.LOADING && <Loader description={t('takePhoto.selfie.justAMoment')} />}

            {step === VALIDATION_STEPS.SUCCESS && success
            && (
                <ResultScreen
                    success
                    title={t('takePhoto.selfie.validationVerified')}
                    description={t('takePhoto.selfie.validatedLivePerson')}
                    actionText={t('general.continue')}
                    actionFn={() => {
                        screenTracker({ screen: '6.REVIEW_DETAILS', screenClass: 'GENERAL_FORM' });
                        const documentToReview = `REVIEW_${SELECTED_DOCUMENT}` as keyof ISteps;
                        nextStepHandler(Constants.FORM_STEPS[documentToReview]);
                    }}
                    showActionButton
                />
            )}

            {step === VALIDATION_STEPS.ERROR && (
                <ResultScreen
                    actionText={errorCode === ERROR_CODES.LIVENESS_CHECK.CANT_CREATE_DETECTOR
                        ? t('error.uploadDocument.forbiddenNationality.buttonText')
                        : t('general.tryAgain')}
                    actionFn={() => onRestart()}
                    title={t(errorMessage.title)}
                    description={t(errorMessage.description)}
                    disableActionButton={disableButton}
                    showActionButton
                    fullscreen
                />
            )}
        </>

    );
};

export default Selfie;
