import {
    FC, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import IAnalyticsManager from '../../../../Business/IAnalyticsManager';
import Constants from '../../../../Utils/Constants';
import { IError, ERRORS_DESCRIPTION, ERROR_CODES } from '../../../../Utils/Constants/Errors';
import DI from '../../../../Utils/DI';
import MediaStreamConfig from '../../../../Utils/MediaStream/MediaStream';
import useGaEventTracker from '../../../Hooks/useGaEventTracker';
import { IFormStepProps } from '../../PageWrapper/PageWrapper';
import ValidateFace from '../ValidateFace/ValidateFace';
import InitChallenge from '../InitChallenge/InitChallenge';
import validateIdentityImage from '../../../Assets/images/livenessCheckByAgent.png';
import { ILivenessCheckInstructions } from '../../../Components/TakePhoto/TakePhoto';
import ResultScreen from '../../../Components/ResultScreen/ResultScreen';
import { VALIDATION_STEPS } from '../ValidateFace/validate-face.types';
import ChallengeDetails from '../../../../Business/data/ChallengeDetails';
import useStepContext from '../../../Hooks/useStepContext';

const CustomerValidation: FC<IFormStepProps> = () => {
    const analyticsManager: IAnalyticsManager = DI.get('AnalyticsManager');

    const { currentFormStep } = useStepContext();
    const [validationStep, setValidationStep] = useState<VALIDATION_STEPS>(currentFormStep.PROPS?.initialStep || VALIDATION_STEPS.INIT);
    const { t } = useTranslation();
    const { eventTracker } = useGaEventTracker();
    const [errorCode, setErrorCode] = useState<number>(
        Constants.ERRORS.ERROR_CODES.CAMERA.UNEXPECTED_ERROR
    );

    const errorMessage: IError = {
        title: ERRORS_DESCRIPTION[errorCode]?.title
        || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title,
        description: ERRORS_DESCRIPTION[errorCode]?.description
        || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description
    };

    const [disableButton, setDisableButton] = useState(false);

    const onOrientationChange = (angle: number) => {
        setValidationStep((prev) => {
            setDisableButton(angle !== 0);
            analyticsManager.updateLivenessCheckErrorCounter('UNSUPPORTED_ORIENTATION');
            onFail(Constants.ERRORS.ERROR_CODES.CAMERA.NOT_ALLOWED_ORIENTATION_CHANGE);
            return prev;
        });
    };

    useEffect(() => {
        if (validationStep !== VALIDATION_STEPS.VALIDATION) {
            MediaStreamConfig.closeMediaStream();
        }
    }, [validationStep]);

    useEffect(() => {
        window.addEventListener('orientationchange', () => onOrientationChange(window.orientation));
        return () => {
            MediaStreamConfig.closeMediaStream();
            window.removeEventListener('orientationchange', () => onOrientationChange(window.orientation));
        };
    }, []);

    const handleValidationProcess = (validationPhase: VALIDATION_STEPS | ChallengeDetails, errorCode?: number): void => {
        if (errorCode) {
            onFail(errorCode);
        }

        setValidationStep(validationPhase as VALIDATION_STEPS);
    };

    const onFail = (errorCode: number): void => {
        if (Constants.ERRORS.ERRORS_DESCRIPTION[errorCode]?.eventTracker) {
            eventTracker({
                name: 'LIVENESS_CHECK',
                objectValue: {
                    FAILURE: Constants.ERRORS.ERRORS_DESCRIPTION[errorCode].eventTracker as string
                }
            });
        }

        setErrorCode(errorCode);
        setValidationStep(VALIDATION_STEPS.ERROR);
    };

    const agentInstructions: ILivenessCheckInstructions[] = [
        {
            instruction: <span>{t('takePhoto.selfie.agentInstructions.takeCustomerPhoto')}</span>

        },
        {
            instruction: <span>{t('takePhoto.selfie.agentInstructions.directLight')}</span>
        }
    ];

    (window as any).livelinessCheckCurrentStep = validationStep;
    return (
        <>
            {validationStep === VALIDATION_STEPS.INIT && (
                <InitChallenge
                    handleError={onFail}
                    handleValidationProcess={handleValidationProcess}
                    ready
                    title={t('takePhoto.selfie.validateCustomerIdentity')}
                    media={{ image: { src: validateIdentityImage } }}
                    buttonText={t('takePhoto.selfie.agentInstructions.takePhoto')}
                    instructions={agentInstructions}
                />
            )}

            {validationStep === VALIDATION_STEPS.VALIDATION && (
                <ValidateFace handleValidationStep={handleValidationProcess} />
            )}

            { validationStep === VALIDATION_STEPS.ERROR && (
                <ResultScreen
                    success={false}
                    title={t(errorMessage.title)}
                    description={t(errorMessage.description)}
                    actionFn={() => setValidationStep(1)}
                    actionText={t('general.tryAgain')}
                    showActionButton
                    disableActionButton={disableButton}
                />
            )}
        </>

    );
};

export default CustomerValidation;
