import {
    FC, memo, useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Constants, {
    COUNTRY_CODE
} from '../../../Utils/Constants';
import DI from '../../../Utils/DI';
import Utils from '../../../Utils/Utils';
import Button from '../Button/Button';
import Card from '../Card/Card';
import Loader from '../Loader/Loader';
import GeneralInformationScreen from './Style';
import Dialog from '../Dialog/Dialog';
import FieldRenderer from '../FieldRenderer/FieldRenderer';
import Modal from '../Modal/Modal';
import { Gender, UserProps } from '../../../Business/data/User';
import IDocumentManager from '../../../Business/IDocumentManager';
import DocumentManager from '../../../Business/DocumentManager';
import IUserManager from '../../../Business/UserManager/IUserManager';
import IChallengeManager from '../../../Business/IChallengeManager';
import {
    ERRORS_DESCRIPTION,
    ERROR_CODES,
    IError,
    REQUEST_REVERSE_OTP_RECOVERABLE_ERRORS
} from '../../../Utils/Constants/Errors';
import VodafoneWhiteLoader from '../../Assets/loaders/vodafone_white_loader.json';
import IAnalyticsManager from '../../../Business/IAnalyticsManager';
import useGaEventTracker from '../../Hooks/useGaEventTracker';
import ISessionManager from '../../../Business/ISessionManager';
import IGeneralInformationProps, { IUserDataItem } from './general-information-types';
import Checkbox from '../Checkbox/Checkbox';
import {
    ISteps,
    redirectToPersonalDocuments
} from '../../../Utils/Constants/FormSteps';
import useStepContext from '../../Hooks/useStepContext';
import { IResultScreenProps } from '../ResultScreen/result-screen-types';
import IRegistrationManager from '../../../Business/RegistrationManager/IRegistrationManager';
import IDataManager from '../../../Business/IDataManager';

const GeneralInformation: FC<IGeneralInformationProps> = ({
    userPersonalInformation,
    setUserPersonalInformation,
    userAddressInformation,
    setUserAddressInformation,
    nextStepHandler
}) => {
    const dataManager: IDataManager = DI.get('DataManager');
    const userManager: IUserManager = dataManager.convertToUserManager();
    const documentManager: IDocumentManager = DI.get('DocumentManager');
    const challengeManager: IChallengeManager = DI.get('ChallengeManager');
    const analyticsManager: IAnalyticsManager = DI.get('AnalyticsManager');
    const sessionManager: ISessionManager = DI.get('SessionManager');
    const registrationManager: IRegistrationManager = dataManager.convertToRegistrationManager();
    const isAgentLogged: boolean | undefined = useMemo(() => sessionManager.getSession()?.getIsValid(), []);
    const { eventTracker } = useGaEventTracker();
    const { currentFormStep } = useStepContext();

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { FORM_STEPS } = Constants;
    const [showDialog, setShowDialog] = useState({
        show: false,
        title: t('generalInformation.edit'),
        description: t('generalInformation.confirmEdit')
    });

    const [error, setError] = useState<IError>({
        hasError: false,
        title: t('error.apiErrors.somethingWentWrong'),
        description: t('general.pleaseRefresh')
    });

    const closeDialog = (): void => setShowDialog((prev) => ({
        ...prev,
        show: false,
        title: t('generalInformation.edit'),
        description: t('generalInformation.confirmEdit')
    }));

    const showDialogHandler = (imageToEdit: string) => {
        setShowDialog((prev) => ({ ...prev, show: true })); // INFO: shows the dialog for images edition

        switch (imageToEdit) { // INFO: selects the correct text to show in the dialog (selfie or document)
        case 'DOCUMENT':
            setShowDialog((prev) => ({
                ...prev,
                title: `${prev.title} ${t('generalInformation.document')} `,
                description: `${prev.description} ${isAgentLogged
                    ? t('generalInformation.customerDocument')
                    : t('generalInformation.personalDocument')}?`
            }));
            setImageToEdit(imageToEdit);
            break;

        case 'SELFIE':
            setShowDialog((prev) => ({
                ...prev,
                title: `${prev.title} ${isAgentLogged ? t('generalInformation.photo') : t('generalInformation.selfie')}`,
                description: `${prev.description} ${isAgentLogged
                    ? t('generalInformation.customerSelfie')
                    : t('generalInformation.userSelfie')}?`
            }));
            setImageToEdit(imageToEdit);
            break;

        default:
            setShowDialog((prev) => ({
                ...prev,
                title: `${prev.title} ${t('generalInformation.data')} `,
                description: `${prev.description} ${t('generalInformation.dataDescription')}?`
            }));
        }
    };

    const [documentImage, setDocumentImage] = useState<any>({
        FRONT: null,
        BACK: null
    });

    useEffect(() => { // INFO: retrieve the captured images and store in an object
        (documentManager as DocumentManager).getProcessedDocuments().forEach((entry) => {
            setDocumentImage((prev: any) => ({
                ...prev, ...{ [entry.orientation]: entry.file }
            }));
        });
    }, []);

    // INFO: validate if all fields of the userPersonalInformation are valid (i.e isValid)
    const validPersonalInformation = Object.entries(userPersonalInformation).every(
        ([, value]) => value.isValid === true
    );

    // INFO: validate if all fields of the userAddressInformation are valid (i.e isValid)
    const validAddressInformation = Object.entries(userAddressInformation).every(
        ([, value]) => value.isValid === true
    );

    const [agentConfirmation, setAgentConfirmation] = useState(false);

    const [imageToEdit, setImageToEdit] = useState<string>('DOCUMENT');
    const SELECTED_DOCUMENT = documentManager.getProcessedDocuments()[0].documentType.toUpperCase();

    const editImageHandler = () => { // INFO: Redirects to the correct screen in order to edit the specified image
        if (imageToEdit === 'DOCUMENT') {
            eventTracker({
                name: 'IMAGE_RETAKES',
                objectValue: { MODIFIED_IMAGE: SELECTED_DOCUMENT }
            });
            analyticsManager.resetDocumentUploadErrorCounter();
            nextStepHandler(redirectToPersonalDocuments(isAgentLogged));
        }

        if (imageToEdit === 'SELFIE') {
            analyticsManager.resetLivenessCheckErrorCounter();
            analyticsManager.setAttemptsCounter(0);
            eventTracker({
                name: 'IMAGE_RETAKES',
                objectValue: { MODIFIED_IMAGE: 'Selfie' }
            });
            if (isAgentLogged) {
                nextStepHandler(FORM_STEPS.CUSTOMER_VALIDATION);
                return;
            }
            nextStepHandler(currentFormStep.PREVIOUS_STEP_HANDLER({ previousStep: currentFormStep, isAgentLogged }));
        }
    };

    // INFO: Retrieves the correct property name for each document number.
    const retrieveDocumentNumber = (): string => ['passportNr', 'voterNr', 'drivingLicenseNr']
        .find((document) => document in userPersonalInformation) || 'voterNr';

    const redirectToConfirmationScreen = (confirmationScreenProps: IResultScreenProps) => {
        nextStepHandler({
            ...FORM_STEPS.CONFIRMATION,
            PROPS: confirmationScreenProps,
            DONT_SHOW_ICON: true
        });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const documentToReview = `REVIEW_${SELECTED_DOCUMENT}` as keyof ISteps;
        setIsLoading(true);
        const userInfo: UserProps = {
            firstName: userPersonalInformation.firstName.input,
            lastName: userPersonalInformation.lastName.input,
            placeOfBirth: userPersonalInformation.placeOfBirth.input,
            dateOfBirth: userPersonalInformation.dateOfBirth.input,
            gender: userPersonalInformation.gender.selectedItem.key.substring(7, 8).toUpperCase() as Gender,
            nationality: t(userPersonalInformation.citizen.selectedItem.key),
            documentNumber: (userPersonalInformation[retrieveDocumentNumber()] as IUserDataItem).input,
            town: `${userAddressInformation.town.selectedItem.description} VILLE`,
            street: userAddressInformation.street.input,
            commune: userAddressInformation.commune.input,
            province: userAddressInformation.province.selectedItem.description
        };
        userManager.saveUserInformation(userInfo);

        let confirmationScreenProps: IResultScreenProps;
        registrationManager.createAccount()
            .then(() => {
                eventTracker({
                    name: 'REGISTRATION',
                    objectValue: {
                        SUCCESS: `TOC: ${SELECTED_DOCUMENT}
                         - ${Utils.getTimeInterval(moment()
        .subtract(moment.unix(analyticsManager.getInitTime()).unix(), 'milliseconds').format('mm:ss'))}`
                    }
                });

                eventTracker({
                    name: 'REGISTRATION',
                    objectValue: { SUCCESS: `Selected document: ${SELECTED_DOCUMENT}` }
                });
                if (isAgentLogged) {
                    nextStepHandler({
                        ...FORM_STEPS.CONFIRMATION_METHOD,
                        PROPS: { REVIEW_DOCUMENT: documentToReview }
                    });
                    return;
                }

                confirmationScreenProps = {
                    success: true,
                    title: 'confirmation.registrationSubmitted',
                    description: 'confirmation.registrationSubmittedDescription',
                    actionText: 'general.rateExperience',
                    actionFn: () => nextStepHandler(Constants.FORM_STEPS.EVALUATE_EXPERIENCE),
                    showActionButton: true
                };

                redirectToConfirmationScreen(confirmationScreenProps);
            },
            (errorCode) => {
                if (Constants.ERRORS.RECOVERABLE_ACCOUNT_CREATION_ERRORS.includes(errorCode)) {
                    // NOTE: Checks if the error is recoverable or not, if the error is recoverable a modal is shown.
                    // NOTE: If the error is not recoverable the user is redirected to another screen with more detailed information regarding the error
                    setError({
                        hasError: true,
                        title: ERRORS_DESCRIPTION[errorCode].title || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title,
                        description: ERRORS_DESCRIPTION[errorCode].description || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description
                    });
                    return;
                }
                confirmationScreenProps = {
                    title: ERRORS_DESCRIPTION[Number(errorCode)]?.title
                        || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title,
                    description: ERRORS_DESCRIPTION[Number(errorCode)]?.description
                        || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description,
                    actionText: 'general.tryAgain',
                    actionFn: () => {
                        if (REQUEST_REVERSE_OTP_RECOVERABLE_ERRORS.includes(errorCode)) {
                            nextStepHandler({
                                ...FORM_STEPS.AGENT_REVERSE_OTP,
                                PROPS: { REVIEW_DOCUMENT: documentToReview }
                            });
                            return;
                        }
                        window.location.reload();
                    },
                    showActionButton: true

                };

                redirectToConfirmationScreen(confirmationScreenProps);
            });
    };

    return (
        <>
            {isLoading && <Loader withBackdrop animation={VodafoneWhiteLoader} description={t('general.justAMoment')} />}

            <GeneralInformationScreen
                onSubmit={handleSubmit}
            >
                <div className="wrapper">
                    <div className="title">
                        <span>{isAgentLogged ? t('generalInformation.reviewCustomerDetails') : t('generalInformation.reviewDetails')}</span>
                    </div>
                    <div className="subtitle">
                        <span>
                            {t('generalInformation.confirmDetails')}
                        </span>
                    </div>

                    <Card cardLabel={t('screenLabels.phoneNumber')} distanceFromLabelToContent={4}>
                        <span className="phone-number">
                            {`${COUNTRY_CODE.code} ${Utils.msisdnFormatter(userManager.getMsisdn())}`}
                        </span>
                    </Card>

                    <Card cardLabel={t('generalInformation.personalInfo')} distanceFromLabelToContent={20}>
                        <ul className="user-data">
                            <FieldRenderer
                                data={userPersonalInformation}
                                setData={setUserPersonalInformation}
                            />
                        </ul>
                    </Card>

                    <Card cardLabel={t('generalInformation.addressInfo')} distanceFromLabelToContent={20}>
                        <ul className="user-data">
                            <FieldRenderer
                                data={userAddressInformation}
                                setData={setUserAddressInformation}
                            />
                        </ul>
                    </Card>

                    <Card cardLabel={t('generalInformation.attachedPhotos')} distanceFromLabelToContent={10}>
                        <div className="attached-photos">
                            <div className="personal-document">
                                <div
                                    onClick={() => showDialogHandler('DOCUMENT')}
                                    className="personal-document-img personal-document--front"
                                    id="edit-document-button"
                                >
                                    <img className="attached-photos__img" src={documentImage.FRONT} alt="Document front" />
                                </div>

                                {documentImage.BACK && (
                                    <div className="personal-document-img">
                                        <img className="attached-photos__img" src={documentImage.BACK} alt="Document back" />
                                    </div>
                                )}
                            </div>

                            <div onClick={() => showDialogHandler('SELFIE')} className="selfie" id="edit-selfie-button">
                                <img className="selfie__img" src={challengeManager.retrieveCapturedSelfie()} alt="Selfie" />
                            </div>
                        </div>
                    </Card>
                    {isAgentLogged && (
                        <div className="agent-confirmation">
                            <Checkbox
                                id="consent-data-usage-checkbox"
                                checked={agentConfirmation}
                                onChangeFn={(confirmed: boolean) => setAgentConfirmation(confirmed)}
                            />

                            <div className="confirmation__description">
                                <span>{t('generalInformation.agentConfirmation')}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className="footer">
                    <Button
                        type="submit"
                        mode="normal"
                        disabled={!(validAddressInformation && validPersonalInformation && (agentConfirmation || !isAgentLogged))}
                        hasShadow
                        id="submit-registration-button"
                    >{t(`${isAgentLogged ? 'reverseOTP.agent.sendReverseOtp' : 'general.submit'}`)}
                    </Button>
                </div>
            </GeneralInformationScreen>

            <Dialog // INFO: Dialog to confirm image edition
                onClose={closeDialog}
                open={showDialog.show}
                title={showDialog.title}
                description={showDialog.description}
                actions={{
                    cancelText: t('general.cancel'),
                    confirmText: t('generalInformation.edit'),
                    cancelAction: closeDialog,
                    confirmAction: editImageHandler
                }}
            />

            <Modal // INFO: Modal for recoverable errors
                onClose={() => setError((prev) => ({ ...prev, hasError: false }))}
                open={!!error.hasError}
                title={t(error.title)}
                description={t(error.description)}
            />
        </>
    );
};

export default memo(GeneralInformation);
