import { FC } from 'react';
import Confirmation from '../../UI/Pages/Confirmation/Confirmation';
import DrivingLicenseBack from '../../UI/Pages/DrivingLicense/DrivingLicenseBack';
import DrivingLicenseFront from '../../UI/Pages/DrivingLicense/DrivingLicenseFront';
import EnterPhoneNumber from '../../UI/Pages/EnterPhoneNumber/EnterPhoneNumber';
import ValidateOtp from '../../UI/Pages/ValidatePhoneNumber/ValidatePhoneNumber';
import Onboarding from '../../UI/Pages/Onboarding/Onboarding';
import PassportBack from '../../UI/Pages/Passport/PassportBack';
import PassportFront from '../../UI/Pages/Passport/PassportFront';
import PersonalDocuments from '../../UI/Pages/PersonalDocuments/PersonalDocuments';
import ReviewDrivingLicenseDetails from '../../UI/Pages/ReviewDetails/ReviewDrivingLicenseDetails/ReviewDrivingLicenseDetails';
import ReviewPassportDetails from '../../UI/Pages/ReviewDetails/ReviewPassportDetails/ReviewPassportDetails';
import ReviewVoterCardDetails from '../../UI/Pages/ReviewDetails/ReviewVoterCardDetails/ReviewVoterCardDetails';
import TermsAndConditions from '../../UI/Pages/TermsAndConditions/TermsAndConditions';
import Selfie from '../../UI/Pages/Selfie/Selfie';
import { IFormStepProps } from '../../UI/Pages/PageWrapper/PageWrapper';
import CookiePolicy from '../../UI/Pages/CookiePolicy/CookiePolicy';
import PrivacyPolicy from '../../UI/Pages/PrivacyPolicy/PrivacyPolicy';
import StarRating from '../../UI/Pages/Rating/Rating';
import ValidateAgent from '../../UI/Pages/AgentLogin/ValidateAgent/ValidateAgent';
import ValidateAgency from '../../UI/Pages/AgentLogin/ValidateAgency/ValidateAgency';
import VoterCardV1Front from '../../UI/Pages/VoterCard/VoterCardV1Front';
import VoterCardV2Front from '../../UI/Pages/VoterCard/VoterCardV2Front';
import CustomerValidation from '../../UI/Pages/Selfie/CustomerValidation/CustomerValidation';
import ValidateAgentPin from '../../UI/Pages/ValidateAgentPin/ValidateAgentPin';
import { VALIDATION_STEPS } from '../../UI/Pages/Selfie/ValidateFace/validate-face.types';
import { BYPASS_LIVENESS_CHECK } from '../Constants';
import ValidateReverseOtp from '../../UI/Pages/ReverseOTP/Customer/ValidateReverseOtp';
import ValidateCustomer from '../../UI/Pages/ReverseOTP/Agent/ValidateCustomer/ValidateCustomer';
import ConfirmationMethod from '../../UI/Pages/ConfirmationMethod/ConfirmationMethod';
import UssdCustomerValidation from '../../UI/Pages/UssdCustomerValidation/UssdCustomerValidation';

export interface IFormStep {
    STEP: number;
    SCREEN: FC<IFormStepProps>;
    TITLE: string;
    KEY: keyof ISteps;
    PROPS?: any; // INFO: Use this property to past custom props to each/any form step screen
    PREVIOUS_STEP_HANDLER: ({ previousStep, isAgentLogged }: IPreviousStepHandler) => IFormStep;
    IS_NOT_VALIDATION_STEP?: boolean; // INFO: When true, indicates that the step isn't necessarily a validation step, eg: Cookie policy
    DONT_SHOW_ICON?: boolean; // INFO: When true, indicates a step where neither the home icon or the back button icon must be shown
    DONT_SHOW_LOGOUT_ICON?: boolean; // INFO: When true, indicates a step where the logout icon must not be shown
    SHOW_LANGUAGES_DROPDOWN?: boolean // When true, indicates a step where the dropdown to toggle the language is shown
}

export interface IPreviousStepHandler {
    previousStep?: IFormStep;
    isAgentLogged?: boolean;
}

export interface ISteps {
    ONBOARDING: string;
    ENTER_PHONE_NUMBER: string;
    VALIDATE_OTP: string;
    PERSONAL_DOCUMENTS: string;
    REVIEW_PASSPORT: string;
    REVIEW_VOTER_CARD_1: string;
    REVIEW_VOTER_CARD_2: string;
    REVIEW_DRIVING_LICENSE: string;
    TERMS_AND_CONDITIONS: string;
    COOKIE_POLICY: string;
    PRIVACY_POLICY: string;
    VOTER_CARD_1_FRONT: string;
    VOTER_CARD_2_FRONT: string;
    PASSPORT_FRONT: string;
    PASSPORT_BACK: string;
    DRIVING_LICENSE_FRONT: string;
    DRIVING_LICENSE_BACK: string;
    SELFIE: string;
    CONFIRMATION: string;
    EVALUATE_EXPERIENCE: string;
    VALIDATE_AGENCY: string;
    VALIDATE_AGENT: string;
    CUSTOMER_VALIDATION: string;
    VALIDATE_AGENT_PIN: string;
    CUSTOMER_REVERSE_OTP: string;
    AGENT_REVERSE_OTP: String;
    CONFIRMATION_METHOD: String;
    USSD_PUSH_VALIDATION: string;
}

type IFormSteps = { [key in keyof ISteps]: IFormStep };

export const REVIEW_DETAILS_INFO = {
    TITLE: 'generalInformation',
    STEP: 5
};

const shouldBypassLivenessCheck = (isAgentLogged?: boolean) => (
    BYPASS_LIVENESS_CHECK
        ? FormSteps.PERSONAL_DOCUMENTS
        : (isAgentLogged ? FormSteps.CUSTOMER_VALIDATION : FormSteps.SELFIE)
);

export const redirectToPersonalDocuments = (isAgentLogged?: boolean): IFormStep => (
    isAgentLogged
        ? { ...FormSteps.PERSONAL_DOCUMENTS, TITLE: 'customerDocuments' }
        : FormSteps.PERSONAL_DOCUMENTS
);

const FormSteps: IFormSteps = {
    ONBOARDING: {
        STEP: 0,
        SCREEN: Onboarding,
        TITLE: 'onboarding',
        KEY: 'ONBOARDING',
        PREVIOUS_STEP_HANDLER: () => FormSteps.ONBOARDING,
        DONT_SHOW_LOGOUT_ICON: true,
        IS_NOT_VALIDATION_STEP: true,
        SHOW_LANGUAGES_DROPDOWN: true
    },

    ENTER_PHONE_NUMBER: {
        STEP: 1,
        SCREEN: EnterPhoneNumber,
        TITLE: 'phoneNumber',
        KEY: 'ENTER_PHONE_NUMBER',
        PREVIOUS_STEP_HANDLER: () => FormSteps.ONBOARDING
    },

    VALIDATE_OTP: {
        STEP: 1,
        SCREEN: ValidateOtp,
        TITLE: 'phoneNumber',
        KEY: 'VALIDATE_OTP',
        PREVIOUS_STEP_HANDLER: () => FormSteps.ENTER_PHONE_NUMBER
    },

    PERSONAL_DOCUMENTS: {
        STEP: 2,
        SCREEN: PersonalDocuments,
        TITLE: 'personalDocuments',
        KEY: 'PERSONAL_DOCUMENTS',
        PREVIOUS_STEP_HANDLER: () => FormSteps.VALIDATE_OTP,
        DONT_SHOW_ICON: true
    },

    REVIEW_PASSPORT: {
        STEP: REVIEW_DETAILS_INFO.STEP,
        SCREEN: ReviewPassportDetails,
        TITLE: REVIEW_DETAILS_INFO.TITLE,
        KEY: 'REVIEW_PASSPORT',
        PREVIOUS_STEP_HANDLER: ({ isAgentLogged }) => shouldBypassLivenessCheck(isAgentLogged)
    },

    REVIEW_VOTER_CARD_1: {
        STEP: REVIEW_DETAILS_INFO.STEP,
        SCREEN: ReviewVoterCardDetails,
        TITLE: REVIEW_DETAILS_INFO.TITLE,
        KEY: 'REVIEW_VOTER_CARD_1',
        PREVIOUS_STEP_HANDLER: ({ isAgentLogged }) => shouldBypassLivenessCheck(isAgentLogged)
    },
    REVIEW_VOTER_CARD_2: {
        STEP: REVIEW_DETAILS_INFO.STEP,
        SCREEN: ReviewVoterCardDetails,
        TITLE: REVIEW_DETAILS_INFO.TITLE,
        KEY: 'REVIEW_VOTER_CARD_2',
        PREVIOUS_STEP_HANDLER: ({ isAgentLogged }) => shouldBypassLivenessCheck(isAgentLogged)
    },

    REVIEW_DRIVING_LICENSE: {
        STEP: REVIEW_DETAILS_INFO.STEP,
        SCREEN: ReviewDrivingLicenseDetails,
        TITLE: REVIEW_DETAILS_INFO.TITLE,
        KEY: 'REVIEW_DRIVING_LICENSE',
        PREVIOUS_STEP_HANDLER: ({ isAgentLogged }) => shouldBypassLivenessCheck(isAgentLogged)
    },

    TERMS_AND_CONDITIONS: {
        STEP: 2,
        SCREEN: TermsAndConditions,
        TITLE: 'termsAndConditions',
        KEY: 'TERMS_AND_CONDITIONS',
        PREVIOUS_STEP_HANDLER: () => FormSteps.ONBOARDING,
        DONT_SHOW_ICON: true,
        SHOW_LANGUAGES_DROPDOWN: true,
        DONT_SHOW_LOGOUT_ICON: true
    },

    COOKIE_POLICY: {
        STEP: 0,
        SCREEN: CookiePolicy,
        TITLE: 'cookiePolicy',
        KEY: 'COOKIE_POLICY',
        PREVIOUS_STEP_HANDLER: () => FormSteps.ONBOARDING,
        IS_NOT_VALIDATION_STEP: true,
        DONT_SHOW_LOGOUT_ICON: true
    },

    PRIVACY_POLICY: {
        STEP: 0,
        SCREEN: PrivacyPolicy,
        TITLE: 'privacyPolicy',
        KEY: 'PRIVACY_POLICY',
        PREVIOUS_STEP_HANDLER: () => FormSteps.ONBOARDING,
        IS_NOT_VALIDATION_STEP: true,
        DONT_SHOW_LOGOUT_ICON: true
    },

    VOTER_CARD_1_FRONT: {
        STEP: 3,
        SCREEN: VoterCardV1Front,
        TITLE: 'voterCardFront',
        KEY: 'VOTER_CARD_1_FRONT',
        PREVIOUS_STEP_HANDLER: ({ isAgentLogged }) => redirectToPersonalDocuments(isAgentLogged)
    },

    VOTER_CARD_2_FRONT: {
        STEP: 3,
        SCREEN: VoterCardV2Front,
        TITLE: 'voterCardFront',
        KEY: 'VOTER_CARD_2_FRONT',
        PREVIOUS_STEP_HANDLER: ({ isAgentLogged }) => redirectToPersonalDocuments(isAgentLogged)
    },

    PASSPORT_FRONT: {
        STEP: 3,
        SCREEN: PassportFront,
        TITLE: 'passportFront',
        KEY: 'PASSPORT_FRONT',
        PREVIOUS_STEP_HANDLER: ({ isAgentLogged }) => redirectToPersonalDocuments(isAgentLogged)
    },

    PASSPORT_BACK: {
        STEP: 3,
        SCREEN: PassportBack,
        TITLE: 'passportBack',
        KEY: 'PASSPORT_BACK',
        PREVIOUS_STEP_HANDLER: ({ isAgentLogged }) => redirectToPersonalDocuments(isAgentLogged)
    },

    DRIVING_LICENSE_FRONT: {
        STEP: 3,
        SCREEN: DrivingLicenseFront,
        TITLE: 'drivingLicenseFront',
        KEY: 'DRIVING_LICENSE_FRONT',
        PREVIOUS_STEP_HANDLER: ({ isAgentLogged }) => redirectToPersonalDocuments(isAgentLogged)
    },

    DRIVING_LICENSE_BACK: {
        STEP: 3,
        SCREEN: DrivingLicenseBack,
        TITLE: 'drivingLicenseBack',
        KEY: 'DRIVING_LICENSE_BACK',
        PREVIOUS_STEP_HANDLER: ({ isAgentLogged }) => redirectToPersonalDocuments(isAgentLogged)
    },
    SELFIE: {
        STEP: 4,
        SCREEN: Selfie,
        TITLE: 'validateYourIdentity',
        KEY: 'SELFIE',
        PREVIOUS_STEP_HANDLER: () => ((window as any)?.livelinessCheckCurrentStep === VALIDATION_STEPS.INIT
            ? { ...FormSteps.PERSONAL_DOCUMENTS, TITLE: 'personalDocuments' }
            : { ...FormSteps.SELFIE, PROPS: { initialStep: VALIDATION_STEPS.INIT } })
    },
    CUSTOMER_VALIDATION: {
        STEP: 4,
        SCREEN: CustomerValidation,
        TITLE: 'validateCustomerIdentity',
        KEY: 'CUSTOMER_VALIDATION',
        PREVIOUS_STEP_HANDLER: () => ((window as any)?.livelinessCheckCurrentStep === VALIDATION_STEPS.INIT
            ? { ...FormSteps.PERSONAL_DOCUMENTS, TITLE: 'customerDocuments' }
            : { ...FormSteps.CUSTOMER_VALIDATION, PROPS: { initialStep: VALIDATION_STEPS.INIT } })
    },
    CONFIRMATION: {
        STEP: 6,
        SCREEN: Confirmation,
        TITLE: 'confirmation',
        KEY: 'CONFIRMATION',
        PREVIOUS_STEP_HANDLER: () => FormSteps.CONFIRMATION
    },
    EVALUATE_EXPERIENCE: {
        STEP: 0,
        SCREEN: StarRating,
        TITLE: 'rating',
        KEY: 'EVALUATE_EXPERIENCE',
        PREVIOUS_STEP_HANDLER: () => FormSteps.EVALUATE_EXPERIENCE,
        IS_NOT_VALIDATION_STEP: true,
        DONT_SHOW_ICON: true,
        DONT_SHOW_LOGOUT_ICON: true
    },
    VALIDATE_AGENCY: {
        STEP: 0,
        SCREEN: ValidateAgency,
        TITLE: 'shortCode',
        KEY: 'VALIDATE_AGENCY',
        PREVIOUS_STEP_HANDLER: () => FormSteps.ONBOARDING,
        IS_NOT_VALIDATION_STEP: true
    },
    VALIDATE_AGENT: {
        STEP: 0,
        SCREEN: ValidateAgent,
        TITLE: 'operatorID',
        KEY: 'VALIDATE_AGENT',
        PREVIOUS_STEP_HANDLER: () => FormSteps.VALIDATE_AGENCY,
        IS_NOT_VALIDATION_STEP: true
    },
    VALIDATE_AGENT_PIN: {
        STEP: 0,
        SCREEN: ValidateAgentPin,
        TITLE: 'validateAgentPin',
        KEY: 'VALIDATE_AGENT_PIN',
        PREVIOUS_STEP_HANDLER: () => FormSteps.VALIDATE_OTP,
        IS_NOT_VALIDATION_STEP: true,
        DONT_SHOW_ICON: true
    },
    CUSTOMER_REVERSE_OTP: {
        STEP: 4,
        SCREEN: ValidateReverseOtp,
        TITLE: 'confirmation',
        KEY: 'CUSTOMER_REVERSE_OTP',
        PREVIOUS_STEP_HANDLER: ({ previousStep }) => ({
            ...FormSteps.TERMS_AND_CONDITIONS,
            PROPS: {
                idParameter: previousStep?.PROPS?.idParameter
            }
        }),
        DONT_SHOW_LOGOUT_ICON: true
    },
    AGENT_REVERSE_OTP: {
        STEP: 6,
        SCREEN: ValidateCustomer,
        TITLE: 'confirmation',
        KEY: 'AGENT_REVERSE_OTP',
        PREVIOUS_STEP_HANDLER: ({ previousStep }) => ({
            ...FormSteps.CONFIRMATION_METHOD,
            PROPS: { REVIEW_DOCUMENT: previousStep!.PROPS.REVIEW_DOCUMENT }
        })
    },
    CONFIRMATION_METHOD: {
        STEP: 6,
        SCREEN: ConfirmationMethod,
        TITLE: 'confirmation',
        KEY: 'CONFIRMATION_METHOD',
        PREVIOUS_STEP_HANDLER: ({ previousStep }) => ({
            ...FormSteps[previousStep!.PROPS.REVIEW_DOCUMENT as keyof ISteps],
            PROPS: { visited: true }
        })
    },
    USSD_PUSH_VALIDATION: {
        STEP: 6,
        SCREEN: UssdCustomerValidation,
        TITLE: 'confirmation',
        KEY: 'USSD_PUSH_VALIDATION',
        PREVIOUS_STEP_HANDLER: ({ previousStep }) => ({
            ...FormSteps.CONFIRMATION_METHOD,
            PROPS: { REVIEW_DOCUMENT: previousStep!.PROPS.REVIEW_DOCUMENT }
        })
    }
};

export default FormSteps;
