import i18next from 'i18next';
import {
    HttpMethods,
    InternalResourceStatusEnum,
    ResourceStatusEnum
} from '../../Protocol/IHttpInterface';
import {
    ConsentOtpStatusEnum,
    IDocumentType,
    ISendReverseOtpResponseData,
    ISubmitRegistrationRequest,
    IValidateCustomerIdResponseDataType
} from '../../Service/IRegistrationService';
import Constants, {
    ISOLanguageCodeEnum,
    LOGGER_TAGS,
    RESEND_REVERSE_OTP_INTERVAL_IN_SECONDS,
    REVERSE_OTP_EXPIRATION_INTERVAL_IN_SECONDS,
    START_CUSTOMER_ACTION_POLLING_WAITING_TIME_IN_MILLISECONDS
} from '../../Utils/Constants';
import { ERROR_CODES } from '../../Utils/Constants/Errors';
import EventEmitter from '../../Utils/EventEmitter';
import Logger from '../../Utils/Logger';
import Utils from '../../Utils/Utils';
import { ResourceAvailabilityInformationProps } from '../data/ResourceAvailabilityInformation';
import RegistrationManager from './RegistrationManager';

class AgentRegistrationManager extends RegistrationManager {
    private _otpAvailabilityInformation!: Omit<ISendReverseOtpResponseData, 'status'>;

    private _ussdAvailabilityInformation!: ResourceAvailabilityInformationProps;

    createAccount(): Promise<any> {
        const registrationData = this.userManager.getUserInformation();
        const documentType = this.removeDocumentTypeVersion(this.documentManager
            .getProcessedDocuments()[0].documentType).toUpperCase() as IDocumentType;
        const submitRegistrationRequest: ISubmitRegistrationRequest = {
            relativeUrl: Constants.API_ENDPOINTS.AGENT.ACCOUNT_CREATION,
            data: {
                msisdn: this.userManager.getMsisdn(),
                resource: {
                    language: ISOLanguageCodeEnum[i18next.language.toUpperCase() as keyof typeof ISOLanguageCodeEnum],
                    ...registrationData,
                    gender: String(this.dataManager.convertToGender(String(registrationData?.gender)))[0].toUpperCase(),
                    documentType
                }
            }
        };

        Logger.log(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Request to create account initiated', registrationData);
        const promise = this.registrationService
            .requestAccountCreation(submitRegistrationRequest)
            .then(async (response) => {
                Logger.log(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Successfully requested account creation', response);
                this._requestSubmissionStatus = {
                    isCreated: response.status === ResourceStatusEnum.SYSTEM_APPROVING
                };
                this.abortPreviousPollingRequest();
                this.setController(new AbortController());
                return Promise.resolve('');
            },
            (errorCode) => {
                Logger.error(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Failed to request account creation', errorCode);
                this._requestSubmissionStatus = {
                    isCreated: false,
                    errorCode: Utils.convertToErrorCode(errorCode)
                };
                return Promise
                    .reject(Utils.convertToErrorCode(errorCode));
            });

        return promise;
    }

    validateAgentPin(pin: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.registrationService.validateAgentPin(String(pin))
                .then((response) => {
                    if (response?.valid) {
                        Logger.log(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Successfully validated PIN', response);
                        resolve('');
                    } else {
                        Logger.error(
                            LOGGER_TAGS.AGENT_REGISTRATION_MANAGER,
                            'Failed to validate PIN',
                            response?.error?.additionalFields?.g2ErrorDescription || response?.error?.message || response
                        );
                        reject(Utils.convertToErrorCode(response?.error?.errorCode) || ERROR_CODES.VALIDATE_AGENT_PIN.GENERIC_FAIL);
                    }
                })
                .catch((error) => {
                    Logger.error(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Failed to validate PIN', error);
                    const errorCode = Utils.convertToErrorCode(error?.errorCode);
                    reject(errorCode || ERROR_CODES.VALIDATE_AGENT_PIN.GENERIC_FAIL);
                });
        });
    }

    async sendReverseOtp(): Promise<Omit<ISendReverseOtpResponseData, 'status'>> {
        try {
            const response = await this.registrationService
                .sendReverseOtp({
                    msisdn: this.userManager.getMsisdn(),
                    language: ISOLanguageCodeEnum[i18next.language.toUpperCase() as keyof typeof ISOLanguageCodeEnum]
                });

            switch (response.status) {
            case InternalResourceStatusEnum.SUCCESS:
                Logger.log(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Successfully sent reverse otp', response);
                this._otpAvailabilityInformation = {
                    timeLeftForExpiration: REVERSE_OTP_EXPIRATION_INTERVAL_IN_SECONDS,
                    timeLeftForResend: RESEND_REVERSE_OTP_INTERVAL_IN_SECONDS,
                    reverseOtpCode: response.code,
                    uniqueIdentifier: response.uniqueIdentifier,
                    isBlocked: false
                };

                setTimeout(() => this
                    .handleCustomerPollingResult({
                        requestId: response.uniqueIdentifier,
                        controller: this._controller
                    }), START_CUSTOMER_ACTION_POLLING_WAITING_TIME_IN_MILLISECONDS);
                return Promise.resolve(this._otpAvailabilityInformation);
            case InternalResourceStatusEnum.BLOCKED:
                Logger.error(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'MSISDN currently blocked', response);
                this._otpAvailabilityInformation = {
                    timeLeftForExpiration: NaN,
                    timeLeftForResend: response?.timeLeft || RESEND_REVERSE_OTP_INTERVAL_IN_SECONDS,
                    reverseOtpCode: '',
                    uniqueIdentifier: '',
                    isBlocked: true
                };
                return Promise.resolve(this._otpAvailabilityInformation);
            default:
                Logger.error(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Unexpected resource status found', response);
                this._otpAvailabilityInformation = {
                    timeLeftForExpiration: response?.timeLeftExpired || REVERSE_OTP_EXPIRATION_INTERVAL_IN_SECONDS,
                    timeLeftForResend: response?.timeLeftRetry || RESEND_REVERSE_OTP_INTERVAL_IN_SECONDS,
                    reverseOtpCode: response?.code || '____',
                    uniqueIdentifier: response?.uniqueIdentifier,
                    isBlocked: response.status
                };
            }
            return Promise.reject(this._otpAvailabilityInformation);
        } catch (error: any) {
            Logger.error(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Failed to send reverse otp', error);
            return Promise.reject(error?.errorCode ? Utils.convertToErrorCode(error.errorCode) : error);
        }
    }

    handleCustomerPollingResult({ requestId, controller }: { requestId: string, controller: AbortController}) {
        Logger.log(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Polling for customer action initiated', requestId);

        this.fallbackService.requestRecordStatus({
            endpointProps: {
                relativeUrl: `${Constants.API_ENDPOINTS.AGENT.QUERY_CONSENT_TERMS_AND_CONDITIONS_ID_STATUS}/${requestId}`,
                httpMethod: HttpMethods.GET
            },
            requestId,
            fieldName: 'consentResponse',
            successCallback: (data) => {
                Logger.log(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Customer successfully performed polling validation', data);
                EventEmitter.dispatch(
                    LOGGER_TAGS.HANDLE_POLING_RESULT, false,
                    {
                        success: data.status === ConsentOtpStatusEnum.VALIDATED,
                        data
                    }
                );
            },
            errorCallback: (error: any) => {
                Logger.error(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Customer failed to perform polling validation', error);
                if (Utils.convertToErrorCode(error?.errorCode) === ERROR_CODES.VALIDATE_REVERSE_OTP.INVALID) {
                    Logger.error(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Request dropped with id', requestId);
                }
                EventEmitter.dispatch(
                    LOGGER_TAGS.HANDLE_POLING_RESULT,
                    false,
                    {
                        success: false,
                        errorCode: error?.errorCode
                            ? Utils.convertToErrorCode(error.errorCode)
                            : ERROR_CODES.GENERIC_ERROR
                    }
                );
            },
            onAbortCallback: () => this._controller = new AbortController(),
            controller,
            helperCallback: this.convertReverseOtpToFallbackObject
        });
    }

    async sendUssdPush(): Promise<any> {
        try {
            const response = await this.registrationService
                .sendUssdPush({
                    msisdn: this.userManager.getMsisdn(),
                    language: ISOLanguageCodeEnum[i18next.language.toUpperCase() as keyof typeof ISOLanguageCodeEnum]
                });

            switch (response.status) {
            case ResourceStatusEnum.SUCCEEDED:
                Logger.log(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Successfully sent USSD push', response);
                this._ussdAvailabilityInformation = {
                    timeLeft: response.data.timeLeftRetry,
                    isBlocked: true
                };

                setTimeout(() => this
                    .handleCustomerPollingResult({
                        requestId: response.data.uniqueIdentifier,
                        controller: this._controller
                    }), START_CUSTOMER_ACTION_POLLING_WAITING_TIME_IN_MILLISECONDS);
                return Promise.resolve(this._otpAvailabilityInformation);
            case InternalResourceStatusEnum.BLOCKED:
                Logger.error(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'MSISDN currently blocked', response);
                this._otpAvailabilityInformation = {
                    timeLeftForExpiration: NaN,
                    timeLeftForResend: response?.timeLeft || RESEND_REVERSE_OTP_INTERVAL_IN_SECONDS,
                    reverseOtpCode: '',
                    uniqueIdentifier: '',
                    isBlocked: true
                };
                return Promise.resolve(this._otpAvailabilityInformation);
            default:
                Logger.error(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Unexpected resource status found', response);
                this._ussdAvailabilityInformation = {
                    timeLeft: response?.data?.timeLeftRetry || 0,
                    isBlocked: Boolean(response?.data?.timeLeftRetry)
                };
            }
            return Promise.reject(this._otpAvailabilityInformation);
        } catch (error: any) {
            Logger.error(LOGGER_TAGS.AGENT_REGISTRATION_MANAGER, 'Failed to send reverse otp', error);
            return Promise.reject(error?.errorCode ? Utils.convertToErrorCode(error.errorCode) : error);
        }
    }

    abortPreviousPollingRequest() {
        if (this._controller) {
            this._controller.abort();
            return;
        }
        this._controller = new AbortController();
    }

    convertReverseOtpToFallbackObject(response: IValidateCustomerIdResponseDataType): IValidateCustomerIdResponseDataType {
        switch (true) {
        case response.data?.status === ConsentOtpStatusEnum.IN_PROGRESS:
        case response.data?.status === ConsentOtpStatusEnum.VALID:
            return { ...response, status: ResourceStatusEnum.PENDING };
        case response.data?.status === ConsentOtpStatusEnum.VALIDATED:
            return { ...response, status: ResourceStatusEnum.SUCCEEDED };

        default: {
            return { ...response, status: ResourceStatusEnum.FAILED };
        }
        }
    }

    getOtpAvailabilityInformation(): Omit<ISendReverseOtpResponseData, 'status'> {
        return this._otpAvailabilityInformation;
    }

    setOtpAvailabilityInformation(value: Omit<ISendReverseOtpResponseData, 'status'>): void {
        this._otpAvailabilityInformation = value;
    }

    getUssdAvailabilityInformation(): ResourceAvailabilityInformationProps {
        return this._ussdAvailabilityInformation;
    }

    setUssdAvailabilityInformation(value: ResourceAvailabilityInformationProps) {
        this._ussdAvailabilityInformation = value;
    }
}

export default new AgentRegistrationManager();
