import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import IRegistrationManager from '../../../Business/RegistrationManager/IRegistrationManager';
import { APP_ROUTES, LOGGER_TAGS } from '../../../Utils/Constants';
import { ERRORS_DESCRIPTION, ERROR_CODES } from '../../../Utils/Constants/Errors';
import MARKET_CONFIGURATIONS from '../../../Utils/Constants/MarketConfigurations';
import Utils from '../../../Utils/Utils';
import Button from '../../Components/Button/Button';
import Input from '../../Components/Input/Input';
import Loader from '../../Components/Loader/Loader';
import Modal from '../../Components/Modal/Modal';
import IAgentLoginData, { ITempError } from '../AgentLogin/agent-login.types';
import { IFormStepProps } from '../PageWrapper/PageWrapper';
import ValidateAgentPinScreen from './Style';
import FormSteps from '../../../Utils/Constants/FormSteps';
import AgentRegistrationManager from '../../../Business/RegistrationManager/AgentRegistrationManager';
import { IResultScreenProps } from '../../Components/ResultScreen/result-screen-types';
import EventEmitter from '../../../Utils/EventEmitter';
import ResultScreen from '../../Components/ResultScreen/ResultScreen';

const ValidateAgentPin: FC<IFormStepProps> = ({ nextStepHandler }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [{ hasError, errorCode }, setError] = useState<ITempError>({
        hasError: false,
        errorCode: ERROR_CODES.GENERIC_ERROR
    });

    const [pin, setPin] = useState<Pick<IAgentLoginData, 'pin'>['pin']>({
        input: '',
        isValid: true,
        isLabelVisible: false,
        type: 'password',
        maxLength: MARKET_CONFIGURATIONS.LENGTHS.PIN.MAX,
        minLength: MARKET_CONFIGURATIONS.LENGTHS.PIN.MIN,
        inputMode: 'tel',
        placeHolder: t('agentLogin.operatorId.pin')

    });

    const history = useHistory();

    const handleChange = (event: any) => {
        const { value } = event.target;

        if ((value.length <= pin.maxLength && !Number.isNaN(parseInt(value, 10))) || value.length === 0) {
            setPin((prev) => ({
                ...prev,
                isLabelVisible: true,
                input: value
            }));
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const agentRegistrationManager: IRegistrationManager = AgentRegistrationManager;
        let confirmationScreenProps: IResultScreenProps;
        agentRegistrationManager.validateAgentPin(Number(pin.input))
            .then(() => {
                confirmationScreenProps = {
                    success: true,
                    title: 'confirmation.customerRegistrationSubmitted',
                    description: 'confirmation.customerRegistrationSubmittedDescription',
                    actionFn: () => {
                        history.push(APP_ROUTES.HOME);
                        EventEmitter.dispatch(LOGGER_TAGS.RESET_FLOW_EVENT_HANDLER);
                    },
                    actionText: 'general.continue',
                    showActionButton: true
                };
                nextStepHandler({
                    ...FormSteps.CONFIRMATION,
                    PROPS: confirmationScreenProps,
                    DONT_SHOW_ICON: true
                });
            })
            .catch((errorCode) => {
                if (errorCode === ERROR_CODES.VALIDATE_AGENT_PIN.INVALID_PIN) {
                    setError((prev) => ({ ...prev, errorCode }));
                    setShowModal(true);
                    return;
                }

                confirmationScreenProps = {
                    success: false,
                    title: ERRORS_DESCRIPTION[Number(errorCode)].title,
                    description: ERRORS_DESCRIPTION[Number(errorCode)].description,
                    actionFn: () => window.location.reload(),
                    actionText: 'general.tryAgain',
                    showActionButton: true
                };
                nextStepHandler({
                    ...FormSteps.CONFIRMATION,
                    PROPS: confirmationScreenProps,
                    DONT_SHOW_ICON: true
                });
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            {isLoading && <Loader description={t('validateAgentPin.justAMoment')} />}
            {!isLoading && !hasError && (
                <ValidateAgentPinScreen onSubmit={handleSubmit}>
                    <div className="wrapper">
                        <h1 className="wrapper__title">{t('validateAgentPin.insertPin')}</h1>
                        <span className="wrapper__description">{t('validateAgentPin.enterPin')}</span>

                        <div className="wrapper__input">
                            <Input
                                data={pin}
                                handleChange={handleChange}
                                type={pin.type}
                                id="pin"
                                inputLabel="M-PESA PIN"
                                grow={1}
                                inputMode={pin.inputMode}
                                maxLength={pin.maxLength}
                                autoFocus={!showModal}
                            />
                        </div>
                    </div>

                    <div className="footer">
                        <div className="footer__button">
                            <Button
                                action={() => {}}
                                mode="normal"
                                type="submit"
                                disabled={!Utils.validateFieldLength({ value: pin.input, max: pin.maxLength, min: pin.minLength })}
                                id="continue-button"
                            >{t('general.submit')}
                            </Button>
                        </div>
                    </div>
                </ValidateAgentPinScreen>
            )}

            {hasError && !isLoading && (
                <ResultScreen
                    title={t(`${ERRORS_DESCRIPTION[errorCode]?.title || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title}`)}
                    description={t(`${ERRORS_DESCRIPTION[errorCode]?.description
                        || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description}`)}
                    actionText={t('general.tryAgain')}
                    actionFn={() => {
                        setPin((prev) => ({ ...prev, input: '', isLabelVisible: false }));
                        setError((prev) => ({ ...prev, hasError: false }));
                    }}
                    showActionButton
                />
            )}

            <Modal
                onClose={() => {
                    setShowModal(false);
                    setPin((prev) => ({
                        ...prev,
                        input: '',
                        isLabelVisible: false
                    }));
                }}
                open={showModal}
                title={t(`${ERRORS_DESCRIPTION[errorCode]?.title || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title}`)}
                description={t(`${ERRORS_DESCRIPTION[errorCode]?.description
                    || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description}`)}
                fullScreen
            />
        </>
    );
};

export default ValidateAgentPin;
