import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

const TakeDocumentPhotoScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .wrapper {
    margin: ${Pixel2Rem(24)} 0 ${Pixel2Rem(66)} 0;
  }

  .title {
    margin: 0 auto ${Pixel2Rem(30)} auto;
    width: 100%;
    color: ${themeProvider.titleColor};
    font-family: ${themeProvider.fontBold};
    justify-self: start;
    font-size: ${Pixel2Rem(22)};
    line-height: ${Pixel2Rem(26)};
  }

  .instructions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
    margin-bottom: ${Pixel2Rem(42)};

    .instructions-item {
      display: flex;
      align-items: baseline;
      margin-bottom: ${Pixel2Rem(16)};
    }

      .instructions-badge {
        display: inline-block;
        margin: 0 ${Pixel2Rem(16)} 0 0;
      }

      .instructions-text {
        font-family: ${themeProvider.fontFamily};
        font-size: ${Pixel2Rem(16)};
        line-height: ${Pixel2Rem(22)};
        letter-spacing: normal;
      }
  }

  .image {
    width: 100%;
    max-width: ${Pixel2Rem(360)};
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-family: ${themeProvider.fontFamily};

    .footer__button {
      box-sizing: border-box;
      margin-bottom: ${Pixel2Rem(20)};
      width: 100%;
    }
  }
`;

export default TakeDocumentPhotoScreen;
