import Logger from './Logger';
import { LOGGER_TAGS } from './Constants';

function _dataURLToBytes(data: any) {
    return data.length - 24;
}

/**
 * Reduce the size (by a percentage) of an image by redrawing it to a blank canvas.
 * @param {Image} img
 * @param reducePercentage
 * @param {String} fileType
 * @returns {string} The data URL of the downsized image.
 * @private
 */
function _reducePercentage(img: any, reducePercentage: number, fileType: string) {
    const newWidth = img.width * reducePercentage;
    const newHeight = img.height * reducePercentage;

    const canvas = _renderToCanvas(newWidth, newHeight, (ctx: any) => {
        ctx.clearRect(0, 0, newWidth, newHeight);
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
    });
    return canvas.toDataURL(fileType, 0.9);
}

/**
 * Creates a canvas element and applies the render function to its 2d context.
 * @param {Number} width
 * @param {Number} height
 * @param {Function} renderFn
 * @returns {Element} The canvas element
 * @private
 */
function _renderToCanvas(width: number, height: number, renderFn: Function) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    renderFn(canvas.getContext('2d'));
    return canvas;
}

export function dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], { type: mimeString });
}

const reduceImageSize = (img: any, fileType: string, maxBytes: number): Promise<any> => {
    let reducePercentage = 0;
    let data = _reducePercentage(img, 1, fileType);
    const imgSizeBytes = _dataURLToBytes(data);

    if (imgSizeBytes > maxBytes) {
        reducePercentage = 0.90;
        Logger.log(
            LOGGER_TAGS.IMAGE_RESIZER,
            `Image is too big: ${imgSizeBytes / 1024}. Will try to reduce in a factor of ${reducePercentage}`
        );
    } else {
        Logger.log(
            LOGGER_TAGS.IMAGE_RESIZER,
            `No need to reduce image: ${imgSizeBytes / 1024}`
        );
        return Promise.resolve(dataURItoBlob(data));
    }

    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
        data = _reducePercentage(img, reducePercentage, fileType);
        let byteSize = _dataURLToBytes(data);

        // Check size and reduce by percentage if necessary, until size is small enough
        if (byteSize > maxBytes) {
            const tmpImgBuffer = new Image();
            tmpImgBuffer.src = data;
            tmpImgBuffer.onload = () => {
                Logger.log(
                    LOGGER_TAGS.IMAGE_RESIZER,
                    `Rescaling image again by percentage ${reducePercentage}... current size: `, (byteSize / 1024)
                );
                data = _reducePercentage(tmpImgBuffer, reducePercentage, fileType);
                byteSize = _dataURLToBytes(data);

                if (byteSize > maxBytes) {
                    tmpImgBuffer.src = data;
                    return;
                }

                Logger.log(
                    LOGGER_TAGS.IMAGE_RESIZER,
                    `Image size reduced to: ${byteSize / 1024}`
                );
                resolve(dataURItoBlob(data));
            };
        } else {
            Logger.log(
                LOGGER_TAGS.IMAGE_RESIZER,
                `Image size reduced to: ${byteSize / 1024}`
            );
            resolve(dataURItoBlob(data));
        }
    });
};

export default reduceImageSize;
