import React, {
    FC, useEffect, useMemo, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Button from '../Button/Button';
import TakeDocumentPhotoScreen from './Style';
import TakeDocumentPhotoWithCamera from '../TakeDocumentPhotoWithCamera/TakeDocumentPhotoWithCamera';
import Constants, { LOGGER_TAGS } from '../../../Utils/Constants';
import useStepContext from '../../Hooks/useStepContext';
import { IError } from '../../../Utils/Constants/Errors';
import { IHttpError } from '../../../Service/IDocumentService';
import EventEmitter from '../../../Utils/EventEmitter';
import Utils from '../../../Utils/Utils';
import DI from '../../../Utils/DI';
import Loader from '../Loader/Loader';
import Badge from '../Badge/Badge';
import IAnalyticsManager, { IDocumentUploadErrorCounter } from '../../../Business/IAnalyticsManager';
import useGaEventTracker from '../../Hooks/useGaEventTracker';
import IChallengeManager from '../../../Business/IChallengeManager';
import MediaStream from '../../../Utils/MediaStream/MediaStream';
import ISessionManager from '../../../Business/ISessionManager';
import ResultScreen from '../ResultScreen/ResultScreen';

export interface ITakeDocumentPhotoInstructions {
    key: string;
    instructionsLength: number;
}
interface ITakeDocumentPhotoProps {
    title: string;
    image: string;
    instructionText: string;
    captureTitle: string;
    validateTitle: string;
    validateSubtitle?: string;
    buttonText: string;
    onChangeFn: Function;
    takeDocumentPhotoInstructions: ITakeDocumentPhotoInstructions;
}

const TakeDocumentPhoto: FC<ITakeDocumentPhotoProps> = ({
    title,
    image,
    instructionText,
    captureTitle,
    validateTitle,
    validateSubtitle,
    buttonText,
    onChangeFn,
    takeDocumentPhotoInstructions
}) => {
    // NOTE: Ltmatos change to >= 1 for tests on desktop (1 camera)
    const canUseCamera = MediaStream.getTotalVideoInputDevices() >= 1;
    const fileInput = useRef(null);
    const [useCamera, setUseCamera] = useState(false);
    const { setCurrentFormStep: nextStepHandler, currentFormStep } = useStepContext();
    const challengeManager: IChallengeManager = DI.get('ChallengeManager');
    const analyticsManager: IAnalyticsManager = DI.get('AnalyticsManager');
    const sessionManager: ISessionManager = DI.get('SessionManager');
    const isAgentLogged = useMemo(() => sessionManager?.getSession()?.getIsValid(), []);
    const { eventTracker } = useGaEventTracker();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<(IError & IHttpError)>({
        title: (
            (Constants.ERRORS.ERRORS_DESCRIPTION[Constants.ERRORS.ERROR_CODES.GENERIC_ERROR] as IError).title
        ),
        description: (
            (Constants.ERRORS.ERRORS_DESCRIPTION[Constants.ERRORS.ERROR_CODES.GENERIC_ERROR] as IError).description
        ),
        errorCode: Constants.ERRORS.ERROR_CODES.GENERIC_ERROR,
        hasError: false
    });

    const onChangeFunction = (file: File) => {
        setIsLoading(true);
        onChangeFn(file);
    };

    const onOrientationChange = (angle: number): boolean => {
        if (angle !== 0) {
            setError(challengeManager.processChallengeError({
                errorCode: Constants.ERRORS.ERROR_CODES.CAMERA.NOT_ALLOWED_ORIENTATION_CHANGE
            } as IHttpError & number));
            eventTracker({
                name: 'OCR',
                objectValue: {
                    FAILURE: `Unsupported orientation ${currentFormStep.KEY}`
                }
            });
        }
        return angle === 0; // NOTE: indicates portrait orientation
    };

    const updateDocumentErrorCounter = (param: keyof IDocumentUploadErrorCounter) => {
        analyticsManager
            .setDocumentUploadErrorCounter({
                ...analyticsManager
                    .getDocumentUploadErrorCounter(),
                [param]: analyticsManager
                    .getDocumentUploadErrorCounter()[param] + 1
            });
    };

    useEffect(() => {
        EventEmitter.subscribe(LOGGER_TAGS.HANDLE_DOCUMENT_UPLOAD_EVENT, (errorObject: IHttpError & IError) => {
            eventTracker({
                name: 'OCR',
                objectValue: {
                    FAILURE: `${errorObject?.errorType || 'SOMETHING_WENT_WRONG'}: ${currentFormStep.KEY}`
                }
            });
            setError(errorObject);
            setIsLoading(false);
        });

        EventEmitter.subscribe(LOGGER_TAGS.HANDLE_DOCUMENT_UPLOAD_ERROR_EVENT, (errorCode: number) => {
            switch (Utils.convertToErrorCode(errorCode)) {
            case Constants.ERRORS.ERROR_CODES.UPLOAD_DOCUMENT.DOCUMENT_EXPIRED:
                updateDocumentErrorCounter('documentExpired');
                nextStepHandler({
                    ...Constants.FORM_STEPS.PERSONAL_DOCUMENTS,
                    TITLE: isAgentLogged ? 'customerDocuments' : 'personalDocuments'
                });
                break;

            case Constants.ERRORS.ERROR_CODES.UPLOAD_DOCUMENT.FORBIDDEN_NATIONALITY:
                updateDocumentErrorCounter('invalidNationality');
                location.reload();
                break;
            case Constants.ERRORS.ERROR_CODES.CAMERA.NOT_ALLOWED_ORIENTATION_CHANGE:
                if (onOrientationChange(window.orientation)) {
                    setError((prev: any) => ({ ...prev, hasError: false }));
                }
                break;

            default:
                updateDocumentErrorCounter('unclearDocument');
                setError((prev: any) => ({ ...prev, hasError: false }));
            }
        });

        window.addEventListener('orientationchange', () => onOrientationChange(window.orientation));

        return () => {
            EventEmitter.unsubscribe(
                LOGGER_TAGS.HANDLE_DOCUMENT_UPLOAD_EVENT,
                (errorObject: any) => setError(challengeManager.processChallengeError(errorObject))
            );

            EventEmitter.unsubscribe(LOGGER_TAGS.HANDLE_DOCUMENT_UPLOAD_ERROR_EVENT, () => {});

            window.removeEventListener('orientationchange', () => onOrientationChange(window.orientation));
        };
    }, []);

    const capturePhotoHandler = () => {
        if (!onOrientationChange(window.orientation)) {
            return;
        }
        analyticsManager.setDocumentUploadInitTime(moment.now());
        if (canUseCamera) {
            setUseCamera(true);
        } else if (fileInput.current !== null) {
            const inputEl = fileInput.current as HTMLInputElement;
            inputEl.value = ''; // INFO: clears img
            inputEl.click();
        }
    };

    return (
        <>
            {isLoading && (<Loader description={t('personalDocuments.validatingDocumentsPhotos')} />) }
            {!useCamera && !error.hasError && !isLoading && (
                <TakeDocumentPhotoScreen>
                    <div className="wrapper">
                        <h1 className="title">{title}</h1>
                        <div className="instructions">
                            {[...Array(takeDocumentPhotoInstructions.instructionsLength)].map((_, index) => (
                                <div key={`${takeDocumentPhotoInstructions.key}_${index}`} className="instructions-item">
                                    <div className="instructions-badge">
                                        <Badge size="MEDIUM" boldContent content={index + 1} />
                                    </div>
                                    <span className="instructions-text">
                                        {t(`${takeDocumentPhotoInstructions.key}.instruction${index + 1}`)}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className="image-container">
                            <img loading="lazy" className="image" src={image} alt="" />
                        </div>
                    </div>

                    <input
                        hidden
                        ref={fileInput}
                        type="file"
                        accept="image/*"
                        onChange={(event) => {
                            const file = event.target.files && event.target.files[0];
                            if (file) {
                                onChangeFunction(file);
                            }
                        }}
                    />
                    <div className="footer">
                        <div className="footer__button">
                            <Button
                                id="take-photo-button"
                                action={capturePhotoHandler}
                                mode="normal"
                            >{buttonText}
                            </Button>
                        </div>
                    </div>
                </TakeDocumentPhotoScreen>
            )}
            {useCamera && !error.hasError && !isLoading && (
                <TakeDocumentPhotoWithCamera
                    captureTitle={captureTitle}
                    validateTitle={validateTitle}
                    validateSubtitle={validateSubtitle}
                    instructionText={instructionText}
                    onChangeFn={onChangeFunction}
                    onDismiss={() => nextStepHandler({
                        ...Constants.FORM_STEPS.PERSONAL_DOCUMENTS,
                        TITLE: isAgentLogged ? 'customerDocuments' : 'personalDocuments'
                    })}
                />
            )}
            {error.hasError && !isLoading && (
                <ResultScreen
                    title={t(error.title)}
                    description={t(error.description)}
                    actionText={error.errorCode !== Constants.ERRORS.ERROR_CODES.GENERIC_ERROR
                        ? t(String((Constants.ERRORS.ERRORS_DESCRIPTION[error.errorCode] as any).callToActionText))
                        : t('error.uploadDocument.unclear.buttonText')}
                    actionFn={() => EventEmitter.dispatch(LOGGER_TAGS.HANDLE_DOCUMENT_UPLOAD_ERROR_EVENT, false, error.errorCode)}
                    showActionButton
                />
            )}
        </>
    );
};

export default React.memo(TakeDocumentPhoto);
