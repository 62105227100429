import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
    subTopicIndex: number;
}

const CookieSubtopicItem: FC<IProps> = ({ subTopicIndex }) => {
    const { t } = useTranslation();

    return (
        <div className="cookie-sub-topic-item">
            <span className="topic">
                {t(`cookiePolicy.subTopic${subTopicIndex}`)}
            </span>
            <span className="description">{t(`cookiePolicy.subDescription${subTopicIndex}`)}</span>
        </div>
    );
};

export default CookieSubtopicItem;
