import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

interface IProps {
    focusColor: string;
    grow?: number;
    isValid: boolean | null;
}

const InputComponent = styled.div<IProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    position: relative;
    flex-grow: ${({ grow }) => grow || 'unset'};

    .input__label {
        flex-grow: 0;
        position: absolute;
        top: ${Pixel2Rem(-9)};
        left: ${Pixel2Rem(10)};
        height: ${Pixel2Rem(19)};
        padding: 0 ${Pixel2Rem(3)};
        font-family: ${themeProvider.fontFamily};
        font-size: ${Pixel2Rem(14)};
        background-color: ${themeProvider.backgroundColor};
        color: ${({ isValid }) => (isValid ? themeProvider.darkGrey : themeProvider.errorColor)};
    }

    .input {
        flex-grow: 6;
        background: none;
        padding: ${Pixel2Rem(12)};
        margin: 0;
        outline: none;
        border: ${Pixel2Rem(1)} solid ${({ isValid }) => (isValid ? themeProvider.darkGrey : themeProvider.errorColor)};
        border-radius: ${Pixel2Rem(6)};
        -webkit-box-sizing: border-box;
        color: ${themeProvider.muted};
        font-family: 'VodafoneR';
        font-size: ${Pixel2Rem(18)};
        letter-spacing: 0;
        line-height: ${Pixel2Rem(24)};

        &:focus {
            border-color: ${({ focusColor }) => focusColor};
        }

        &:focus + label {
            color: ${({ focusColor }) => focusColor};
        }
        &::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type='number'] {
            -moz-appearance: textfield;
        }
    }
`;

export default InputComponent;
