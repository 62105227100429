export interface IFaceBoxDimensions {
    AREA_BOX_WIDTH_RATIO: number;
    AREA_BOX_HEIGHT_RATIO: number;
    AREA_BOX_ASPECT_RATIO: number;
}

export interface IFaceBox {
    ANDROID: IFaceBoxDimensions
    IOS: IFaceBoxDimensions
}

export interface ILivelinessCheck {
        boxConfig: {
            FACE_BOX: IFaceBox;
            NOSE_BOX_SIZE: number;
            NOSE_BOX_CENTER_MIN_H_DIST: number;
            NOSE_BOX_CENTER_MAX_H_DIST: number;
            NOSE_BOX_CENTER_MAX_V_DIST: number;
            ROTATE_FACE_DIVISION_FACTOR: number;
        },
        mediaConfig: {
            DRAW_DETECTIONS: boolean;
            PROFILING: boolean;
            API_URL?: string;
            API_START_ENDPOINT: string;
            API_FRAMES_ENDPOINT_PATTERN: string;
            API_VERIFY_ENDPOINT_PATTERN: string;
            IMAGE_JPG_QUALITY: number;
            STATE_AREA_DURATION_IN_SECONDS: number;
            STATE_SMILE_DURATION_IN_SECONDS: number;
            STATE_NEUTRAL_FACE_EXPRESSION_DURATION_IN_SECONDS: number;
            STATE_NOSE_DURATION_IN_SECONDS: number;
            STATE_SMILE_MAX_FRAMES_WITHOUT_FACE: number;
            STATE_AREA_MAX_FRAMES_WITHOUT_FACE: number;
            STATE_NOSE_MAX_FRAMES_WITHOUT_FACE: number;
            STATE_AREA_PERCENT_MAX_FRAMES_WITHOUT_FACE: number;
            STATE_NEUTRAL_FACE_EXPRESSION_MAX_FRAMES_WITHOUT_FACE: number;
            MAX_FPS: number;
            MAX_FRAMES_PER_CHALLENGE: number;
            FACE_AREA_TOLERANCE_PERCENT: number;
            FLIP_VIDEO: boolean;
            LANDMARK_INDEX: number;
            VIDEO_HEIGHT: number;
            VIDEO_WIDTH: number;
            MIN_FACE_AREA_PERCENT: number;
            MIN_FACE_AREA_PERCENT_TOLERANCE: number;
            MAX_FACES: number;
            REFINE_LANDMARKS: boolean;
            MINIMUM_SMILE_CONFIDENCE_LEVEL_PERCENTAGE: number;
            MINIMUM_NEUTRAL_EXPRESSION_CONFIDENCE_LEVEL_PERCENTAGE: number;
            MINIMUM_NOSE_STATE_FRAMES: number;
            NEUTRAL_FACE_STATE_FRAMES: number;
        };
}

const LivelinessCheck: ILivelinessCheck = {
    boxConfig: {
        FACE_BOX: {
            ANDROID: {
                AREA_BOX_WIDTH_RATIO: 0.75,
                AREA_BOX_HEIGHT_RATIO: 0.75,
                AREA_BOX_ASPECT_RATIO: 0.75
            },

            IOS: {
                AREA_BOX_WIDTH_RATIO: 0.9,
                AREA_BOX_HEIGHT_RATIO: 1,
                AREA_BOX_ASPECT_RATIO: 0.8
            }
        },
        NOSE_BOX_SIZE: 30,
        NOSE_BOX_CENTER_MIN_H_DIST: 45,
        NOSE_BOX_CENTER_MAX_H_DIST: 75,
        NOSE_BOX_CENTER_MAX_V_DIST: 40,
        ROTATE_FACE_DIVISION_FACTOR: 3
    },
    mediaConfig: {
        DRAW_DETECTIONS: false,
        PROFILING: false,
        // API_URL: https://CHANGE_ME.amazonaws.com/Prod/challenge/
        API_START_ENDPOINT: 'start',
        API_FRAMES_ENDPOINT_PATTERN: '{challengeId}/frames',
        API_VERIFY_ENDPOINT_PATTERN: '{challengeId}/verify',
        IMAGE_JPG_QUALITY: 0.7,
        STATE_AREA_DURATION_IN_SECONDS: 30,
        STATE_SMILE_DURATION_IN_SECONDS: 10,
        STATE_NEUTRAL_FACE_EXPRESSION_DURATION_IN_SECONDS: 15,
        STATE_NOSE_DURATION_IN_SECONDS: 15,
        STATE_SMILE_MAX_FRAMES_WITHOUT_FACE: 10,
        STATE_AREA_MAX_FRAMES_WITHOUT_FACE: 10,
        STATE_NOSE_MAX_FRAMES_WITHOUT_FACE: 10,
        STATE_AREA_PERCENT_MAX_FRAMES_WITHOUT_FACE: 10,
        STATE_NEUTRAL_FACE_EXPRESSION_MAX_FRAMES_WITHOUT_FACE: 10,
        MAX_FPS: 2,
        MAX_FRAMES_PER_CHALLENGE: 30,
        FACE_AREA_TOLERANCE_PERCENT: 15,
        FLIP_VIDEO: true,
        LANDMARK_INDEX: 30,
        VIDEO_HEIGHT: 480,
        VIDEO_WIDTH: 640,
        MAX_FACES: 1,
        REFINE_LANDMARKS: false,
        MINIMUM_SMILE_CONFIDENCE_LEVEL_PERCENTAGE: 42,
        MINIMUM_NEUTRAL_EXPRESSION_CONFIDENCE_LEVEL_PERCENTAGE: 38,
        MIN_FACE_AREA_PERCENT: 35,
        MIN_FACE_AREA_PERCENT_TOLERANCE: 0,
        MINIMUM_NOSE_STATE_FRAMES: 2,
        NEUTRAL_FACE_STATE_FRAMES: 1
    }
};

// eslint-disable-next-line no-undef
export const LivenessCheckConstraints: MediaStreamConstraints = {
    audio: false,
    video: {
        facingMode: 'user',
        width: LivelinessCheck.mediaConfig.VIDEO_WIDTH,
        height: LivelinessCheck.mediaConfig.VIDEO_HEIGHT,
        frameRate: {
            ideal: 60
        }
    }
};

export default LivelinessCheck;
