import { FC, ReactNode } from 'react';
import CustomizedWrapperComponent from './Style';

interface IProps {
    borderColor?: string,
    label: string
    isLabelVisible?: boolean;
    children: ReactNode;
}

const CustomizedWrapper: FC<IProps> = ({
    children, borderColor, label, isLabelVisible = true
}) => (
    <CustomizedWrapperComponent
        isLabelVisible={isLabelVisible}
        borderColor={borderColor}
    >
        <span className="wrapper__label">
            {label}
        </span>
        {children}
    </CustomizedWrapperComponent>
);

export default CustomizedWrapper;
