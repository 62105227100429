import React, { FC } from 'react';
import { IIConProps } from './icon-types';
import { ReactComponent as FallbackIcon } from '../../Assets/icons/ic_check.svg';

const Icon: FC<IIConProps> = ({ icon }) => {
    // eslint-disable-next-line no-console
    console.warn('Refactor this component');

    let IconComponent;

    let exists = false;
    if (icon) {
        IconComponent = icon;
        exists = true;
    } else {
        IconComponent = FallbackIcon;
    }

    return exists ? <IconComponent /> : <></>;
};

export default React.memo(Icon);
