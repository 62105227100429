import { FunctionComponent, SVGProps } from 'react';
import ChallengeDetails from '../../../../Business/data/ChallengeDetails';
import { PictureSideEnum } from '../../../../Business/IChallengeManager';

interface IValidateFace {
    validateTitle: string;
    instruction: string;
    step: PictureSideEnum;
    icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined;}>;
    picture: string;
}

export enum VALIDATION_STEPS {
    INIT = 1,
    VALIDATION = 2,
    ERROR = -1,
    LOADING = 3,
    SUCCESS = 4
}

export interface IValidateFaceProps {
    handleValidationStep: (validationPhase: VALIDATION_STEPS | ChallengeDetails, errorCode?: number) => void;
}

export default IValidateFace;
