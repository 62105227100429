import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import registrationManager from '../../../Business/RegistrationManager/AgentRegistrationManager';
import Button from '../../Components/Button/Button';
import Loader from '../../Components/Loader/Loader';
import ResultScreen from '../../Components/ResultScreen/ResultScreen';
import Timer from '../../Components/Timer/Timer';
import { IFormStepProps } from '../PageWrapper/PageWrapper';
import { ReactComponent as SuccessIcon } from '../../Assets/icons/ic_validated.svg';
import UssdCustomerValidationScreen from './Style';
import FormSteps from '../../../Utils/Constants/FormSteps';
import useStepContext from '../../Hooks/useStepContext';
import { ERRORS_DESCRIPTION, ERROR_CODES, IError } from '../../../Utils/Constants/Errors';
import { LOGGER_TAGS } from '../../../Utils/Constants';
import EventEmitter from '../../../Utils/EventEmitter';
import Logger from '../../../Utils/Logger';
import { IPolingResultHandler } from '../ReverseOTP/Agent/ValidateCustomer/validate-customer.types';

const UssdCustomerValidation: FC<IFormStepProps> = ({ nextStepHandler }) => {
    const { t } = useTranslation();
    const timeLeft = registrationManager.getUssdAvailabilityInformation()?.timeLeft;

    const [isLoading, setIsLoading] = useState(false);
    const { currentFormStep } = useStepContext();

    const [{ hasError, title, description }, setError] = useState<IError>({
        hasError: currentFormStep?.PROPS?.errorCode,
        title: ERRORS_DESCRIPTION[currentFormStep?.PROPS?.errorCode || ERROR_CODES.GENERIC_ERROR].title,
        description: ERRORS_DESCRIPTION[currentFormStep?.PROPS?.errorCode || ERROR_CODES.GENERIC_ERROR].description
    });

    const initialUssdAvailabilityInfo = {
        canResend: !timeLeft,
        timeLeftForRetry: moment().add(timeLeft, 's').unix()
    };

    const [{ canResend, timeLeftForRetry }, setUssdAvailability] = useState(initialUssdAvailabilityInfo);

    const enableUssdPushHandler = () => {
        setUssdAvailability((prev) => ({
            ...prev,
            canResend: true
        }));
    };

    const handleCustomerPolingResult = ({ success, errorCode }: IPolingResultHandler) => {
        if (success) {
            nextStepHandler(FormSteps.VALIDATE_AGENT_PIN);
            return;
        }

        if (errorCode === ERROR_CODES.VALIDATE_REVERSE_OTP.INVALID) {
            Logger.error(LOGGER_TAGS.UI, 'Request dropped');
            return;
        }
        setError({
            hasError: true,
            title: ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title,
            description: ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description
        });

        setUssdAvailability({ canResend: true, timeLeftForRetry: 0 });

        setIsLoading(false);
    };

    useEffect(() => {
        EventEmitter.subscribe(LOGGER_TAGS.HANDLE_POLING_RESULT, handleCustomerPolingResult);
        return () => {
            EventEmitter.unsubscribe(LOGGER_TAGS.HANDLE_POLING_RESULT, handleCustomerPolingResult);
            registrationManager.getController().abort();
        };
    }, []);

    return (
        <>
            {isLoading
                ? <Loader description={t('general.justAMoment')} />
                : hasError
                    ? (
                        <ResultScreen
                            title={t(title)}
                            description={t(description)}
                            actionFn={() => nextStepHandler({
                                ...FormSteps.CONFIRMATION_METHOD,
                                PROPS: { REVIEW_DOCUMENT: currentFormStep.PROPS.REVIEW_DOCUMENT }
                            })}
                            actionText={(
                                <>
                                    {t('general.retry')}&nbsp;
                                    {!canResend && timeLeft && (
                                        <>(<Timer
                                            actionFn={enableUssdPushHandler}
                                            timeLeft={timeLeftForRetry}
                                        />)
                                        </>
                                    )}
                                </>
                            )}
                            showActionButton
                            disableActionButton={!canResend}
                        />
                    )
                    : (
                        <UssdCustomerValidationScreen>
                            <div className="content">
                                <SuccessIcon className="content__icon" />
                                <span className="content__title">{t('confirmationMethod.ussdOption.title')}</span>
                                <span className="content__description">{t('confirmationMethod.ussdOption.description')}</span>
                            </div>

                            <div className="footer">
                                <Button
                                    id="continue-button"
                                    type="submit"
                                    mode="normal"
                                    disabled={!canResend}
                                    action={() => nextStepHandler({
                                        ...FormSteps.CONFIRMATION_METHOD,
                                        PROPS: { REVIEW_DOCUMENT: currentFormStep.PROPS.REVIEW_DOCUMENT }
                                    })}
                                >
                                    <>
                                        {t('general.retry')}&nbsp;
                                        {!canResend && (
                                            <>(<Timer
                                                actionFn={enableUssdPushHandler}
                                                timeLeft={timeLeftForRetry}
                                            />)
                                            </>
                                        ) }
                                    </>
                                </Button>
                            </div>
                        </UssdCustomerValidationScreen>
                    )}
        </>
    );
};

export default UssdCustomerValidation;
