import { FC } from 'react';
import Input from '../Input/Input';

interface IProps {
    id: string;
    inputLabel: string;
    handleChange: any;
    data: any;
    maxLength: number;
}

const NumberField:FC<IProps> = ({
    id, inputLabel, handleChange, data, maxLength
}) => (
    <>
        <Input
            inputLabel={inputLabel}
            type="number"
            data={data}
            id={id}
            handleChange={handleChange}
            maxLength={maxLength}
        />
    </>
);

export default NumberField;
