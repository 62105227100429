import { FC } from 'react';
import DialogComponent from './Style';
import { ReactComponent as CloseDialogIcon } from '../../Assets/icons/ic_close_black.svg';

export interface IActions {
    cancelText: string,
    confirmText: string,
    cancelAction: () => void,
    confirmAction: () => void,
}
interface IProps {
    open: boolean;
    CloseIcon?: FC;
    title: string;
    description: string;
    onClose: any,
    actions: IActions;
}
const Dialog: FC<IProps> = ({
    open,
    CloseIcon = CloseDialogIcon,
    title,
    description,
    onClose,
    actions
}) => (
    <>
        <DialogComponent open={open}>
            <div
                className="backdrop"
                role="button"
                tabIndex={0}
            />
            <div className="dialog-view">
                <div className="dialog-content">
                    <div id="close-dialog-icon" className="dialog-content__close-icon" onClick={onClose}>
                        <CloseIcon />
                    </div>
                    <div className="dialog-body">
                        <div className="dialog-content__title">
                            <span>{title}</span>
                        </div>
                        <div className="dialog-content__description">
                            <span>{description}</span>
                        </div>

                        <div className="actions">
                            <button
                                onClick={actions.cancelAction}
                                type="button"
                                className="actions__button actions__cancel-action"
                                id="dialog-cancel-action-button"
                            >{actions.cancelText}
                            </button>
                            <button
                                onClick={actions.confirmAction}
                                type="button"
                                className="actions__button actions__confirm-action"
                                id="dialog-confirm-action-button"
                            >{actions.confirmText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </DialogComponent>
    </>
);

export default Dialog;
