import IRegistrationService, { ISubmitRegistrationResponseData } from '../../Service/IRegistrationService';
import DI from '../../Utils/DI';
import IRegistrationManager, { IAccountCreationStatus } from './IRegistrationManager';
import IUserManager from '../UserManager/IUserManager';
import IDocumentManager from '../IDocumentManager';
import IDataManager from '../IDataManager';
import IFallbackService from '../../Service/IFallbackService';

class RegistrationManager implements IRegistrationManager {
    protected _requestSubmissionStatus!: IAccountCreationStatus;

    protected _controller!: AbortController;

    protected get registrationService(): IRegistrationService {
        return DI.get('RegistrationService');
    }

    protected get userManager(): IUserManager {
        const dataManager: IDataManager = DI.get('DataManager');
        return dataManager.convertToUserManager();
    }

    protected get documentManager(): IDocumentManager {
        return DI.get('DocumentManager');
    }

    protected get dataManager(): IDataManager {
        return DI.get('DataManager');
    }

    protected get fallbackService(): IFallbackService {
        return DI.get('FallbackService');
    }

    createAccount(): Promise<ISubmitRegistrationResponseData> {
        throw new Error('Method not implemented.');
    }

    validateAgentPin(agentPin: number): Promise<any> {
        throw new Error('Method not implemented.');
    }

    protected removeDocumentTypeVersion(documentType: string): string {
        // INFO: The document types version is present within its name(Eg: VOTER_CARD_1_0)
        // This function is used  to remove the version number from the document type name
        return documentType.replace(/_\d+/g, '');
    }

    getRequestSubmissionStatus(): IAccountCreationStatus {
        return this._requestSubmissionStatus;
    }

    setRequestSubmissionStatus(value: IAccountCreationStatus) {
        this._requestSubmissionStatus = value;
    }

    getController() {
        return this._controller;
    }

    setController(value: AbortController) {
        this._controller = value;
    }
}

export default RegistrationManager;
