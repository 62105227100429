import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

const OnboardingScreen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;

    .page-context {
        margin: ${Pixel2Rem(24)} 0 ${Pixel2Rem(24)} 0;

        .page-context__title {
            color: ${themeProvider.titleColor};
            font-family: ${themeProvider.fontBold};
            font-size: ${Pixel2Rem(32)};
            font-weight: 900;
            letter-spacing: 0;
            line-height: ${Pixel2Rem(33)};
            margin-bottom: ${Pixel2Rem(30)};

            .title__highlight {
                color: ${themeProvider.primaryColor};
            }
        }

        .page-context__subtitle {
            color: ${themeProvider.titleColor};
            font-family: ${themeProvider.fontFamily};
            font-size: ${Pixel2Rem(18)};
            letter-spacing: 0;
            line-height: ${Pixel2Rem(20)};
            margin-bottom: ${Pixel2Rem(32)};
        }
    }

    .requirements {
        display: flex;
        flex-direction: column;
        align-items: start;

        .requirement-item {
            margin-bottom: ${Pixel2Rem(36)};
            display: flex;
            flex-direction: row;
            align-items: center;

            .requirement-item__icon {
                margin-right: ${Pixel2Rem(18)};
                height: ${Pixel2Rem(48)};
                width: ${Pixel2Rem(48)};
            }

            .requirement-item__description {
                color: ${themeProvider.textColor};
                font-family: ${themeProvider.fontFamily};
                font-size: ${Pixel2Rem(18)};
                font-weight: bold;
                letter-spacing: 0;
                line-height: ${Pixel2Rem(20)};
            }
        }
    }

    .login {
        display: flex;
        justify-content: center;
        font-family: ${themeProvider.fontFamily};
        font-style: normal;
        font-weight: 400;
        font-size: ${Pixel2Rem(13)};
        line-height: ${Pixel2Rem(15)};
        letter-spacing: ${Pixel2Rem(0.37)};
        margin-top: ${Pixel2Rem(20)};
        align-items: center;

        .login__isAgent {
            margin-right: ${Pixel2Rem(6)};
        }
    }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        font-family: ${themeProvider.fontFamily};
        justify-content: space-around;
        .footer__button {
            margin-bottom: ${Pixel2Rem(20)};
            width: 100%;
        }

        .already-see-status {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: ${Pixel2Rem(18)};
            letter-spacing: ${Pixel2Rem(0.5)};
            line-height: ${Pixel2Rem(20)};
            margin-bottom: ${Pixel2Rem(18)};

            .already-see-status__text {
                color: ${themeProvider.textColor};
                margin: auto ${Pixel2Rem(5)};
            }

            .already-see-status__link {
                color: ${themeProvider.primaryColor} !important;
                cursor: pointer;
            }
        }

        .terms-and-conditions {
            color: ${themeProvider.textColor};
            font-size: ${Pixel2Rem(13)};
            letter-spacing: ${Pixel2Rem(0.36)};
            line-height: ${Pixel2Rem(15)};
            text-align: center;
            margin-bottom: ${Pixel2Rem(15)};

            .terms-and-conditions__highlight {
                color: ${themeProvider.primaryColor};
                margin: auto ${Pixel2Rem(5)};
                cursor: pointer;
            }
        }
    }
`;

export default OnboardingScreen;
