import moment from 'moment';
import {
    FC, useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import IUserManager, { IRequestMsisdnError } from '../../../Business/UserManager/IUserManager';
import Constants, {
    APP_ROUTES, DEFAULT_OTP_NUM_OF_DIGITS, RESEND_CODE_INTERVAL_IN_SECONDS
} from '../../../Utils/Constants';
import DI from '../../../Utils/DI';
import Button from '../../Components/Button/Button';
import Loader from '../../Components/Loader/Loader';
import Modal, { IModalAction } from '../../Components/Modal/Modal';
import OtpWrapper from '../../Components/OtpWrapper/OtpWrapper';
import Timer, { IHelperMessage } from '../../Components/Timer/Timer';
import { IFormStepProps } from '../PageWrapper/PageWrapper';
import ValidatePhoneNumberScreen from './Style';
import IAnalyticsManager from '../../../Business/IAnalyticsManager';
import useGaEventTracker from '../../Hooks/useGaEventTracker';
import useStepContext from '../../Hooks/useStepContext';
import ISessionManager from '../../../Business/ISessionManager';
import { ResourceAvailabilityInformationProps } from '../../../Business/data/ResourceAvailabilityInformation';
import { ERRORS_DESCRIPTION, ERROR_CODES, IError } from '../../../Utils/Constants/Errors';
import Utils from '../../../Utils/Utils';
import IDataManager from '../../../Business/IDataManager';
import IOtp from './validate-phone-number-types';
import ResultScreen from '../../Components/ResultScreen/ResultScreen';

const ValidatePhoneNumber: FC<IFormStepProps> = ({ nextStepHandler }) => {
    const dataManager: IDataManager = DI.get('DataManager');
    const userManager: IUserManager = dataManager.convertToUserManager();
    const analyticsManager: IAnalyticsManager = DI.get('AnalyticsManager');
    const sessionManager: ISessionManager = DI.get('SessionManager');
    const isAgentLogged = useMemo(() => sessionManager?.getSession()?.getIsValid(), []);
    const { eventTracker, screenTracker } = useGaEventTracker();
    const { currentFormStep } = useStepContext();
    const { title, description } = currentFormStep.PROPS;

    const { pathname } = useLocation();

    const { t } = useTranslation();

    const [error, setError] = useState<IError>({
        hasError: false,
        title: t('error.incorrect-code'),
        description: t('error.please')
    });

    const [isLoading, setIsLoading] = useState(false);
    const [showDuplicatedNumberError, setShowDuplicatedNumberError] = useState(false);

    const [helperMessage, setHelperMessage] = useState<IHelperMessage>({
        whileIsCountingMessage: `${t('error.arrived')} ${t('validatePhoneNumber.retry')}`,
        endOfTimeMessage: {
            text: t('error.arrived'),
            actionText: t('validatePhoneNumber.resendCode')
        }
    });

    const [msisdnInformation, setMsisdnInformation] = useState<ResourceAvailabilityInformationProps>({
        isBlocked: userManager.getMsisdnInformation()?.isBlocked() || false,
        timeLeft: userManager.getMsisdnInformation()?.getTimeLeft() || RESEND_CODE_INTERVAL_IN_SECONDS
    });

    const [eventDate, setEventDate] = useState(moment().add(RESEND_CODE_INTERVAL_IN_SECONDS, 's').unix()); //* date of the event(event: userResendsCode)

    const initialOtp: IOtp = Array(DEFAULT_OTP_NUM_OF_DIGITS).fill(''); // INFO: Initializing OTP with empty strings

    const [otp, setOtp] = useState<IOtp>(initialOtp);

    const clearOtp = () => setOtp(initialOtp);

    const formIsInValid = otp.some((item) => item === '');

    const [modalAction, setModalAction] = useState<IModalAction>({
        action: null,
        text: null
    });

    const history = useHistory();

    const handleSubmit = () => {
        setIsLoading(true);
        const otpInput = otp.join('');
        clearOtp();
        userManager.validateOtp({ msisdn: userManager.getMsisdn(), otp: otpInput })
            .then(() => {
                if (pathname === APP_ROUTES.LOGIN) {
                    history.push(APP_ROUTES.REGISTRATION);
                    screenTracker({ screen: '1.ENTER_PHONE_NUMBER', screenClass: 'INITIALIZE_FLOW_BY_AGENT' });
                    nextStepHandler(Constants.FORM_STEPS.ENTER_PHONE_NUMBER);
                    return;
                }

                screenTracker({ screen: '3.PERSONAL_DOCUMENTS', screenClass: 'SELECT_DOCUMENTS' });
                nextStepHandler({
                    ...Constants.FORM_STEPS.PERSONAL_DOCUMENTS,
                    TITLE: isAgentLogged ? 'customerDocuments' : Constants.FORM_STEPS.PERSONAL_DOCUMENTS.TITLE
                });
            })
            .catch((error) => handleError(error))
            .finally(() => setIsLoading(false));
    };

    const handleError = (error: any) => {
        setError((prev) => ({
            ...prev,
            hasError: true
        }));

        if (error.errorCode !== ERROR_CODES.VALIDATE_OTP.BLOCKED_OTP) {
            setHelperMessage((prev) => ({
                ...prev,
                whileIsCountingMessage: `${t('error.arrived')} ${t('validatePhoneNumber.retry')}`
            }));
        }
        switch (error.errorCode) {
        case ERROR_CODES.VALIDATE_OTP.BLOCKED_OTP:
            setHelperMessage((prev) => ({
                ...prev,
                whileIsCountingMessage: t('error.expired-helper'),
                endOfTimeMessage: {
                    text: t('validatePhoneNumber.sendNewCodeDescription'),
                    actionText: t('validatePhoneNumber.sendNewCode')
                }
            }));
            setError((prev) => ({
                ...prev,
                title: t('error.incorrect-code'),
                description: t('error.blocked-description')
            }));
            setMsisdnInformation({
                isBlocked: error.data?._isBlocked,
                timeLeft: error.data?._timeLeft
            });
            eventTracker({ name: 'OTP', objectValue: { FAILURE: 'BLOCKED_OTP' } });
            break;
        case ERROR_CODES.VALIDATE_OTP.INVALID_OTP:
            analyticsManager
                .setIncorrectOtpCounter(analyticsManager.getIncorrectOtpCounter() + 1);
            eventTracker({ name: 'OTP', objectValue: { FAILURE: 'INVALID_OTP' } });
            setError((prev) => ({
                ...prev,
                title: t('error.incorrect-code'),
                description: t('error.please')
            }));
            break;
        case ERROR_CODES.VALIDATE_OTP.OTP_EXPIRED:
            analyticsManager
                .setOtpExpiredCounter(analyticsManager.getOtpExpiredCounter() + 1);
            eventTracker({ name: 'OTP', objectValue: { FAILURE: 'EXPIRED_OTP' } });
            setModalAction({
                action: resendCodeFromModal,
                text: `${t('general.resend')}.`
            });
            setMsisdnInformation((prev) => ({
                ...prev,
                isBlocked: true,
                timeLeft: eventDate - moment().unix()
            }));
            setError((prev) => ({
                ...prev,
                title: t('error.expired'),
                description: t('error.expired-description')
            }));
            break;
        case ERROR_CODES.VALIDATE_MSISDN.DUPLICATED:
        case ERROR_CODES.VALIDATE_OTP.DUPLICATED_PHONE_NUMBER:
            setShowDuplicatedNumberError(true);
            analyticsManager
                .setDuplicatedNumberCounter(analyticsManager
                    .getDuplicatedNumberCounter() + 1);
            eventTracker({ name: 'OTP', objectValue: { FAILURE: 'DUPLICATED_MSISDN' } });
            setError((prev) => ({
                ...prev,
                title: t('error.duplicatedPhoneNumber'),
                description: t('error.duplicatedPhoneNumberDescription')
            }));
            break;
        default:
            setError((prev) => ({
                ...prev,
                title: t((ERRORS_DESCRIPTION[
                    ERROR_CODES.GENERIC_ERROR
                ] as IError).title),
                description: t('error.please')
            }));
        }
        setIsLoading(false);
    };

    const resendCode = () => {
        setIsLoading(true);
        clearOtp();
        setHelperMessage((prev) => ({
            ...prev,
            whileIsCountingMessage: `${t('error.arrived')} ${t('validatePhoneNumber.retry')}`
        }));

        userManager.sendOtp()
            .then((response) => handleResendCodeSuccess(response))
            .catch((error) => handleResendCodeError(error))
            .finally(() => setIsLoading(false));
    };

    const handleResendCodeSuccess = (response: any) => {
        setMsisdnInformation((prev) => ({
            ...prev,
            isBlocked: response._isBlocked,
            timeLeft: response._timeLeft

        }));
    };

    const resendCodeFromModal = () => {
        setError((prev) => ({
            ...prev,
            hasError: false
        }));
        resendCode();
    };

    const handleResendCodeError = ({ errorCode, manager }: IRequestMsisdnError) => {
        const timeLeft = Utils
            .convertToMinutesColonSeconds(moment()
                .add(manager.retrieveResourceAvailabilityInformation().getTimeLeft(), 's').unix() - moment().unix());
        setError((prev) => ({
            ...prev,
            hasError: true,
            title: t(ERRORS_DESCRIPTION[errorCode]?.title
                || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title),
            description: t(ERRORS_DESCRIPTION[errorCode]?.description || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description, {
                minutes: timeLeft.minutes,
                seconds: timeLeft.seconds
            })
        }));

        setHelperMessage((prev) => ({
            ...prev,
            whileIsCountingMessage: t('enterPhoneNumber.blocked'),
            endOfTimeMessage: {
                text: t('validatePhoneNumber.sendNewCodeDescription'),
                actionText: t('validatePhoneNumber.sendNewCode')
            }
        }));

        setMsisdnInformation({
            isBlocked: manager.retrieveResourceAvailabilityInformation().isBlocked(),
            timeLeft: manager.retrieveResourceAvailabilityInformation().getTimeLeft()
        });

        setModalAction({
            action: null,
            text: null
        });
    };

    const closeModal = () => {
        setError((prev) => ({ ...prev, hasError: false }));
        setModalAction({
            action: null,
            text: null
        });
    };

    useEffect(() => {
        setEventDate(moment().add(msisdnInformation.timeLeft, 's').unix());
    }, [msisdnInformation]);

    return (
        <>
            {showDuplicatedNumberError && (
                <ResultScreen
                    title={error.title}
                    description={error.description}
                    actionText={t('general.tryAgain')}
                    actionFn={() => {
                        setShowDuplicatedNumberError(!showDuplicatedNumberError);
                        nextStepHandler(Constants.FORM_STEPS.ENTER_PHONE_NUMBER);
                    }}
                    showActionButton
                />
            )}
            {!showDuplicatedNumberError
            && (
                <>
                    <Modal
                        onClose={() => closeModal()}
                        open={!!error.hasError}
                        title={error.title}
                        description={error.description}
                        modalAction={modalAction}
                    />
                    {isLoading ? (
                        <Loader description={t('validatePhoneNumber.validatingPhoneNumber')} />
                    ) : (
                        <ValidatePhoneNumberScreen onSubmit={handleSubmit}>
                            <div className="wrapper">
                                <div className="title">
                                    <span>
                                        {title}
                                    </span>
                                </div>
                                <div className="subtitle">
                                    <span>
                                        {description}
                                    </span>

                                </div>

                                <OtpWrapper
                                    otp={otp}
                                    setOtp={setOtp}
                                    disabled={error.hasError || msisdnInformation.isBlocked}
                                />

                                <div className="error">
                                    <Timer
                                        message={helperMessage}
                                        timeLeft={eventDate}
                                        action={resendCode}
                                    />
                                </div>
                            </div>
                            <div className="footer">
                                <Button
                                    disabled={formIsInValid || msisdnInformation.isBlocked}
                                    type="submit"
                                    action={() => handleSubmit()}
                                    mode="normal"
                                    id="continue-button"
                                >{t('general.continue')}
                                </Button>
                            </div>
                        </ValidatePhoneNumberScreen>
                    )}
                </>
            )}
        </>
    );
};

export default ValidatePhoneNumber;
