import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

const UssdCustomerValidationScreen = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 auto;
    align-items: center;
    font-family: ${themeProvider.fontFamily};

    .content {
        display: flex;
        flex-direction: column;
        gap: ${Pixel2Rem(16)};
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: ${themeProvider.titleColor};

        .content__icon {
            width: ${Pixel2Rem(120)};
            height: ${Pixel2Rem(120)};
            margin-bottom: ${Pixel2Rem(32)};
        }

        .content__title {
            font-family: ${themeProvider.fontBold};
            font-size: ${Pixel2Rem(24)};
            line-height: ${Pixel2Rem(30)};;
        }

        .content__description {
            font-size: ${Pixel2Rem(16)};;
            line-height: ${Pixel2Rem(22)};
        }
    }

    .footer {
        width: fill-available;
        padding-bottom: ${Pixel2Rem(10)};
    }

`;

export default UssdCustomerValidationScreen;
