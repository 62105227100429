import { Face } from '@tensorflow-models/face-landmarks-detection';
import moment from 'moment';
import Resizer from 'react-image-file-resizer';
import ChallengeDetails, { LivenessCheckRotationDirections } from '../Business/data/ChallengeDetails';
import Constants, {
    AGENT_FALLBACK_NAME,
    BrowserCameraPermissionNameEnum,
    LOGGER_TAGS,
    MAX_IMAGE_SIZE
} from './Constants';
import Logger from './Logger';
import MediaStreamConfig from './MediaStream/MediaStream';
import reduceImageSize from './ResizeImage';

interface IField {
    value: string | number;
    max: number;
    min: number
}

abstract class Utils {
    static msisdnFormatter = (value: number | string) => String(value).split(/(\d{3})/).join(' ').trim();

    static generateRandomString(length: number): string {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }

    static convertToErrorCode(errorFromBackend: string | number): number {
        return typeof errorFromBackend === 'string' ? parseInt(errorFromBackend.replace(/-/g, ''), 10) : errorFromBackend;
    }

    static convertToMinutesColonSeconds(totalSeconds: number): { minutes: number, seconds: number } {
        const minutes = Math.floor(totalSeconds / 60);

        return totalSeconds > 0 ? {
            minutes,
            seconds: totalSeconds - minutes * 60
        } : {
            minutes: 0,
            seconds: 0
        };
    }

    static ScrollTo(to: number) {
        window.scrollTo({
            top: to,
            behavior: 'smooth'
        });
    }

    static isNumber = (value: any): boolean => !Number.isNaN(parseInt(value, 10));

    static resizeImageFileIfNeeded(file: File): Promise<File> {
        return new Promise((resolve) => {
            if (file.size <= MAX_IMAGE_SIZE) {
                resolve(file);
            } else {
                const reader = new FileReader();

                reader.onload = () => {
                    const image = new Image();

                    image.onload = () => {
                        reduceImageSize(image, file.type, MAX_IMAGE_SIZE)
                            .then((result) => {
                                const resized = new File([result], `resized_${file.name}`, { type: file.type });
                                resolve(resized);
                            });
                    };

                    if (typeof reader.result === 'string') {
                        image.src = reader.result;
                    }
                };

                reader.readAsDataURL(file);
            }
        });
    }

    static validateDate = (value: string) => moment(value, 'DD/MM/YYYY', true).isValid()
        && (moment().year() - moment(value, 'DD/MM/YYYY').year() >= 18)
        && (moment().year() - moment(value, 'DD/MM/YYYY').year() <= 100);

    static min = (...values: number[]) => Math.min(...values);

    static choice = (array: any[]) => array[Math.floor(Math.random() * array.length)];

    //  *Returns true if the current screen orientation matches the provided parameter
    static isOrientation = (orientation: 'portrait' | 'landscape') => window.matchMedia(`(orientation: ${orientation})`).matches;

    static randInt = (min: number, max: number) => Math
        .floor(Math.random() * (max - min + 1) + min);

    // *Sorts an array of objects based on the property provided as parameter (to be improved)
    static sortArray = (arrayToSort: any[], parameter: any, order: 'ASC' | 'DESC' = 'ASC') => arrayToSort
        .sort((firstEntry: any, secondEntry: any) => (
            (firstEntry[parameter]
                > secondEntry[parameter]
            ) ? 1 : -1)
        );

    static convertBlobToBase64 = (blob: Blob): Promise<string> => {
        const promise: Promise<string> = new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.readAsDataURL(blob);
        });

        return promise;
    };

    static validateFieldLength = (
        { value, min, max }: IField
    ): boolean => String(value).trim().length >= min && String(value).trim().length <= max;

    //* Returns true if the provided string contains numbers
    static hasNumber = (value: string): boolean => /\d/.test(value);

    //* Returns the (if)provided RegEx output or
    // true if the provided string doesn't contains any special characters
    static withoutSpecialChars = (value: string, regex?: RegExp): boolean => (regex ? regex.test(value) : /^[a-z0-9]*$/i.test(value));

    // * Returns true if the provided string contains any of the
    // following set of characters: (,),[,-,],(needs refactor)
    static unicodeChars = (value: string): boolean => /^(^$)|[A-z-()'[\]-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ[^`\\_^]\s-]*$/.test(value)
        || /^(^$)|[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'()[\]\s-]+$/.test(value);

    // * Returns true if the provided string only contains numbers or a back slash
    static dateCharacters = (value: string): boolean => /^[0-9/]*$/.test(value);

    // Returns true if the provided string contains alphabet characters
    // and/or unicode characters and/or ',.çº
    static addressStreetCharacters = (value: string): any => /^[A-Za-z0-9-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ',.çº\s]+$/.test(value);

    static validateField = (
        value: number | string, min: number, max: number
    ): boolean => value.toString().trim().length >= min
        && value.toString().trim().length <= max;

    static validateDocumentFormat = (value: string) => /^[A-Z]{2}[0-9]{1,11}?$/.test(value);

    static validatePassportFormat = (value: string) => /^[A-Z]{2}[0-9]{1,9}?$/.test(value);

    static convertArrayToObject = (array: any[], key: any) => {
        const initialValue = {};
        return array.reduce((obj: any[], item: any) => ({
            ...obj,
            [item[key]]: item
        }), initialValue);
    };

    static getTownsByProvinceID = (provinceID: number | undefined) => Constants
        .MARKET_GEO_INFORMATION.TOWNS
        .filter((town) => town.provinceID === provinceID);

    static getTownsByProvinceIDFiltered = (provinceID: number | undefined) => Constants
        .MARKET_GEO_INFORMATION.TOWNS
        .filter((town) => (town.provinceID === provinceID && !town.description.includes('VILLE')));

    static getProvinceById = (provinceID: number | undefined) => Constants
        .MARKET_GEO_INFORMATION.PROVINCES
        .find((province) => province.id === provinceID);

    static convertToUTF8 = (value: string) => {
        if (!value) {
            return undefined;
        }
        let convertedString;
        try {
            convertedString = decodeURIComponent(encodeURIComponent(value));
        } catch {
            convertedString = undefined;
        }

        return convertedString || '';
    };

    static resizeFile = (file: File) => new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            900,
            800,
            'JPEG',
            100,
            0,
            (uri: any) => {
                resolve(uri);
            },
            'base64'
        );
    });

    static getTimeInterval = (time: string): string => {
        if (Number(time.substring(3)) > 0) {
            if (Number(time.substring(0, 2)) === 0) {
                return '<1min';
            }
            return `<${Number(time.substring(0, 2)) + 1}min`;
        }
        return '';
    };

    static getIntervalValue = (count: number): number => {
        const intervals: { [key: number]: any } = {
            1: 3000,
            5: 5000
        };

        const interval = Number(Object.keys(intervals).sort()
            .find((keyItem) => count <= Number(keyItem)));

        return intervals[interval] || 10000;
    };

    static getTwoInitials = (compoundName: string): string => {
        try {
            const names = compoundName.split(' ').filter((name) => name.trim() !== '');

            if (names.length === 0) {
                return AGENT_FALLBACK_NAME;
            }

            return (
                names[0].charAt(0).toUpperCase()
              + names[names.length - 1].charAt(0).toUpperCase()
            );
        } catch (error) {
            Logger.error(LOGGER_TAGS.UTILS, 'Unexpected error occurred', error);
            return AGENT_FALLBACK_NAME;
        }
    };

    static detectFacialExpressionSmilePercentage = (face: Face): number => {
        const keypoints = face.keypoints.map((keypoint) => [keypoint.x, keypoint.y]);

        const xCoords = keypoints.map((keypoint) => keypoint[0]);
        const yCoords = keypoints.map((keypoint) => keypoint[1]);

        const minX = Math.min(...xCoords);
        const maxX = Math.max(...xCoords);
        const minY = Math.min(...yCoords);
        const maxY = Math.max(...yCoords);

        const width = maxX - minX;
        const height = maxY - minY;
        const aspectRatio = height / width;

        const normalizedKeypoints = keypoints.map((keypoint) => [
            (keypoint[0] - minX) / width,
            ((keypoint[1] - minY) / height) * aspectRatio
        ]);

        const mouth = {
            left: {
                x: normalizedKeypoints[292][0],
                y: normalizedKeypoints[292][1]
            },
            right: {
                x: normalizedKeypoints[61][0],
                y: normalizedKeypoints[61][1]
            },
            top: {
                x: normalizedKeypoints[0][0],
                y: normalizedKeypoints[0][1]
            },
            bottom: {
                x: normalizedKeypoints[17][0],
                y: normalizedKeypoints[17][1]
            }
        };

        const mouthDistance = Math
            .sqrt((mouth.left.x - mouth.right.x) ** 2 + (mouth.left.y - mouth.right.y) ** 2);

        const mouthMidpoint = {
            x: (mouth.left.x + mouth.right.x) / 2,
            y: (mouth.left.y + mouth.right.y) / 2
        };

        const mouthTopDistance = Math
            .sqrt((mouthMidpoint.x - mouth.top.x) ** 2 + (mouthMidpoint.y - mouth.top.y) ** 2);

        const detectedSmileConfidence = Math.ceil(Number((Math.abs(mouthTopDistance - mouthDistance) * 100).toFixed(2)));
        return detectedSmileConfidence;
    };

    static getHelpersPosition = (details: ChallengeDetails): {
        animationTopPosition: number,
        animationLeftPosition: number,
        helperTopPosition: number,
        helperLeftPosition: number
    } => (MediaStreamConfig
        .getMediaStreamInfo()!.actualWidth > MediaStreamConfig.getMediaStreamInfo()!.actualHeight
        ? { // IOS positions
            animationTopPosition: details.areaBox.yMin - 106.9,
            animationLeftPosition: details.areaBox.xMin - 44,
            helperTopPosition: 53,
            helperLeftPosition: details.noseBox.rotationDirection === LivenessCheckRotationDirections.LEFT
                ? details.areaBox.xMin + 10
                : details.areaBox.xMin - 43
        }
        : { // Android positions
            animationTopPosition: details.areaBox.yMin - 105,
            animationLeftPosition: details.areaBox.xMin - 44,
            helperTopPosition: 53,
            helperLeftPosition: details.noseBox.rotationDirection === LivenessCheckRotationDirections.LEFT
                ? details.areaBox.xMin + 7
                : details.areaBox.xMin - 43
        });

    static removeDuplicates = <T>(arr: T[], prop: keyof T): T[] => {
        const unique = arr.reduce((acc: any, obj: any) => {
            acc[obj[prop]] = obj;
            return acc;
        }, {});
        return Object.values(unique);
    };

    static isDesktop = () => {
        const { userAgent } = navigator;
        const platforms = ['Win32', 'Win64', 'MacIntel'];

        if (platforms.indexOf(window.navigator.platform) !== -1) {
            return true;
        } if (/Mobi|Android/i.test(userAgent)) {
            return false;
        }

        return false;
    };

    static getBrowserName(): keyof typeof BrowserCameraPermissionNameEnum {
        switch (true) {
        case (navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1:
            return 'OPERA';
        case navigator.userAgent.indexOf('Chrome') !== -1:
            return 'CHROME';
        case navigator.userAgent.indexOf('Safari') !== -1:
            return 'SAFARI';
        case navigator.userAgent.indexOf('Firefox') !== -1:
            return 'FIREFOX';
        case navigator.userAgent.indexOf('MSIE') !== -1 || (!!document.DOCUMENT_NODE === true):
            return 'IE';
        case window.navigator.userAgent.indexOf('Edge') > -1:
            return 'EDGE';
        default: return 'UNKNOWN';
        }
    }
}

export default Utils;
