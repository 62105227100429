import styled from 'styled-components';
import { themeProvider } from '../../Theme/ThemeProvider';

interface ICounterWrapperProps {
    isOpen: boolean;
}

const CounterComponent = styled.div<ICounterWrapperProps>`
    height: 100%;
    width: 100vw;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0;
    color: ${themeProvider.backgroundColor};
    font-family: ${themeProvider.fontBold};
    font-size: 100pt;
    background-color: ${themeProvider.blackBackgroundWithOpacity};
    z-index: 1200;

`;

export default CounterComponent;
