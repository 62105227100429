import IGaTrackerManager from '../../Business/IGaTrackerManager';
import DI from '../../Utils/DI';

interface IGA {
    eventTracker: (eventTrackerProps: IEventTrackerProps) => void;
    screenTracker: (screenTrackerProps: IScreenTrackerProps) => void;
}

export interface IEventTrackerProps {
    name:string;
    objectValue?: any;
}

interface IScreenTrackerProps {
    screen: string;
    screenClass: string;
}

const useGaEventTracker = (): IGA => {
    const gaTrackerManager: IGaTrackerManager = DI.get('GaTrackerManager');
    const eventTracker = (event: IEventTrackerProps) => {
        const payload = gaTrackerManager.convertToGAEventPayload(event);
        gaTrackerManager.trackEvent(payload);
    };

    const screenTracker = ({ screen, screenClass }: IScreenTrackerProps) => gaTrackerManager.trackScreen({ screen, screenClass });

    return { eventTracker, screenTracker };
};
export default useGaEventTracker;
