import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

interface IBadgeSize {
    size: number;
    padding: {
        x: number;
        y: number;
    };
}

export interface IBadgeSizes {
    SMALL: IBadgeSize;
    MEDIUM: IBadgeSize;
    LARGE: IBadgeSize;
}

const BadgeSizes: IBadgeSizes = {
    SMALL: {
        size: 20,
        padding: {
            x: 0,
            y: 0
        }
    },
    MEDIUM: {
        size: 25,
        padding: {
            x: 0,
            y: 0
        }
    },
    LARGE: {
        size: 30,
        padding: {
            x: 2,
            y: 2
        }
    }
};

interface IBadgeProps {
    size: keyof IBadgeSizes;
    boldContent: boolean;
    color?: string;
    backgroundColor?: string;
}

const BadgeComponent = styled.div<IBadgeProps>`
    width: ${({ size }) => Pixel2Rem(BadgeSizes[size].size)};
    height: ${({ size }) => Pixel2Rem(BadgeSizes[size].size)};
    padding: ${({ size }) => Pixel2Rem(BadgeSizes[size].padding.y)} ${({ size }) => Pixel2Rem(BadgeSizes[size].padding.x)};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ backgroundColor }) => backgroundColor || themeProvider.badgeColor};
    color: ${({ color }) => color || themeProvider.primaryColor};
    font-size: ${Pixel2Rem(14)};
    font-family: ${({ boldContent }) => (boldContent ? themeProvider.fontBold : themeProvider.fontFamily)};
    line-heigh: ${Pixel2Rem(20)};
    border-radius: 50%;
`;

export default BadgeComponent;
