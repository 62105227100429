import moment from 'moment';
import {
    useState, useEffect, FC, memo
} from 'react';
import { LOGGER_TAGS } from '../../../Utils/Constants';
import Logger from '../../../Utils/Logger';
import Utils from '../../../Utils/Utils';
import TimerComponent from './Style';

interface IEndOfTimeMessage {
    text?: string,
    actionText?: string
}
export interface IHelperMessage {
    whileIsCountingMessage: string,
    endOfTimeMessage: IEndOfTimeMessage
}
interface IProps {
    timeLeft: number;
    message?: IHelperMessage;
    action?: any,
    actionFn?: any;
}

const Timer: FC<IProps> = ({
    timeLeft,
    message,
    action,
    actionFn
}) => {
    const { minutes: initialMinute, seconds: initialSeconds } = Utils
        .convertToMinutesColonSeconds(timeLeft - moment().unix());

    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    const [showTimer, setShowTimer] = useState(true);

    useEffect(() => {
        // TODO: Refactor this component
        Logger.warn(LOGGER_TAGS.UI, 'Refactor this component to use as a counter');
        setMinutes(initialMinute);
        setSeconds(initialSeconds);
    }, [timeLeft]);

    useEffect(() => {
        const myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    // block
                    clearInterval(myInterval);
                    setShowTimer(false);
                    if (actionFn) {
                        actionFn();
                    }
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [seconds]);
    return (
        <>
            <TimerComponent>
                {showTimer
                    ? (
                        <>
                            <span>{message && message.whileIsCountingMessage}</span>
                            <span>
                                {minutes < 10 ? `0${minutes}` : minutes}
                                :
                                {seconds < 10 ? `0${seconds}` : seconds}
                            </span>
                        </>
                    )
                    : (
                        message && (
                            <>
                                <span>
                                    {`${message.endOfTimeMessage.text} `}
                                </span>
                                <span id="timer-text" onClick={() => action()} className="timer__action-text">
                                    <b>{message.endOfTimeMessage.actionText}</b>
                                </span>
                            </>
                        )
                    )}
            </TimerComponent>
        </>
    );
};

export default memo(Timer);
