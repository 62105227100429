import {
    createContext, FC, useState, Dispatch, SetStateAction, ReactNode
} from 'react';
import Constants, { APP_ROUTES } from '../../Utils/Constants';
import { IFormStep } from '../../Utils/Constants/FormSteps';

interface IStepContext {
    currentFormStep: IFormStep
    setCurrentFormStep: Dispatch<SetStateAction<IFormStep>>;
}

const initialState: IStepContext = {
    currentFormStep: location.pathname === APP_ROUTES.TERMS_AND_CONDITIONS
        ? Constants.FORM_STEPS.TERMS_AND_CONDITIONS
        : Constants.FORM_STEPS.ONBOARDING,
    setCurrentFormStep: () => {}
};

const StepContext = createContext<IStepContext>(initialState);

export const StepContextProvider: FC< { children: ReactNode }> = ({ children }) => {
    const [currentFormStep,
        setCurrentFormStep] = useState<IFormStep>(initialState.currentFormStep);

    return (
        <StepContext.Provider value={{
            currentFormStep, setCurrentFormStep
        }}
        >
            {children}
        </StepContext.Provider>
    );
};

export default StepContext;
