import React, {
    useState, useRef, FC, useEffect
} from 'react';

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop
} from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from '../../Hooks/useDebounceEffect';

import 'react-image-crop/dist/ReactCrop.css';
import EventEmitter from '../../../Utils/EventEmitter';
import { LOGGER_TAGS } from '../../../Utils/Constants';

interface ICropImageProps {
    imgSrc: string;
    onSetCrop?: any
}

// This is to demonstrate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => centerCrop(
    makeAspectCrop({ unit: '%', width: 90 }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight
);

const CropImage: FC<ICropImageProps> = ({
    imgSrc,
    onSetCrop
}) => {
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState<number | undefined>(16 / 10);

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    };

    useEffect(() => {
        EventEmitter.subscribe(LOGGER_TAGS.CROP_AREA_HANDLER, () => {
            onSetCrop(String(previewCanvasRef.current?.toDataURL()));
        });

        return EventEmitter.unsubscribe(LOGGER_TAGS.CROP_AREA_HANDLER, () => {});
    }, []);

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width
                && completedCrop?.height
                && imgRef.current
                && previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate
                )
                    .then(() => {
                        if (completedCrop) {
                            setAspect(undefined);
                        }
                    });
            }
        },
        100,
        [completedCrop, scale, rotate]
    );

    return (
        <>
            {imgSrc && (
                <div
                    className="alo"
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {Boolean(imgSrc) && (
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                            style={{ marginRight: '20px' }}
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                                width={400}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}

                    <div>
                        {Boolean(completedCrop) && (
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    visibility: 'hidden',
                                    opacity: '0',
                                    display: 'none'
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default CropImage;
