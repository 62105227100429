import {
    FC, useEffect, useLayoutEffect, useMemo, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import lottie from 'lottie-web';
import ChallengeDetails, { LivenessCheckRotationDirections } from '../../../../Business/data/ChallengeDetails';
import ChallengeManager from '../../../../Business/ChallengeManager';
import ChallengeWrapper from './Style';
import { ReactComponent as BackIcon } from '../../../Assets/icons/ic_chevron_left_white.svg';
import { CHALLENGE_INSTRUCTIONS, TIME_TO_START_CHALLENGE_IN_MILLISECONDS } from '../../../../Utils/Constants';
import Counter from '../../../Components/Counter/Counter';
import DI from '../../../../Utils/DI';
import IChallengeProcessorManager from '../../../../Business/IChallengeProcessorManager';
import { ERROR_CODES } from '../../../../Utils/Constants/Errors';
import MediaStreamConfig from '../../../../Utils/MediaStream/MediaStream';
import Utils from '../../../../Utils/Utils';
import Icon from '../../../Components/Icon/Icon';
import { IStateInfo } from '../../../../Business/data/State/state-types';

interface IProps {
    details: ChallengeDetails;
    onLocalSuccessHandler: Function;
    onFailHandler: Function;
    backButtonHandler: () => void;
}

const Challenge: FC<IProps> = ({
    details,
    onFailHandler,
    onLocalSuccessHandler,
    backButtonHandler
}) => {
    const challengeProcessorManager: IChallengeProcessorManager = DI.get('ChallengeProcessorManager');
    const videoWidth = MediaStreamConfig.getMediaStreamInfo()!.actualWidth;
    const videoHeight = MediaStreamConfig.getMediaStreamInfo()!.actualHeight;
    const [showInstructions, setShowInstructions] = useState(false);
    const { t } = useTranslation();
    const onLocalEnd = (
        success: boolean, remoteVerifier: ChallengeManager | undefined, errorCode?: number
    ): void => {
        if (success) {
            onLocalSuccessHandler(remoteVerifier as ChallengeManager);
        } else {
            onFailHandler(errorCode);
        }
    };

    const handleBackButton = () => {
        MediaStreamConfig.closeMediaStream();
        backButtonHandler();
    };

    const onStateProcess = (stateInfo: IStateInfo) => {
        setShowAnimation(true);
        setStateInfo(stateInfo);
    };

    const [stateInfo, setStateInfo] = useState<Omit<IStateInfo, 'name'>>({
        instruction: CHALLENGE_INSTRUCTIONS.NO_FACE_DETECTED,
        instructionIcon: undefined,
        animation: undefined,
        helperIcon: undefined
    });

    const [showAnimation, setShowAnimation] = useState(true);

    useEffect(() => {
        challengeProcessorManager.initChallengeProcessor({
            challengeDetails: details as ChallengeDetails,
            videoElementId: 'webcamVideo',
            canvasElementId: 'overlayCanvas',
            endCallback: onLocalEnd,
            stateChangeHandler: onStateProcess
        });
    }, []);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        try {
            wrapperRef.current?.appendChild(MediaStreamConfig.getVideo());
            wrapperRef.current?.appendChild(MediaStreamConfig.getCanvas());
        } catch (error) {
            onLocalEnd(false, undefined, ERROR_CODES.GENERIC_ERROR);
        }
    }, []);

    const {
        animationTopPosition,
        animationLeftPosition,
        helperTopPosition,
        helperLeftPosition
    } = useMemo(() => Utils.getHelpersPosition(details), [details]);

    const handleAnimationCompletion = () => setTimeout(() => setShowAnimation(false), 1500);

    const animationRef = useRef<any>(null);

    useLayoutEffect(() => {
        const successfulStateConfirmationAnimation = lottie.loadAnimation({
            container: animationRef.current,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            animationData: stateInfo?.animation
        });

        successfulStateConfirmationAnimation.addEventListener('complete', handleAnimationCompletion);
        return () => successfulStateConfirmationAnimation.removeEventListener('complete', handleAnimationCompletion);
    }, [stateInfo]);

    return (
        <>
            <Counter
                startAtSecond={TIME_TO_START_CHALLENGE_IN_MILLISECONDS / 1000}
                endOfTimeAction={() => {
                    challengeProcessorManager.startProcessingChallenge();
                    setShowInstructions(true);
                }}
            />
            <ChallengeWrapper
                videoHeight={videoHeight}
                videoWidth={videoWidth}
            >
                <div ref={wrapperRef} className="detection-wrapper">
                    {showAnimation && (
                        <div
                            ref={animationRef}
                            className="detection-wrapper__check-icon"
                            style={{
                                top: `${animationTopPosition}%`,
                                left: `${animationLeftPosition}%`
                            }}
                        />
                    )}
                    <BackIcon id="exit-challenge-button" onClick={handleBackButton} className="detection-wrapper__back-icon" />
                    {stateInfo?.helperIcon && (
                        <stateInfo.helperIcon
                            className="detection-wrapper__helper-icon"
                            style={{
                                transform: details.noseBox.rotationDirection === LivenessCheckRotationDirections.LEFT
                                    ? 'rotateY(180deg)' : 'none',
                                top: `${helperTopPosition}%`,
                                left: `${helperLeftPosition}%`
                            }}
                        />
                    )}
                    {showInstructions && (
                        <div className="detection-wrapper-instructions">
                            <div className="detection-wrapper-instructions__icon detection-wrapper-instructions__center-face">
                                <Icon icon={stateInfo.instructionIcon} />
                            </div>
                            <span className="detection-wrapper-instructions__description">
                                {t(`takePhoto.selfie.livenessCheckInstructions.${stateInfo.instruction}`)}
                            </span>
                        </div>
                    )}
                </div>

            </ChallengeWrapper>
        </>
    );
};

export default Challenge;
