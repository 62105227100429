import React, { KeyboardEvent } from 'react';
import { themeProvider } from '../../Theme/ThemeProvider';
import InputComponent from './Style';

interface IProps {
    type: 'number' | 'text' | 'password' | 'file';
    id: string | undefined;
    handleChange: (event: any) => void;
    autoFocus?: boolean,
    inputLabel: string,
    disabled?: boolean,
    grow?: number,
    data: IInputData,
    inputMode?: 'text' | 'tel' | 'numeric',
    autoComplete?: 'off' | 'on',
    maxLength: number,
    onKeyDownFn?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

export interface IInputData {
    isValid: boolean| null;
    placeHolder?: string;
    input: string | number;
    isLabelVisible?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, IProps>((
    {
        type,
        id,
        handleChange,
        autoFocus = false,
        inputLabel,
        disabled = false,
        grow,
        data,
        inputMode = 'text',
        autoComplete,
        maxLength = 9,
        onKeyDownFn

    },
    ref
) => {
    const {
        isValid, placeHolder, input: value, isLabelVisible = true
    } = data;

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (onKeyDownFn) {
            onKeyDownFn(event);
        }
    };

    return (

        <>
            <InputComponent
                focusColor={isValid ? themeProvider.successColor : themeProvider.errorColor}
                grow={grow}
                isValid={isValid}
            >
                <input
                    className="input"
                    disabled={disabled}
                    autoFocus={autoFocus}
                    onChange={handleChange}
                    type={type}
                    value={value}
                    id={id}
                    name={id}
                    placeholder={placeHolder || ''}
                    inputMode={inputMode}
                    autoComplete={autoComplete}
                    maxLength={maxLength}
                    onKeyDown={(event) => handleKeyDown(event)}
                    ref={ref}
                />

                {isLabelVisible && (
                    <label
                        htmlFor={id}
                        className="input__label"
                    >
                        {inputLabel}
                    </label>
                )}
            </InputComponent>
        </>

    );
});

export default Input;
