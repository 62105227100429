import React, {
    FC, useEffect, useMemo, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './Style';
import { dataURItoBlob } from '../../../Utils/ResizeImage';
import Button from '../Button/Button';
import ArrowLeftPictureIcon from '../../Assets/icons/ic_arrow_left.svg';
import ClosePictureIcon from '../../Assets/icons/ic_close_white.svg';
import { themeProvider } from '../../Theme/ThemeProvider';
import Constants, { LOGGER_TAGS } from '../../../Utils/Constants';
import { IError } from '../../../Utils/Constants/Errors';
import CropImage from '../CropImage/CropImage';
import EventEmitter from '../../../Utils/EventEmitter';
import AdjustImageIcon from '../../Assets/icons/ic_crop.svg';
import MediaStreamConfig from '../../../Utils/MediaStream/MediaStream';
import ResultScreen from '../ResultScreen/ResultScreen';

interface ITakeDocumentPhotoWithCameraProps {
    captureTitle: string;
    validateTitle: string;
    instructionText: string;
    validateSubtitle?: string;
    onChangeFn: Function;
    onDismiss: Function;
}

const TakeDocumentPhotoWithCamera: FC<ITakeDocumentPhotoWithCameraProps> = ({
    captureTitle,
    validateTitle,
    instructionText,
    validateSubtitle,
    onChangeFn,
    onDismiss
}) => {
    const { t } = useTranslation();
    const videoRef = useRef<HTMLVideoElement>(null);
    const photoFrameRef = useRef<HTMLDivElement>(null);
    const [dataURI, setDataURI] = useState<string>('');
    const [hasPhotoTaken, setHasPhotoTaken] = useState(false);
    const [showEnlargeImage, setShowEnlargeImage] = useState(false);
    const [showCameraAccessError, setShowCameraAccessError] = useState<boolean>(false);
    const hiddenCanvas = document.createElement('canvas');
    const [errorCode, setErrorCode] = useState<number>(
        Constants.ERRORS.ERROR_CODES.CAMERA.NOT_ALLOWED
    );

    const errorMessage = useMemo((): IError => ({
        title: t((Constants.ERRORS.ERRORS_DESCRIPTION[errorCode] as IError).title),
        description: t((Constants.ERRORS.ERRORS_DESCRIPTION[errorCode] as IError).description)
    }), [errorCode]);

    const [imgSrc, setImgSrc] = useState<string>('');

    /* TODO: a possible future improvement is to have the w/h ratio
        of the capture box change according to the document type
    */

    useEffect(() => {
        getVideo();
        return () => {
            MediaStreamConfig.closeMediaStream();
        };
    }, [hasPhotoTaken, videoRef.current]);

    const getVideo = () => {
        setShowCameraAccessError(false);
        if (videoRef.current) {
            const videoEl: HTMLVideoElement = videoRef.current;
            // TODO: perhaps move the media access request to
            // TakeDocumentPhoto to be inline with the ux?
            // TODO: move these to config or constants
            askForCameraPermission(
                (mediaStream: MediaStream) => {
                    videoEl.srcObject = mediaStream;
                    videoEl.play();
                    setShowCameraAccessError(false);
                },
                (errorCode) => {
                    setErrorCode(errorCode);
                    setShowCameraAccessError(true);
                });
        }
    };

    const askForCameraPermission = (
        successCallback: (stream: MediaStream) => void,
        errorCallback: (codeError: number) => void
    ) => {
        const constraints = {
            audio: false,
            video: {
                width: { ideal: 1920 },
                height: { ideal: 1080 },
                facingMode: 'environment'
            }
        };
        MediaStreamConfig.loadMediaStream({ constraints, successCallback, errorCallback });
    };

    const resetPhoto = () => {
        const ctx = hiddenCanvas.getContext('2d');
        if (ctx) {
            ctx.canvas.hidden = false;
            hiddenCanvas.width = 1;
            hiddenCanvas.height = 1;
            ctx.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
            setDataURI('');
            setHasPhotoTaken(false);
            setImgSrc('');
        }
    };

    const takePhoto = () => {
        const video = videoRef.current;
        const photoC = photoFrameRef.current;

        if (video && photoC) {
            const ctx = hiddenCanvas.getContext('2d');
            if (ctx) {
                const rectP = photoC.getBoundingClientRect();
                const videoP = video.getBoundingClientRect();

                const { videoWidth: streamWidth, videoHeight: streamHeight } = video;

                const {
                    width: photoWidth,
                    height: photoHeight,
                    top: photoTop,
                    left: photoLeft
                } = rectP;

                const {
                    width: videoWidth,
                    height: videoHeight,
                    top: videoTop,
                    left: videoLeft
                } = videoP;

                const topOffset = photoTop - videoTop;
                const leftOffset = photoLeft - videoLeft;

                const originLeft = (streamWidth * leftOffset) / videoWidth;
                const originTop = (streamHeight * topOffset) / videoHeight;
                const originWidth = (streamWidth * photoWidth) / videoWidth;
                const originHeight = (streamHeight * photoHeight) / videoHeight;

                hiddenCanvas.width = originWidth;
                hiddenCanvas.height = originHeight;

                // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
                ctx.drawImage(
                    video,
                    originLeft,
                    originTop,
                    originWidth,
                    originHeight,
                    0,
                    0,
                    originWidth,
                    originHeight
                );

                setDataURI(hiddenCanvas.toDataURL('image/png'));
                setHasPhotoTaken(true);
            }
        }
    };

    const confirmPhoto = () => {
        if (dataURI) {
            const file = new File([dataURItoBlob(imgSrc)], 'document_photo', { type: 'image/png' });
            onChangeFn(file);
        }
    };

    return (
        <>
            {!showCameraAccessError && (
                <S.Container>
                    {hasPhotoTaken && dataURI && !imgSrc
                        ? (

                            <S.CropContainer>
                                <S.CloseButton
                                    icon={ArrowLeftPictureIcon}
                                    onClick={() => onDismiss()}
                                    id="dismiss-crop-button"
                                />
                                <div>
                                    <S.SideTitle>{t('takePhoto.adjustImage')}</S.SideTitle>
                                    <div>
                                        <CropImage
                                            imgSrc={dataURI}
                                            onSetCrop={setImgSrc}
                                        />
                                    </div>
                                    <S.SideSubTitle>{t('takePhoto.dragPhoto')}</S.SideSubTitle>
                                </div>
                                <S.CropControllers>
                                    <Button
                                        type="submit"
                                        mode="normal"
                                        action={() => {
                                            EventEmitter.dispatch(LOGGER_TAGS.CROP_AREA_HANDLER);
                                        }}
                                        id="confirm-cropped-area-button"
                                    >{t('general.continue')}
                                    </Button>
                                </S.CropControllers>
                            </S.CropContainer>
                        )
                        : (
                            <S.CroppedPhotoContainer>
                                <div className="title">
                                    <S.ValidateTitle>{validateTitle}</S.ValidateTitle>
                                </div>

                                <div>
                                    <div className="document-photo">
                                        <S.Photo src={imgSrc} alt="" />
                                        <S.EnlargeImageButton
                                            id="adjust-image-button"
                                            onClick={() => {
                                                setImgSrc('');
                                            }}
                                        >
                                            <img src={AdjustImageIcon} alt="Adjust" />
                                            <span> {t('takePhoto.adjustImage')}</span>
                                        </S.EnlargeImageButton>
                                    </div>
                                    <S.ValidateSubTitle>{validateSubtitle || t('takePhoto.validateSubTitle')}</S.ValidateSubTitle>
                                </div>
                                <S.Footer>

                                    <Button
                                        action={() => confirmPhoto()}
                                        mode="normal"
                                        id="submit-photo-button"
                                    >{t('takePhoto.submitPhoto')}
                                    </Button>

                                    <Button
                                        action={() => resetPhoto()}
                                        mode="flat"
                                        textColor={themeProvider.textColor}
                                        id="retake-photo-button"
                                    >{t('takePhoto.retakePhoto')}
                                    </Button>

                                </S.Footer>

                                {showEnlargeImage && dataURI && (
                                    <S.ConfirmImageContainer>
                                        <S.CloseButton
                                            icon={ClosePictureIcon}
                                            onClick={() => setShowEnlargeImage(false)}
                                        />
                                        <S.DocumentImagePreview src={dataURI} alt="Document Preview" />
                                        <div />
                                    </S.ConfirmImageContainer>
                                ) }

                            </S.CroppedPhotoContainer>
                        )}
                    {!hasPhotoTaken && !dataURI && (
                        <S.VideoContainer>
                            <S.CloseButton
                                icon={ArrowLeftPictureIcon}
                                onClick={() => onDismiss()}
                                id="dismiss-camera-button"
                            />
                            <S.Video playsInline muted autoPlay ref={videoRef} />
                            <S.FrameWrapper>
                                <S.SideTitle>{captureTitle}</S.SideTitle>
                                <S.PhotoFrame ref={photoFrameRef}>
                                    <div className="borders">
                                        <p />
                                    </div>
                                </S.PhotoFrame>
                                <S.SideSubTitle>{instructionText}</S.SideSubTitle>
                            </S.FrameWrapper>
                            <S.Controllers>
                                <S.TakePhotoButton id="take-photo-camera-button" onClick={() => takePhoto()} />
                            </S.Controllers>
                        </S.VideoContainer>
                    )}
                </S.Container>
            )}

            {showCameraAccessError && (
                <ResultScreen
                    title={errorMessage.title}
                    description={errorMessage.description}
                    actionText={t('general.tryAgain')}
                    actionFn={() => window.location.reload()}
                    showActionButton
                />
            )}

        </>
    );
};

export default React.memo(TakeDocumentPhotoWithCamera);
