import { LOGGER_TAGS } from '../../../Utils/Constants';
import EventEmitter from '../../../Utils/EventEmitter';
import Logger from '../../../Utils/Logger';
import { IInterceptor } from '../interceptors-types.ts';

class ExpiredSessionInterceptor implements IInterceptor {
    interceptorFn(): void {
        Logger.error(LOGGER_TAGS.ENCRYPTION_SERVICE, 'Cookie expired.');
        EventEmitter.dispatch(LOGGER_TAGS.SESSION_EXPIRED_EVENT);
    }
}

export default new ExpiredSessionInterceptor();
