import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

interface IProps {
    isInput?: boolean;
    labelColor?: string;
    isDesktop?: boolean;
}

const DropdownComponent = styled.ul<IProps>`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: ${({ isInput }) => (isInput ? Pixel2Rem(52) : Pixel2Rem(46))};
    right: ${({ isInput }) => (isInput ? 0 : Pixel2Rem(16))};
    width: ${({ isInput }) => (isInput ? '100%' : Pixel2Rem(208))};
    max-height: ${Pixel2Rem(270)};
    overflow-y: auto;
    font-family: ${themeProvider.fontFamily};
    padding-left: 0;
    z-index: ${({ isInput }) => (isInput ? '50' : '200')};
    background-color: ${themeProvider.backgroundColor};
    border-radius: ${Pixel2Rem(6)};
    box-shadow: rgb(0 0 0 / 26%) 0 ${Pixel2Rem(2)} ${Pixel2Rem(8)};
`;

export const ItemToggler = styled.div<IProps>`
    display: ${({ isInput }) => (isInput ? 'flex' : 'unset')};
    align-items: center;
    justify-content: space-between;
    width: ${({ isInput }) => (isInput ? 'fill-available' : 'unset')};
    font-family: ${themeProvider.fontFamily};
    outline: none;
    ${({ isDesktop }) => isDesktop && 'cursor: pointer'};

    .item__description {
        flex-grow: 1;
        color: ${({ labelColor }) => labelColor};
        font-size: ${Pixel2Rem(16)};
        letter-spacing: 0;
    }
`;

export default DropdownComponent;
