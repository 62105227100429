import { ConfirmationMethodsEnum, IAvailableConfirmationOptions } from './confirmation-method-types';
import { ReactComponent as UssdIcon } from '../../Assets/icons/ic_mobile.svg';
import { ReactComponent as SmsIcon } from '../../Assets/icons/ic_chat.svg';

const availableConfirmationMethods: IAvailableConfirmationOptions[] = [
    {
        icon: UssdIcon,
        type: ConfirmationMethodsEnum.USSD,
        isEnabled: true
    },
    {
        icon: SmsIcon,
        type: ConfirmationMethodsEnum.SMS,
        isEnabled: true
    }
];

export default availableConfirmationMethods;
