import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

interface IProps {
    borderColor?: string;
    isLabelVisible?: boolean
}

const CustomizedWrapperComponent = styled.div<IProps>`
    position: relative;
    display: flex;
    flex-grow: 0;
    align-items: center;
    border: ${Pixel2Rem(1)} solid;
    border-color: ${({ borderColor }) => borderColor};
    border-radius: ${Pixel2Rem(6)};
    padding: ${Pixel2Rem(15)} ${Pixel2Rem(12)};

    .wrapper__label {
        left: ${Pixel2Rem(9)};
        flex-grow: 0;
        position: absolute;
        top: ${Pixel2Rem(-9)};
        height: ${Pixel2Rem(19)};
        padding: 0 ${Pixel2Rem(3)};
        font-family: ${themeProvider.fontFamily};
        font-size: ${Pixel2Rem(14)};
        background-color: ${themeProvider.backgroundColor};
        color: ${({ borderColor }) => borderColor};
        display: ${({ isLabelVisible }) => (isLabelVisible ? 'block' : 'none')};
    }
`;

export default CustomizedWrapperComponent;
