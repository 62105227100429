/* eslint-disable max-len */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ValidateCustomerScreen from './Style';
import { ReactComponent as DialPadIcon } from '../../../../Assets/icons/ic_dial_pad.svg';
import Button from '../../../../Components/Button/Button';
import Timer from '../../../../Components/Timer/Timer';
import { LOGGER_TAGS } from '../../../../../Utils/Constants';
import EventEmitter from '../../../../../Utils/EventEmitter';
import { IFormStepProps } from '../../../PageWrapper/PageWrapper';
import FormSteps from '../../../../../Utils/Constants/FormSteps';
import { IOtpAvailabilityInformation, IPolingResultHandler } from './validate-customer.types';
import registrationManager from '../../../../../Business/RegistrationManager/AgentRegistrationManager';
import Loader from '../../../../Components/Loader/Loader';
import Modal from '../../../../Components/Modal/Modal';
import { ERRORS_DESCRIPTION, ERROR_CODES, IError } from '../../../../../Utils/Constants/Errors';
import ResultScreen from '../../../../Components/ResultScreen/ResultScreen';
import Logger from '../../../../../Utils/Logger';
import useStepContext from '../../../../Hooks/useStepContext';

const ValidateCustomer: FC<IFormStepProps> = ({ nextStepHandler }) => {
    const {
        timeLeftForExpiration,
        timeLeftForResend,
        reverseOtpCode,
        isBlocked
    } = registrationManager.getOtpAvailabilityInformation() || {
        timeLeftForExpiration: NaN,
        timeLeftForResend: NaN,
        reverseOtpCode: '____',
        isBlocked: false
    };
    const { t } = useTranslation();
    const { currentFormStep } = useStepContext();

    const initialOtpAvailabilityInformation: IOtpAvailabilityInformation = {
        otpExpiration: {
            isExpired: false,
            timeLeft: moment().add(timeLeftForExpiration, 's').unix()
        },
        otpResend: {
            canResend: false,
            isBlocked,
            timeLeft: moment().add(timeLeftForResend, 's').unix()
        }
    };
    const [
        { otpExpiration, otpResend },
        setOtpAvailabilityInformation
    ] = useState<IOtpAvailabilityInformation>(initialOtpAvailabilityInformation);
    const [isLoading, setIsLoading] = useState(false);
    const [{ hasError, title, description }, setError] = useState<IError>({
        hasError: currentFormStep?.PROPS?.errorCode,
        title: ERRORS_DESCRIPTION[currentFormStep?.PROPS?.errorCode || ERROR_CODES.GENERIC_ERROR].title,
        description: ERRORS_DESCRIPTION[currentFormStep?.PROPS?.errorCode || ERROR_CODES.GENERIC_ERROR].description
    });

    const codeExpirationHandler = () => {
        setOtpAvailabilityInformation((prev) => ({
            ...prev,
            otpExpiration: { ...prev.otpExpiration, isExpired: true },
            otpResend: { ...prev.otpResend, canResend: true }
        }));
    };

    const enableResendReverseOtpHandler = (fieldToUpdate: keyof Pick<IOtpAvailabilityInformation, 'otpResend'>['otpResend']) => {
        setOtpAvailabilityInformation((prev) => ({
            ...prev,
            otpResend: { ...prev.otpResend, [fieldToUpdate]: !prev.otpResend[fieldToUpdate] }
        }));
    };

    const sendOtpHandler = () => {
        setIsLoading(true);
        registrationManager.sendReverseOtp()
            .then(() => {
                registrationManager.abortPreviousPollingRequest();
                setOtpAvailabilityInformation({
                    otpExpiration: {
                        isExpired: false,
                        timeLeft: moment().add(timeLeftForExpiration, 's').unix()
                    },
                    otpResend: {
                        canResend: false,
                        timeLeft: moment().add(timeLeftForResend, 's').unix(),
                        isBlocked
                    }
                });
            })
            .catch((errorCode) => {
                setError({
                    hasError: true,
                    title: ERRORS_DESCRIPTION[errorCode]?.title || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title,
                    description: ERRORS_DESCRIPTION[errorCode]?.description || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description
                });
            })
            .finally(() => setIsLoading(false));
    };

    const handleCustomerPolingResult = ({ success, errorCode }: IPolingResultHandler) => {
        if (success) {
            nextStepHandler(FormSteps.VALIDATE_AGENT_PIN);
            return;
        }

        if (errorCode === ERROR_CODES.VALIDATE_REVERSE_OTP.INVALID) {
            Logger.error(LOGGER_TAGS.UI, 'Request dropped');
            return;
        }

        setError({
            hasError: true,
            title: ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title,
            description: ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description
        });
    };

    useEffect(() => {
        EventEmitter.subscribe(LOGGER_TAGS.HANDLE_POLING_RESULT, handleCustomerPolingResult);
        return () => {
            EventEmitter.unsubscribe(LOGGER_TAGS.HANDLE_POLING_RESULT, handleCustomerPolingResult);
            registrationManager.getController().abort();
        };
    }, []);

    return (
        <>
            {isLoading
                ? <Loader description={t('general.justAMoment')} />
                : isBlocked
                    ? (
                        <ResultScreen
                            title={t('spamGuard.errors.resourceBlockedTitle')}
                            description={t('spamGuard.errors.reverseOtpBlockedDescription')}
                            actionFn={sendOtpHandler}
                            actionText={(
                                <>
                                    {t('general.retry')}&nbsp;
                                    {(otpResend.isBlocked) && (
                                        <>(<Timer
                                            actionFn={() => enableResendReverseOtpHandler('isBlocked')}
                                            timeLeft={otpResend.timeLeft}
                                        />)
                                        </>
                                    )}
                                </>
                            )}
                            showActionButton
                            disableActionButton={otpResend.isBlocked}
                        />
                    )
                    : (
                        <ValidateCustomerScreen>
                            <div className="validate-customer">
                                <DialPadIcon className="validate-customer__icon" />
                                <div className="validate_customer__code">
                                    <span>{otpExpiration.isExpired ? '____' : reverseOtpCode}</span>
                                </div>
                                <div className="validate-customer__expiration-time">
                                    {otpExpiration.isExpired
                                        ? <span>{t('reverseOTP.agent.codeExpired')}</span>
                                        : (
                                            <>
                                                <span>{t('reverseOTP.agent.codeExpiration')}</span>&nbsp;
                                                <Timer timeLeft={otpExpiration.timeLeft} actionFn={codeExpirationHandler} />
                                            </>

                                        )}
                                </div>
                                <div className="validate-customer__instructions">
                                    <span>{otpExpiration.isExpired
                                        ? t('reverseOTP.agent.instructionsToRetry')
                                        : t('reverseOTP.agent.instructionsToProceed')}
                                    </span>
                                </div>
                            </div>
                            <div className="footer">
                                <Button
                                    id="continue-button"
                                    type="submit"
                                    mode="normal"
                                    disabled={!otpResend.canResend && !otpExpiration.isExpired} // INFO: Enables the button if either the code is expired or resend countdown has ended
                                    action={sendOtpHandler}
                                >
                                    <>
                                        {t('general.retry')}&nbsp;
                                        {(!otpResend.canResend) && !otpExpiration.isExpired && (
                                            <>(<Timer
                                                actionFn={() => enableResendReverseOtpHandler('canResend')}
                                                timeLeft={otpResend.timeLeft}
                                            />)
                                            </>
                                        ) }
                                    </>
                                </Button>
                            </div>
                        </ValidateCustomerScreen>
                    )}

            <Modal
                open={Boolean(hasError)}
                title={t(title)}
                description={t(description)}
                onClose={() => {
                    codeExpirationHandler();
                    setError((prev) => ({ ...prev, hasError: false }));
                }}
            />

        </>
    );
};

export default ValidateCustomer;
