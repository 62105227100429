const MARKET_CONFIGURATIONS = {
    LENGTHS: {
        SHORT_CODE: {
            MIN: 4,
            MAX: 12
        },
        PIN: {
            MIN: 4,
            MAX: 6
        },
        OPERATOR_ID: {
            MAX: 10,
            MIN: 4
        }
    }
};

export default MARKET_CONFIGURATIONS;
