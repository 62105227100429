abstract class DI {
    private static instancesMap: Map<string, Object> = new Map();

    static register(name: string, instance: Object) {
        if (DI.instancesMap.get(name)) {
            return;
        }

        DI.instancesMap.set(name, instance);
    }

    static get(name: string): any {
        return DI.instancesMap.get(name) || {};
    }
}

export default DI;
