import {
    FC, memo, useEffect, useState
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OnboardingScreen from './Style';
import Button from '../../Components/Button/Button';
import { ReactComponent as ValidateNumberIcon } from '../../Assets/icons/ic_dial_pad.svg';
import { ReactComponent as ScanDocumentIcon } from '../../Assets/icons/ic_scan_id.svg';
import { ReactComponent as FaceRecognitionIcon } from '../../Assets/icons/ic_face_recognition.svg';
import Constants, {
    APP_ROUTES,
    IS_AGENT_AIDED_FLOW_ENABLED,
    LOGGER_TAGS,
    MIN_REQUIRED_NR_OF_CAMERAS
} from '../../../Utils/Constants';
import { IFormStepProps } from '../PageWrapper/PageWrapper';
import EventEmitter from '../../../Utils/EventEmitter';
import DI from '../../../Utils/DI';
import Utils from '../../../Utils/Utils';
import useGaEventTracker from '../../Hooks/useGaEventTracker';
import Loader from '../../Components/Loader/Loader';
import MediaStreamConfig from '../../../Utils/MediaStream/MediaStream';
import ISessionManager from '../../../Business/ISessionManager';
import IOnboardingRequirement, { IInitRegistrationProps, IRegistrationType } from './onboarding-types';

const Onboarding: FC<IFormStepProps> = memo(({ nextStepHandler }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const sessionManager: ISessionManager = DI.get('SessionManager');
    const { screenTracker } = useGaEventTracker();
    const [isLoading, setIsLoading] = useState(false);

    const requirements: IOnboardingRequirement[] = [
        { icon: <ValidateNumberIcon />, description: 'requirement-1' },
        { icon: <ScanDocumentIcon />, description: 'requirement-2' },
        { icon: <FaceRecognitionIcon />, description: 'requirement-3' }
    ];

    const termsAndConditionsHandler = () => {
        history.push(APP_ROUTES.TERMS_AND_CONDITIONS);
        nextStepHandler({
            ...Constants.FORM_STEPS.TERMS_AND_CONDITIONS,
            DONT_SHOW_ICON: false,
            IS_NOT_VALIDATION_STEP: true,
            SHOW_LANGUAGES_DROPDOWN: false,
            STEP: 0
        });
    };

    const privacyPolicyHandler = () => {
        history.push(APP_ROUTES.PRIVACY_POLICY);
        nextStepHandler(Constants.FORM_STEPS.PRIVACY_POLICY);
    };

    const initSelfRegistration = ({ title, description }: IInitRegistrationProps) => {
        history.push(APP_ROUTES.REGISTRATION);
        nextStepHandler(Constants.FORM_STEPS.ENTER_PHONE_NUMBER);
        screenTracker({ screen: '1.ENTER_PHONE_NUMBER', screenClass: 'INITIALIZE_FLOW' });
    };

    const initAgentRegistration = () => {
        setIsLoading(true);
        sessionManager.validateSession()
            .then(() => initSelfRegistration({
                title: 'enterPhoneNumber.enterCustomerNumber',
                description: 'enterPhoneNumber.provideCustomerNumber'
            }))
            .catch(() => {
                // INFO: Indicates that the user is not logged in
                history.push(APP_ROUTES.LOGIN);
                nextStepHandler(Constants.FORM_STEPS.VALIDATE_AGENCY);
                screenTracker({ screen: '8.VALIDATE_AGENCY', screenClass: 'INITIALIZE_LOGIN' });
            })
            .finally(() => setIsLoading(false));
    };

    const initRegistrationHandler = (registrationType: IRegistrationType) => {
        if (MediaStreamConfig.getTotalVideoInputDevices() < MIN_REQUIRED_NR_OF_CAMERAS) {
            EventEmitter.dispatch(LOGGER_TAGS.NOT_ENOUGH_CAMERAS_EVENT);
            return;
        }

        switch (registrationType) {
        case 'SELF':
            initSelfRegistration({
                title: 'enterPhoneNumber.enterNumber',
                description: 'enterPhoneNumber.provideNumber'
            });
            break;

        case 'AGENT':
            initAgentRegistration();
            break;

        default:
            initSelfRegistration({
                title: 'enterPhoneNumber.enterNumber',
                description: 'enterPhoneNumber.provideNumber'
            });
        }
    };
    useEffect(() => {
        Utils.ScrollTo(0);
    }, []);

    return (
        <>
            {!isLoading && (
                <OnboardingScreen>
                    <div className="page-context">
                        <div className="page-context__title">
                            <span>
                                {t('homePage.actionCall')}
                                <span className="title__highlight"> M-PESA </span>
                                {t('general.account')}{t('general.premium')}
                            </span>
                        </div>

                        <div className="page-context__subtitle">
                            <span>{t('homePage.requirements')}</span>
                        </div>
                    </div>

                    <ul className="requirements">
                        {requirements.map((requirement) => (
                            <li
                                key={requirement.description}
                                className="requirement-item"
                            >
                                <div className="requirement-item__icon">
                                    {requirement.icon}
                                </div>
                                <div className="requirement-item__description">
                                    {t(`homePage.${requirement.description}`)}
                                </div>
                            </li>
                        ))}
                    </ul>

                    <div className="footer">
                        <div className="footer__button">
                            <Button
                                id="register-button"
                                action={() => initRegistrationHandler('SELF')}
                                mode="normal"
                            >{t('homePage.register')}
                            </Button>

                            {IS_AGENT_AIDED_FLOW_ENABLED && (
                                <div className="login">
                                    <span className="login__isAgent">{t('homePage.isAgent')}</span>
                                    <Button
                                        id="login-button"
                                        mode="flat"
                                        action={() => initRegistrationHandler('AGENT')}
                                    >{t('homePage.login')}
                                    </Button>
                                </div>
                            )}
                        </div>

                        <div className="terms-and-conditions">
                            <span>
                                {t('termsAndConditions.agreement')}
                                <span
                                    id="terms-and-conditions-link"
                                    className="terms-and-conditions__highlight"
                                    onClick={termsAndConditionsHandler}
                                >
                                    {t('screenLabels.termsAndConditions')}
                                </span>
                                and
                                <span id="privacy-policy-link" className="terms-and-conditions__highlight" onClick={privacyPolicyHandler}>
                                    {t('screenLabels.privacyPolicy')}
                                </span>
                            </span>
                        </div>
                    </div>
                </OnboardingScreen>
            )}

            {isLoading && <Loader description={t('general.justAMoment')} />}
        </>
    );
});

export default Onboarding;
