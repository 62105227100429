import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

interface IProps {
    distanceFromLabelToContent: number
}

const CardComponent = styled.div<IProps>`
    height: auto;
    padding: ${Pixel2Rem(13)} ${Pixel2Rem(16)};
    margin: ${Pixel2Rem(16)} 0;
    border-radius: ${Pixel2Rem(6)};
    box-shadow: 0 ${Pixel2Rem(2)} ${Pixel2Rem(8)} 0 ${themeProvider.cardShadow};
    background-color: ${themeProvider.backgroundColor};

    .card__label {
        height: ${Pixel2Rem(16)};
        margin-bottom: ${({ distanceFromLabelToContent }) => Pixel2Rem(distanceFromLabelToContent)};
        color: ${themeProvider.muted};
        font-family: ${themeProvider.fontFamily};
        font-size: ${Pixel2Rem(14)};
        font-weight: bold;
        letter-spacing: 0;
        line-height: ${Pixel2Rem(16)};
    }

`;

export default CardComponent;
