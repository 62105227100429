import React, { RefObject } from 'react';

export interface IClickAwayListenerProps {
  onClick: (e: any) => void;
  children: React.ReactElement;
}

function useOutsideClick(ref: RefObject<HTMLDivElement>, onClick: (e: MouseEvent) => void) {
    const handleClickOutside = (event: any) => {
        if (ref && ref.current && !ref.current.contains(event.target)) {
            onClick(event);
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);
    });
}

const ClickAwayListener = ({ onClick, children }: IClickAwayListenerProps) => {
    const wrapperRef = React.useRef<HTMLDivElement>(null);
    useOutsideClick(wrapperRef, onClick);
    return <>{React.cloneElement(children, { ref: wrapperRef })}</>;
};

export default ClickAwayListener;
