import IEncryptionService from '../../../Service/IEncryptionService';
import { LOGGER_TAGS } from '../../../Utils/Constants';
import DI from '../../../Utils/DI';
import EventEmitter from '../../../Utils/EventEmitter';
import { CookiesEnum } from '../../protocol-constants';
import { IInterceptor } from '../interceptors-types.ts';

class EncryptionCookieRenewalInterceptor implements IInterceptor {
    private _encryptionCookie = CookiesEnum.ENCRYPTION_COOKIE;

    interceptorFn(): Promise<any> {
        const encryptionService: IEncryptionService = DI.get('EncryptionService');

        return new Promise(async (resolve, reject) => {
            try {
                await encryptionService.initKeyChanges();
                resolve('');
            } catch (error) {
                EventEmitter.dispatch(LOGGER_TAGS.SESSION_EXPIRED_EVENT);
                reject(error);
            }
        });
    }

    hasCookieExpired(): boolean {
        const cookiesArray = document.cookie.split(';');

        for (let i = 0; i < cookiesArray.length; i++) {
            const cookiePair = cookiesArray[i].split('=');

            const [cookieKeyName, cookieValue] = cookiePair;

            if (cookieKeyName.trim() === this._encryptionCookie) {
                return cookieValue.trim() === '';
            }
        }

        return true;
    }
}

export default new EncryptionCookieRenewalInterceptor();
