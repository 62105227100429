import { FC, useState } from 'react';
import StarsRatingComponent from './Style';

interface IProps {
    handleRating: any;
    emptyFeedbackColor: string;
    averageFeedbackColor: string;
    satisfiedFeedbackColor: string;

}

const StarsRating:FC<IProps> = ({
    handleRating,
    emptyFeedbackColor,
    averageFeedbackColor,
    satisfiedFeedbackColor
}) => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    return (
        <>
            <StarsRatingComponent
                emptyFeedbackColor={emptyFeedbackColor}
                averageFeedbackColor={averageFeedbackColor}
                satisfiedFeedbackColor={satisfiedFeedbackColor}
            >
                {[...Array(5)].map((_, index) => {
                    index += 1;
                    return (
                        <div
                            key={index}
                            className={index <= (hover || rating) ? (rating >= 4) ? 'satisfied-star' : 'average-star' : 'empty-star'}
                            onClick={() => {
                                setRating(index);
                                handleRating(index);
                            }}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                        >
                            <span id={`rating-star-${index}`} className="star">&#9733;</span>
                        </div>
                    );
                })}
            </StarsRatingComponent>
        </>
    );
};

export default StarsRating;
