import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';

interface IProps {
  mode: 'flat' | 'normal',
  hasShadow?: boolean,
  textColor: string
}

const ButtonComponent = styled.button<IProps>`
  width:  ${({ mode }) => (mode === 'flat' ? 'unset' : '100%')};
  display:  ${({ mode }) => (mode === 'flat' ? 'inline' : 'flex')};
  height:  ${({ mode }) => (mode === 'flat' ? 'auto' : Pixel2Rem(44))};
  padding: ${({ mode }) => (mode === 'flat' ? '0' : 'auto')};
  font-weight: 500;
  letter-spacing: ${Pixel2Rem(0.5)};
  line-height: normal;
  text-align: center;
  border: none;
  border-radius: ${Pixel2Rem(6)};;
  outline: none;
  transition: .3s;
  color: ${({ mode, textColor }) => textColor || (mode === 'flat' ? themeProvider.primaryColor : themeProvider.backgroundColor)};
  background-color: ${({ mode }) => (mode === 'flat' ? themeProvider.backgroundColor : themeProvider.primaryColor)};
  cursor: pointer;
  box-shadow: ${({ hasShadow }) => (hasShadow
        ? `0 -${Pixel2Rem(10)} ${Pixel2Rem(15)} ${themeProvider.backgroundColor}, 0 ${Pixel2Rem(10)} ${Pixel2Rem(5)} transparent`
        : 'unset')};

  &:disabled, [disabled] {
    background-color: ${themeProvider.disableColor};
  }

  ${({ mode }) => mode === 'normal' && `
    justify-content: center;
    align-items: center;`
}
`;

export default ButtonComponent;
