import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import customerRegistrationManager from '../../../../Business/RegistrationManager/CustomerRegistrationManager';
import { REVERSE_OTP_NUM_OF_DIGITS } from '../../../../Utils/Constants';
import { ERRORS_DESCRIPTION, ERROR_CODES, IError } from '../../../../Utils/Constants/Errors';
import FormSteps from '../../../../Utils/Constants/FormSteps';
import Button from '../../../Components/Button/Button';
import Loader from '../../../Components/Loader/Loader';
import Modal from '../../../Components/Modal/Modal';
import OtpWrapper from '../../../Components/OtpWrapper/OtpWrapper';
import { IResultScreenProps } from '../../../Components/ResultScreen/result-screen-types';
import { IFormStepProps } from '../../PageWrapper/PageWrapper';
import IOtp from '../../ValidatePhoneNumber/validate-phone-number-types';
import ValidateReverseOtpScreen from './Style';

const ValidateReverseOtp: FC<IFormStepProps> = ({ nextStepHandler }) => {
    const { t } = useTranslation();
    const initialOtp: IOtp = Array(REVERSE_OTP_NUM_OF_DIGITS).fill('');

    const [otp, setOtp] = useState<IOtp>(initialOtp);
    const formIsInValid = otp.some((item) => item === '');

    const [isLoading, setIsLoading] = useState(false);
    const [{ hasError, description, title }, setError] = useState<IError>({
        hasError: false,
        title: ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title,
        description: ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description
    });
    const goToResultScreen = (resultScreenProps: IResultScreenProps) => nextStepHandler({
        ...FormSteps.CONFIRMATION,
        DONT_SHOW_ICON: true,
        PROPS: { ...resultScreenProps }
    });

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        const otpInput = otp.join('');
        setOtp(initialOtp);
        let resultScreenProps: IResultScreenProps;
        customerRegistrationManager.validateReverseOtp({
            otp: otpInput,
            uniqueIdentifier: customerRegistrationManager.getCustomerIRegistrationId()
        })
            .then(() => {
                resultScreenProps = {
                    title: t('reverseOTP.customer.registrationConfirmed'),
                    description: t('reverseOTP.customer.registrationConfirmedDescription'),
                    success: true
                };

                goToResultScreen(resultScreenProps);
            })
            .catch((errorCode) => {
                if (errorCode === ERROR_CODES.VALIDATE_REVERSE_OTP.INCORRECT) {
                    setError({
                        hasError: true,
                        title: ERRORS_DESCRIPTION[errorCode].title,
                        description: ERRORS_DESCRIPTION[errorCode].description
                    });
                    return;
                }

                resultScreenProps = {
                    title: t(ERRORS_DESCRIPTION[errorCode]?.title || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title),
                    description: t(ERRORS_DESCRIPTION[errorCode]?.description || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description),
                    fullscreen: true
                };

                goToResultScreen(resultScreenProps);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            {isLoading
                ? <Loader description={t('general.justAMoment')} />
                : (
                    <ValidateReverseOtpScreen onSubmit={handleSubmit}>
                        <div className="reverseOtp">
                            <span className="reverseOtp__title">{t('reverseOTP.customer.confirmRegistration')}</span>
                            <span className="reverseOtp__description">{t('reverseOTP.customer.enterOtp')}</span>
                            <div className="reverseOtp__otp-container">
                                <OtpWrapper disabled={hasError} otp={otp} setOtp={setOtp} allowLetters />
                            </div>

                        </div>
                        <div className="footer">
                            <Button
                                mode="normal"
                                id="confirm-otp-button"
                                action={handleSubmit}
                                disabled={formIsInValid}
                            >{t('general.confirm')}
                            </Button>
                        </div>
                    </ValidateReverseOtpScreen>
                )}
            <Modal
                open={Boolean(hasError)}
                title={t(title)}
                description={t(description)}
                onClose={() => setError((prev) => (({ ...prev, hasError: false })))}
            />
        </>
    );
};

export default ValidateReverseOtp;
