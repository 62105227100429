import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ISessionManager from '../../../../Business/ISessionManager';
import Constants from '../../../../Utils/Constants';
import { ERRORS_DESCRIPTION, ERROR_CODES } from '../../../../Utils/Constants/Errors';
import MARKET_CONFIGURATIONS from '../../../../Utils/Constants/MarketConfigurations';
import DI from '../../../../Utils/DI';
import Utils from '../../../../Utils/Utils';
import Button from '../../../Components/Button/Button';
import Input, { IInputData } from '../../../Components/Input/Input';
import Loader from '../../../Components/Loader/Loader';
import ResultScreen from '../../../Components/ResultScreen/ResultScreen';
import useGaEventTracker from '../../../Hooks/useGaEventTracker';
import { IFormStepProps } from '../../PageWrapper/PageWrapper';
import { ITempError } from '../agent-login.types';
import ValidateAgencyScreen from './Style';

const ValidateAgency: FC<IFormStepProps> = ({ nextStepHandler }) => {
    const { t } = useTranslation();
    const [shortCode, setShortCode] = useState<IInputData>({ isValid: true, input: '' });
    const sessionManager: ISessionManager = DI.get('SessionManager');
    const [error, setError] = useState<ITempError>({
        hasError: false,
        errorCode: NaN
    });
    const [isLoading, setIsLoading] = useState(false);

    const errorTitle = useMemo(() => ERRORS_DESCRIPTION[error.errorCode]?.title
        || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title, [error]);

    const errorDescription = useMemo(() => ERRORS_DESCRIPTION[error.errorCode]?.description
        || ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description, [error]);

    const isButtonDisabled: boolean = Utils.validateFieldLength(
        {
            value: shortCode.input,
            max: MARKET_CONFIGURATIONS.LENGTHS.SHORT_CODE.MAX,
            min: MARKET_CONFIGURATIONS.LENGTHS.SHORT_CODE.MIN
        }
    );
    const { screenTracker } = useGaEventTracker();

    const handleChange = (event: any) => {
        const { value } = event.target;

        if (
            value.length <= MARKET_CONFIGURATIONS.LENGTHS.SHORT_CODE.MAX
            && Number.isInteger((Number(value)))
        ) {
            setShortCode((prev) => ({
                ...prev,
                input: value
            }));
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        sessionManager.validateAgency({ receiverIdentifier: String(shortCode.input) })
            .then(() => {
                nextStepHandler(Constants.FORM_STEPS.VALIDATE_AGENT);
                screenTracker({ screen: '8.VALIDATE_AGENT', screenClass: 'INITIALIZE_LOGIN' });
            })
            .catch((errorCode) => {
                setIsLoading(false);
                setError((prev) => ({ ...prev, hasError: true, errorCode }));
            });
    };

    return (
        <>
            {!isLoading && !error.hasError && (
                <ValidateAgencyScreen onSubmit={handleSubmit}>
                    <div className="short-code-wrapper">
                        <h1 className="short-code__title">{t('agentLogin.getStarted')}</h1>
                        <span className="short-code-wrapper__description">{t('agentLogin.shortCode.enterShortCode')}</span>

                        <div className="short-code-wrapper__input">
                            <Input
                                data={shortCode}
                                handleChange={handleChange}
                                inputMode="numeric"
                                autoFocus
                                type="text"
                                id="msisdn"
                                inputLabel={t('agentLogin.shortCode.tillNumber')}
                                grow={1}
                                maxLength={MARKET_CONFIGURATIONS.LENGTHS.SHORT_CODE.MAX}
                            />
                        </div>

                    </div>

                    <div className="footer">
                        <div className="footer__button">
                            <Button
                                action={() => {}}
                                mode="normal"
                                type="submit"
                                disabled={!isButtonDisabled}
                                id="continue-button"
                            >{t('general.continue')}
                            </Button>
                        </div>
                    </div>
                </ValidateAgencyScreen>
            )}

            {isLoading && <Loader description={t('general.justAMoment')} />}

            {error.hasError && !isLoading && (
                <ResultScreen
                    title={t(`${errorTitle}`)}
                    description={t(`${errorDescription}`)}
                    actionText={t('general.tryAgain')}
                    actionFn={() => {
                        setShortCode((prev) => ({
                            ...prev,
                            input: ''
                        }));
                        setError((prev) => ({ ...prev, hasError: false }));
                    }}
                    showActionButton
                />
            )}
        </>
    );
};

export default ValidateAgency;
