import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationMethodScreen from './Style';
import { ReactComponent as ChevronRight } from '../../Assets/icons/ic_chevron_right.svg';
import { ConfirmationMethodsEnum } from './confirmation-method-types';
import availableConfirmationMethods from './confirmation-method-constants';
import useStepContext from '../../Hooks/useStepContext';
import FormSteps from '../../../Utils/Constants/FormSteps';
import { ERRORS_DESCRIPTION, ERROR_CODES, IError } from '../../../Utils/Constants/Errors';
import registrationManager from '../../../Business/RegistrationManager/AgentRegistrationManager';
import Loader from '../../Components/Loader/Loader';
import ResultScreen from '../../Components/ResultScreen/ResultScreen';
import Logger from '../../../Utils/Logger';
import { LOGGER_TAGS } from '../../../Utils/Constants';

const ConfirmationMethod: FC = () => {
    const { t } = useTranslation();
    const { currentFormStep, setCurrentFormStep: nextStepHandler } = useStepContext();
    const [isLoading, setIsLoading] = useState(false);
    const [{ hasError, title, description }, setError] = useState<IError>({ hasError: false, title: '', description: '' });

    const selectMethodHandler = async (option: ConfirmationMethodsEnum) => {
        setIsLoading(true);
        switch (option) {
        case ConfirmationMethodsEnum.SMS:
            registrationManager.abortPreviousPollingRequest();
            registrationManager.setController(new AbortController());
            try {
                await registrationManager.sendReverseOtp();
                nextStepHandler({
                    ...FormSteps.AGENT_REVERSE_OTP,
                    PROPS: { REVIEW_DOCUMENT: currentFormStep.PROPS.REVIEW_DOCUMENT }
                });
            } catch (error) {
                const errorCode = Number(error) || ERROR_CODES.GENERIC_ERROR;
                nextStepHandler({
                    ...FormSteps.AGENT_REVERSE_OTP,
                    PROPS: { REVIEW_DOCUMENT: currentFormStep.PROPS.REVIEW_DOCUMENT, errorCode }
                });
            }
            setIsLoading(false);
            break;

        case ConfirmationMethodsEnum.USSD:
            registrationManager.abortPreviousPollingRequest();
            registrationManager.setController(new AbortController());
            try {
                await registrationManager.sendUssdPush();
                nextStepHandler({
                    ...FormSteps.USSD_PUSH_VALIDATION,
                    PROPS: { REVIEW_DOCUMENT: currentFormStep.PROPS.REVIEW_DOCUMENT }
                });
            } catch (error) {
                const errorCode = Number(error) || ERROR_CODES.GENERIC_ERROR;
                nextStepHandler({
                    ...FormSteps.USSD_PUSH_VALIDATION,
                    PROPS: { REVIEW_DOCUMENT: currentFormStep.PROPS.REVIEW_DOCUMENT, errorCode }
                });
            }
            setIsLoading(false);
            break;

        default:
            Logger.error(LOGGER_TAGS.UI, 'Unexpected option provided', option);
            setError({
                hasError: true,
                title: ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].title,
                description: ERRORS_DESCRIPTION[ERROR_CODES.GENERIC_ERROR].description
            });
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            {!isLoading && !hasError && (
                <ConfirmationMethodScreen>
                    <h1 className="confirmation-method__title">{t('confirmationMethod.selectMethodTitle')}</h1>
                    <span className="confirmation-method__description">{t('confirmationMethod.selectMethodDescription')}</span>

                    <ul className="confirmation-options">
                        {availableConfirmationMethods.map((option, index) => (
                            option.isEnabled && (
                                <li onClick={() => selectMethodHandler(option.type)} key={index} className="confirmation-options-item">
                                    <div className="confirmation-options-item-wrapper">
                                        <option.icon className="confirmation-options-item__icon" />
                                        <div className="confirmation-item-content">
                                            <span className="confirmation-item-content__title">
                                                {t(`confirmationMethod.${option.type}OptionTitle`)}
                                            </span>
                                            <span className="confirmation-item-content__description">
                                                {t(`confirmationMethod.${option.type}OptionDescription`)}
                                            </span>
                                        </div>
                                    </div>
                                    <ChevronRight className="confirmation-options-item--indicator" />
                                </li>
                            )
                        ))}
                    </ul>
                </ConfirmationMethodScreen>
            )}
            {hasError && (
                <ResultScreen
                    title={t(title)}
                    description={t(description)}
                    actionText={t('general.tryAgain')}
                    actionFn={() => setError((prev) => ({ ...prev, hasError: false }))}
                    showActionButton
                />
            )}
        </>
    );
};

export default ConfirmationMethod;
