import styled from 'styled-components';
import Pixel2Rem from '../../../Utils/Pixel2Rem';
import { themeProvider } from '../../Theme/ThemeProvider';
import { IResultScreenComponentProps } from './result-screen-types';

const ResultScreenWrapper = styled.div<IResultScreenComponentProps>`
    display: flex;
    flex-direction: column;
    height: ${({ isFullscreen }) => (isFullscreen ? '100vh' : '100%')};
    width: ${({ isFullscreen }) => (isFullscreen ? '100vw' : '100%')};;
    justify-content: space-between;
    position: ${({ isFullscreen }) => (isFullscreen ? 'absolute' : 'static')};
    z-index: ${({ isFullscreen }) => (isFullscreen ? '105' : 'auto')};
    background-color: ${themeProvider.backgroundColor};
    inset: 0 auto auto 0;

    .result {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 auto;


        .result__icon {
            width: 100%;
            max-width: ${Pixel2Rem(120)};
            margin: 0 auto ${Pixel2Rem(22)} auto;
        }

        .result__title {
            margin: ${Pixel2Rem(10)};
            color: ${themeProvider.darkGrey};
            font-family: ${themeProvider.fontBold};
            text-align: center;
            font-size: ${Pixel2Rem(24)};
            line-height: ${Pixel2Rem(30)};
          }
      
          .result__description {
            padding: 0 ${Pixel2Rem(24)};
            margin: ${Pixel2Rem(10)};
      
            color: ${themeProvider.darkGrey};
            font-family: ${themeProvider.fontFamily};
            text-align: center;
            font-size: ${Pixel2Rem(16)};
            line-height: ${Pixel2Rem(22)};
          }
    }

    .footer {
        display: ${({ showActionButton }) => (showActionButton ? 'flex' : 'none')};
        flex-direction: column;
        height: ${Pixel2Rem(57)};
        align-items: center;
        ${({ isFullscreen, showActionButton }) => isFullscreen && showActionButton && `padding: 0 ${Pixel2Rem(24)}`};

        font-family: ${themeProvider.fontFamily};
        
    
        .footer__button {
          box-sizing: border-box;
          margin-bottom: ${Pixel2Rem(10)};
          width: 100%;
          padding: 0;
        }
    }
`;

export default ResultScreenWrapper;
