import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

interface IInterceptorsMap {
    subscribeInterceptor(interceptorKey: InterceptorsEnum, interceptor: IInterceptor): void;
    setCurrentInterceptor(response: AxiosResponse | AxiosError, interceptorType: InterceptorTypeEnum): void
}

export interface IInterceptor {
    interceptorFn(): void;
}

export interface ConnectionStatus{
    status: boolean;
}

export enum InterceptorsEnum {
    INTERNET_CONNECTION = 'InternetConnection',
    ENCRYPTION_SESSION_EXPIRED = 'EncryptionCookieExpired',
    ENCRYPTION_SESSION_RENEWAL = 'EncryptionSessionRenewal'
}

export enum InterceptorTypeEnum {
    RESPONSE = 1,
    REQUEST = 2
}

export type ResponseInterceptorDataType = AxiosResponse | AxiosError;

export type InterceptorDataType = ResponseInterceptorDataType | AxiosRequestConfig;

export default IInterceptorsMap;
