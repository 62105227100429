import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownItemComponent from './Style';
import { ReactComponent as CheckIcon } from '../../../Assets/icons/ic_check.svg';
import { IDropdownItem } from '../Dropdown';

interface IProps {
    item: IDropdownItem;
    selectedOption: IDropdownItem
}

const DropdownItem: FC<IProps> = ({ item, selectedOption }) => {
    const Component = item.icon;
    const { t } = useTranslation();
    return (
        <DropdownItemComponent>
            {Component && <Component className="item__icon" />}
            <div className="item__description">
                {t(item.description) || t(item.key)}
            </div>
            {item === selectedOption && (
                <CheckIcon className="item__selected-icon" />
            )}
        </DropdownItemComponent>
    );
};

export default DropdownItem;
