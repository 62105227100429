import { FC } from 'react';
import ModalComponent from './Style';
import { ReactComponent as CloseModalIcon } from '../../Assets/icons/ic_close_black.svg';
import { ReactComponent as ModalContextIcon } from '../../Assets/icons/ic_modal_error.svg';

export interface IModalAction {
    action: (() => void) | null,
    text: string | null
}
interface IProps {
    open: boolean;
    CloseIcon?: FC;
    ModalIcon?: FC;
    title: string;
    description: string;
    onClose: any,
    modalAction?: IModalAction;
    fullScreen?: boolean;
}
const Modal: FC<IProps> = ({
    open,
    CloseIcon = CloseModalIcon,
    ModalIcon = ModalContextIcon,
    title,
    description,
    onClose,
    modalAction,
    fullScreen = false
}) => (
    <>
        <ModalComponent open={open} fullScreen={fullScreen}>
            <div
                className="backdrop"
                onClick={onClose}
            />
            <div className="modal-view">
                <div className="modal-content">
                    <div className="modal-content__close-icon" onClick={onClose}>
                        <CloseIcon id="close-icon" />
                    </div>
                    <div className="modal-content-wrapper">
                        <div className="modal-content__icon">
                            <ModalIcon />
                        </div>
                        <div className="modal-content__title">
                            <span>{title}</span>
                        </div>
                        <div className="modal-content__description">
                            <span>{description}</span>
                            {modalAction
                            && (
                                <span
                                    id="modal-action"
                                    className="modal-content__action"
                                    onClick={() => modalAction?.action?.()}
                                >{modalAction?.text}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </ModalComponent>
    </>
);

export default Modal;
